import { ExampleComponent } from './components/Example';
import { Photos } from './components/Photos/Photos';
import { DropdownTest } from './components/DropdownTest';
import { Routes, Route, Link } from 'react-router-dom';
import { tl } from './translations';
import './fonts.less'

export function App() {
    return (
        <div>
            <h1>Hello, urban transport world!</h1>
            <Link to="/">{tl('main_page')}</Link>
            <br />
            <Link to="/photos">{tl('photos')}</Link>
            <Routes>
                <Route path="/" element={<DropdownTest />} />
                <Route path="/photos" element={<Photos />} />
                <Route path="/dropdown" element={<DropdownTest />} />
            </Routes>
        </div>
    );
}
