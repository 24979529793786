export const cities = [{
        "country": "AD",
        "name": "Sant Julià de Lòria",
        "lat": "42.46372",
        "lng": "1.49129"
    },
    {
        "country": "AD",
        "name": "Pas de la Casa",
        "lat": "42.54277",
        "lng": "1.73361"
    },
    {
        "country": "AD",
        "name": "Ordino",
        "lat": "42.55623",
        "lng": "1.53319"
    },
    {
        "country": "AD",
        "name": "les Escaldes",
        "lat": "42.50729",
        "lng": "1.53414"
    },
    {
        "country": "AD",
        "name": "la Massana",
        "lat": "42.54499",
        "lng": "1.51483"
    },
    {
        "country": "AD",
        "name": "Encamp",
        "lat": "42.53474",
        "lng": "1.58014"
    },
    {
        "country": "AD",
        "name": "Canillo",
        "lat": "42.5676",
        "lng": "1.59756"
    },
    {
        "country": "AD",
        "name": "Arinsal",
        "lat": "42.57205",
        "lng": "1.48453"
    },
    {
        "country": "AD",
        "name": "Andorra la Vella",
        "lat": "42.50779",
        "lng": "1.52109"
    },
    {
        "country": "AE",
        "name": "Umm al Qaywayn",
        "lat": "25.56473",
        "lng": "55.55517"
    },
    {
        "country": "AE",
        "name": "Ras al-Khaimah",
        "lat": "25.78953",
        "lng": "55.9432"
    },
    {
        "country": "AE",
        "name": "Muzayri‘",
        "lat": "23.14355",
        "lng": "53.7881"
    },
    {
        "country": "AE",
        "name": "Murbaḩ",
        "lat": "25.27623",
        "lng": "56.36256"
    },
    {
        "country": "AE",
        "name": "Khawr Fakkān",
        "lat": "25.33132",
        "lng": "56.34199"
    },
    {
        "country": "AE",
        "name": "Dubai",
        "lat": "25.0657",
        "lng": "55.17128"
    },
    {
        "country": "AE",
        "name": "Dibba Al-Fujairah",
        "lat": "25.59246",
        "lng": "56.26176"
    },
    {
        "country": "AE",
        "name": "Dibba Al-Hisn",
        "lat": "25.61955",
        "lng": "56.27291"
    },
    {
        "country": "AE",
        "name": "Sharjah",
        "lat": "25.33737",
        "lng": "55.41206"
    },
    {
        "country": "AE",
        "name": "Ar Ruways",
        "lat": "24.11028",
        "lng": "52.73056"
    },
    {
        "country": "AE",
        "name": "Al Fujayrah",
        "lat": "25.11641",
        "lng": "56.34141"
    },
    {
        "country": "AE",
        "name": "Al Ain",
        "lat": "24.19167",
        "lng": "55.76056"
    },
    {
        "country": "AE",
        "name": "Ajman",
        "lat": "25.41111",
        "lng": "55.43504"
    },
    {
        "country": "AE",
        "name": "Adh Dhayd",
        "lat": "25.28812",
        "lng": "55.88157"
    },
    {
        "country": "AE",
        "name": "Abu Dhabi",
        "lat": "24.46667",
        "lng": "54.36667"
    },
    {
        "country": "AF",
        "name": "Zorkot",
        "lat": "33.54149",
        "lng": "69.73446"
    },
    {
        "country": "AF",
        "name": "Wulêswālī Bihsūd",
        "lat": "34.3436",
        "lng": "67.90567"
    },
    {
        "country": "AF",
        "name": "Kuhsān",
        "lat": "34.65389",
        "lng": "61.19778"
    },
    {
        "country": "AF",
        "name": "Lāsh",
        "lat": "35.3782",
        "lng": "64.77457"
    },
    {
        "country": "AF",
        "name": "Tukzār",
        "lat": "35.94831",
        "lng": "66.42132"
    },
    {
        "country": "AF",
        "name": "Mīray",
        "lat": "33.32462",
        "lng": "68.44068"
    },
    {
        "country": "AF",
        "name": "Āq Kupruk",
        "lat": "36.08352",
        "lng": "66.84029"
    },
    {
        "country": "AF",
        "name": "Zurmat",
        "lat": "33.43778",
        "lng": "69.02774"
    },
    {
        "country": "AF",
        "name": "Zaybāk",
        "lat": "36.52947",
        "lng": "71.3441"
    },
    {
        "country": "AF",
        "name": "Zīārat-e Shāh Maqşūd",
        "lat": "31.9848",
        "lng": "65.4736"
    },
    {
        "country": "AF",
        "name": "Zindah Jān",
        "lat": "34.34264",
        "lng": "61.74675"
    },
    {
        "country": "AF",
        "name": "Zarghūn Shahr",
        "lat": "32.84734",
        "lng": "68.44573"
    },
    {
        "country": "AF",
        "name": "Zaṟah Sharan",
        "lat": "33.14641",
        "lng": "68.79213"
    },
    {
        "country": "AF",
        "name": "Zaranj",
        "lat": "30.95962",
        "lng": "61.86037"
    },
    {
        "country": "AF",
        "name": "Zamtō Kêlay",
        "lat": "32.3726",
        "lng": "66.17708"
    },
    {
        "country": "AF",
        "name": "Yangī Qal‘ah",
        "lat": "37.46572",
        "lng": "69.61131"
    },
    {
        "country": "AF",
        "name": "Yaḩyá Khēl",
        "lat": "32.93742",
        "lng": "68.64622"
    },
    {
        "country": "AF",
        "name": "Wāshēr",
        "lat": "32.25122",
        "lng": "63.85553"
    },
    {
        "country": "AF",
        "name": "Tōrmay",
        "lat": "33.68847",
        "lng": "68.40205"
    },
    {
        "country": "AF",
        "name": "Tūlak",
        "lat": "33.97509",
        "lng": "63.72868"
    },
    {
        "country": "AF",
        "name": "Tītān",
        "lat": "33.69032",
        "lng": "63.86361"
    },
    {
        "country": "AF",
        "name": "Tīr Pul",
        "lat": "34.59431",
        "lng": "61.26895"
    },
    {
        "country": "AF",
        "name": "Taywarah",
        "lat": "33.52118",
        "lng": "64.42116"
    },
    {
        "country": "AF",
        "name": "Bāzār-e Tashkān",
        "lat": "36.88168",
        "lng": "70.27674"
    },
    {
        "country": "AF",
        "name": "Tarinkot",
        "lat": "32.62998",
        "lng": "65.87806"
    },
    {
        "country": "AF",
        "name": "Taloqan",
        "lat": "36.73605",
        "lng": "69.53451"
    },
    {
        "country": "AF",
        "name": "Tagāw-Bāy",
        "lat": "35.69941",
        "lng": "66.06164"
    },
    {
        "country": "AF",
        "name": "Tagāb",
        "lat": "34.85501",
        "lng": "69.64917"
    },
    {
        "country": "AF",
        "name": "Markaz-e Ḩukūmat-e Sulţān-e Bakwāh",
        "lat": "32.24139",
        "lng": "62.94936"
    },
    {
        "country": "AF",
        "name": "Spīn Bōldak",
        "lat": "31.00575",
        "lng": "66.40001"
    },
    {
        "country": "AF",
        "name": "Spērah",
        "lat": "33.20204",
        "lng": "69.5152"
    },
    {
        "country": "AF",
        "name": "Sōzmah Qal‘ah",
        "lat": "36.09916",
        "lng": "66.20823"
    },
    {
        "country": "AF",
        "name": "Siyāhgird",
        "lat": "35.00553",
        "lng": "68.85578"
    },
    {
        "country": "AF",
        "name": "Sheywah",
        "lat": "34.57169",
        "lng": "70.58859"
    },
    {
        "country": "AF",
        "name": "Shīnḏanḏ",
        "lat": "33.30294",
        "lng": "62.1474"
    },
    {
        "country": "AF",
        "name": "Shaykh Amīr Kêlay",
        "lat": "33.28744",
        "lng": "69.91283"
    },
    {
        "country": "AF",
        "name": "Qāshqāl",
        "lat": "35.03975",
        "lng": "69.00685"
    },
    {
        "country": "AF",
        "name": "Shibirghān",
        "lat": "36.66757",
        "lng": "65.7529"
    },
    {
        "country": "AF",
        "name": "Shwāk",
        "lat": "33.42386",
        "lng": "69.37684"
    },
    {
        "country": "AF",
        "name": "Shahr-e Şafā",
        "lat": "31.80347",
        "lng": "66.32376"
    },
    {
        "country": "AF",
        "name": "Shahrān",
        "lat": "36.97984",
        "lng": "70.73928"
    },
    {
        "country": "AF",
        "name": "Shahrak",
        "lat": "34.10737",
        "lng": "64.3052"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Shāh Jōy",
        "lat": "32.52154",
        "lng": "67.41315"
    },
    {
        "country": "AF",
        "name": "Wulêswālī Sayyid Karam",
        "lat": "33.69056",
        "lng": "69.36881"
    },
    {
        "country": "AF",
        "name": "Markaz-e Sayyidābād",
        "lat": "34.00037",
        "lng": "68.71346"
    },
    {
        "country": "AF",
        "name": "Şayād",
        "lat": "36.13529",
        "lng": "65.8297"
    },
    {
        "country": "AF",
        "name": "Sidqābād",
        "lat": "35.02298",
        "lng": "69.35112"
    },
    {
        "country": "AF",
        "name": "Sāyagaz",
        "lat": "32.56521",
        "lng": "67.03324"
    },
    {
        "country": "AF",
        "name": "Sar-e Tayghān",
        "lat": "33.50998",
        "lng": "65.67632"
    },
    {
        "country": "AF",
        "name": "Sarōbī",
        "lat": "34.58962",
        "lng": "69.76005"
    },
    {
        "country": "AF",
        "name": "Sar Kāṉī",
        "lat": "34.79023",
        "lng": "71.10962"
    },
    {
        "country": "AF",
        "name": "Sarfirāz Kalā",
        "lat": "32.98333",
        "lng": "67.96517"
    },
    {
        "country": "AF",
        "name": "Sar-e Pul",
        "lat": "36.21544",
        "lng": "65.93249"
    },
    {
        "country": "AF",
        "name": "Sar Chakān",
        "lat": "35.70177",
        "lng": "65.23055"
    },
    {
        "country": "AF",
        "name": "Sangīn",
        "lat": "32.07275",
        "lng": "64.8359"
    },
    {
        "country": "AF",
        "name": "Sang-e Māshah",
        "lat": "33.1396",
        "lng": "67.44046"
    },
    {
        "country": "AF",
        "name": "Sang-e Chārak",
        "lat": "35.84972",
        "lng": "66.43694"
    },
    {
        "country": "AF",
        "name": "Sang Atesh",
        "lat": "35.248",
        "lng": "63.0044"
    },
    {
        "country": "AF",
        "name": "Sangar Sarāy",
        "lat": "34.40744",
        "lng": "70.63937"
    },
    {
        "country": "AF",
        "name": "Aībak",
        "lat": "36.26468",
        "lng": "68.01551"
    },
    {
        "country": "AF",
        "name": "Rū-ye Sang",
        "lat": "35.31999",
        "lng": "67.63387"
    },
    {
        "country": "AF",
        "name": "Rūdbār",
        "lat": "30.15",
        "lng": "62.6"
    },
    {
        "country": "AF",
        "name": "Rustāq",
        "lat": "37.12604",
        "lng": "69.83045"
    },
    {
        "country": "AF",
        "name": "Rabāţ-e Sangī-ye Pā’īn",
        "lat": "34.79951",
        "lng": "62.13917"
    },
    {
        "country": "AF",
        "name": "Rāmak",
        "lat": "33.52234",
        "lng": "68.6264"
    },
    {
        "country": "AF",
        "name": "Qurghān",
        "lat": "36.91939",
        "lng": "65.0649"
    },
    {
        "country": "AF",
        "name": "Quchanghī",
        "lat": "34.06183",
        "lng": "66.27801"
    },
    {
        "country": "AF",
        "name": "Sangalak-i-Kaisar",
        "lat": "35.68747",
        "lng": "64.29318"
    },
    {
        "country": "AF",
        "name": "Qarqīn",
        "lat": "37.41853",
        "lng": "66.04358"
    },
    {
        "country": "AF",
        "name": "Qarghah’ī",
        "lat": "34.55402",
        "lng": "70.24292"
    },
    {
        "country": "AF",
        "name": "Qarchī Gak",
        "lat": "37.03999",
        "lng": "66.78891"
    },
    {
        "country": "AF",
        "name": "Qarāwul",
        "lat": "37.21959",
        "lng": "68.7802"
    },
    {
        "country": "AF",
        "name": "Qarah Bāgh",
        "lat": "34.94023",
        "lng": "61.77589"
    },
    {
        "country": "AF",
        "name": "Qarah Bāgh",
        "lat": "33.19801",
        "lng": "68.10798"
    },
    {
        "country": "AF",
        "name": "Qala i Naw",
        "lat": "34.98735",
        "lng": "63.12891"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Kūf",
        "lat": "38.04119",
        "lng": "70.47298"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Kuhnah",
        "lat": "32.61591",
        "lng": "63.66681"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Shahr",
        "lat": "35.54729",
        "lng": "65.5676"
    },
    {
        "country": "AF",
        "name": "Qalāt",
        "lat": "32.10575",
        "lng": "66.90833"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Shāhī",
        "lat": "34.64921",
        "lng": "70.5914"
    },
    {
        "country": "AF",
        "name": "Qādis",
        "lat": "34.8085",
        "lng": "63.43003"
    },
    {
        "country": "AF",
        "name": "Pārūn",
        "lat": "35.42064",
        "lng": "70.92261"
    },
    {
        "country": "AF",
        "name": "Pul-e Khumrī",
        "lat": "35.94458",
        "lng": "68.71512"
    },
    {
        "country": "AF",
        "name": "Pul-e ‘Alam",
        "lat": "33.99529",
        "lng": "69.02274"
    },
    {
        "country": "AF",
        "name": "Pasnay",
        "lat": "35.76664",
        "lng": "65.76486"
    },
    {
        "country": "AF",
        "name": "Pāshmūl",
        "lat": "31.5572",
        "lng": "65.4343"
    },
    {
        "country": "AF",
        "name": "Pasāband",
        "lat": "33.68956",
        "lng": "64.8531"
    },
    {
        "country": "AF",
        "name": "Panjāb",
        "lat": "34.38795",
        "lng": "67.02327"
    },
    {
        "country": "AF",
        "name": "Paghmān",
        "lat": "34.58787",
        "lng": "68.95091"
    },
    {
        "country": "AF",
        "name": "Ōmnah",
        "lat": "32.90029",
        "lng": "68.79627"
    },
    {
        "country": "AF",
        "name": "Qaryeh-ye Owbeh",
        "lat": "34.36972",
        "lng": "63.17639"
    },
    {
        "country": "AF",
        "name": "Uruzgān",
        "lat": "32.92775",
        "lng": "66.63253"
    },
    {
        "country": "AF",
        "name": "Urgun",
        "lat": "32.9515",
        "lng": "69.1828"
    },
    {
        "country": "AF",
        "name": "Nūsay",
        "lat": "38.4435",
        "lng": "70.80413"
    },
    {
        "country": "AF",
        "name": "Nūrgal",
        "lat": "34.61341",
        "lng": "70.76498"
    },
    {
        "country": "AF",
        "name": "Now Zād",
        "lat": "32.40315",
        "lng": "64.46959"
    },
    {
        "country": "AF",
        "name": "Nīlī",
        "lat": "33.72178",
        "lng": "66.13023"
    },
    {
        "country": "AF",
        "name": "Nayak",
        "lat": "34.73414",
        "lng": "66.95292"
    },
    {
        "country": "AF",
        "name": "Nāyak",
        "lat": "32.5723",
        "lng": "66.77403"
    },
    {
        "country": "AF",
        "name": "Now Dahānak",
        "lat": "35.62398",
        "lng": "70.00511"
    },
    {
        "country": "AF",
        "name": "Ōkak",
        "lat": "33.89793",
        "lng": "67.95652"
    },
    {
        "country": "AF",
        "name": "Nāṟay",
        "lat": "35.21995",
        "lng": "71.52284"
    },
    {
        "country": "AF",
        "name": "Narang",
        "lat": "34.76009",
        "lng": "71.04738"
    },
    {
        "country": "AF",
        "name": "Nīkêh",
        "lat": "33.18934",
        "lng": "69.27179"
    },
    {
        "country": "AF",
        "name": "Nahrīn",
        "lat": "36.0649",
        "lng": "69.13343"
    },
    {
        "country": "AF",
        "name": "Ḩukūmat-e Nād ‘Alī",
        "lat": "31.64286",
        "lng": "64.23982"
    },
    {
        "country": "AF",
        "name": "Mūsá Qal‘ah",
        "lat": "32.44565",
        "lng": "64.74486"
    },
    {
        "country": "AF",
        "name": "Bala Murghab",
        "lat": "35.58408",
        "lng": "63.32886"
    },
    {
        "country": "AF",
        "name": "Muqêr",
        "lat": "32.82009",
        "lng": "67.76934"
    },
    {
        "country": "AF",
        "name": "Muḩammad Āghah Wuluswālī",
        "lat": "34.21422",
        "lng": "69.10026"
    },
    {
        "country": "AF",
        "name": "Mīzān ‘Alāqahdārī",
        "lat": "32.17635",
        "lng": "66.51271"
    },
    {
        "country": "AF",
        "name": "Mīr Bachah Kōṯ",
        "lat": "34.74999",
        "lng": "69.11899"
    },
    {
        "country": "AF",
        "name": "Mīrān",
        "lat": "34.22037",
        "lng": "68.31259"
    },
    {
        "country": "AF",
        "name": "Mīrābād",
        "lat": "30.43624",
        "lng": "61.8383"
    },
    {
        "country": "AF",
        "name": "Maymana",
        "lat": "35.92139",
        "lng": "64.78361"
    },
    {
        "country": "AF",
        "name": "Maīdān Khūlah",
        "lat": "33.64916",
        "lng": "69.7812"
    },
    {
        "country": "AF",
        "name": "Mingajik",
        "lat": "37.0279",
        "lng": "66.12817"
    },
    {
        "country": "AF",
        "name": "Mehtar Lām",
        "lat": "34.67139",
        "lng": "70.20944"
    },
    {
        "country": "AF",
        "name": "Mazār-e Sharīf",
        "lat": "36.70904",
        "lng": "67.11087"
    },
    {
        "country": "AF",
        "name": "Māymay",
        "lat": "38.40856",
        "lng": "71.03839"
    },
    {
        "country": "AF",
        "name": "Mutā Khān",
        "lat": "33.24001",
        "lng": "68.8663"
    },
    {
        "country": "AF",
        "name": "Mashhad",
        "lat": "36.81665",
        "lng": "70.10211"
    },
    {
        "country": "AF",
        "name": "Mardīān",
        "lat": "36.99252",
        "lng": "66.29654"
    },
    {
        "country": "AF",
        "name": "Mandōl",
        "lat": "35.28403",
        "lng": "70.17017"
    },
    {
        "country": "AF",
        "name": "Māmā Khēl",
        "lat": "34.24494",
        "lng": "69.9968"
    },
    {
        "country": "AF",
        "name": "Lashkar Gāh",
        "lat": "31.59382",
        "lng": "64.37161"
    },
    {
        "country": "AF",
        "name": "Lāsh-e Juwayn",
        "lat": "31.71382",
        "lng": "61.62272"
    },
    {
        "country": "AF",
        "name": "Larkird",
        "lat": "35.48936",
        "lng": "66.66409"
    },
    {
        "country": "AF",
        "name": "La‘l",
        "lat": "34.50141",
        "lng": "66.27997"
    },
    {
        "country": "AF",
        "name": "Kushk",
        "lat": "33.29565",
        "lng": "61.95221"
    },
    {
        "country": "AF",
        "name": "Kōṯowāl",
        "lat": "34.10319",
        "lng": "70.46073"
    },
    {
        "country": "AF",
        "name": "Kushk-e Kuhnah",
        "lat": "34.8711",
        "lng": "62.54762"
    },
    {
        "country": "AF",
        "name": "Kuran wa Munjan",
        "lat": "36.02866",
        "lng": "70.77258"
    },
    {
        "country": "AF",
        "name": "Kunduz",
        "lat": "36.72895",
        "lng": "68.857"
    },
    {
        "country": "AF",
        "name": "Khōshī",
        "lat": "33.99916",
        "lng": "69.21582"
    },
    {
        "country": "AF",
        "name": "Khōshāmand",
        "lat": "32.68567",
        "lng": "68.23801"
    },
    {
        "country": "AF",
        "name": "Khwājah Ghār",
        "lat": "37.06588",
        "lng": "69.42126"
    },
    {
        "country": "AF",
        "name": "Khwājah Dū Kōh",
        "lat": "36.82881",
        "lng": "65.62432"
    },
    {
        "country": "AF",
        "name": "Deh Khwāhān",
        "lat": "37.88965",
        "lng": "70.21875"
    },
    {
        "country": "AF",
        "name": "Khulbisāt",
        "lat": "33.4948",
        "lng": "70.00605"
    },
    {
        "country": "AF",
        "name": "Khūgyāṉī",
        "lat": "31.56267",
        "lng": "66.5814"
    },
    {
        "country": "AF",
        "name": "Khōst",
        "lat": "33.33951",
        "lng": "69.92041"
    },
    {
        "country": "AF",
        "name": "Khulm",
        "lat": "36.69736",
        "lng": "67.69826"
    },
    {
        "country": "AF",
        "name": "Khudāydād Khēl",
        "lat": "35.17835",
        "lng": "67.68758"
    },
    {
        "country": "AF",
        "name": "Khinjān",
        "lat": "35.58908",
        "lng": "68.90093"
    },
    {
        "country": "AF",
        "name": "Khinj",
        "lat": "35.42898",
        "lng": "69.73482"
    },
    {
        "country": "AF",
        "name": "Khāsh",
        "lat": "31.52919",
        "lng": "62.79055"
    },
    {
        "country": "AF",
        "name": "Khān Neshīn",
        "lat": "30.5488",
        "lng": "63.7888"
    },
    {
        "country": "AF",
        "name": "Khānaqāh",
        "lat": "36.62587",
        "lng": "69.53695"
    },
    {
        "country": "AF",
        "name": "Chahār Bāgh",
        "lat": "37.00108",
        "lng": "65.22392"
    },
    {
        "country": "AF",
        "name": "Khandūd",
        "lat": "36.95127",
        "lng": "72.318"
    },
    {
        "country": "AF",
        "name": "Khānaqāh",
        "lat": "36.86279",
        "lng": "66.16656"
    },
    {
        "country": "AF",
        "name": "Khanabad",
        "lat": "36.6825",
        "lng": "69.11556"
    },
    {
        "country": "AF",
        "name": "Khamyāb",
        "lat": "37.5275",
        "lng": "65.75833"
    },
    {
        "country": "AF",
        "name": "Khākirān",
        "lat": "32.8385",
        "lng": "67.07377"
    },
    {
        "country": "AF",
        "name": "Kaz̲h̲ah",
        "lat": "34.24088",
        "lng": "70.18274"
    },
    {
        "country": "AF",
        "name": "Kishk-e Nakhūd",
        "lat": "31.62519",
        "lng": "65.05511"
    },
    {
        "country": "AF",
        "name": "Karukh",
        "lat": "34.48108",
        "lng": "62.5863"
    },
    {
        "country": "AF",
        "name": "Kanḏay",
        "lat": "34.9536",
        "lng": "70.77897"
    },
    {
        "country": "AF",
        "name": "Kandahār",
        "lat": "31.61332",
        "lng": "65.71013"
    },
    {
        "country": "AF",
        "name": "Kalān Deh",
        "lat": "33.83676",
        "lng": "68.40233"
    },
    {
        "country": "AF",
        "name": "Kalakān",
        "lat": "34.78371",
        "lng": "69.15001"
    },
    {
        "country": "AF",
        "name": "Kalafgān",
        "lat": "36.77226",
        "lng": "69.94489"
    },
    {
        "country": "AF",
        "name": "Kajrān",
        "lat": "33.20357",
        "lng": "65.47311"
    },
    {
        "country": "AF",
        "name": "Kai",
        "lat": "34.12819",
        "lng": "70.71828"
    },
    {
        "country": "AF",
        "name": "Kabul",
        "lat": "34.52813",
        "lng": "69.17233"
    },
    {
        "country": "AF",
        "name": "Jurm",
        "lat": "36.86477",
        "lng": "70.83421"
    },
    {
        "country": "AF",
        "name": "Jawand",
        "lat": "35.06361",
        "lng": "64.14917"
    },
    {
        "country": "AF",
        "name": "Jānī Khēl",
        "lat": "32.76315",
        "lng": "68.39722"
    },
    {
        "country": "AF",
        "name": "Jalrēz",
        "lat": "34.4708",
        "lng": "68.65438"
    },
    {
        "country": "AF",
        "name": "Jalālābād",
        "lat": "34.42647",
        "lng": "70.45153"
    },
    {
        "country": "AF",
        "name": "Jabal os Saraj",
        "lat": "35.11833",
        "lng": "69.23778"
    },
    {
        "country": "AF",
        "name": "Ḩukūmat-e Shīnkaī",
        "lat": "31.98303",
        "lng": "67.33558"
    },
    {
        "country": "AF",
        "name": "Herāt",
        "lat": "34.34817",
        "lng": "62.19967"
    },
    {
        "country": "AF",
        "name": "Ḩājī Khēl",
        "lat": "35.10922",
        "lng": "69.43341"
    },
    {
        "country": "AF",
        "name": "Ḩāfiz̧ Moghul",
        "lat": "37.05643",
        "lng": "70.40061"
    },
    {
        "country": "AF",
        "name": "Khafizan",
        "lat": "34.28665",
        "lng": "70.37247"
    },
    {
        "country": "AF",
        "name": "Guz̄arah",
        "lat": "34.21223",
        "lng": "62.21235"
    },
    {
        "country": "AF",
        "name": "Gōshtah",
        "lat": "34.35822",
        "lng": "70.76264"
    },
    {
        "country": "AF",
        "name": "Gōmal Kêlay",
        "lat": "32.50989",
        "lng": "68.85598"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Gēlān",
        "lat": "32.72692",
        "lng": "67.63696"
    },
    {
        "country": "AF",
        "name": "Ghōriyān",
        "lat": "34.3448",
        "lng": "61.49321"
    },
    {
        "country": "AF",
        "name": "Ghormach",
        "lat": "35.73062",
        "lng": "63.78264"
    },
    {
        "country": "AF",
        "name": "Ghurayd Gharamē",
        "lat": "36.5222",
        "lng": "70.79063"
    },
    {
        "country": "AF",
        "name": "Ghazni",
        "lat": "33.55391",
        "lng": "68.42096"
    },
    {
        "country": "AF",
        "name": "Gereshk",
        "lat": "31.82089",
        "lng": "64.57005"
    },
    {
        "country": "AF",
        "name": "Gardez",
        "lat": "33.59744",
        "lng": "69.22592"
    },
    {
        "country": "AF",
        "name": "Fayzabad",
        "lat": "37.11664",
        "lng": "70.58002"
    },
    {
        "country": "AF",
        "name": "Faīẕābād",
        "lat": "36.82091",
        "lng": "66.45921"
    },
    {
        "country": "AF",
        "name": "Fayẕābād",
        "lat": "36.29111",
        "lng": "64.86222"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Fārsī",
        "lat": "33.78529",
        "lng": "63.24735"
    },
    {
        "country": "AF",
        "name": "Farkhār",
        "lat": "36.57288",
        "lng": "69.85783"
    },
    {
        "country": "AF",
        "name": "Farah",
        "lat": "32.37451",
        "lng": "62.11638"
    },
    {
        "country": "AF",
        "name": "Istālif",
        "lat": "34.83268",
        "lng": "69.07756"
    },
    {
        "country": "AF",
        "name": "Kafir Qala",
        "lat": "34.66667",
        "lng": "61.06667"
    },
    {
        "country": "AF",
        "name": "Injīl",
        "lat": "34.30157",
        "lng": "62.2465"
    },
    {
        "country": "AF",
        "name": "Imām Şāḩib",
        "lat": "37.18897",
        "lng": "68.93644"
    },
    {
        "country": "AF",
        "name": "Dōshī",
        "lat": "35.60918",
        "lng": "68.68473"
    },
    {
        "country": "AF",
        "name": "Dowlatyār",
        "lat": "34.55132",
        "lng": "65.79302"
    },
    {
        "country": "AF",
        "name": "Dowlat Shāh",
        "lat": "34.9504",
        "lng": "70.07102"
    },
    {
        "country": "AF",
        "name": "Dowlatābād",
        "lat": "36.98821",
        "lng": "66.82069"
    },
    {
        "country": "AF",
        "name": "Dowlatābād",
        "lat": "36.43259",
        "lng": "64.92071"
    },
    {
        "country": "AF",
        "name": "Dū Qal‘ah",
        "lat": "32.14237",
        "lng": "61.4469"
    },
    {
        "country": "AF",
        "name": "Dūāb",
        "lat": "35.56277",
        "lng": "67.82191"
    },
    {
        "country": "AF",
        "name": "Dê Nārkhēl Kêlay",
        "lat": "32.43205",
        "lng": "65.63484"
    },
    {
        "country": "AF",
        "name": "Dehī",
        "lat": "35.95806",
        "lng": "67.27998"
    },
    {
        "country": "AF",
        "name": "Deh-e Şalāḩ",
        "lat": "35.69031",
        "lng": "69.31511"
    },
    {
        "country": "AF",
        "name": "Deh-e Now",
        "lat": "34.74767",
        "lng": "69.04784"
    },
    {
        "country": "AF",
        "name": "Dehdādī",
        "lat": "36.66319",
        "lng": "66.99339"
    },
    {
        "country": "AF",
        "name": "Dwah Manḏay",
        "lat": "33.28159",
        "lng": "69.58531"
    },
    {
        "country": "AF",
        "name": "Dasht-e Qal‘ah",
        "lat": "37.1539",
        "lng": "69.4435"
    },
    {
        "country": "AF",
        "name": "Dasht-e Qal‘ah",
        "lat": "33.02978",
        "lng": "63.8395"
    },
    {
        "country": "AF",
        "name": "Dasht-e Archī",
        "lat": "37.13333",
        "lng": "69.16667"
    },
    {
        "country": "AF",
        "name": "Darzāb",
        "lat": "35.97744",
        "lng": "65.37828"
    },
    {
        "country": "AF",
        "name": "Markaz-e Ḩukūmat-e Darwēshān",
        "lat": "31.13231",
        "lng": "64.1934"
    },
    {
        "country": "AF",
        "name": "Darqad",
        "lat": "37.38414",
        "lng": "69.45294"
    },
    {
        "country": "AF",
        "name": "Darāyim",
        "lat": "36.86667",
        "lng": "70.38333"
    },
    {
        "country": "AF",
        "name": "Dāngām",
        "lat": "34.99249",
        "lng": "71.41935"
    },
    {
        "country": "AF",
        "name": "Ḏanḏar",
        "lat": "34.79095",
        "lng": "69.47504"
    },
    {
        "country": "AF",
        "name": "Ḩukūmatī Dahanah-ye Ghōrī",
        "lat": "35.90617",
        "lng": "68.48869"
    },
    {
        "country": "AF",
        "name": "Tsowkêy",
        "lat": "34.69246",
        "lng": "70.92688"
    },
    {
        "country": "AF",
        "name": "Chīras",
        "lat": "35.41674",
        "lng": "65.98234"
    },
    {
        "country": "AF",
        "name": "Chisht-e Sharīf",
        "lat": "34.34731",
        "lng": "63.73966"
    },
    {
        "country": "AF",
        "name": "Chinār",
        "lat": "32.01583",
        "lng": "65.10721"
    },
    {
        "country": "AF",
        "name": "Chimtāl",
        "lat": "36.67818",
        "lng": "66.80325"
    },
    {
        "country": "AF",
        "name": "Charkh",
        "lat": "33.79712",
        "lng": "68.93749"
    },
    {
        "country": "AF",
        "name": "Charikar",
        "lat": "35.01361",
        "lng": "69.17139"
    },
    {
        "country": "AF",
        "name": "Dowr-e Rabāţ",
        "lat": "36.69132",
        "lng": "68.79093"
    },
    {
        "country": "AF",
        "name": "Tsapêraī",
        "lat": "33.83003",
        "lng": "69.9192"
    },
    {
        "country": "AF",
        "name": "Tsamkanī",
        "lat": "33.80298",
        "lng": "69.81671"
    },
    {
        "country": "AF",
        "name": "Chakaray",
        "lat": "34.34099",
        "lng": "69.4377"
    },
    {
        "country": "AF",
        "name": "Chākarān",
        "lat": "36.91112",
        "lng": "71.0688"
    },
    {
        "country": "AF",
        "name": "Chahār Qal‘ah",
        "lat": "33.55267",
        "lng": "68.29828"
    },
    {
        "country": "AF",
        "name": "Chahār Burj",
        "lat": "34.24475",
        "lng": "62.19165"
    },
    {
        "country": "AF",
        "name": "Chāh Āb",
        "lat": "37.39773",
        "lng": "69.81464"
    },
    {
        "country": "AF",
        "name": "Fayrōz Kōh",
        "lat": "34.51952",
        "lng": "65.25093"
    },
    {
        "country": "AF",
        "name": "Bulōlah",
        "lat": "34.87706",
        "lng": "68.0879"
    },
    {
        "country": "AF",
        "name": "Bal Chirāgh",
        "lat": "35.84026",
        "lng": "65.2309"
    },
    {
        "country": "AF",
        "name": "Bāzār-e Tālah",
        "lat": "35.39205",
        "lng": "68.22317"
    },
    {
        "country": "AF",
        "name": "Bāsawul",
        "lat": "34.24749",
        "lng": "70.87218"
    },
    {
        "country": "AF",
        "name": "Būrkah",
        "lat": "36.22242",
        "lng": "69.15037"
    },
    {
        "country": "AF",
        "name": "Barg-e Matāl",
        "lat": "35.67283",
        "lng": "71.34339"
    },
    {
        "country": "AF",
        "name": "Baraki Barak",
        "lat": "33.96744",
        "lng": "68.9492"
    },
    {
        "country": "AF",
        "name": "Banū",
        "lat": "35.63348",
        "lng": "69.26019"
    },
    {
        "country": "AF",
        "name": "Bāmyān",
        "lat": "34.82156",
        "lng": "67.82734"
    },
    {
        "country": "AF",
        "name": "Balkh",
        "lat": "36.75635",
        "lng": "66.8972"
    },
    {
        "country": "AF",
        "name": "Bahārak",
        "lat": "37.00254",
        "lng": "70.90664"
    },
    {
        "country": "AF",
        "name": "Bagrāmī",
        "lat": "34.49376",
        "lng": "69.27427"
    },
    {
        "country": "AF",
        "name": "Baghlān",
        "lat": "36.13068",
        "lng": "68.70829"
    },
    {
        "country": "AF",
        "name": "Ḩukūmatī Azrah",
        "lat": "34.17355",
        "lng": "69.64573"
    },
    {
        "country": "AF",
        "name": "Ārt Khwājah",
        "lat": "37.08571",
        "lng": "69.47958"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Aṯghar",
        "lat": "31.73506",
        "lng": "67.3574"
    },
    {
        "country": "AF",
        "name": "Āsmār",
        "lat": "35.03333",
        "lng": "71.35809"
    },
    {
        "country": "AF",
        "name": "Ashkāsham",
        "lat": "36.68333",
        "lng": "71.53333"
    },
    {
        "country": "AF",
        "name": "Asadābād",
        "lat": "34.87311",
        "lng": "71.14697"
    },
    {
        "country": "AF",
        "name": "Āqchah",
        "lat": "36.905",
        "lng": "66.18341"
    },
    {
        "country": "AF",
        "name": "Andkhōy",
        "lat": "36.95293",
        "lng": "65.12376"
    },
    {
        "country": "AF",
        "name": "’Unābah",
        "lat": "35.23251",
        "lng": "69.37719"
    },
    {
        "country": "AF",
        "name": "Anār Darah",
        "lat": "32.7587",
        "lng": "61.65397"
    },
    {
        "country": "AF",
        "name": "Amānzī",
        "lat": "30.21144",
        "lng": "66.04765"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī-ye Almār",
        "lat": "35.84616",
        "lng": "64.53074"
    },
    {
        "country": "AF",
        "name": "‘Alī Shēr ‘Alāqahdārī",
        "lat": "33.4362",
        "lng": "70.06711"
    },
    {
        "country": "AF",
        "name": "Wuluswālī ‘Alīngār",
        "lat": "34.83529",
        "lng": "70.3593"
    },
    {
        "country": "AF",
        "name": "‘Alī Khēl",
        "lat": "33.94253",
        "lng": "69.71908"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Yōsuf Khēl",
        "lat": "33.05195",
        "lng": "68.65"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Dīshū",
        "lat": "30.43206",
        "lng": "63.29802"
    },
    {
        "country": "AF",
        "name": "Alah Sāy",
        "lat": "34.8965",
        "lng": "69.72049"
    },
    {
        "country": "AF",
        "name": "Pachīr wa Āgām",
        "lat": "34.20011",
        "lng": "70.27806"
    },
    {
        "country": "AF",
        "name": "Afaki",
        "lat": "36.66172",
        "lng": "69.35095"
    },
    {
        "country": "AF",
        "name": "Adraskan",
        "lat": "33.64573",
        "lng": "62.26959"
    },
    {
        "country": "AF",
        "name": "Āb-e Kamarī",
        "lat": "35.08796",
        "lng": "63.0678"
    },
    {
        "country": "AF",
        "name": "Khadīr",
        "lat": "33.92232",
        "lng": "65.93398"
    },
    {
        "country": "AF",
        "name": "Ghulām ‘Alī",
        "lat": "34.95076",
        "lng": "69.2287"
    },
    {
        "country": "AF",
        "name": "Qarah Bāgh Bāzār",
        "lat": "34.8529",
        "lng": "69.17062"
    },
    {
        "country": "AF",
        "name": "Zargarān",
        "lat": "34.78764",
        "lng": "69.05878"
    },
    {
        "country": "AF",
        "name": "Surkh Bilandī",
        "lat": "34.68474",
        "lng": "69.02863"
    },
    {
        "country": "AF",
        "name": "Pul-e Sangī",
        "lat": "34.59972",
        "lng": "69.25993"
    },
    {
        "country": "AF",
        "name": "Langar",
        "lat": "35.99474",
        "lng": "68.05081"
    },
    {
        "country": "AF",
        "name": "Bāgh-e Maīdān",
        "lat": "35.22156",
        "lng": "69.21397"
    },
    {
        "country": "AF",
        "name": "Ibrāhīm Khān",
        "lat": "35.05352",
        "lng": "69.25654"
    },
    {
        "country": "AF",
        "name": "Qaranghū Tōghaī",
        "lat": "37.21528",
        "lng": "67.63972"
    },
    {
        "country": "AF",
        "name": "Bāzārak",
        "lat": "35.31292",
        "lng": "69.51519"
    },
    {
        "country": "AF",
        "name": "Shērwānī-ye Bālā",
        "lat": "35.01468",
        "lng": "69.59934"
    },
    {
        "country": "AF",
        "name": "Kirāmān",
        "lat": "35.2973",
        "lng": "69.68125"
    },
    {
        "country": "AF",
        "name": "‘Alāqahdārī Saṟōbī",
        "lat": "32.79331",
        "lng": "69.0866"
    },
    {
        "country": "AF",
        "name": "Zerok-Alakadari",
        "lat": "33.15806",
        "lng": "69.31278"
    },
    {
        "country": "AF",
        "name": "Kushkak",
        "lat": "32.28991",
        "lng": "61.52194"
    },
    {
        "country": "AF",
        "name": "Khayr Kōṯ",
        "lat": "32.85355",
        "lng": "68.44971"
    },
    {
        "country": "AF",
        "name": "Chowṉêy",
        "lat": "33.38278",
        "lng": "66.27233"
    },
    {
        "country": "AF",
        "name": "Wuṯahpūr",
        "lat": "34.91914",
        "lng": "71.09863"
    },
    {
        "country": "AF",
        "name": "Karbori",
        "lat": "34.96597",
        "lng": "71.2746"
    },
    {
        "country": "AF",
        "name": "Sulţānpūr-e ‘Ulyā",
        "lat": "34.41204",
        "lng": "70.29634"
    },
    {
        "country": "AF",
        "name": "Babasakhib",
        "lat": "31.66144",
        "lng": "65.66098"
    },
    {
        "country": "AF",
        "name": "Chandal Bā’ī",
        "lat": "34.58469",
        "lng": "68.95524"
    },
    {
        "country": "AF",
        "name": "Dahan-e Jarf",
        "lat": "34.92891",
        "lng": "68.46273"
    },
    {
        "country": "AF",
        "name": "Maydanshakhr",
        "lat": "34.39561",
        "lng": "68.86618"
    },
    {
        "country": "AF",
        "name": "Dū Laīnah",
        "lat": "34.15599",
        "lng": "64.78566"
    },
    {
        "country": "AF",
        "name": "Qaram Qōl",
        "lat": "36.83006",
        "lng": "65.04299"
    },
    {
        "country": "AF",
        "name": "Pul-e Ḩişār",
        "lat": "35.61794",
        "lng": "69.47134"
    },
    {
        "country": "AF",
        "name": "Lab-Sar",
        "lat": "36.02634",
        "lng": "66.83799"
    },
    {
        "country": "AF",
        "name": "Ţāqchah Khānah",
        "lat": "36.61922",
        "lng": "69.62805"
    },
    {
        "country": "AF",
        "name": "March",
        "lat": "37.52845",
        "lng": "70.4482"
    },
    {
        "country": "AF",
        "name": "Zīrakī",
        "lat": "37.59233",
        "lng": "70.55814"
    },
    {
        "country": "AF",
        "name": "Aliabad",
        "lat": "36.52115",
        "lng": "68.89985"
    },
    {
        "country": "AF",
        "name": "Pas Pul",
        "lat": "37.47355",
        "lng": "70.61617"
    },
    {
        "country": "AF",
        "name": "Qal‘ah-ye Na‘īm",
        "lat": "34.39951",
        "lng": "69.16705"
    },
    {
        "country": "AF",
        "name": "Markaz-e Woluswalī-ye Āchīn",
        "lat": "34.12583",
        "lng": "70.70778"
    },
    {
        "country": "AF",
        "name": "Manogay",
        "lat": "34.99015",
        "lng": "70.91302"
    },
    {
        "country": "AF",
        "name": "Stêr Giyān",
        "lat": "32.97704",
        "lng": "69.37308"
    },
    {
        "country": "AF",
        "name": "Sharan",
        "lat": "33.17568",
        "lng": "68.73045"
    },
    {
        "country": "AF",
        "name": "Salām Khēl",
        "lat": "33.67067",
        "lng": "69.32983"
    },
    {
        "country": "AG",
        "name": "Saint John’s",
        "lat": "17.12096",
        "lng": "-61.84329"
    },
    {
        "country": "AG",
        "name": "Potters Village",
        "lat": "17.11337",
        "lng": "-61.81962"
    },
    {
        "country": "AG",
        "name": "Piggotts",
        "lat": "17.11667",
        "lng": "-61.8"
    },
    {
        "country": "AG",
        "name": "Parham",
        "lat": "17.09682",
        "lng": "-61.77046"
    },
    {
        "country": "AG",
        "name": "Liberta",
        "lat": "17.04141",
        "lng": "-61.79052"
    },
    {
        "country": "AG",
        "name": "Falmouth",
        "lat": "17.02741",
        "lng": "-61.78136"
    },
    {
        "country": "AG",
        "name": "Codrington",
        "lat": "17.63333",
        "lng": "-61.83333"
    },
    {
        "country": "AG",
        "name": "Bolands",
        "lat": "17.06565",
        "lng": "-61.87466"
    },
    {
        "country": "AG",
        "name": "All Saints",
        "lat": "17.06671",
        "lng": "-61.79303"
    },
    {
        "country": "AI",
        "name": "West End Village",
        "lat": "18.17191",
        "lng": "-63.14941"
    },
    {
        "country": "AI",
        "name": "The Valley",
        "lat": "18.21704",
        "lng": "-63.05783"
    },
    {
        "country": "AI",
        "name": "The Quarter",
        "lat": "18.20799",
        "lng": "-63.04178"
    },
    {
        "country": "AI",
        "name": "Farrington",
        "lat": "18.215",
        "lng": "-63.02238"
    },
    {
        "country": "AI",
        "name": "Stoney Ground",
        "lat": "18.22026",
        "lng": "-63.04607"
    },
    {
        "country": "AI",
        "name": "South Hill Village",
        "lat": "18.19201",
        "lng": "-63.08779"
    },
    {
        "country": "AI",
        "name": "Sandy Ground Village",
        "lat": "18.20118",
        "lng": "-63.08998"
    },
    {
        "country": "AI",
        "name": "North Side",
        "lat": "18.22919",
        "lng": "-63.04401"
    },
    {
        "country": "AI",
        "name": "North Hill Village",
        "lat": "18.20542",
        "lng": "-63.07847"
    },
    {
        "country": "AI",
        "name": "Island Harbour",
        "lat": "18.25601",
        "lng": "-63.0102"
    },
    {
        "country": "AI",
        "name": "George Hill",
        "lat": "18.19939",
        "lng": "-63.0665"
    },
    {
        "country": "AI",
        "name": "East End Village",
        "lat": "18.23333",
        "lng": "-63"
    },
    {
        "country": "AI",
        "name": "Blowing Point Village",
        "lat": "18.17648",
        "lng": "-63.09375"
    },
    {
        "country": "AI",
        "name": "Sandy Hill",
        "lat": "18.22104",
        "lng": "-63.01329"
    },
    {
        "country": "AL",
        "name": "Xarrë",
        "lat": "39.72833",
        "lng": "20.05444"
    },
    {
        "country": "AL",
        "name": "Sarandë",
        "lat": "39.87534",
        "lng": "20.00477"
    },
    {
        "country": "AL",
        "name": "Mesopotam",
        "lat": "39.91028",
        "lng": "20.09222"
    },
    {
        "country": "AL",
        "name": "Markat",
        "lat": "39.73278",
        "lng": "20.19528"
    },
    {
        "country": "AL",
        "name": "Livadhja",
        "lat": "39.78917",
        "lng": "20.12194"
    },
    {
        "country": "AL",
        "name": "Konispol",
        "lat": "39.65889",
        "lng": "20.18139"
    },
    {
        "country": "AL",
        "name": "Kakavijë",
        "lat": "39.90778",
        "lng": "20.35833"
    },
    {
        "country": "AL",
        "name": "Finiq",
        "lat": "39.90639",
        "lng": "20.05833"
    },
    {
        "country": "AL",
        "name": "Dhivër",
        "lat": "39.83611",
        "lng": "20.16861"
    },
    {
        "country": "AL",
        "name": "Delvinë",
        "lat": "39.95111",
        "lng": "20.09778"
    },
    {
        "country": "AL",
        "name": "Aliko",
        "lat": "39.85861",
        "lng": "20.07917"
    },
    {
        "country": "AL",
        "name": "Buçimas",
        "lat": "40.89139",
        "lng": "20.68139"
    },
    {
        "country": "AL",
        "name": "Zhepë",
        "lat": "40.67778",
        "lng": "20.28694"
    },
    {
        "country": "AL",
        "name": "Zerqan",
        "lat": "41.50222",
        "lng": "20.36056"
    },
    {
        "country": "AL",
        "name": "Zavalinë",
        "lat": "40.98083",
        "lng": "20.28083"
    },
    {
        "country": "AL",
        "name": "Zapod",
        "lat": "42.05056",
        "lng": "20.5525"
    },
    {
        "country": "AL",
        "name": "Zall-Reç",
        "lat": "41.87611",
        "lng": "20.32"
    },
    {
        "country": "AL",
        "name": "Zall-Dardhë",
        "lat": "41.80667",
        "lng": "20.33611"
    },
    {
        "country": "AL",
        "name": "Xibër-Murrizë",
        "lat": "41.45472",
        "lng": "20.02556"
    },
    {
        "country": "AL",
        "name": "Vreshtas",
        "lat": "40.79694",
        "lng": "20.77694"
    },
    {
        "country": "AL",
        "name": "Voskopojë",
        "lat": "40.63306",
        "lng": "20.58889"
    },
    {
        "country": "AL",
        "name": "Voskop",
        "lat": "40.61194",
        "lng": "20.69028"
    },
    {
        "country": "AL",
        "name": "Vithkuq",
        "lat": "40.525",
        "lng": "20.5825"
    },
    {
        "country": "AL",
        "name": "Vithkuq",
        "lat": "40.20472",
        "lng": "20.25944"
    },
    {
        "country": "AL",
        "name": "Vërtop",
        "lat": "40.62889",
        "lng": "20.05389"
    },
    {
        "country": "AL",
        "name": "Vergo",
        "lat": "40.00667",
        "lng": "20.00889"
    },
    {
        "country": "AL",
        "name": "Vendresha e Vogël",
        "lat": "40.50722",
        "lng": "20.13472"
    },
    {
        "country": "AL",
        "name": "Velçan",
        "lat": "40.95472",
        "lng": "20.46222"
    },
    {
        "country": "AL",
        "name": "Ujmisht",
        "lat": "41.90833",
        "lng": "20.34694"
    },
    {
        "country": "AL",
        "name": "Udënisht",
        "lat": "40.96333",
        "lng": "20.63917"
    },
    {
        "country": "AL",
        "name": "Tunjë",
        "lat": "40.83417",
        "lng": "20.11194"
    },
    {
        "country": "AL",
        "name": "Tregan",
        "lat": "41.02472",
        "lng": "20.07833"
    },
    {
        "country": "AL",
        "name": "Trebisht-Muçinë",
        "lat": "41.41111",
        "lng": "20.53556"
    },
    {
        "country": "AL",
        "name": "Trebinjë",
        "lat": "40.91333",
        "lng": "20.55306"
    },
    {
        "country": "AL",
        "name": "Topojan",
        "lat": "40.37722",
        "lng": "20.21333"
    },
    {
        "country": "AL",
        "name": "Tomin",
        "lat": "41.69083",
        "lng": "20.42167"
    },
    {
        "country": "AL",
        "name": "Tepelenë",
        "lat": "40.29583",
        "lng": "20.01917"
    },
    {
        "country": "AL",
        "name": "Surroj",
        "lat": "41.99222",
        "lng": "20.34417"
    },
    {
        "country": "AL",
        "name": "Sult",
        "lat": "40.88472",
        "lng": "20.09944"
    },
    {
        "country": "AL",
        "name": "Sukë",
        "lat": "40.37528",
        "lng": "20.15472"
    },
    {
        "country": "AL",
        "name": "Suç",
        "lat": "41.57694",
        "lng": "20.05139"
    },
    {
        "country": "AL",
        "name": "Stravaj",
        "lat": "41.00528",
        "lng": "20.42306"
    },
    {
        "country": "AL",
        "name": "Steblevë",
        "lat": "41.33722",
        "lng": "20.47"
    },
    {
        "country": "AL",
        "name": "Sllovë",
        "lat": "41.80111",
        "lng": "20.40833"
    },
    {
        "country": "AL",
        "name": "Skore",
        "lat": "40.10722",
        "lng": "20.36639"
    },
    {
        "country": "AL",
        "name": "Skënderbegas",
        "lat": "40.77611",
        "lng": "20.24056"
    },
    {
        "country": "AL",
        "name": "Shushicë",
        "lat": "41.0975",
        "lng": "20.14833"
    },
    {
        "country": "AL",
        "name": "Shupenzë",
        "lat": "41.53",
        "lng": "20.42361"
    },
    {
        "country": "AL",
        "name": "Shtiqën",
        "lat": "42.04056",
        "lng": "20.43417"
    },
    {
        "country": "AL",
        "name": "Shishtavec",
        "lat": "41.98",
        "lng": "20.60583"
    },
    {
        "country": "AL",
        "name": "Shirgjan",
        "lat": "41.05194",
        "lng": "20.05333"
    },
    {
        "country": "AL",
        "name": "Shëngjin",
        "lat": "41.92611",
        "lng": "20.18833"
    },
    {
        "country": "AL",
        "name": "Shënmëri",
        "lat": "42.10417",
        "lng": "20.23833"
    },
    {
        "country": "AL",
        "name": "Selishtë",
        "lat": "41.6275",
        "lng": "20.27917"
    },
    {
        "country": "AL",
        "name": "Saraqinishtë",
        "lat": "40.10528",
        "lng": "20.22944"
    },
    {
        "country": "AL",
        "name": "Rukaj",
        "lat": "41.68361",
        "lng": "20.01917"
    },
    {
        "country": "AL",
        "name": "Rajcë",
        "lat": "41.09028",
        "lng": "20.57639"
    },
    {
        "country": "AL",
        "name": "Roshnik",
        "lat": "40.73278",
        "lng": "20.03944"
    },
    {
        "country": "AL",
        "name": "Qukës-Skënderbe",
        "lat": "41.08389",
        "lng": "20.43806"
    },
    {
        "country": "AL",
        "name": "Qestorat",
        "lat": "40.1175",
        "lng": "20.19972"
    },
    {
        "country": "AL",
        "name": "Qafëmal",
        "lat": "42.09333",
        "lng": "20.09056"
    },
    {
        "country": "AL",
        "name": "Përrenjas-Fshat",
        "lat": "41.06667",
        "lng": "20.53528"
    },
    {
        "country": "AL",
        "name": "Përrenjas",
        "lat": "41.07306",
        "lng": "20.54889"
    },
    {
        "country": "AL",
        "name": "Proptisht",
        "lat": "40.98611",
        "lng": "20.5025"
    },
    {
        "country": "AL",
        "name": "Progër",
        "lat": "40.69417",
        "lng": "20.94028"
    },
    {
        "country": "AL",
        "name": "Potom",
        "lat": "40.48833",
        "lng": "20.37333"
    },
    {
        "country": "AL",
        "name": "Poroçan",
        "lat": "40.94194",
        "lng": "20.29861"
    },
    {
        "country": "AL",
        "name": "Polis-Gostimë",
        "lat": "41.13333",
        "lng": "20.25833"
    },
    {
        "country": "AL",
        "name": "Poliçan",
        "lat": "40.61222",
        "lng": "20.09806"
    },
    {
        "country": "AL",
        "name": "Pojan",
        "lat": "40.72583",
        "lng": "20.8375"
    },
    {
        "country": "AL",
        "name": "Pogradec",
        "lat": "40.9025",
        "lng": "20.6525"
    },
    {
        "country": "AL",
        "name": "Pishaj",
        "lat": "40.88111",
        "lng": "20.18278"
    },
    {
        "country": "AL",
        "name": "Pirg",
        "lat": "40.785",
        "lng": "20.70611"
    },
    {
        "country": "AL",
        "name": "Gjinkar",
        "lat": "40.19944",
        "lng": "20.40611"
    },
    {
        "country": "AL",
        "name": "Petran",
        "lat": "40.20944",
        "lng": "20.41833"
    },
    {
        "country": "AL",
        "name": "Peshkopi",
        "lat": "41.685",
        "lng": "20.42889"
    },
    {
        "country": "AL",
        "name": "Përmet",
        "lat": "40.23361",
        "lng": "20.35167"
    },
    {
        "country": "AL",
        "name": "Ostreni i Math",
        "lat": "41.43083",
        "lng": "20.45556"
    },
    {
        "country": "AL",
        "name": "Orosh",
        "lat": "41.83333",
        "lng": "20.08333"
    },
    {
        "country": "AL",
        "name": "Orenjë",
        "lat": "41.28472",
        "lng": "20.21194"
    },
    {
        "country": "AL",
        "name": "Odrie",
        "lat": "40.13333",
        "lng": "20.16667"
    },
    {
        "country": "AL",
        "name": "Novoselë",
        "lat": "40.76139",
        "lng": "20.69833"
    },
    {
        "country": "AL",
        "name": "Picar",
        "lat": "40.16667",
        "lng": "20.04833"
    },
    {
        "country": "AL",
        "name": "Mollas",
        "lat": "40.92694",
        "lng": "20.00361"
    },
    {
        "country": "AL",
        "name": "Mollas",
        "lat": "40.42556",
        "lng": "20.67444"
    },
    {
        "country": "AL",
        "name": "Mollaj",
        "lat": "40.56028",
        "lng": "20.74028"
    },
    {
        "country": "AL",
        "name": "Miras",
        "lat": "40.50917",
        "lng": "20.92833"
    },
    {
        "country": "AL",
        "name": "Melan",
        "lat": "41.65417",
        "lng": "20.46528"
    },
    {
        "country": "AL",
        "name": "Martanesh",
        "lat": "41.4",
        "lng": "20.2"
    },
    {
        "country": "AL",
        "name": "Maqellarë",
        "lat": "41.58889",
        "lng": "20.48306"
    },
    {
        "country": "AL",
        "name": "Maliq",
        "lat": "40.70583",
        "lng": "20.69972"
    },
    {
        "country": "AL",
        "name": "Macukull",
        "lat": "41.68861",
        "lng": "20.11028"
    },
    {
        "country": "AL",
        "name": "Lunik",
        "lat": "41.28917",
        "lng": "20.32361"
    },
    {
        "country": "AL",
        "name": "Llugaj",
        "lat": "42.34139",
        "lng": "20.10861"
    },
    {
        "country": "AL",
        "name": "Lis",
        "lat": "41.6275",
        "lng": "20.08528"
    },
    {
        "country": "AL",
        "name": "Liqenas",
        "lat": "40.78667",
        "lng": "20.90222"
    },
    {
        "country": "AL",
        "name": "Librazhd",
        "lat": "41.17944",
        "lng": "20.315"
    },
    {
        "country": "AL",
        "name": "Libonik",
        "lat": "40.70444",
        "lng": "20.70861"
    },
    {
        "country": "AL",
        "name": "Libohovë",
        "lat": "40.03111",
        "lng": "20.26306"
    },
    {
        "country": "AL",
        "name": "Leskovik",
        "lat": "40.15139",
        "lng": "20.59722"
    },
    {
        "country": "AL",
        "name": "Leshnjë",
        "lat": "40.53694",
        "lng": "20.31333"
    },
    {
        "country": "AL",
        "name": "Lenias",
        "lat": "40.76667",
        "lng": "20.39139"
    },
    {
        "country": "AL",
        "name": "Lekas",
        "lat": "40.60222",
        "lng": "20.5125"
    },
    {
        "country": "AL",
        "name": "Lazarat",
        "lat": "40.04667",
        "lng": "20.1475"
    },
    {
        "country": "AL",
        "name": "Labinot-Mal",
        "lat": "41.20083",
        "lng": "20.15222"
    },
    {
        "country": "AL",
        "name": "Labinot-Fushë",
        "lat": "41.14056",
        "lng": "20.14611"
    },
    {
        "country": "AL",
        "name": "Kushovë",
        "lat": "40.78889",
        "lng": "20.18889"
    },
    {
        "country": "AL",
        "name": "Kurbnesh",
        "lat": "41.77972",
        "lng": "20.08361"
    },
    {
        "country": "AL",
        "name": "Kukur",
        "lat": "40.86778",
        "lng": "20.36361"
    },
    {
        "country": "AL",
        "name": "Kukës",
        "lat": "42.07694",
        "lng": "20.42194"
    },
    {
        "country": "AL",
        "name": "Kthella e Epërme",
        "lat": "41.81194",
        "lng": "20.05889"
    },
    {
        "country": "AL",
        "name": "Krumë",
        "lat": "42.19694",
        "lng": "20.41333"
    },
    {
        "country": "AL",
        "name": "Korçë",
        "lat": "40.61861",
        "lng": "20.78083"
    },
    {
        "country": "AL",
        "name": "Kolsh",
        "lat": "42.07806",
        "lng": "20.34194"
    },
    {
        "country": "AL",
        "name": "Kodovjat",
        "lat": "40.80556",
        "lng": "20.25111"
    },
    {
        "country": "AL",
        "name": "Klos",
        "lat": "41.50694",
        "lng": "20.08667"
    },
    {
        "country": "AL",
        "name": "Klos",
        "lat": "40.94611",
        "lng": "20.00972"
    },
    {
        "country": "AL",
        "name": "Këlcyrë",
        "lat": "40.31306",
        "lng": "20.18944"
    },
    {
        "country": "AL",
        "name": "Kastriot",
        "lat": "41.72944",
        "lng": "20.37722"
    },
    {
        "country": "AL",
        "name": "Kardhiq",
        "lat": "40.12167",
        "lng": "20.0275"
    },
    {
        "country": "AL",
        "name": "Kalis",
        "lat": "41.83889",
        "lng": "20.36667"
    },
    {
        "country": "AL",
        "name": "Iballë",
        "lat": "42.1875",
        "lng": "20.0025"
    },
    {
        "country": "AL",
        "name": "Hotolisht",
        "lat": "41.15667",
        "lng": "20.39722"
    },
    {
        "country": "AL",
        "name": "Hoçisht",
        "lat": "40.60833",
        "lng": "20.91417"
    },
    {
        "country": "AL",
        "name": "Gramsh",
        "lat": "40.86972",
        "lng": "20.18444"
    },
    {
        "country": "AL",
        "name": "Gostimë",
        "lat": "41.14556",
        "lng": "20.24"
    },
    {
        "country": "AL",
        "name": "Golaj",
        "lat": "42.2475",
        "lng": "20.38028"
    },
    {
        "country": "AL",
        "name": "Gjorica e Sipërme",
        "lat": "41.53444",
        "lng": "20.45"
    },
    {
        "country": "AL",
        "name": "Gjirokastër",
        "lat": "40.07583",
        "lng": "20.13889"
    },
    {
        "country": "AL",
        "name": "Gjinar",
        "lat": "41.0225",
        "lng": "20.18889"
    },
    {
        "country": "AL",
        "name": "Gjinaj",
        "lat": "42.11806",
        "lng": "20.43583"
    },
    {
        "country": "AL",
        "name": "Gjergjan",
        "lat": "41.04306",
        "lng": "20.02972"
    },
    {
        "country": "AL",
        "name": "Gjegjan",
        "lat": "41.93778",
        "lng": "20.01111"
    },
    {
        "country": "AL",
        "name": "Fushë-Muhurr",
        "lat": "41.67972",
        "lng": "20.33139"
    },
    {
        "country": "AL",
        "name": "Fushë-Lurë",
        "lat": "41.80611",
        "lng": "20.23194"
    },
    {
        "country": "AL",
        "name": "Fushë-Çidhnë",
        "lat": "41.76056",
        "lng": "20.34111"
    },
    {
        "country": "AL",
        "name": "Fushë-Bulqizë",
        "lat": "41.52194",
        "lng": "20.28222"
    },
    {
        "country": "AL",
        "name": "Fushë-Arrëz",
        "lat": "42.06222",
        "lng": "20.01667"
    },
    {
        "country": "AL",
        "name": "Frashër",
        "lat": "40.36889",
        "lng": "20.42444"
    },
    {
        "country": "AL",
        "name": "Fierzë",
        "lat": "42.26056",
        "lng": "20.01694"
    },
    {
        "country": "AL",
        "name": "Fajzë",
        "lat": "42.16694",
        "lng": "20.35083"
    },
    {
        "country": "AL",
        "name": "Ersekë",
        "lat": "40.33778",
        "lng": "20.67889"
    },
    {
        "country": "AL",
        "name": "Elbasan",
        "lat": "41.1125",
        "lng": "20.08222"
    },
    {
        "country": "AL",
        "name": "Drenovë",
        "lat": "40.58333",
        "lng": "20.79222"
    },
    {
        "country": "AL",
        "name": "Dishnicë",
        "lat": "40.65139",
        "lng": "20.81361"
    },
    {
        "country": "AL",
        "name": "Derjan",
        "lat": "41.67361",
        "lng": "20.05833"
    },
    {
        "country": "AL",
        "name": "Gjerbës",
        "lat": "40.62917",
        "lng": "20.25111"
    },
    {
        "country": "AL",
        "name": "Çorovodë",
        "lat": "40.50417",
        "lng": "20.22722"
    },
    {
        "country": "AL",
        "name": "Çlirim",
        "lat": "40.4175",
        "lng": "20.55028"
    },
    {
        "country": "AL",
        "name": "Cerava",
        "lat": "40.85139",
        "lng": "20.725"
    },
    {
        "country": "AL",
        "name": "Çepan",
        "lat": "40.42056",
        "lng": "20.26056"
    },
    {
        "country": "AL",
        "name": "Çarshovë",
        "lat": "40.11806",
        "lng": "20.54083"
    },
    {
        "country": "AL",
        "name": "Kokaj",
        "lat": "41.87833",
        "lng": "20.50472"
    },
    {
        "country": "AL",
        "name": "Buz",
        "lat": "40.445",
        "lng": "20.00472"
    },
    {
        "country": "AL",
        "name": "Bushtricë",
        "lat": "41.87861",
        "lng": "20.42194"
    },
    {
        "country": "AL",
        "name": "Burrel",
        "lat": "41.61028",
        "lng": "20.00889"
    },
    {
        "country": "AL",
        "name": "Bulqizë",
        "lat": "41.49167",
        "lng": "20.22194"
    },
    {
        "country": "AL",
        "name": "Bujan",
        "lat": "42.32639",
        "lng": "20.07639"
    },
    {
        "country": "AL",
        "name": "Bradashesh",
        "lat": "41.105",
        "lng": "20.0225"
    },
    {
        "country": "AL",
        "name": "Blinisht",
        "lat": "41.86139",
        "lng": "20"
    },
    {
        "country": "AL",
        "name": "Blerim",
        "lat": "42.16056",
        "lng": "20.19194"
    },
    {
        "country": "AL",
        "name": "Bilisht",
        "lat": "40.6275",
        "lng": "20.99"
    },
    {
        "country": "AL",
        "name": "Bicaj",
        "lat": "41.99472",
        "lng": "20.4125"
    },
    {
        "country": "AL",
        "name": "Dardhas",
        "lat": "40.84583",
        "lng": "20.65722"
    },
    {
        "country": "AL",
        "name": "Barmash",
        "lat": "40.27778",
        "lng": "20.61833"
    },
    {
        "country": "AL",
        "name": "Ballaban",
        "lat": "40.41667",
        "lng": "20.13444"
    },
    {
        "country": "AL",
        "name": "Arrën",
        "lat": "41.91306",
        "lng": "20.28833"
    },
    {
        "country": "AL",
        "name": "Arras",
        "lat": "41.73583",
        "lng": "20.32417"
    },
    {
        "country": "AL",
        "name": "Bytyç",
        "lat": "42.31",
        "lng": "20.20278"
    },
    {
        "country": "AL",
        "name": "Librazhd-Qendër",
        "lat": "41.19694",
        "lng": "20.33556"
    },
    {
        "country": "AL",
        "name": "Funarë",
        "lat": "41.19639",
        "lng": "20.05972"
    },
    {
        "country": "AL",
        "name": "Moglicë",
        "lat": "40.71806",
        "lng": "20.43806"
    },
    {
        "country": "AL",
        "name": "Bogovë",
        "lat": "40.57111",
        "lng": "20.15"
    },
    {
        "country": "AL",
        "name": "Piskovë",
        "lat": "40.27917",
        "lng": "20.2725"
    },
    {
        "country": "AL",
        "name": "Lukovë",
        "lat": "39.99222",
        "lng": "19.91389"
    },
    {
        "country": "AL",
        "name": "Ksamil",
        "lat": "39.76889",
        "lng": "19.99972"
    },
    {
        "country": "AL",
        "name": "Zharrëz",
        "lat": "40.70917",
        "lng": "19.64972"
    },
    {
        "country": "AL",
        "name": "Zejmen",
        "lat": "41.70778",
        "lng": "19.68917"
    },
    {
        "country": "AL",
        "name": "Zall-Herr",
        "lat": "41.38944",
        "lng": "19.8275"
    },
    {
        "country": "AL",
        "name": "Zall-Bastar",
        "lat": "41.42972",
        "lng": "19.93028"
    },
    {
        "country": "AL",
        "name": "Xhafzotaj",
        "lat": "41.34444",
        "lng": "19.5475"
    },
    {
        "country": "AL",
        "name": "Vukatanë",
        "lat": "42.02806",
        "lng": "19.54778"
    },
    {
        "country": "AL",
        "name": "Vranisht",
        "lat": "40.20972",
        "lng": "19.69083"
    },
    {
        "country": "AL",
        "name": "Vorë",
        "lat": "41.39083",
        "lng": "19.655"
    },
    {
        "country": "AL",
        "name": "Vlorë",
        "lat": "40.46667",
        "lng": "19.48972"
    },
    {
        "country": "AL",
        "name": "Velipojë",
        "lat": "41.87833",
        "lng": "19.40556"
    },
    {
        "country": "AL",
        "name": "Velabisht",
        "lat": "40.70167",
        "lng": "19.93222"
    },
    {
        "country": "AL",
        "name": "Vaqarr",
        "lat": "41.29917",
        "lng": "19.74417"
    },
    {
        "country": "AL",
        "name": "Ura Vajgurore",
        "lat": "40.76889",
        "lng": "19.87778"
    },
    {
        "country": "AL",
        "name": "Ungrej",
        "lat": "41.87528",
        "lng": "19.79472"
    },
    {
        "country": "AL",
        "name": "Ulëz",
        "lat": "41.68278",
        "lng": "19.89333"
    },
    {
        "country": "AL",
        "name": "Topojë",
        "lat": "40.76306",
        "lng": "19.43278"
    },
    {
        "country": "AL",
        "name": "Tirana",
        "lat": "41.3275",
        "lng": "19.81889"
    },
    {
        "country": "AL",
        "name": "Thumanë",
        "lat": "41.5475",
        "lng": "19.67778"
    },
    {
        "country": "AL",
        "name": "Tërbuf",
        "lat": "41.04",
        "lng": "19.61417"
    },
    {
        "country": "AL",
        "name": "Synej",
        "lat": "41.18",
        "lng": "19.5425"
    },
    {
        "country": "AL",
        "name": "Sukth",
        "lat": "41.38056",
        "lng": "19.53778"
    },
    {
        "country": "AL",
        "name": "Strum",
        "lat": "40.75111",
        "lng": "19.7375"
    },
    {
        "country": "AL",
        "name": "Sinjë",
        "lat": "40.64806",
        "lng": "19.86806"
    },
    {
        "country": "AL",
        "name": "Sinaballaj",
        "lat": "41.06889",
        "lng": "19.69944"
    },
    {
        "country": "AL",
        "name": "Shkodër",
        "lat": "42.06828",
        "lng": "19.51258"
    },
    {
        "country": "AL",
        "name": "Shijak",
        "lat": "41.34556",
        "lng": "19.56722"
    },
    {
        "country": "AL",
        "name": "Shezë",
        "lat": "41.02417",
        "lng": "19.81611"
    },
    {
        "country": "AL",
        "name": "Shënkoll",
        "lat": "41.69083",
        "lng": "19.65333"
    },
    {
        "country": "AL",
        "name": "Shëngjin",
        "lat": "41.81361",
        "lng": "19.59389"
    },
    {
        "country": "AL",
        "name": "Shëngjergj",
        "lat": "41.18333",
        "lng": "19.53333"
    },
    {
        "country": "AL",
        "name": "Shalës",
        "lat": "40.99861",
        "lng": "19.94778"
    },
    {
        "country": "AL",
        "name": "Nicaj-Shalë",
        "lat": "42.29944",
        "lng": "19.805"
    },
    {
        "country": "AL",
        "name": "Sevaster",
        "lat": "40.39639",
        "lng": "19.72972"
    },
    {
        "country": "AL",
        "name": "Selitë",
        "lat": "41.63528",
        "lng": "19.755"
    },
    {
        "country": "AL",
        "name": "Valbonë",
        "lat": "42.45139",
        "lng": "19.89167"
    },
    {
        "country": "AL",
        "name": "Selenicë",
        "lat": "40.53056",
        "lng": "19.63583"
    },
    {
        "country": "AL",
        "name": "Ruzhdie",
        "lat": "40.67083",
        "lng": "19.69833"
    },
    {
        "country": "AL",
        "name": "Rubik",
        "lat": "41.77444",
        "lng": "19.78611"
    },
    {
        "country": "AL",
        "name": "Rrogozhinë",
        "lat": "41.07639",
        "lng": "19.66528"
    },
    {
        "country": "AL",
        "name": "Rrëshen",
        "lat": "41.7675",
        "lng": "19.87556"
    },
    {
        "country": "AL",
        "name": "Rrashbull",
        "lat": "41.32278",
        "lng": "19.51028"
    },
    {
        "country": "AL",
        "name": "Rrasa e Sipërme",
        "lat": "40.96778",
        "lng": "19.82111"
    },
    {
        "country": "AL",
        "name": "Rrapë",
        "lat": "42.04444",
        "lng": "19.97056"
    },
    {
        "country": "AL",
        "name": "Roskovec",
        "lat": "40.7375",
        "lng": "19.70222"
    },
    {
        "country": "AL",
        "name": "Remas",
        "lat": "40.88639",
        "lng": "19.51139"
    },
    {
        "country": "AL",
        "name": "Qerret",
        "lat": "42.05306",
        "lng": "19.83722"
    },
    {
        "country": "AL",
        "name": "Qelëz",
        "lat": "42.08972",
        "lng": "19.895"
    },
    {
        "country": "AL",
        "name": "Pukë",
        "lat": "42.04444",
        "lng": "19.89972"
    },
    {
        "country": "AL",
        "name": "Progonat",
        "lat": "40.21333",
        "lng": "19.94472"
    },
    {
        "country": "AL",
        "name": "Prezë",
        "lat": "41.42722",
        "lng": "19.67278"
    },
    {
        "country": "AL",
        "name": "Poshnje",
        "lat": "40.78028",
        "lng": "19.84417"
    },
    {
        "country": "AL",
        "name": "Portëz",
        "lat": "40.69917",
        "lng": "19.57444"
    },
    {
        "country": "AL",
        "name": "Peza e Madhe",
        "lat": "41.2175",
        "lng": "19.69611"
    },
    {
        "country": "AL",
        "name": "Petrelë",
        "lat": "41.25306",
        "lng": "19.85306"
    },
    {
        "country": "AL",
        "name": "Përparim",
        "lat": "41.05",
        "lng": "19.79917"
    },
    {
        "country": "AL",
        "name": "Perondi",
        "lat": "40.77889",
        "lng": "19.92306"
    },
    {
        "country": "AL",
        "name": "Peqin",
        "lat": "41.04611",
        "lng": "19.75111"
    },
    {
        "country": "AL",
        "name": "Patos Fshat",
        "lat": "40.64278",
        "lng": "19.65083"
    },
    {
        "country": "AL",
        "name": "Patos",
        "lat": "40.68333",
        "lng": "19.61944"
    },
    {
        "country": "AL",
        "name": "Paskuqan",
        "lat": "41.35389",
        "lng": "19.80667"
    },
    {
        "country": "AL",
        "name": "Papër",
        "lat": "41.05167",
        "lng": "19.96083"
    },
    {
        "country": "AL",
        "name": "Pajovë",
        "lat": "41.05472",
        "lng": "19.8375"
    },
    {
        "country": "AL",
        "name": "Otllak",
        "lat": "40.74389",
        "lng": "19.93528"
    },
    {
        "country": "AL",
        "name": "Orikum",
        "lat": "40.32528",
        "lng": "19.47139"
    },
    {
        "country": "AL",
        "name": "Novoselë",
        "lat": "40.625",
        "lng": "19.46694"
    },
    {
        "country": "AL",
        "name": "Nikël",
        "lat": "41.44417",
        "lng": "19.74833"
    },
    {
        "country": "AL",
        "name": "Nicaj-Shosh",
        "lat": "42.24444",
        "lng": "19.75806"
    },
    {
        "country": "AL",
        "name": "Ngraçan",
        "lat": "40.64444",
        "lng": "19.7925"
    },
    {
        "country": "AL",
        "name": "Ndroq",
        "lat": "41.26389",
        "lng": "19.65583"
    },
    {
        "country": "AL",
        "name": "Milot",
        "lat": "41.68389",
        "lng": "19.71556"
    },
    {
        "country": "AL",
        "name": "Memaliaj",
        "lat": "40.35167",
        "lng": "19.98028"
    },
    {
        "country": "AL",
        "name": "Mbrostar-Urë",
        "lat": "40.75361",
        "lng": "19.57944"
    },
    {
        "country": "AL",
        "name": "Manzë",
        "lat": "41.42861",
        "lng": "19.59306"
    },
    {
        "country": "AL",
        "name": "Mamurras",
        "lat": "41.5775",
        "lng": "19.69222"
    },
    {
        "country": "AL",
        "name": "Maminas",
        "lat": "41.37917",
        "lng": "19.6075"
    },
    {
        "country": "AL",
        "name": "Luzi i Vogël",
        "lat": "41.1275",
        "lng": "19.57389"
    },
    {
        "country": "AL",
        "name": "Lushnjë",
        "lat": "40.94194",
        "lng": "19.705"
    },
    {
        "country": "AL",
        "name": "Luftinjë",
        "lat": "40.44278",
        "lng": "19.95444"
    },
    {
        "country": "AL",
        "name": "Libofshë",
        "lat": "40.83444",
        "lng": "19.55222"
    },
    {
        "country": "AL",
        "name": "Lezhë",
        "lat": "41.78361",
        "lng": "19.64361"
    },
    {
        "country": "AL",
        "name": "Levan",
        "lat": "40.67611",
        "lng": "19.48972"
    },
    {
        "country": "AL",
        "name": "Lekbibaj",
        "lat": "42.29472",
        "lng": "19.93167"
    },
    {
        "country": "AL",
        "name": "Lekaj",
        "lat": "41.11444",
        "lng": "19.60972"
    },
    {
        "country": "AL",
        "name": "Laç",
        "lat": "41.63556",
        "lng": "19.71306"
    },
    {
        "country": "AL",
        "name": "Kutë",
        "lat": "40.47333",
        "lng": "19.76639"
    },
    {
        "country": "AL",
        "name": "Kutalli",
        "lat": "40.78389",
        "lng": "19.78667"
    },
    {
        "country": "AL",
        "name": "Kurjan",
        "lat": "40.7175",
        "lng": "19.74556"
    },
    {
        "country": "AL",
        "name": "Kuman",
        "lat": "40.72667",
        "lng": "19.68667"
    },
    {
        "country": "AL",
        "name": "Kuçovë",
        "lat": "40.80028",
        "lng": "19.91667"
    },
    {
        "country": "AL",
        "name": "Kuç",
        "lat": "42.03972",
        "lng": "19.52528"
    },
    {
        "country": "AL",
        "name": "Kryevidh",
        "lat": "41.10056",
        "lng": "19.5275"
    },
    {
        "country": "AL",
        "name": "Krutja e Poshtme",
        "lat": "40.87194",
        "lng": "19.68083"
    },
    {
        "country": "AL",
        "name": "Krujë",
        "lat": "41.50917",
        "lng": "19.79278"
    },
    {
        "country": "AL",
        "name": "Krrabë",
        "lat": "41.21556",
        "lng": "19.97139"
    },
    {
        "country": "AL",
        "name": "Krahës",
        "lat": "40.43861",
        "lng": "19.84444"
    },
    {
        "country": "AL",
        "name": "Kozarë",
        "lat": "40.83083",
        "lng": "19.90056"
    },
    {
        "country": "AL",
        "name": "Kotë",
        "lat": "40.38944",
        "lng": "19.60222"
    },
    {
        "country": "AL",
        "name": "Koplik",
        "lat": "42.21361",
        "lng": "19.43639"
    },
    {
        "country": "AL",
        "name": "Kombësi",
        "lat": "41.58167",
        "lng": "19.98556"
    },
    {
        "country": "AL",
        "name": "Kolonjë",
        "lat": "40.82417",
        "lng": "19.60389"
    },
    {
        "country": "AL",
        "name": "Kolç",
        "lat": "41.78667",
        "lng": "19.67611"
    },
    {
        "country": "AL",
        "name": "Kavajë",
        "lat": "41.18556",
        "lng": "19.55694"
    },
    {
        "country": "AL",
        "name": "Katundi i Ri",
        "lat": "41.40306",
        "lng": "19.51806"
    },
    {
        "country": "AL",
        "name": "Kastrat",
        "lat": "42.3525",
        "lng": "19.48917"
    },
    {
        "country": "AL",
        "name": "Kashar",
        "lat": "41.34972",
        "lng": "19.71028"
    },
    {
        "country": "AL",
        "name": "Karinë",
        "lat": "41.06306",
        "lng": "19.71611"
    },
    {
        "country": "AL",
        "name": "Karbunara e Vogël",
        "lat": "40.92194",
        "lng": "19.71778"
    },
    {
        "country": "AL",
        "name": "Kamëz",
        "lat": "41.38167",
        "lng": "19.76028"
    },
    {
        "country": "AL",
        "name": "Kallmeti i Madh",
        "lat": "41.84889",
        "lng": "19.68694"
    },
    {
        "country": "AL",
        "name": "Kallmet",
        "lat": "41.41806",
        "lng": "19.82139"
    },
    {
        "country": "AL",
        "name": "Kalenjë",
        "lat": "40.59",
        "lng": "19.77833"
    },
    {
        "country": "AL",
        "name": "Kajan",
        "lat": "40.91417",
        "lng": "19.88944"
    },
    {
        "country": "AL",
        "name": "Kaçinar",
        "lat": "41.89333",
        "lng": "19.89861"
    },
    {
        "country": "AL",
        "name": "Ishëm",
        "lat": "41.54528",
        "lng": "19.6"
    },
    {
        "country": "AL",
        "name": "Hysgjokaj",
        "lat": "40.97167",
        "lng": "19.78972"
    },
    {
        "country": "AL",
        "name": "Hot",
        "lat": "42.36194",
        "lng": "19.44417"
    },
    {
        "country": "AL",
        "name": "Himarë",
        "lat": "40.10167",
        "lng": "19.74472"
    },
    {
        "country": "AL",
        "name": "Helmas",
        "lat": "41.18028",
        "lng": "19.60694"
    },
    {
        "country": "AL",
        "name": "Hekal",
        "lat": "40.56194",
        "lng": "19.73583"
    },
    {
        "country": "AL",
        "name": "Hasan",
        "lat": "41.48278",
        "lng": "19.69972"
    },
    {
        "country": "AL",
        "name": "Hajmel",
        "lat": "41.95306",
        "lng": "19.63778"
    },
    {
        "country": "AL",
        "name": "Gurra e Vogël",
        "lat": "41.53611",
        "lng": "19.99083"
    },
    {
        "country": "AL",
        "name": "Guri i Zi",
        "lat": "42.045",
        "lng": "19.57472"
    },
    {
        "country": "AL",
        "name": "Gruemirë",
        "lat": "42.15917",
        "lng": "19.51889"
    },
    {
        "country": "AL",
        "name": "Greshicë",
        "lat": "40.55194",
        "lng": "19.77833"
    },
    {
        "country": "AL",
        "name": "Grekan",
        "lat": "40.92944",
        "lng": "19.9475"
    },
    {
        "country": "AL",
        "name": "Gradishtë",
        "lat": "40.88806",
        "lng": "19.58583"
    },
    {
        "country": "AL",
        "name": "Gracen",
        "lat": "41.15194",
        "lng": "19.96361"
    },
    {
        "country": "AL",
        "name": "Grabjan",
        "lat": "40.95306",
        "lng": "19.58083"
    },
    {
        "country": "AL",
        "name": "Gosë e Madhe",
        "lat": "41.09056",
        "lng": "19.62472"
    },
    {
        "country": "AL",
        "name": "Golem",
        "lat": "40.96778",
        "lng": "19.68528"
    },
    {
        "country": "AL",
        "name": "Gjoçaj",
        "lat": "41.02556",
        "lng": "19.72389"
    },
    {
        "country": "AL",
        "name": "Gjepalaj",
        "lat": "41.33306",
        "lng": "19.57806"
    },
    {
        "country": "AL",
        "name": "Fushëkuqe",
        "lat": "41.65028",
        "lng": "19.62"
    },
    {
        "country": "AL",
        "name": "Fushë-Krujë",
        "lat": "41.47833",
        "lng": "19.71778"
    },
    {
        "country": "AL",
        "name": "Fratar",
        "lat": "40.5",
        "lng": "19.81667"
    },
    {
        "country": "AL",
        "name": "Frakulla e Madhe",
        "lat": "40.65583",
        "lng": "19.5025"
    },
    {
        "country": "AL",
        "name": "Fier-Shegan",
        "lat": "40.86722",
        "lng": "19.78528"
    },
    {
        "country": "AL",
        "name": "Fier-Çifçi",
        "lat": "40.71667",
        "lng": "19.56667"
    },
    {
        "country": "AL",
        "name": "Fier",
        "lat": "40.72389",
        "lng": "19.55611"
    },
    {
        "country": "AL",
        "name": "Farka e Madhe",
        "lat": "41.30556",
        "lng": "19.86694"
    },
    {
        "country": "AL",
        "name": "Durrës",
        "lat": "41.32355",
        "lng": "19.45469"
    },
    {
        "country": "AL",
        "name": "Drenovë",
        "lat": "40.60056",
        "lng": "19.70083"
    },
    {
        "country": "AL",
        "name": "Divjakë",
        "lat": "40.99667",
        "lng": "19.52944"
    },
    {
        "country": "AL",
        "name": "Dërmënas",
        "lat": "40.74556",
        "lng": "19.49389"
    },
    {
        "country": "AL",
        "name": "Vau i Dejës",
        "lat": "42.01",
        "lng": "19.62472"
    },
    {
        "country": "AL",
        "name": "Dajt",
        "lat": "41.39361",
        "lng": "19.91583"
    },
    {
        "country": "AL",
        "name": "Dajç",
        "lat": "41.99167",
        "lng": "19.41056"
    },
    {
        "country": "AL",
        "name": "Dajç",
        "lat": "41.91556",
        "lng": "19.60444"
    },
    {
        "country": "AL",
        "name": "Cukalat",
        "lat": "40.73028",
        "lng": "19.79056"
    },
    {
        "country": "AL",
        "name": "Cudhi Zall",
        "lat": "41.50944",
        "lng": "19.84583"
    },
    {
        "country": "AL",
        "name": "Cërrik",
        "lat": "41.03167",
        "lng": "19.97583"
    },
    {
        "country": "AL",
        "name": "Cakran",
        "lat": "40.60333",
        "lng": "19.62611"
    },
    {
        "country": "AL",
        "name": "Bushat",
        "lat": "42.11944",
        "lng": "19.89972"
    },
    {
        "country": "AL",
        "name": "Bubullimë",
        "lat": "40.81806",
        "lng": "19.64083"
    },
    {
        "country": "AL",
        "name": "Bubq",
        "lat": "41.47417",
        "lng": "19.65167"
    },
    {
        "country": "AL",
        "name": "Brataj",
        "lat": "40.26778",
        "lng": "19.66917"
    },
    {
        "country": "AL",
        "name": "Bërzhitë",
        "lat": "41.24528",
        "lng": "19.90111"
    },
    {
        "country": "AL",
        "name": "Bërxull",
        "lat": "41.38",
        "lng": "19.6875"
    },
    {
        "country": "AL",
        "name": "Bërdica e Madhe",
        "lat": "42.01528",
        "lng": "19.48167"
    },
    {
        "country": "AL",
        "name": "Berat",
        "lat": "40.70583",
        "lng": "19.95222"
    },
    {
        "country": "AL",
        "name": "Belsh",
        "lat": "40.975",
        "lng": "19.88194"
    },
    {
        "country": "AL",
        "name": "Baz",
        "lat": "41.63194",
        "lng": "19.92917"
    },
    {
        "country": "AL",
        "name": "Banaj",
        "lat": "40.82472",
        "lng": "19.83917"
    },
    {
        "country": "AL",
        "name": "Ballsh",
        "lat": "40.59889",
        "lng": "19.73472"
    },
    {
        "country": "AL",
        "name": "Ballagat",
        "lat": "40.99528",
        "lng": "19.76528"
    },
    {
        "country": "AL",
        "name": "Baldushk",
        "lat": "41.21389",
        "lng": "19.79694"
    },
    {
        "country": "AL",
        "name": "Armen",
        "lat": "40.53639",
        "lng": "19.59611"
    },
    {
        "country": "AL",
        "name": "Aranitas",
        "lat": "40.59444",
        "lng": "19.80667"
    },
    {
        "country": "AL",
        "name": "Allkaj",
        "lat": "40.8575",
        "lng": "19.75528"
    },
    {
        "country": "AL",
        "name": "Golem",
        "lat": "41.24583",
        "lng": "19.53472"
    },
    {
        "country": "AL",
        "name": "Balldreni i Ri",
        "lat": "41.81917",
        "lng": "19.64028"
    },
    {
        "country": "AL",
        "name": "Grudë-Fushë",
        "lat": "42.16222",
        "lng": "19.49667"
    },
    {
        "country": "AL",
        "name": "Fierzë",
        "lat": "40.92056",
        "lng": "19.84694"
    },
    {
        "country": "AL",
        "name": "Dushk",
        "lat": "40.72667",
        "lng": "19.75722"
    },
    {
        "country": "AL",
        "name": "Selitë",
        "lat": "40.5475",
        "lng": "19.84806"
    },
    {
        "country": "AL",
        "name": "Vllahinë",
        "lat": "40.45694",
        "lng": "19.64361"
    },
    {
        "country": "AL",
        "name": "Bajram Curri",
        "lat": "42.35734",
        "lng": "20.07679"
    },
    {
        "country": "AM",
        "name": "Zarrit’ap’",
        "lat": "39.63892",
        "lng": "45.51111"
    },
    {
        "country": "AM",
        "name": "Zangakatun",
        "lat": "39.82233",
        "lng": "45.04169"
    },
    {
        "country": "AM",
        "name": "Goravan",
        "lat": "39.90832",
        "lng": "44.73328"
    },
    {
        "country": "AM",
        "name": "Yeghegnavan",
        "lat": "39.83893",
        "lng": "44.61951"
    },
    {
        "country": "AM",
        "name": "Yeghegnadzor",
        "lat": "39.76389",
        "lng": "45.33239"
    },
    {
        "country": "AM",
        "name": "Vostan",
        "lat": "39.96515",
        "lng": "44.55937"
    },
    {
        "country": "AM",
        "name": "Vosketap’",
        "lat": "39.88114",
        "lng": "44.64917"
    },
    {
        "country": "AM",
        "name": "Vernashen",
        "lat": "39.79236",
        "lng": "45.36389"
    },
    {
        "country": "AM",
        "name": "Verishen",
        "lat": "39.53543",
        "lng": "46.32063"
    },
    {
        "country": "AM",
        "name": "Vedi",
        "lat": "39.91388",
        "lng": "44.7251"
    },
    {
        "country": "AM",
        "name": "Vayk’",
        "lat": "39.6889",
        "lng": "45.46668"
    },
    {
        "country": "AM",
        "name": "Tegh",
        "lat": "39.55826",
        "lng": "46.48054"
    },
    {
        "country": "AM",
        "name": "Surenavan",
        "lat": "39.79449",
        "lng": "44.77508"
    },
    {
        "country": "AM",
        "name": "Sisavan",
        "lat": "39.90802",
        "lng": "44.66721"
    },
    {
        "country": "AM",
        "name": "Shinuhayr",
        "lat": "39.4367",
        "lng": "46.31787"
    },
    {
        "country": "AM",
        "name": "Lusarrat",
        "lat": "39.87403",
        "lng": "44.58678"
    },
    {
        "country": "AM",
        "name": "Shahumyan",
        "lat": "39.94171",
        "lng": "44.57233"
    },
    {
        "country": "AM",
        "name": "Shatin",
        "lat": "39.83612",
        "lng": "45.30292"
    },
    {
        "country": "AM",
        "name": "Shaghat",
        "lat": "39.55698",
        "lng": "45.90727"
    },
    {
        "country": "AM",
        "name": "Rrind",
        "lat": "39.76111",
        "lng": "45.17792"
    },
    {
        "country": "AM",
        "name": "Noyakert",
        "lat": "39.83069",
        "lng": "44.66949"
    },
    {
        "country": "AM",
        "name": "Mrgavan",
        "lat": "39.97251",
        "lng": "44.53565"
    },
    {
        "country": "AM",
        "name": "Meghri",
        "lat": "38.90292",
        "lng": "46.24458"
    },
    {
        "country": "AM",
        "name": "Malishka",
        "lat": "39.74731",
        "lng": "45.4057"
    },
    {
        "country": "AM",
        "name": "Khndzoresk",
        "lat": "39.50568",
        "lng": "46.4361"
    },
    {
        "country": "AM",
        "name": "Kapan",
        "lat": "39.20755",
        "lng": "46.40576"
    },
    {
        "country": "AM",
        "name": "Jermuk",
        "lat": "39.84168",
        "lng": "45.66949"
    },
    {
        "country": "AM",
        "name": "Goris",
        "lat": "39.51111",
        "lng": "46.34168"
    },
    {
        "country": "AM",
        "name": "Gladzor",
        "lat": "39.7807",
        "lng": "45.34729"
    },
    {
        "country": "AM",
        "name": "Getap’",
        "lat": "39.76392",
        "lng": "45.30829"
    },
    {
        "country": "AM",
        "name": "Dzorastan",
        "lat": "39.27059",
        "lng": "46.3572"
    },
    {
        "country": "AM",
        "name": "Dalar",
        "lat": "39.97653",
        "lng": "44.52649"
    },
    {
        "country": "AM",
        "name": "Burastan",
        "lat": "39.99157",
        "lng": "44.49681"
    },
    {
        "country": "AM",
        "name": "Akner",
        "lat": "39.53491",
        "lng": "46.30732"
    },
    {
        "country": "AM",
        "name": "Brrnakot’",
        "lat": "39.49742",
        "lng": "45.97241"
    },
    {
        "country": "AM",
        "name": "Berk’anush",
        "lat": "39.9779",
        "lng": "44.51672"
    },
    {
        "country": "AM",
        "name": "Aygezard",
        "lat": "39.95436",
        "lng": "44.60229"
    },
    {
        "country": "AM",
        "name": "Aygepat",
        "lat": "39.95845",
        "lng": "44.59981"
    },
    {
        "country": "AM",
        "name": "Aygavan",
        "lat": "39.87327",
        "lng": "44.66984"
    },
    {
        "country": "AM",
        "name": "Avshar",
        "lat": "39.85553",
        "lng": "44.65832"
    },
    {
        "country": "AM",
        "name": "Hats’avan",
        "lat": "39.46405",
        "lng": "45.97047"
    },
    {
        "country": "AM",
        "name": "Artashat",
        "lat": "39.96144",
        "lng": "44.54447"
    },
    {
        "country": "AM",
        "name": "Yeghegis",
        "lat": "39.87231",
        "lng": "45.3501"
    },
    {
        "country": "AM",
        "name": "Armash",
        "lat": "39.76672",
        "lng": "44.8111"
    },
    {
        "country": "AM",
        "name": "Areni",
        "lat": "39.71668",
        "lng": "45.18329"
    },
    {
        "country": "AM",
        "name": "Ararat",
        "lat": "39.83069",
        "lng": "44.70569"
    },
    {
        "country": "AM",
        "name": "Angeghakot’",
        "lat": "39.56952",
        "lng": "45.94452"
    },
    {
        "country": "AM",
        "name": "Aghavnadzor",
        "lat": "39.78607",
        "lng": "45.2279"
    },
    {
        "country": "AM",
        "name": "Agarakadzor",
        "lat": "39.73608",
        "lng": "45.35553"
    },
    {
        "country": "AM",
        "name": "Agarak",
        "lat": "39.20684",
        "lng": "46.5446"
    },
    {
        "country": "AM",
        "name": "Aralez",
        "lat": "39.90008",
        "lng": "44.6557"
    },
    {
        "country": "AM",
        "name": "Horrom",
        "lat": "40.65973",
        "lng": "43.89032"
    },
    {
        "country": "AM",
        "name": "Margara",
        "lat": "40.03332",
        "lng": "44.18048"
    },
    {
        "country": "AM",
        "name": "Zovuni",
        "lat": "40.51111",
        "lng": "44.4389"
    },
    {
        "country": "AM",
        "name": "Zovaber",
        "lat": "40.56671",
        "lng": "44.79028"
    },
    {
        "country": "AM",
        "name": "Zorak",
        "lat": "40.09168",
        "lng": "44.39447"
    },
    {
        "country": "AM",
        "name": "Zarr",
        "lat": "40.25848",
        "lng": "44.73328"
    },
    {
        "country": "AM",
        "name": "Yerevan",
        "lat": "40.18111",
        "lng": "44.51361"
    },
    {
        "country": "AM",
        "name": "Yerazgavors",
        "lat": "40.69505",
        "lng": "43.74722"
    },
    {
        "country": "AM",
        "name": "Yeraskhahun",
        "lat": "40.07233",
        "lng": "44.21948"
    },
    {
        "country": "AM",
        "name": "Yeranos",
        "lat": "40.20428",
        "lng": "45.19209"
    },
    {
        "country": "AM",
        "name": "Yeghegnut",
        "lat": "40.90302",
        "lng": "44.63155"
    },
    {
        "country": "AM",
        "name": "Yeghegnut",
        "lat": "40.08893",
        "lng": "44.16669"
    },
    {
        "country": "AM",
        "name": "Ejmiatsin",
        "lat": "40.16557",
        "lng": "44.29462"
    },
    {
        "country": "AM",
        "name": "Yeghvard",
        "lat": "40.32507",
        "lng": "44.48608"
    },
    {
        "country": "AM",
        "name": "Voskevaz",
        "lat": "40.27508",
        "lng": "44.30011"
    },
    {
        "country": "AM",
        "name": "Voskevan",
        "lat": "41.12081",
        "lng": "45.06381"
    },
    {
        "country": "AM",
        "name": "Voskehask",
        "lat": "40.76426",
        "lng": "43.77474"
    },
    {
        "country": "AM",
        "name": "Verin Getashen",
        "lat": "40.13068",
        "lng": "45.25293"
    },
    {
        "country": "AM",
        "name": "Verin Dvin",
        "lat": "40.02434",
        "lng": "44.59038"
    },
    {
        "country": "AM",
        "name": "Verin Artashat",
        "lat": "39.99731",
        "lng": "44.58893"
    },
    {
        "country": "AM",
        "name": "Akht’ala",
        "lat": "41.16838",
        "lng": "44.75811"
    },
    {
        "country": "AM",
        "name": "Varser",
        "lat": "40.55548",
        "lng": "44.90832"
    },
    {
        "country": "AM",
        "name": "Vardenis",
        "lat": "40.18329",
        "lng": "45.73053"
    },
    {
        "country": "AM",
        "name": "Vardenik",
        "lat": "40.13348",
        "lng": "45.44311"
    },
    {
        "country": "AM",
        "name": "Vahan",
        "lat": "40.57549",
        "lng": "45.39769"
    },
    {
        "country": "AM",
        "name": "Vaghashen",
        "lat": "40.13611",
        "lng": "45.33069"
    },
    {
        "country": "AM",
        "name": "Vahagni",
        "lat": "40.90698",
        "lng": "44.60873"
    },
    {
        "country": "AM",
        "name": "Ushi",
        "lat": "40.34729",
        "lng": "44.37512"
    },
    {
        "country": "AM",
        "name": "Urrut",
        "lat": "41.06778",
        "lng": "44.39628"
    },
    {
        "country": "AM",
        "name": "Tsovinar",
        "lat": "40.15959",
        "lng": "45.46786"
    },
    {
        "country": "AM",
        "name": "Tsovazard",
        "lat": "40.4751",
        "lng": "45.05011"
    },
    {
        "country": "AM",
        "name": "Tsovak",
        "lat": "40.18254",
        "lng": "45.63286"
    },
    {
        "country": "AM",
        "name": "Tsovagyugh",
        "lat": "40.63348",
        "lng": "44.96112"
    },
    {
        "country": "AM",
        "name": "Tsaghkunk’",
        "lat": "40.18048",
        "lng": "44.27228"
    },
    {
        "country": "AM",
        "name": "Tsaghkahovit",
        "lat": "40.63428",
        "lng": "44.22241"
    },
    {
        "country": "AM",
        "name": "Tsaghkaber",
        "lat": "40.79849",
        "lng": "44.10144"
    },
    {
        "country": "AM",
        "name": "Tsaghkadzor",
        "lat": "40.53259",
        "lng": "44.72025"
    },
    {
        "country": "AM",
        "name": "Tsovasar",
        "lat": "40.1382",
        "lng": "45.19096"
    },
    {
        "country": "AM",
        "name": "Tashir",
        "lat": "41.12072",
        "lng": "44.28462"
    },
    {
        "country": "AM",
        "name": "Tandzut",
        "lat": "40.06952",
        "lng": "44.07788"
    },
    {
        "country": "AM",
        "name": "T’alin",
        "lat": "40.39172",
        "lng": "43.87793"
    },
    {
        "country": "AM",
        "name": "Step’anavan",
        "lat": "41.00995",
        "lng": "44.38531"
    },
    {
        "country": "AM",
        "name": "Spitak",
        "lat": "40.83221",
        "lng": "44.26731"
    },
    {
        "country": "AM",
        "name": "Spandaryan",
        "lat": "40.66105",
        "lng": "44.01551"
    },
    {
        "country": "AM",
        "name": "Alashkert",
        "lat": "40.10712",
        "lng": "44.05108"
    },
    {
        "country": "AM",
        "name": "Solak",
        "lat": "40.46252",
        "lng": "44.70709"
    },
    {
        "country": "AM",
        "name": "Sis",
        "lat": "40.05829",
        "lng": "44.38892"
    },
    {
        "country": "AM",
        "name": "Shnogh",
        "lat": "41.14693",
        "lng": "44.84043"
    },
    {
        "country": "AM",
        "name": "Shirak",
        "lat": "40.84042",
        "lng": "43.91582"
    },
    {
        "country": "AM",
        "name": "Shenavan",
        "lat": "40.48328",
        "lng": "44.38348"
    },
    {
        "country": "AM",
        "name": "Shenavan",
        "lat": "40.05548",
        "lng": "43.93048"
    },
    {
        "country": "AM",
        "name": "Sevan",
        "lat": "40.5473",
        "lng": "44.94171"
    },
    {
        "country": "AM",
        "name": "Sarukhan",
        "lat": "40.29169",
        "lng": "45.13068"
    },
    {
        "country": "AM",
        "name": "Sarigyugh",
        "lat": "41.03531",
        "lng": "45.14486"
    },
    {
        "country": "AM",
        "name": "Saratak",
        "lat": "40.6709",
        "lng": "43.87231"
    },
    {
        "country": "AM",
        "name": "Saramej",
        "lat": "40.77487",
        "lng": "44.2222"
    },
    {
        "country": "AM",
        "name": "Sarahart’",
        "lat": "40.87043",
        "lng": "44.21407"
    },
    {
        "country": "AM",
        "name": "Geghakert",
        "lat": "40.18516",
        "lng": "44.24331"
    },
    {
        "country": "AM",
        "name": "Ptghni",
        "lat": "40.25568",
        "lng": "44.58612"
    },
    {
        "country": "AM",
        "name": "Ptghunk’",
        "lat": "40.16388",
        "lng": "44.36389"
    },
    {
        "country": "AM",
        "name": "P’shatavan",
        "lat": "40.03888",
        "lng": "44.06671"
    },
    {
        "country": "AM",
        "name": "Prroshyan",
        "lat": "40.24731",
        "lng": "44.41949"
    },
    {
        "country": "AM",
        "name": "P’ok’r Mant’ash",
        "lat": "40.64026",
        "lng": "44.04666"
    },
    {
        "country": "AM",
        "name": "Zoravan",
        "lat": "40.35553",
        "lng": "44.52228"
    },
    {
        "country": "AM",
        "name": "Pemzashen",
        "lat": "40.58612",
        "lng": "43.94311"
    },
    {
        "country": "AM",
        "name": "Parravak’ar",
        "lat": "40.98248",
        "lng": "45.36696"
    },
    {
        "country": "AM",
        "name": "Oshakan",
        "lat": "40.26392",
        "lng": "44.31671"
    },
    {
        "country": "AM",
        "name": "Sardarapat",
        "lat": "40.13206",
        "lng": "44.00969"
    },
    {
        "country": "AM",
        "name": "Odzun",
        "lat": "41.05321",
        "lng": "44.61341"
    },
    {
        "country": "AM",
        "name": "Noyemberyan",
        "lat": "41.17244",
        "lng": "44.99917"
    },
    {
        "country": "AM",
        "name": "Nor Gyugh",
        "lat": "40.26672",
        "lng": "44.65832"
    },
    {
        "country": "AM",
        "name": "Nor Geghi",
        "lat": "40.32233",
        "lng": "44.58331"
    },
    {
        "country": "AM",
        "name": "Norashen",
        "lat": "41.18886",
        "lng": "44.33336"
    },
    {
        "country": "AM",
        "name": "Norashen",
        "lat": "40.0013",
        "lng": "44.59296"
    },
    {
        "country": "AM",
        "name": "Nor Armavir",
        "lat": "40.08612",
        "lng": "43.99451"
    },
    {
        "country": "AM",
        "name": "Noramarg",
        "lat": "40.02228",
        "lng": "44.42511"
    },
    {
        "country": "AM",
        "name": "Norakert",
        "lat": "40.19733",
        "lng": "44.3501"
    },
    {
        "country": "AM",
        "name": "Noratus",
        "lat": "40.37793",
        "lng": "45.18048"
    },
    {
        "country": "AM",
        "name": "Nerk’in Getashen",
        "lat": "40.14172",
        "lng": "45.27087"
    },
    {
        "country": "AM",
        "name": "Navur",
        "lat": "40.86695",
        "lng": "45.34179"
    },
    {
        "country": "AM",
        "name": "Nalbandyan",
        "lat": "40.0639",
        "lng": "43.98889"
    },
    {
        "country": "AM",
        "name": "Basen",
        "lat": "40.75767",
        "lng": "43.99274"
    },
    {
        "country": "AM",
        "name": "Hovtamej",
        "lat": "40.18329",
        "lng": "44.25848"
    },
    {
        "country": "AM",
        "name": "Mrgashen",
        "lat": "40.28607",
        "lng": "44.54449"
    },
    {
        "country": "AM",
        "name": "Mrgashat",
        "lat": "40.13068",
        "lng": "44.08069"
    },
    {
        "country": "AM",
        "name": "Mrganush",
        "lat": "40.02857",
        "lng": "44.55831"
    },
    {
        "country": "AM",
        "name": "Mosesgegh",
        "lat": "40.90534",
        "lng": "45.48838"
    },
    {
        "country": "AM",
        "name": "Mets Parni",
        "lat": "40.83472",
        "lng": "44.11108"
    },
    {
        "country": "AM",
        "name": "Mets Masrik",
        "lat": "40.21948",
        "lng": "45.76391"
    },
    {
        "country": "AM",
        "name": "Mets Mant’ash",
        "lat": "40.64376",
        "lng": "44.05653"
    },
    {
        "country": "AM",
        "name": "Metsavan",
        "lat": "41.20156",
        "lng": "44.22877"
    },
    {
        "country": "AM",
        "name": "Metsamor",
        "lat": "40.07233",
        "lng": "44.29169"
    },
    {
        "country": "AM",
        "name": "Merdzavan",
        "lat": "40.1814",
        "lng": "44.40033"
    },
    {
        "country": "AM",
        "name": "Meghrashen",
        "lat": "40.6723",
        "lng": "43.95831"
    },
    {
        "country": "AM",
        "name": "Meghradzor",
        "lat": "40.60611",
        "lng": "44.65147"
    },
    {
        "country": "AM",
        "name": "Mayakovski",
        "lat": "40.25293",
        "lng": "44.63892"
    },
    {
        "country": "AM",
        "name": "Masis",
        "lat": "40.06542",
        "lng": "44.41618"
    },
    {
        "country": "AM",
        "name": "Martuni",
        "lat": "40.13892",
        "lng": "45.30548"
    },
    {
        "country": "AM",
        "name": "Marmarashen",
        "lat": "40.05829",
        "lng": "44.47229"
    },
    {
        "country": "AM",
        "name": "Marmashen",
        "lat": "40.83486",
        "lng": "43.7779"
    },
    {
        "country": "AM",
        "name": "Margahovit",
        "lat": "40.73381",
        "lng": "44.68474"
    },
    {
        "country": "AM",
        "name": "Maralik",
        "lat": "40.57507",
        "lng": "43.87231"
    },
    {
        "country": "AM",
        "name": "Mayisyan",
        "lat": "40.84715",
        "lng": "43.83938"
    },
    {
        "country": "AM",
        "name": "Madina",
        "lat": "40.07637",
        "lng": "45.25507"
    },
    {
        "country": "AM",
        "name": "Lukashin",
        "lat": "40.18726",
        "lng": "44.0039"
    },
    {
        "country": "AM",
        "name": "Lorut",
        "lat": "40.93717",
        "lng": "44.77142"
    },
    {
        "country": "AM",
        "name": "Lichk’",
        "lat": "40.15933",
        "lng": "45.23467"
    },
    {
        "country": "AM",
        "name": "Lerrnavan",
        "lat": "40.7882",
        "lng": "44.16024"
    },
    {
        "country": "AM",
        "name": "Lerrnapat",
        "lat": "40.81538",
        "lng": "44.39344"
    },
    {
        "country": "AM",
        "name": "Lerrnants’k’",
        "lat": "40.79532",
        "lng": "44.27435"
    },
    {
        "country": "AM",
        "name": "Lerrnanist",
        "lat": "40.46676",
        "lng": "44.79249"
    },
    {
        "country": "AM",
        "name": "Lerrnakert",
        "lat": "40.5625",
        "lng": "43.9389"
    },
    {
        "country": "AM",
        "name": "Khoronk’",
        "lat": "40.13611",
        "lng": "44.24731"
    },
    {
        "country": "AM",
        "name": "Lchashen",
        "lat": "40.51947",
        "lng": "44.93048"
    },
    {
        "country": "AM",
        "name": "Lchap’",
        "lat": "40.45569",
        "lng": "45.07507"
    },
    {
        "country": "AM",
        "name": "Lanjaghbyur",
        "lat": "40.26947",
        "lng": "45.14447"
    },
    {
        "country": "AM",
        "name": "Kotayk’",
        "lat": "40.27789",
        "lng": "44.66388"
    },
    {
        "country": "AM",
        "name": "Kosh",
        "lat": "40.30011",
        "lng": "44.16107"
    },
    {
        "country": "AM",
        "name": "Vanadzor",
        "lat": "40.80456",
        "lng": "44.4939"
    },
    {
        "country": "AM",
        "name": "Hnaberd",
        "lat": "40.63721",
        "lng": "44.14058"
    },
    {
        "country": "AM",
        "name": "Khasht’arrak",
        "lat": "40.93668",
        "lng": "45.1821"
    },
    {
        "country": "AM",
        "name": "Kasakh",
        "lat": "40.53697",
        "lng": "44.41046"
    },
    {
        "country": "AM",
        "name": "Karchaghbyur",
        "lat": "40.17048",
        "lng": "45.57785"
    },
    {
        "country": "AM",
        "name": "Karbi",
        "lat": "40.33069",
        "lng": "44.37793"
    },
    {
        "country": "AM",
        "name": "Karanlukh",
        "lat": "40.10444",
        "lng": "45.28972"
    },
    {
        "country": "AM",
        "name": "Kaputan",
        "lat": "40.32507",
        "lng": "44.70007"
    },
    {
        "country": "AM",
        "name": "K’anak’erravan",
        "lat": "40.24739",
        "lng": "44.53511"
    },
    {
        "country": "AM",
        "name": "Kamo",
        "lat": "40.82572",
        "lng": "43.95071"
    },
    {
        "country": "AM",
        "name": "Gavarr",
        "lat": "40.35398",
        "lng": "45.12386"
    },
    {
        "country": "AM",
        "name": "Mayisyan",
        "lat": "40.15701",
        "lng": "44.09192"
    },
    {
        "country": "AM",
        "name": "Shahumyan",
        "lat": "40.77482",
        "lng": "44.54596"
    },
    {
        "country": "AM",
        "name": "Ijevan",
        "lat": "40.87877",
        "lng": "45.14851"
    },
    {
        "country": "AM",
        "name": "Hrazdan",
        "lat": "40.49748",
        "lng": "44.7662"
    },
    {
        "country": "AM",
        "name": "Armavir",
        "lat": "40.15446",
        "lng": "44.03815"
    },
    {
        "country": "AM",
        "name": "Hayanist",
        "lat": "40.12231",
        "lng": "44.37793"
    },
    {
        "country": "AM",
        "name": "Haghartsin",
        "lat": "40.77614",
        "lng": "44.96847"
    },
    {
        "country": "AM",
        "name": "Gyumri",
        "lat": "40.7942",
        "lng": "43.84528"
    },
    {
        "country": "AM",
        "name": "Gyulagarak",
        "lat": "40.96715",
        "lng": "44.47144"
    },
    {
        "country": "AM",
        "name": "Ghukasavan",
        "lat": "40.12793",
        "lng": "44.41669"
    },
    {
        "country": "AM",
        "name": "Geghamasar",
        "lat": "40.31091",
        "lng": "45.67924"
    },
    {
        "country": "AM",
        "name": "Gugark’",
        "lat": "40.8046",
        "lng": "44.54025"
    },
    {
        "country": "AM",
        "name": "Tsiatsan",
        "lat": "40.1861",
        "lng": "44.26947"
    },
    {
        "country": "AM",
        "name": "Goght’",
        "lat": "40.1347",
        "lng": "44.78332"
    },
    {
        "country": "AM",
        "name": "Gogaran",
        "lat": "40.89255",
        "lng": "44.19915"
    },
    {
        "country": "AM",
        "name": "Getazat",
        "lat": "40.03844",
        "lng": "44.56369"
    },
    {
        "country": "AM",
        "name": "Getashen",
        "lat": "40.04449",
        "lng": "43.94171"
    },
    {
        "country": "AM",
        "name": "Geghanist",
        "lat": "40.14587",
        "lng": "44.43048"
    },
    {
        "country": "AM",
        "name": "Geghamavan",
        "lat": "40.5625",
        "lng": "44.88892"
    },
    {
        "country": "AM",
        "name": "Garrni",
        "lat": "40.11931",
        "lng": "44.73442"
    },
    {
        "country": "AM",
        "name": "Gagarin",
        "lat": "40.54026",
        "lng": "44.86962"
    },
    {
        "country": "AM",
        "name": "Fantan",
        "lat": "40.39447",
        "lng": "44.6861"
    },
    {
        "country": "AM",
        "name": "Fioletovo",
        "lat": "40.72241",
        "lng": "44.71769"
    },
    {
        "country": "AM",
        "name": "Dzoraghbyur",
        "lat": "40.20412",
        "lng": "44.6415"
    },
    {
        "country": "AM",
        "name": "Dzoragyugh",
        "lat": "40.16957",
        "lng": "45.18337"
    },
    {
        "country": "AM",
        "name": "Dzit’hank’ov",
        "lat": "40.50848",
        "lng": "43.82092"
    },
    {
        "country": "AM",
        "name": "Jrashen",
        "lat": "40.79028",
        "lng": "44.18664"
    },
    {
        "country": "AM",
        "name": "Jrashen",
        "lat": "40.05275",
        "lng": "44.51259"
    },
    {
        "country": "AM",
        "name": "Jrahovit",
        "lat": "40.0473",
        "lng": "44.4751"
    },
    {
        "country": "AM",
        "name": "Janfida",
        "lat": "40.04449",
        "lng": "44.02789"
    },
    {
        "country": "AM",
        "name": "Dvin",
        "lat": "40.01984",
        "lng": "44.58376"
    },
    {
        "country": "AM",
        "name": "Dsegh",
        "lat": "40.9617",
        "lng": "44.65003"
    },
    {
        "country": "AM",
        "name": "Drakhtik",
        "lat": "40.56497",
        "lng": "45.2367"
    },
    {
        "country": "AM",
        "name": "Doghs",
        "lat": "40.22229",
        "lng": "44.27228"
    },
    {
        "country": "AM",
        "name": "Dilijan",
        "lat": "40.7417",
        "lng": "44.8501"
    },
    {
        "country": "AM",
        "name": "Ddmashen",
        "lat": "40.57028",
        "lng": "44.82295"
    },
    {
        "country": "AM",
        "name": "Darpas",
        "lat": "40.83674",
        "lng": "44.42494"
    },
    {
        "country": "AM",
        "name": "Dalarik",
        "lat": "40.2279",
        "lng": "43.87793"
    },
    {
        "country": "AM",
        "name": "Chochkan",
        "lat": "41.18118",
        "lng": "44.83217"
    },
    {
        "country": "AM",
        "name": "Chambarak",
        "lat": "40.59655",
        "lng": "45.35498"
    },
    {
        "country": "AM",
        "name": "Bjni",
        "lat": "40.45831",
        "lng": "44.65008"
    },
    {
        "country": "AM",
        "name": "Byureghavan",
        "lat": "40.31417",
        "lng": "44.59333"
    },
    {
        "country": "AM",
        "name": "Byurakan",
        "lat": "40.33894",
        "lng": "44.27275"
    },
    {
        "country": "AM",
        "name": "Buzhakan",
        "lat": "40.45569",
        "lng": "44.51947"
    },
    {
        "country": "AM",
        "name": "Berd",
        "lat": "40.88135",
        "lng": "45.38901"
    },
    {
        "country": "AM",
        "name": "Bazum",
        "lat": "40.86763",
        "lng": "44.43978"
    },
    {
        "country": "AM",
        "name": "Byuravan",
        "lat": "40.01604",
        "lng": "44.51889"
    },
    {
        "country": "AM",
        "name": "Bambakashat",
        "lat": "40.10828",
        "lng": "44.01947"
    },
    {
        "country": "AM",
        "name": "Balahovit",
        "lat": "40.25153",
        "lng": "44.60828"
    },
    {
        "country": "AM",
        "name": "Bagratashen",
        "lat": "41.24358",
        "lng": "44.81737"
    },
    {
        "country": "AM",
        "name": "Azatan",
        "lat": "40.71959",
        "lng": "43.82727"
    },
    {
        "country": "AM",
        "name": "Haykavan",
        "lat": "40.80312",
        "lng": "43.75173"
    },
    {
        "country": "AM",
        "name": "Aygestan",
        "lat": "40.00293",
        "lng": "44.55829"
    },
    {
        "country": "AM",
        "name": "Aygeshat",
        "lat": "40.23608",
        "lng": "44.28888"
    },
    {
        "country": "AM",
        "name": "Aygeshat",
        "lat": "40.07507",
        "lng": "44.0611"
    },
    {
        "country": "AM",
        "name": "Aknalich",
        "lat": "40.14728",
        "lng": "44.16669"
    },
    {
        "country": "AM",
        "name": "Aygehovit",
        "lat": "40.97951",
        "lng": "45.25033"
    },
    {
        "country": "AM",
        "name": "Artimet",
        "lat": "40.15008",
        "lng": "44.26672"
    },
    {
        "country": "AM",
        "name": "Astghadzor",
        "lat": "40.12231",
        "lng": "45.35553"
    },
    {
        "country": "AM",
        "name": "Ashtarak",
        "lat": "40.2991",
        "lng": "44.36204"
    },
    {
        "country": "AM",
        "name": "Ashnak",
        "lat": "40.33069",
        "lng": "43.91669"
    },
    {
        "country": "AM",
        "name": "Arzni",
        "lat": "40.2973",
        "lng": "44.59869"
    },
    {
        "country": "AM",
        "name": "Arzakan",
        "lat": "40.45007",
        "lng": "44.60828"
    },
    {
        "country": "AM",
        "name": "Arteni",
        "lat": "40.2973",
        "lng": "43.76672"
    },
    {
        "country": "AM",
        "name": "Arshaluys",
        "lat": "40.16949",
        "lng": "44.21393"
    },
    {
        "country": "AM",
        "name": "Argel",
        "lat": "40.37793",
        "lng": "44.6001"
    },
    {
        "country": "AM",
        "name": "Argavand",
        "lat": "40.15289",
        "lng": "44.4389"
    },
    {
        "country": "AM",
        "name": "Argavand",
        "lat": "40.0611",
        "lng": "44.09448"
    },
    {
        "country": "AM",
        "name": "Arevshat",
        "lat": "40.65345",
        "lng": "44.04419"
    },
    {
        "country": "AM",
        "name": "Arevshat",
        "lat": "40.03963",
        "lng": "44.54179"
    },
    {
        "country": "AM",
        "name": "Arevik",
        "lat": "40.7417",
        "lng": "43.9043"
    },
    {
        "country": "AM",
        "name": "Arevik",
        "lat": "40.1001",
        "lng": "44.09448"
    },
    {
        "country": "AM",
        "name": "Arevashogh",
        "lat": "40.86039",
        "lng": "44.27438"
    },
    {
        "country": "AM",
        "name": "Arevashat",
        "lat": "40.14447",
        "lng": "44.37512"
    },
    {
        "country": "AM",
        "name": "Archis",
        "lat": "41.16351",
        "lng": "44.87631"
    },
    {
        "country": "AM",
        "name": "Arbat’",
        "lat": "40.13892",
        "lng": "44.40289"
    },
    {
        "country": "AM",
        "name": "Arazap’",
        "lat": "40.04169",
        "lng": "44.14728"
    },
    {
        "country": "AM",
        "name": "Arrap’i",
        "lat": "40.78276",
        "lng": "43.80583"
    },
    {
        "country": "AM",
        "name": "Aramus",
        "lat": "40.25095",
        "lng": "44.66351"
    },
    {
        "country": "AM",
        "name": "Arak’s",
        "lat": "40.05548",
        "lng": "44.30292"
    },
    {
        "country": "AM",
        "name": "Aragats",
        "lat": "40.48889",
        "lng": "44.3529"
    },
    {
        "country": "AM",
        "name": "Aparan",
        "lat": "40.59323",
        "lng": "44.3589"
    },
    {
        "country": "AM",
        "name": "Apaga",
        "lat": "40.09729",
        "lng": "44.25293"
    },
    {
        "country": "AM",
        "name": "Anushavan",
        "lat": "40.65008",
        "lng": "43.98053"
    },
    {
        "country": "AM",
        "name": "Amasia",
        "lat": "40.95442",
        "lng": "43.7872"
    },
    {
        "country": "AM",
        "name": "Alaverdi",
        "lat": "41.09766",
        "lng": "44.67316"
    },
    {
        "country": "AM",
        "name": "Akunk’",
        "lat": "40.26672",
        "lng": "44.6861"
    },
    {
        "country": "AM",
        "name": "Akunk’",
        "lat": "40.15886",
        "lng": "45.72568"
    },
    {
        "country": "AM",
        "name": "Akhuryan",
        "lat": "40.78003",
        "lng": "43.90027"
    },
    {
        "country": "AM",
        "name": "Aghavnatun",
        "lat": "40.2333",
        "lng": "44.25295"
    },
    {
        "country": "AM",
        "name": "Aghavnadzor",
        "lat": "40.58195",
        "lng": "44.69581"
    },
    {
        "country": "AM",
        "name": "Agarak",
        "lat": "41.01072",
        "lng": "44.46845"
    },
    {
        "country": "AM",
        "name": "Agarakavan",
        "lat": "40.33069",
        "lng": "44.07233"
    },
    {
        "country": "AM",
        "name": "Abovyan",
        "lat": "40.27368",
        "lng": "44.63348"
    },
    {
        "country": "AM",
        "name": "Abovyan",
        "lat": "40.04851",
        "lng": "44.54742"
    },
    {
        "country": "AM",
        "name": "Vardablur",
        "lat": "40.97083",
        "lng": "44.50889"
    },
    {
        "country": "AM",
        "name": "Bardzrashen",
        "lat": "40.08533",
        "lng": "44.57957"
    },
    {
        "country": "AM",
        "name": "Nshavan",
        "lat": "40.02787",
        "lng": "44.52565"
    },
    {
        "country": "AM",
        "name": "Norabats’",
        "lat": "40.10553",
        "lng": "44.43329"
    },
    {
        "country": "AM",
        "name": "Berdavan",
        "lat": "41.20503",
        "lng": "44.99967"
    },
    {
        "country": "AM",
        "name": "Shirakamut",
        "lat": "40.86056",
        "lng": "44.15278"
    },
    {
        "country": "AM",
        "name": "Azatamut",
        "lat": "40.98204",
        "lng": "45.18551"
    },
    {
        "country": "AM",
        "name": "Getahovit",
        "lat": "40.89784",
        "lng": "45.13971"
    },
    {
        "country": "AM",
        "name": "Artsvaberd",
        "lat": "40.83947",
        "lng": "45.47033"
    },
    {
        "country": "AM",
        "name": "Nor Yerznka",
        "lat": "40.30011",
        "lng": "44.38892"
    },
    {
        "country": "AM",
        "name": "Sasunik",
        "lat": "40.25012",
        "lng": "44.34448"
    },
    {
        "country": "AM",
        "name": "Kamaris",
        "lat": "40.23539",
        "lng": "44.69459"
    },
    {
        "country": "AM",
        "name": "Gandzak",
        "lat": "40.31472",
        "lng": "45.11139"
    },
    {
        "country": "AM",
        "name": "Myasnikyan",
        "lat": "40.18048",
        "lng": "43.91949"
    },
    {
        "country": "AM",
        "name": "Lenughi",
        "lat": "40.12512",
        "lng": "43.96393"
    },
    {
        "country": "AM",
        "name": "Metsamor",
        "lat": "40.14447",
        "lng": "44.1167"
    },
    {
        "country": "AM",
        "name": "Gay",
        "lat": "40.08444",
        "lng": "44.30528"
    },
    {
        "country": "AM",
        "name": "Baghramyan",
        "lat": "40.19452",
        "lng": "44.36951"
    },
    {
        "country": "AM",
        "name": "Musalerr",
        "lat": "40.1557",
        "lng": "44.37793"
    },
    {
        "country": "AM",
        "name": "Darakert",
        "lat": "40.10553",
        "lng": "44.41388"
    },
    {
        "country": "AM",
        "name": "Dashtavan",
        "lat": "40.1001",
        "lng": "44.39172"
    },
    {
        "country": "AM",
        "name": "Nizami",
        "lat": "40.09168",
        "lng": "44.4057"
    },
    {
        "country": "AM",
        "name": "Artsvanist",
        "lat": "40.14617",
        "lng": "45.51711"
    },
    {
        "country": "AM",
        "name": "Vardadzor",
        "lat": "40.18701",
        "lng": "45.19212"
    },
    {
        "country": "AM",
        "name": "Taronik",
        "lat": "40.13367",
        "lng": "44.19957"
    },
    {
        "country": "AM",
        "name": "Aknashen",
        "lat": "40.09551",
        "lng": "44.28604"
    },
    {
        "country": "AM",
        "name": "Haykashen",
        "lat": "40.07233",
        "lng": "44.30829"
    },
    {
        "country": "AM",
        "name": "Hovtashat",
        "lat": "40.09729",
        "lng": "44.34448"
    },
    {
        "country": "AM",
        "name": "Sayat’-Nova",
        "lat": "40.07507",
        "lng": "44.40008"
    },
    {
        "country": "AM",
        "name": "Voskehat",
        "lat": "40.14172",
        "lng": "44.33069"
    },
    {
        "country": "AM",
        "name": "Griboyedov",
        "lat": "40.11307",
        "lng": "44.27169"
    },
    {
        "country": "AM",
        "name": "Gmbet’",
        "lat": "40.22369",
        "lng": "44.25409"
    },
    {
        "country": "AM",
        "name": "Aygek",
        "lat": "40.1889",
        "lng": "44.38611"
    },
    {
        "country": "AM",
        "name": "Hovtashen",
        "lat": "40.02508",
        "lng": "44.45007"
    },
    {
        "country": "AM",
        "name": "Dimitrov",
        "lat": "40.00848",
        "lng": "44.4917"
    },
    {
        "country": "AM",
        "name": "Mrgavet",
        "lat": "40.02789",
        "lng": "44.48328"
    },
    {
        "country": "AM",
        "name": "Arevabuyr",
        "lat": "40.03607",
        "lng": "44.46948"
    },
    {
        "country": "AO",
        "name": "Saurimo",
        "lat": "-9.66078",
        "lng": "20.39155"
    },
    {
        "country": "AO",
        "name": "Lucapa",
        "lat": "-8.41915",
        "lng": "20.74466"
    },
    {
        "country": "AO",
        "name": "Lumeje",
        "lat": "-11.55",
        "lng": "20.78333"
    },
    {
        "country": "AO",
        "name": "Luau",
        "lat": "-10.70727",
        "lng": "22.22466"
    },
    {
        "country": "AO",
        "name": "Léua",
        "lat": "-11.65",
        "lng": "20.45"
    },
    {
        "country": "AO",
        "name": "Cazaji",
        "lat": "-11.06715",
        "lng": "20.70148"
    },
    {
        "country": "AO",
        "name": "Uíge",
        "lat": "-7.60874",
        "lng": "15.06131"
    },
    {
        "country": "AO",
        "name": "Soio",
        "lat": "-6.1349",
        "lng": "12.36894"
    },
    {
        "country": "AO",
        "name": "N'zeto",
        "lat": "-7.23116",
        "lng": "12.8666"
    },
    {
        "country": "AO",
        "name": "N’dalatando",
        "lat": "-9.29782",
        "lng": "14.91162"
    },
    {
        "country": "AO",
        "name": "Mbanza Congo",
        "lat": "-6.26703",
        "lng": "14.2401"
    },
    {
        "country": "AO",
        "name": "Malanje",
        "lat": "-9.54015",
        "lng": "16.34096"
    },
    {
        "country": "AO",
        "name": "Luanda",
        "lat": "-8.83682",
        "lng": "13.23432"
    },
    {
        "country": "AO",
        "name": "Caxito",
        "lat": "-8.57848",
        "lng": "13.66425"
    },
    {
        "country": "AO",
        "name": "Camabatela",
        "lat": "-8.18812",
        "lng": "15.37495"
    },
    {
        "country": "AO",
        "name": "Cabinda",
        "lat": "-5.55",
        "lng": "12.2"
    },
    {
        "country": "AO",
        "name": "Uacu Cungo",
        "lat": "-11.35669",
        "lng": "15.11719"
    },
    {
        "country": "AO",
        "name": "Sumbe",
        "lat": "-11.20605",
        "lng": "13.84371"
    },
    {
        "country": "AO",
        "name": "Quibala",
        "lat": "-10.73366",
        "lng": "14.97995"
    },
    {
        "country": "AO",
        "name": "Ondjiva",
        "lat": "-17.06667",
        "lng": "15.73333"
    },
    {
        "country": "AO",
        "name": "Namibe",
        "lat": "-15.19611",
        "lng": "12.15222"
    },
    {
        "country": "AO",
        "name": "Menongue",
        "lat": "-14.6585",
        "lng": "17.69099"
    },
    {
        "country": "AO",
        "name": "Luena",
        "lat": "-11.78333",
        "lng": "19.91667"
    },
    {
        "country": "AO",
        "name": "Lubango",
        "lat": "-14.91717",
        "lng": "13.4925"
    },
    {
        "country": "AO",
        "name": "Longonjo",
        "lat": "-12.90667",
        "lng": "15.25333"
    },
    {
        "country": "AO",
        "name": "Lobito",
        "lat": "-12.3644",
        "lng": "13.53601"
    },
    {
        "country": "AO",
        "name": "Cuito",
        "lat": "-12.38333",
        "lng": "16.93333"
    },
    {
        "country": "AO",
        "name": "Huambo",
        "lat": "-12.77611",
        "lng": "15.73917"
    },
    {
        "country": "AO",
        "name": "Chissamba",
        "lat": "-12.16667",
        "lng": "17.33333"
    },
    {
        "country": "AO",
        "name": "Catumbela",
        "lat": "-12.43002",
        "lng": "13.54677"
    },
    {
        "country": "AO",
        "name": "Catabola",
        "lat": "-12.15",
        "lng": "17.28333"
    },
    {
        "country": "AO",
        "name": "Camacupa",
        "lat": "-12.01667",
        "lng": "17.48333"
    },
    {
        "country": "AO",
        "name": "Caluquembe",
        "lat": "-13.78333",
        "lng": "14.68333"
    },
    {
        "country": "AO",
        "name": "Caconda",
        "lat": "-13.73333",
        "lng": "15.06667"
    },
    {
        "country": "AO",
        "name": "Caála",
        "lat": "-12.8525",
        "lng": "15.56056"
    },
    {
        "country": "AO",
        "name": "Benguela",
        "lat": "-12.57626",
        "lng": "13.40547"
    },
    {
        "country": "AO",
        "name": "Chela",
        "lat": "-12.30261",
        "lng": "15.43358"
    },
    {
        "country": "AO",
        "name": "Chinjenje",
        "lat": "-12.93244",
        "lng": "14.99238"
    },
    {
        "country": "AQ",
        "name": "McMurdo Station",
        "lat": "-77.846",
        "lng": "166.676"
    },
    {
        "country": "AR",
        "name": "Zárate",
        "lat": "-34.09814",
        "lng": "-59.02858"
    },
    {
        "country": "AR",
        "name": "Yataity Calle",
        "lat": "-29.01913",
        "lng": "-58.90846"
    },
    {
        "country": "AR",
        "name": "Yapeyú",
        "lat": "-29.46914",
        "lng": "-56.81841"
    },
    {
        "country": "AR",
        "name": "Wanda",
        "lat": "-25.96879",
        "lng": "-54.56285"
    },
    {
        "country": "AR",
        "name": "Villa Paranacito",
        "lat": "-33.72207",
        "lng": "-58.65798"
    },
    {
        "country": "AR",
        "name": "Villa Ortúzar",
        "lat": "-34.57973",
        "lng": "-58.46829"
    },
    {
        "country": "AR",
        "name": "Villa Ocampo",
        "lat": "-28.48752",
        "lng": "-59.35515"
    },
    {
        "country": "AR",
        "name": "Villa María Grande",
        "lat": "-31.66565",
        "lng": "-59.90182"
    },
    {
        "country": "AR",
        "name": "Villa Mantero",
        "lat": "-32.39727",
        "lng": "-58.74596"
    },
    {
        "country": "AR",
        "name": "Villa Lugano",
        "lat": "-34.67907",
        "lng": "-58.47263"
    },
    {
        "country": "AR",
        "name": "Villa Hernandarias",
        "lat": "-31.23101",
        "lng": "-59.985"
    },
    {
        "country": "AR",
        "name": "Villaguay",
        "lat": "-31.8653",
        "lng": "-59.02689"
    },
    {
        "country": "AR",
        "name": "Villa Gesell",
        "lat": "-37.26394",
        "lng": "-56.97304"
    },
    {
        "country": "AR",
        "name": "Villa Escolar",
        "lat": "-26.62209",
        "lng": "-58.67134"
    },
    {
        "country": "AR",
        "name": "Villa Elisa",
        "lat": "-32.1632",
        "lng": "-58.40082"
    },
    {
        "country": "AR",
        "name": "Villa del Rosario",
        "lat": "-30.79567",
        "lng": "-57.91257"
    },
    {
        "country": "AR",
        "name": "Verónica",
        "lat": "-35.38796",
        "lng": "-57.33691"
    },
    {
        "country": "AR",
        "name": "Veinticinco de Mayo",
        "lat": "-27.37679",
        "lng": "-54.74312"
    },
    {
        "country": "AR",
        "name": "Urdinarrain",
        "lat": "-32.68573",
        "lng": "-58.89323"
    },
    {
        "country": "AR",
        "name": "Ubajay",
        "lat": "-31.79358",
        "lng": "-58.3135"
    },
    {
        "country": "AR",
        "name": "Tres Capones",
        "lat": "-28.00641",
        "lng": "-55.60471"
    },
    {
        "country": "AR",
        "name": "Tigre",
        "lat": "-34.42603",
        "lng": "-58.57962"
    },
    {
        "country": "AR",
        "name": "Tandil",
        "lat": "-37.32167",
        "lng": "-59.13316"
    },
    {
        "country": "AR",
        "name": "Tacuarendí",
        "lat": "-28.41265",
        "lng": "-59.26"
    },
    {
        "country": "AR",
        "name": "Tabossi",
        "lat": "-31.80135",
        "lng": "-59.93477"
    },
    {
        "country": "AR",
        "name": "Suipacha",
        "lat": "-34.7702",
        "lng": "-59.68783"
    },
    {
        "country": "AR",
        "name": "Sauce de Luna",
        "lat": "-31.23794",
        "lng": "-59.21872"
    },
    {
        "country": "AR",
        "name": "Sauce",
        "lat": "-30.08671",
        "lng": "-58.78777"
    },
    {
        "country": "AR",
        "name": "San Vicente",
        "lat": "-35.025",
        "lng": "-58.42276"
    },
    {
        "country": "AR",
        "name": "San Vicente",
        "lat": "-26.61667",
        "lng": "-54.13333"
    },
    {
        "country": "AR",
        "name": "Santo Tomé",
        "lat": "-28.54939",
        "lng": "-56.04077"
    },
    {
        "country": "AR",
        "name": "Santo Pipó",
        "lat": "-27.14132",
        "lng": "-55.40867"
    },
    {
        "country": "AR",
        "name": "Santa Rosa",
        "lat": "-28.26318",
        "lng": "-58.11891"
    },
    {
        "country": "AR",
        "name": "Santa María",
        "lat": "-27.90357",
        "lng": "-55.38541"
    },
    {
        "country": "AR",
        "name": "Santa Lucía",
        "lat": "-28.98746",
        "lng": "-59.10287"
    },
    {
        "country": "AR",
        "name": "Santa Elena",
        "lat": "-30.94768",
        "lng": "-59.78696"
    },
    {
        "country": "AR",
        "name": "Santa Anita",
        "lat": "-32.17476",
        "lng": "-58.78622"
    },
    {
        "country": "AR",
        "name": "Santa Ana",
        "lat": "-30.90004",
        "lng": "-57.93162"
    },
    {
        "country": "AR",
        "name": "Santa Ana",
        "lat": "-27.3674",
        "lng": "-55.58091"
    },
    {
        "country": "AR",
        "name": "San Salvador",
        "lat": "-31.62487",
        "lng": "-58.50524"
    },
    {
        "country": "AR",
        "name": "San Roque",
        "lat": "-28.57457",
        "lng": "-58.70817"
    },
    {
        "country": "AR",
        "name": "San Pedro",
        "lat": "-33.67918",
        "lng": "-59.66633"
    },
    {
        "country": "AR",
        "name": "San Pedro",
        "lat": "-26.62207",
        "lng": "-54.10842"
    },
    {
        "country": "AR",
        "name": "San Miguel del Monte",
        "lat": "-35.43962",
        "lng": "-58.80675"
    },
    {
        "country": "AR",
        "name": "San Miguel",
        "lat": "-27.99585",
        "lng": "-57.58964"
    },
    {
        "country": "AR",
        "name": "San Luis del Palmar",
        "lat": "-27.5079",
        "lng": "-58.55454"
    },
    {
        "country": "AR",
        "name": "San Lorenzo",
        "lat": "-28.13306",
        "lng": "-58.76733"
    },
    {
        "country": "AR",
        "name": "San Justo",
        "lat": "-34.6766",
        "lng": "-58.56058"
    },
    {
        "country": "AR",
        "name": "San Justo",
        "lat": "-32.44654",
        "lng": "-58.43569"
    },
    {
        "country": "AR",
        "name": "San José de Feliciano",
        "lat": "-30.38452",
        "lng": "-58.75167"
    },
    {
        "country": "AR",
        "name": "San José",
        "lat": "-27.76979",
        "lng": "-55.7826"
    },
    {
        "country": "AR",
        "name": "San Javier",
        "lat": "-30.57781",
        "lng": "-59.9317"
    },
    {
        "country": "AR",
        "name": "San Javier",
        "lat": "-27.87427",
        "lng": "-55.13509"
    },
    {
        "country": "AR",
        "name": "San Isidro",
        "lat": "-34.4721",
        "lng": "-58.52708"
    },
    {
        "country": "AR",
        "name": "San Ignacio",
        "lat": "-27.25586",
        "lng": "-55.5339"
    },
    {
        "country": "AR",
        "name": "San Gustavo",
        "lat": "-30.68961",
        "lng": "-59.3984"
    },
    {
        "country": "AR",
        "name": "San Francisco de Laishí",
        "lat": "-26.24262",
        "lng": "-58.63039"
    },
    {
        "country": "AR",
        "name": "San Cosme",
        "lat": "-27.37123",
        "lng": "-58.51214"
    },
    {
        "country": "AR",
        "name": "San Clemente del Tuyú",
        "lat": "-36.35694",
        "lng": "-56.72351"
    },
    {
        "country": "AR",
        "name": "San Cayetano",
        "lat": "-38.34653",
        "lng": "-59.60954"
    },
    {
        "country": "AR",
        "name": "San Carlos",
        "lat": "-27.74586",
        "lng": "-55.89731"
    },
    {
        "country": "AR",
        "name": "San Antonio de Areco",
        "lat": "-34.2503",
        "lng": "-59.47163"
    },
    {
        "country": "AR",
        "name": "San Andrés de Giles",
        "lat": "-34.44721",
        "lng": "-59.44451"
    },
    {
        "country": "AR",
        "name": "Saladillo",
        "lat": "-35.63708",
        "lng": "-59.77788"
    },
    {
        "country": "AR",
        "name": "Saladas",
        "lat": "-28.25384",
        "lng": "-58.62591"
    },
    {
        "country": "AR",
        "name": "Ruiz de Montoya",
        "lat": "-26.98333",
        "lng": "-55.05"
    },
    {
        "country": "AR",
        "name": "Rosario del Tala",
        "lat": "-32.30286",
        "lng": "-59.14545"
    },
    {
        "country": "AR",
        "name": "Roque Pérez",
        "lat": "-35.39794",
        "lng": "-59.33271"
    },
    {
        "country": "AR",
        "name": "Riachuelo",
        "lat": "-27.57754",
        "lng": "-58.73945"
    },
    {
        "country": "AR",
        "name": "Riacho Eh-Eh",
        "lat": "-25.36209",
        "lng": "-58.2775"
    },
    {
        "country": "AR",
        "name": "Retiro",
        "lat": "-34.58333",
        "lng": "-58.38333"
    },
    {
        "country": "AR",
        "name": "Resistencia",
        "lat": "-27.46056",
        "lng": "-58.98389"
    },
    {
        "country": "AR",
        "name": "Reconquista",
        "lat": "-29.15",
        "lng": "-59.65"
    },
    {
        "country": "AR",
        "name": "Rauch",
        "lat": "-36.7745",
        "lng": "-59.08973"
    },
    {
        "country": "AR",
        "name": "Ranchos",
        "lat": "-35.51619",
        "lng": "-58.31733"
    },
    {
        "country": "AR",
        "name": "Quilmes",
        "lat": "-34.72904",
        "lng": "-58.26374"
    },
    {
        "country": "AR",
        "name": "Puerto Yeruá",
        "lat": "-31.53713",
        "lng": "-58.01527"
    },
    {
        "country": "AR",
        "name": "Puerto Vilelas",
        "lat": "-27.51414",
        "lng": "-58.93906"
    },
    {
        "country": "AR",
        "name": "Puerto Tirol",
        "lat": "-27.37218",
        "lng": "-59.08206"
    },
    {
        "country": "AR",
        "name": "Puerto Rico",
        "lat": "-26.79598",
        "lng": "-55.02402"
    },
    {
        "country": "AR",
        "name": "Puerto Piray",
        "lat": "-26.46779",
        "lng": "-54.71476"
    },
    {
        "country": "AR",
        "name": "Puerto Libertad",
        "lat": "-25.91641",
        "lng": "-54.62089"
    },
    {
        "country": "AR",
        "name": "Puerto Leoni",
        "lat": "-26.96069",
        "lng": "-55.1657"
    },
    {
        "country": "AR",
        "name": "Puerto Iguazú",
        "lat": "-25.59912",
        "lng": "-54.57355"
    },
    {
        "country": "AR",
        "name": "Puerto Ibicuy",
        "lat": "-33.73333",
        "lng": "-59.18333"
    },
    {
        "country": "AR",
        "name": "Puerto Esperanza",
        "lat": "-26.01517",
        "lng": "-54.67306"
    },
    {
        "country": "AR",
        "name": "Puerto Eldorado",
        "lat": "-26.40843",
        "lng": "-54.69463"
    },
    {
        "country": "AR",
        "name": "Puerto Bermejo",
        "lat": "-26.93173",
        "lng": "-58.50538"
    },
    {
        "country": "AR",
        "name": "Pueblo Libertador",
        "lat": "-30.22087",
        "lng": "-59.38981"
    },
    {
        "country": "AR",
        "name": "Pronunciamiento",
        "lat": "-32.34617",
        "lng": "-58.43287"
    },
    {
        "country": "AR",
        "name": "Presidencia Roca",
        "lat": "-26.1409",
        "lng": "-59.59541"
    },
    {
        "country": "AR",
        "name": "Presidencia de la Plaza",
        "lat": "-27.00147",
        "lng": "-59.84243"
    },
    {
        "country": "AR",
        "name": "Posadas",
        "lat": "-27.36708",
        "lng": "-55.89608"
    },
    {
        "country": "AR",
        "name": "Pontevedra",
        "lat": "-34.74785",
        "lng": "-58.70072"
    },
    {
        "country": "AR",
        "name": "Pirané",
        "lat": "-25.73239",
        "lng": "-59.10879"
    },
    {
        "country": "AR",
        "name": "Pinamar",
        "lat": "-37.10795",
        "lng": "-56.8614"
    },
    {
        "country": "AR",
        "name": "Pilar",
        "lat": "-34.45866",
        "lng": "-58.9142"
    },
    {
        "country": "AR",
        "name": "Pila",
        "lat": "-36.00058",
        "lng": "-58.14391"
    },
    {
        "country": "AR",
        "name": "Piedras Blancas",
        "lat": "-31.18623",
        "lng": "-59.95957"
    },
    {
        "country": "AR",
        "name": "Picada Gobernador López",
        "lat": "-27.67069",
        "lng": "-55.24585"
    },
    {
        "country": "AR",
        "name": "Perugorría",
        "lat": "-29.34132",
        "lng": "-58.61059"
    },
    {
        "country": "AR",
        "name": "Pedro R. Fernández",
        "lat": "-28.75097",
        "lng": "-58.65583"
    },
    {
        "country": "AR",
        "name": "Paso de los Libres",
        "lat": "-29.71251",
        "lng": "-57.08771"
    },
    {
        "country": "AR",
        "name": "Paso de la Patria",
        "lat": "-27.31676",
        "lng": "-58.57197"
    },
    {
        "country": "AR",
        "name": "Panambí",
        "lat": "-27.72369",
        "lng": "-54.91515"
    },
    {
        "country": "AR",
        "name": "Pampa del Indio",
        "lat": "-26.06468",
        "lng": "-59.91898"
    },
    {
        "country": "AR",
        "name": "Pampa Almirón",
        "lat": "-26.7",
        "lng": "-59.13333"
    },
    {
        "country": "AR",
        "name": "Palo Santo",
        "lat": "-25.56332",
        "lng": "-59.33781"
    },
    {
        "country": "AR",
        "name": "Palmar Grande",
        "lat": "-27.94195",
        "lng": "-57.90057"
    },
    {
        "country": "AR",
        "name": "Olivos",
        "lat": "-34.50747",
        "lng": "-58.48703"
    },
    {
        "country": "AR",
        "name": "Oberá",
        "lat": "-27.48706",
        "lng": "-55.11994"
    },
    {
        "country": "AR",
        "name": "Nueve de Julio",
        "lat": "-28.84051",
        "lng": "-58.8265"
    },
    {
        "country": "AR",
        "name": "Nuestra Señora del Rosario de Caa Catí",
        "lat": "-27.75072",
        "lng": "-57.62073"
    },
    {
        "country": "AR",
        "name": "Nogoyá",
        "lat": "-32.39387",
        "lng": "-59.78953"
    },
    {
        "country": "AR",
        "name": "Necochea",
        "lat": "-38.5545",
        "lng": "-58.73961"
    },
    {
        "country": "AR",
        "name": "Navarro",
        "lat": "-35.00559",
        "lng": "-59.27699"
    },
    {
        "country": "AR",
        "name": "Morón",
        "lat": "-34.6509",
        "lng": "-58.61956"
    },
    {
        "country": "AR",
        "name": "Monte Caseros",
        "lat": "-30.25359",
        "lng": "-57.63626"
    },
    {
        "country": "AR",
        "name": "Montecarlo",
        "lat": "-26.5662",
        "lng": "-54.757"
    },
    {
        "country": "AR",
        "name": "Mojón Grande",
        "lat": "-27.71165",
        "lng": "-55.15631"
    },
    {
        "country": "AR",
        "name": "Mocoretá",
        "lat": "-30.61891",
        "lng": "-57.96344"
    },
    {
        "country": "AR",
        "name": "Miramar",
        "lat": "-38.27161",
        "lng": "-57.8389"
    },
    {
        "country": "AR",
        "name": "Merlo",
        "lat": "-34.66627",
        "lng": "-58.72927"
    },
    {
        "country": "AR",
        "name": "Mercedes",
        "lat": "-34.65145",
        "lng": "-59.43068"
    },
    {
        "country": "AR",
        "name": "Mercedes",
        "lat": "-29.18416",
        "lng": "-58.07519"
    },
    {
        "country": "AR",
        "name": "Mburucuyá",
        "lat": "-28.04532",
        "lng": "-58.22835"
    },
    {
        "country": "AR",
        "name": "Mártires",
        "lat": "-27.43333",
        "lng": "-55.38333"
    },
    {
        "country": "AR",
        "name": "Mariano I. Loza",
        "lat": "-29.37667",
        "lng": "-58.19436"
    },
    {
        "country": "AR",
        "name": "Margarita Belén",
        "lat": "-27.2616",
        "lng": "-58.97219"
    },
    {
        "country": "AR",
        "name": "Mar del Tuyú",
        "lat": "-36.57531",
        "lng": "-56.68883"
    },
    {
        "country": "AR",
        "name": "Mar del Plata",
        "lat": "-38.00228",
        "lng": "-57.55754"
    },
    {
        "country": "AR",
        "name": "Marcos Paz",
        "lat": "-34.78063",
        "lng": "-58.8379"
    },
    {
        "country": "AR",
        "name": "Malabrigo",
        "lat": "-29.34636",
        "lng": "-59.96957"
    },
    {
        "country": "AR",
        "name": "Makallé",
        "lat": "-27.20687",
        "lng": "-59.28696"
    },
    {
        "country": "AR",
        "name": "Maipú",
        "lat": "-36.86274",
        "lng": "-57.88094"
    },
    {
        "country": "AR",
        "name": "Magdalena",
        "lat": "-35.08065",
        "lng": "-57.51301"
    },
    {
        "country": "AR",
        "name": "Maciá",
        "lat": "-32.1722",
        "lng": "-59.39947"
    },
    {
        "country": "AR",
        "name": "Luján",
        "lat": "-34.57028",
        "lng": "-59.105"
    },
    {
        "country": "AR",
        "name": "Lucas González",
        "lat": "-32.3843",
        "lng": "-59.53013"
    },
    {
        "country": "AR",
        "name": "Los Helechos",
        "lat": "-27.5576",
        "lng": "-55.07683"
    },
    {
        "country": "AR",
        "name": "Los Conquistadores",
        "lat": "-30.5908",
        "lng": "-58.46773"
    },
    {
        "country": "AR",
        "name": "Los Charrúas",
        "lat": "-31.17548",
        "lng": "-58.18774"
    },
    {
        "country": "AR",
        "name": "Loreto",
        "lat": "-27.76834",
        "lng": "-57.27531"
    },
    {
        "country": "AR",
        "name": "Loreto",
        "lat": "-27.33635",
        "lng": "-55.52225"
    },
    {
        "country": "AR",
        "name": "Lomas de Zamora",
        "lat": "-34.76088",
        "lng": "-58.40632"
    },
    {
        "country": "AR",
        "name": "Lomas de Vallejos",
        "lat": "-27.73501",
        "lng": "-57.9185"
    },
    {
        "country": "AR",
        "name": "Lobos",
        "lat": "-35.18286",
        "lng": "-59.08975"
    },
    {
        "country": "AR",
        "name": "Lobería",
        "lat": "-38.15574",
        "lng": "-58.7913"
    },
    {
        "country": "AR",
        "name": "Libertad",
        "lat": "-30.043",
        "lng": "-57.8202"
    },
    {
        "country": "AR",
        "name": "Leandro N. Alem",
        "lat": "-27.60341",
        "lng": "-55.32491"
    },
    {
        "country": "AR",
        "name": "La Verde",
        "lat": "-27.12634",
        "lng": "-59.37352"
    },
    {
        "country": "AR",
        "name": "Las Toscas",
        "lat": "-28.3529",
        "lng": "-59.25795"
    },
    {
        "country": "AR",
        "name": "Las Garcitas",
        "lat": "-26.58333",
        "lng": "-59.8"
    },
    {
        "country": "AR",
        "name": "Las Flores",
        "lat": "-36.01403",
        "lng": "-59.09984"
    },
    {
        "country": "AR",
        "name": "Larroque",
        "lat": "-33.03595",
        "lng": "-59.00125"
    },
    {
        "country": "AR",
        "name": "La Plata",
        "lat": "-34.92145",
        "lng": "-57.95453"
    },
    {
        "country": "AR",
        "name": "La Paz",
        "lat": "-30.74485",
        "lng": "-59.64566"
    },
    {
        "country": "AR",
        "name": "Lapachito",
        "lat": "-27.15997",
        "lng": "-59.38605"
    },
    {
        "country": "AR",
        "name": "Lanús",
        "lat": "-34.70252",
        "lng": "-58.3955"
    },
    {
        "country": "AR",
        "name": "La Leonesa",
        "lat": "-27.03786",
        "lng": "-58.70347"
    },
    {
        "country": "AR",
        "name": "Laguna Naick-Neck",
        "lat": "-25.24769",
        "lng": "-58.09383"
    },
    {
        "country": "AR",
        "name": "Laguna Limpia",
        "lat": "-26.49565",
        "lng": "-59.68083"
    },
    {
        "country": "AR",
        "name": "La Escondida",
        "lat": "-27.10724",
        "lng": "-59.44784"
    },
    {
        "country": "AR",
        "name": "La Eduvigis",
        "lat": "-26.85545",
        "lng": "-59.06842"
    },
    {
        "country": "AR",
        "name": "La Cruz",
        "lat": "-29.17443",
        "lng": "-56.64326"
    },
    {
        "country": "AR",
        "name": "La Criolla",
        "lat": "-31.26904",
        "lng": "-58.10558"
    },
    {
        "country": "AR",
        "name": "Juan Pujol",
        "lat": "-30.41873",
        "lng": "-57.85612"
    },
    {
        "country": "AR",
        "name": "Jardín América",
        "lat": "-27.04346",
        "lng": "-55.22698"
    },
    {
        "country": "AR",
        "name": "Ituzaingó",
        "lat": "-34.65803",
        "lng": "-58.66317"
    },
    {
        "country": "AR",
        "name": "Ituzaingó",
        "lat": "-27.58162",
        "lng": "-56.68231"
    },
    {
        "country": "AR",
        "name": "Itatí",
        "lat": "-27.27043",
        "lng": "-58.24458"
    },
    {
        "country": "AR",
        "name": "Itá Ibaté",
        "lat": "-27.42573",
        "lng": "-57.33758"
    },
    {
        "country": "AR",
        "name": "Ibarreta",
        "lat": "-25.21438",
        "lng": "-59.85851"
    },
    {
        "country": "AR",
        "name": "Hurlingham",
        "lat": "-34.59168",
        "lng": "-58.64608"
    },
    {
        "country": "AR",
        "name": "Herrera",
        "lat": "-32.43516",
        "lng": "-58.62462"
    },
    {
        "country": "AR",
        "name": "Herradura",
        "lat": "-26.48705",
        "lng": "-58.31198"
    },
    {
        "country": "AR",
        "name": "Herlitzka",
        "lat": "-27.56516",
        "lng": "-58.25557"
    },
    {
        "country": "AR",
        "name": "Hasenkamp",
        "lat": "-31.51226",
        "lng": "-59.83545"
    },
    {
        "country": "AR",
        "name": "Guaraní",
        "lat": "-27.51667",
        "lng": "-55.16667"
    },
    {
        "country": "AR",
        "name": "Gualeguaychú",
        "lat": "-33.00937",
        "lng": "-58.51722"
    },
    {
        "country": "AR",
        "name": "Gualeguay",
        "lat": "-33.14156",
        "lng": "-59.30966"
    },
    {
        "country": "AR",
        "name": "Goya",
        "lat": "-29.14395",
        "lng": "-59.2651"
    },
    {
        "country": "AR",
        "name": "Gobernador Roca",
        "lat": "-27.18636",
        "lng": "-55.46433"
    },
    {
        "country": "AR",
        "name": "Gobernador Mansilla",
        "lat": "-32.54453",
        "lng": "-59.3548"
    },
    {
        "country": "AR",
        "name": "Gobernador Juan E. Martínez",
        "lat": "-28.91705",
        "lng": "-58.93292"
    },
    {
        "country": "AR",
        "name": "Gobernador Ingeniero Valentín Virasoro",
        "lat": "-28.05",
        "lng": "-56.03333"
    },
    {
        "country": "AR",
        "name": "General Vedia",
        "lat": "-26.93382",
        "lng": "-58.6604"
    },
    {
        "country": "AR",
        "name": "General San Martín",
        "lat": "-34.57424",
        "lng": "-58.53496"
    },
    {
        "country": "AR",
        "name": "General Rodríguez",
        "lat": "-34.60838",
        "lng": "-58.95253"
    },
    {
        "country": "AR",
        "name": "General Lavalle",
        "lat": "-36.40633",
        "lng": "-56.94329"
    },
    {
        "country": "AR",
        "name": "General Las Heras",
        "lat": "-34.92726",
        "lng": "-58.94621"
    },
    {
        "country": "AR",
        "name": "General Juan Madariaga",
        "lat": "-36.99725",
        "lng": "-57.13951"
    },
    {
        "country": "AR",
        "name": "General José de San Martín",
        "lat": "-26.53743",
        "lng": "-59.34158"
    },
    {
        "country": "AR",
        "name": "General Guido",
        "lat": "-36.64074",
        "lng": "-57.79174"
    },
    {
        "country": "AR",
        "name": "General Galarza",
        "lat": "-32.72034",
        "lng": "-59.39615"
    },
    {
        "country": "AR",
        "name": "General Conesa",
        "lat": "-36.52013",
        "lng": "-57.32537"
    },
    {
        "country": "AR",
        "name": "General Campos",
        "lat": "-31.52311",
        "lng": "-58.4049"
    },
    {
        "country": "AR",
        "name": "General Belgrano",
        "lat": "-35.76952",
        "lng": "-58.49341"
    },
    {
        "country": "AR",
        "name": "General Alvear",
        "lat": "-27.43333",
        "lng": "-55.16667"
    },
    {
        "country": "AR",
        "name": "Garupá",
        "lat": "-27.48171",
        "lng": "-55.82921"
    },
    {
        "country": "AR",
        "name": "Garuhapé",
        "lat": "-26.81768",
        "lng": "-54.95665"
    },
    {
        "country": "AR",
        "name": "Garruchos",
        "lat": "-28.18514",
        "lng": "-55.63947"
    },
    {
        "country": "AR",
        "name": "Formosa",
        "lat": "-26.17753",
        "lng": "-58.17814"
    },
    {
        "country": "AR",
        "name": "Fontana",
        "lat": "-27.41813",
        "lng": "-59.02392"
    },
    {
        "country": "AR",
        "name": "Florentino Ameghino",
        "lat": "-27.56667",
        "lng": "-55.13333"
    },
    {
        "country": "AR",
        "name": "Florencio Varela",
        "lat": "-34.82722",
        "lng": "-58.39556"
    },
    {
        "country": "AR",
        "name": "Felipe Yofré",
        "lat": "-29.10226",
        "lng": "-58.33772"
    },
    {
        "country": "AR",
        "name": "Federal",
        "lat": "-30.95465",
        "lng": "-58.78326"
    },
    {
        "country": "AR",
        "name": "Federación",
        "lat": "-31.00621",
        "lng": "-57.89962"
    },
    {
        "country": "AR",
        "name": "José María Ezeiza",
        "lat": "-34.84787",
        "lng": "-58.52869"
    },
    {
        "country": "AR",
        "name": "Esquina",
        "lat": "-30.01444",
        "lng": "-59.52719"
    },
    {
        "country": "AR",
        "name": "Espinillo",
        "lat": "-24.98132",
        "lng": "-58.5521"
    },
    {
        "country": "AR",
        "name": "Ensenada",
        "lat": "-34.86186",
        "lng": "-57.91126"
    },
    {
        "country": "AR",
        "name": "Empedrado",
        "lat": "-27.95125",
        "lng": "-58.80542"
    },
    {
        "country": "AR",
        "name": "El Soberbio",
        "lat": "-27.29847",
        "lng": "-54.19877"
    },
    {
        "country": "AR",
        "name": "Eldorado",
        "lat": "-26.40484",
        "lng": "-54.62478"
    },
    {
        "country": "AR",
        "name": "El Colorado",
        "lat": "-26.30808",
        "lng": "-59.37291"
    },
    {
        "country": "AR",
        "name": "El Alcázar",
        "lat": "-26.71459",
        "lng": "-54.81523"
    },
    {
        "country": "AR",
        "name": "Dos de Mayo",
        "lat": "-27.02277",
        "lng": "-54.68669"
    },
    {
        "country": "AR",
        "name": "Dos Arroyos",
        "lat": "-27.70784",
        "lng": "-55.23364"
    },
    {
        "country": "AR",
        "name": "Domínguez",
        "lat": "-31.9871",
        "lng": "-58.96197"
    },
    {
        "country": "AR",
        "name": "Dolores",
        "lat": "-36.31321",
        "lng": "-57.67918"
    },
    {
        "country": "AR",
        "name": "Curuzú Cuatiá",
        "lat": "-29.79171",
        "lng": "-58.0546"
    },
    {
        "country": "AR",
        "name": "Cruz de los Milagros",
        "lat": "-28.83646",
        "lng": "-59.00476"
    },
    {
        "country": "AR",
        "name": "Coté-Lai",
        "lat": "-27.5",
        "lng": "-59.6"
    },
    {
        "country": "AR",
        "name": "Corrientes",
        "lat": "-27.4806",
        "lng": "-58.8341"
    },
    {
        "country": "AR",
        "name": "Coronel Vidal",
        "lat": "-37.44604",
        "lng": "-57.72865"
    },
    {
        "country": "AR",
        "name": "Conscripto Bernardi",
        "lat": "-31.04837",
        "lng": "-59.08435"
    },
    {
        "country": "AR",
        "name": "Concordia",
        "lat": "-31.39296",
        "lng": "-58.02089"
    },
    {
        "country": "AR",
        "name": "Concepción del Uruguay",
        "lat": "-32.48249",
        "lng": "-58.23722"
    },
    {
        "country": "AR",
        "name": "Concepción de la Sierra",
        "lat": "-27.98311",
        "lng": "-55.52031"
    },
    {
        "country": "AR",
        "name": "Concepción",
        "lat": "-28.39175",
        "lng": "-57.88777"
    },
    {
        "country": "AR",
        "name": "Comandante Fontana",
        "lat": "-25.33453",
        "lng": "-59.68212"
    },
    {
        "country": "AR",
        "name": "Colonias Unidas",
        "lat": "-26.69825",
        "lng": "-59.63154"
    },
    {
        "country": "AR",
        "name": "Colonia Elisa",
        "lat": "-26.93041",
        "lng": "-59.51861"
    },
    {
        "country": "AR",
        "name": "Colonia Elía",
        "lat": "-32.66625",
        "lng": "-58.32148"
    },
    {
        "country": "AR",
        "name": "Colonia Benítez",
        "lat": "-27.33099",
        "lng": "-58.94622"
    },
    {
        "country": "AR",
        "name": "Colonia Aurora",
        "lat": "-27.47428",
        "lng": "-54.52498"
    },
    {
        "country": "AR",
        "name": "Colón",
        "lat": "-32.22337",
        "lng": "-58.14341"
    },
    {
        "country": "AR",
        "name": "Colegiales",
        "lat": "-34.57365",
        "lng": "-58.44924"
    },
    {
        "country": "AR",
        "name": "Clorinda",
        "lat": "-25.28481",
        "lng": "-57.71851"
    },
    {
        "country": "AR",
        "name": "Ciervo Petiso",
        "lat": "-26.58041",
        "lng": "-59.63094"
    },
    {
        "country": "AR",
        "name": "Chavarría",
        "lat": "-28.95489",
        "lng": "-58.57277"
    },
    {
        "country": "AR",
        "name": "Chascomús",
        "lat": "-35.57297",
        "lng": "-58.00809"
    },
    {
        "country": "AR",
        "name": "Charadai",
        "lat": "-27.63333",
        "lng": "-59.9"
    },
    {
        "country": "AR",
        "name": "Chajarí",
        "lat": "-30.75048",
        "lng": "-57.97962"
    },
    {
        "country": "AR",
        "name": "Cerro Corá",
        "lat": "-27.5131",
        "lng": "-55.60896"
    },
    {
        "country": "AR",
        "name": "Cerro Azul",
        "lat": "-27.6331",
        "lng": "-55.4962"
    },
    {
        "country": "AR",
        "name": "Ceibas",
        "lat": "-33.43333",
        "lng": "-58.75"
    },
    {
        "country": "AR",
        "name": "Castelli",
        "lat": "-36.08949",
        "lng": "-57.80393"
    },
    {
        "country": "AR",
        "name": "Caseros",
        "lat": "-34.60333",
        "lng": "-58.56409"
    },
    {
        "country": "AR",
        "name": "Caseros",
        "lat": "-32.46325",
        "lng": "-58.47872"
    },
    {
        "country": "AR",
        "name": "Carmen de Areco",
        "lat": "-34.37743",
        "lng": "-59.82395"
    },
    {
        "country": "AR",
        "name": "Caraguatay",
        "lat": "-26.60587",
        "lng": "-54.78093"
    },
    {
        "country": "AR",
        "name": "Capitán Solari",
        "lat": "-26.80215",
        "lng": "-59.56089"
    },
    {
        "country": "AR",
        "name": "Capitán Sarmiento",
        "lat": "-34.17238",
        "lng": "-59.79048"
    },
    {
        "country": "AR",
        "name": "Capioví",
        "lat": "-26.92998",
        "lng": "-55.06084"
    },
    {
        "country": "AR",
        "name": "Capilla del Señor",
        "lat": "-34.29207",
        "lng": "-59.10179"
    },
    {
        "country": "AR",
        "name": "Cañuelas",
        "lat": "-35.05184",
        "lng": "-58.76061"
    },
    {
        "country": "AR",
        "name": "Candelaria",
        "lat": "-27.4595",
        "lng": "-55.74536"
    },
    {
        "country": "AR",
        "name": "Campo Viera",
        "lat": "-27.38333",
        "lng": "-55.03333"
    },
    {
        "country": "AR",
        "name": "Campo Ramón",
        "lat": "-27.46108",
        "lng": "-55.01901"
    },
    {
        "country": "AR",
        "name": "Campo Grande",
        "lat": "-27.2077",
        "lng": "-54.97977"
    },
    {
        "country": "AR",
        "name": "Campana",
        "lat": "-34.16874",
        "lng": "-58.95914"
    },
    {
        "country": "AR",
        "name": "Buenos Aires",
        "lat": "-34.61315",
        "lng": "-58.37723"
    },
    {
        "country": "AR",
        "name": "Brandsen",
        "lat": "-35.16842",
        "lng": "-58.23427"
    },
    {
        "country": "AR",
        "name": "Bovril",
        "lat": "-31.34311",
        "lng": "-59.44512"
    },
    {
        "country": "AR",
        "name": "Bonpland",
        "lat": "-29.81708",
        "lng": "-57.42974"
    },
    {
        "country": "AR",
        "name": "Bonpland",
        "lat": "-27.48218",
        "lng": "-55.47756"
    },
    {
        "country": "AR",
        "name": "Boedo",
        "lat": "-34.63333",
        "lng": "-58.41667"
    },
    {
        "country": "AR",
        "name": "Berón de Astrada",
        "lat": "-27.55067",
        "lng": "-57.5346"
    },
    {
        "country": "AR",
        "name": "Bernardo de Irigoyen",
        "lat": "-26.2552",
        "lng": "-53.64581"
    },
    {
        "country": "AR",
        "name": "Berisso",
        "lat": "-34.8735",
        "lng": "-57.88303"
    },
    {
        "country": "AR",
        "name": "Berazategui",
        "lat": "-34.76531",
        "lng": "-58.21278"
    },
    {
        "country": "AR",
        "name": "Benito Juárez",
        "lat": "-37.67188",
        "lng": "-59.80653"
    },
    {
        "country": "AR",
        "name": "Bella Vista",
        "lat": "-28.50918",
        "lng": "-59.04009"
    },
    {
        "country": "AR",
        "name": "Belgrano",
        "lat": "-34.5627",
        "lng": "-58.45829"
    },
    {
        "country": "AR",
        "name": "Belén de Escobar",
        "lat": "-34.34833",
        "lng": "-58.79265"
    },
    {
        "country": "AR",
        "name": "Basail",
        "lat": "-27.86667",
        "lng": "-59.3"
    },
    {
        "country": "AR",
        "name": "Barranqueras",
        "lat": "-27.48132",
        "lng": "-58.93925"
    },
    {
        "country": "AR",
        "name": "Barracas",
        "lat": "-34.64966",
        "lng": "-58.38341"
    },
    {
        "country": "AR",
        "name": "Baradero",
        "lat": "-33.81105",
        "lng": "-59.50807"
    },
    {
        "country": "AR",
        "name": "Balcarce",
        "lat": "-37.84616",
        "lng": "-58.25522"
    },
    {
        "country": "AR",
        "name": "Azul",
        "lat": "-36.77698",
        "lng": "-59.85854"
    },
    {
        "country": "AR",
        "name": "Azara",
        "lat": "-28.0616",
        "lng": "-55.67797"
    },
    {
        "country": "AR",
        "name": "Ayacucho",
        "lat": "-37.15185",
        "lng": "-58.48691"
    },
    {
        "country": "AR",
        "name": "Avellaneda",
        "lat": "-29.11761",
        "lng": "-59.65834"
    },
    {
        "country": "AR",
        "name": "Arroyo del Medio",
        "lat": "-27.7",
        "lng": "-55.41667"
    },
    {
        "country": "AR",
        "name": "Aristóbulo del Valle",
        "lat": "-27.09625",
        "lng": "-54.89626"
    },
    {
        "country": "AR",
        "name": "Apóstoles",
        "lat": "-27.91421",
        "lng": "-55.75355"
    },
    {
        "country": "AR",
        "name": "Alvear",
        "lat": "-29.09683",
        "lng": "-56.55043"
    },
    {
        "country": "AR",
        "name": "Almafuerte",
        "lat": "-27.50518",
        "lng": "-55.39497"
    },
    {
        "country": "AR",
        "name": "Aldea San Antonio",
        "lat": "-32.62376",
        "lng": "-58.70333"
    },
    {
        "country": "AR",
        "name": "Alba Posse",
        "lat": "-27.56978",
        "lng": "-54.68262"
    },
    {
        "country": "AR",
        "name": "Guernica",
        "lat": "-34.91722",
        "lng": "-58.38694"
    },
    {
        "country": "AR",
        "name": "Villa Basilio Nievas",
        "lat": "-31.55",
        "lng": "-68.73333"
    },
    {
        "country": "AR",
        "name": "Zapala",
        "lat": "-38.89916",
        "lng": "-70.05442"
    },
    {
        "country": "AR",
        "name": "Yuto",
        "lat": "-23.64342",
        "lng": "-64.47194"
    },
    {
        "country": "AR",
        "name": "Yerba Buena",
        "lat": "-26.81667",
        "lng": "-65.31667"
    },
    {
        "country": "AR",
        "name": "Yacimiento Río Turbio",
        "lat": "-51.57321",
        "lng": "-72.3508"
    },
    {
        "country": "AR",
        "name": "Winifreda",
        "lat": "-36.22643",
        "lng": "-64.23388"
    },
    {
        "country": "AR",
        "name": "Wenceslao Escalante",
        "lat": "-33.17303",
        "lng": "-62.77078"
    },
    {
        "country": "AR",
        "name": "Vista Alegre",
        "lat": "-38.75",
        "lng": "-68.18333"
    },
    {
        "country": "AR",
        "name": "Vinchina",
        "lat": "-28.75964",
        "lng": "-68.20692"
    },
    {
        "country": "AR",
        "name": "Villa Valeria",
        "lat": "-34.34093",
        "lng": "-64.9203"
    },
    {
        "country": "AR",
        "name": "Villa Urquiza",
        "lat": "-31.64731",
        "lng": "-60.3748"
    },
    {
        "country": "AR",
        "name": "Villa Unión",
        "lat": "-29.41347",
        "lng": "-62.78822"
    },
    {
        "country": "AR",
        "name": "Villa Unión",
        "lat": "-29.31595",
        "lng": "-68.22658"
    },
    {
        "country": "AR",
        "name": "Villa Tulumba",
        "lat": "-30.39552",
        "lng": "-64.12241"
    },
    {
        "country": "AR",
        "name": "Villa Trinidad",
        "lat": "-30.21329",
        "lng": "-61.87597"
    },
    {
        "country": "AR",
        "name": "Villa Santa Rosa",
        "lat": "-31.74434",
        "lng": "-68.31411"
    },
    {
        "country": "AR",
        "name": "Villa Rumipal",
        "lat": "-32.1879",
        "lng": "-64.48027"
    },
    {
        "country": "AR",
        "name": "Villa Regina",
        "lat": "-39.1",
        "lng": "-67.06667"
    },
    {
        "country": "AR",
        "name": "Villa Reducción",
        "lat": "-33.20105",
        "lng": "-63.86234"
    },
    {
        "country": "AR",
        "name": "Villa Paula de Sarmiento",
        "lat": "-31.4933",
        "lng": "-68.53838"
    },
    {
        "country": "AR",
        "name": "Villa Ojo de Agua",
        "lat": "-29.50003",
        "lng": "-63.69377"
    },
    {
        "country": "AR",
        "name": "Villa Nueva",
        "lat": "-32.89722",
        "lng": "-68.78038"
    },
    {
        "country": "AR",
        "name": "Villa Nueva",
        "lat": "-32.43293",
        "lng": "-63.24763"
    },
    {
        "country": "AR",
        "name": "Villa Mugueta",
        "lat": "-33.31129",
        "lng": "-61.05515"
    },
    {
        "country": "AR",
        "name": "Villa Media Agua",
        "lat": "-31.98267",
        "lng": "-68.42394"
    },
    {
        "country": "AR",
        "name": "Villa María",
        "lat": "-32.40751",
        "lng": "-63.24016"
    },
    {
        "country": "AR",
        "name": "Villa Las Rosas",
        "lat": "-31.95021",
        "lng": "-65.05354"
    },
    {
        "country": "AR",
        "name": "Villa La Angostura",
        "lat": "-40.76173",
        "lng": "-71.64631"
    },
    {
        "country": "AR",
        "name": "Villa Krause",
        "lat": "-31.56667",
        "lng": "-68.53333"
    },
    {
        "country": "AR",
        "name": "Villa Huidobro",
        "lat": "-34.83826",
        "lng": "-64.58686"
    },
    {
        "country": "AR",
        "name": "Villa Giardino",
        "lat": "-31.03333",
        "lng": "-64.48333"
    },
    {
        "country": "AR",
        "name": "Villa General Roca",
        "lat": "-32.66535",
        "lng": "-66.45052"
    },
    {
        "country": "AR",
        "name": "Villa General Mitre",
        "lat": "-29.1431",
        "lng": "-62.65248"
    },
    {
        "country": "AR",
        "name": "Villa General Belgrano",
        "lat": "-31.97542",
        "lng": "-64.5559"
    },
    {
        "country": "AR",
        "name": "Villa Dolores",
        "lat": "-31.94585",
        "lng": "-65.18958"
    },
    {
        "country": "AR",
        "name": "Villa de Soto",
        "lat": "-30.85523",
        "lng": "-64.99947"
    },
    {
        "country": "AR",
        "name": "Villa de María",
        "lat": "-29.90195",
        "lng": "-63.72289"
    },
    {
        "country": "AR",
        "name": "Villa del Totoral",
        "lat": "-30.81667",
        "lng": "-63.71667"
    },
    {
        "country": "AR",
        "name": "Villa del Salvador",
        "lat": "-31.44155",
        "lng": "-68.39745"
    },
    {
        "country": "AR",
        "name": "Villa del Rosario",
        "lat": "-31.5566",
        "lng": "-63.53452"
    },
    {
        "country": "AR",
        "name": "Villa del Dique",
        "lat": "-32.17667",
        "lng": "-64.45543"
    },
    {
        "country": "AR",
        "name": "Villa Cura Brochero",
        "lat": "-31.70578",
        "lng": "-65.01796"
    },
    {
        "country": "AR",
        "name": "Villa Constitución",
        "lat": "-33.22778",
        "lng": "-60.3297"
    },
    {
        "country": "AR",
        "name": "Villa Concepción del Tío",
        "lat": "-31.32259",
        "lng": "-62.81354"
    },
    {
        "country": "AR",
        "name": "Chañar Ladeado",
        "lat": "-33.32524",
        "lng": "-62.03831"
    },
    {
        "country": "AR",
        "name": "Villa Castelli",
        "lat": "-29.00295",
        "lng": "-68.21277"
    },
    {
        "country": "AR",
        "name": "Villa Carlos Paz",
        "lat": "-31.42414",
        "lng": "-64.49778"
    },
    {
        "country": "AR",
        "name": "Villa Cañás",
        "lat": "-34.00565",
        "lng": "-61.60757"
    },
    {
        "country": "AR",
        "name": "Villa Bustos",
        "lat": "-29.28483",
        "lng": "-67.03308"
    },
    {
        "country": "AR",
        "name": "Villa Berthet",
        "lat": "-27.29174",
        "lng": "-60.41263"
    },
    {
        "country": "AR",
        "name": "Villa Atamisqui",
        "lat": "-28.49609",
        "lng": "-63.81609"
    },
    {
        "country": "AR",
        "name": "Villa Ascasubi",
        "lat": "-32.16351",
        "lng": "-63.89157"
    },
    {
        "country": "AR",
        "name": "Villa Ángela",
        "lat": "-27.57383",
        "lng": "-60.71526"
    },
    {
        "country": "AR",
        "name": "Villa Allende",
        "lat": "-31.29458",
        "lng": "-64.29538"
    },
    {
        "country": "AR",
        "name": "Villa Aberastain",
        "lat": "-31.65",
        "lng": "-68.58333"
    },
    {
        "country": "AR",
        "name": "Viedma",
        "lat": "-40.81345",
        "lng": "-62.99668"
    },
    {
        "country": "AR",
        "name": "Vicuña Mackenna",
        "lat": "-33.91965",
        "lng": "-64.39215"
    },
    {
        "country": "AR",
        "name": "Victorica",
        "lat": "-36.21505",
        "lng": "-65.43586"
    },
    {
        "country": "AR",
        "name": "Victoria",
        "lat": "-32.61841",
        "lng": "-60.15478"
    },
    {
        "country": "AR",
        "name": "Viamonte",
        "lat": "-33.74647",
        "lng": "-63.09764"
    },
    {
        "country": "AR",
        "name": "Viale",
        "lat": "-31.86782",
        "lng": "-60.00722"
    },
    {
        "country": "AR",
        "name": "Vera",
        "lat": "-29.4593",
        "lng": "-60.21261"
    },
    {
        "country": "AR",
        "name": "Venado Tuerto",
        "lat": "-33.74556",
        "lng": "-61.96885"
    },
    {
        "country": "AR",
        "name": "28 de Noviembre",
        "lat": "-51.5839",
        "lng": "-72.21382"
    },
    {
        "country": "AR",
        "name": "Veinticinco de Mayo",
        "lat": "-37.7741",
        "lng": "-67.71638"
    },
    {
        "country": "AR",
        "name": "Veinticinco de Mayo",
        "lat": "-35.4323",
        "lng": "-60.17271"
    },
    {
        "country": "AR",
        "name": "Vedia",
        "lat": "-34.49558",
        "lng": "-61.54138"
    },
    {
        "country": "AR",
        "name": "Valle Hermoso",
        "lat": "-31.11732",
        "lng": "-64.48084"
    },
    {
        "country": "AR",
        "name": "Valle Grande",
        "lat": "-23.47735",
        "lng": "-64.9425"
    },
    {
        "country": "AR",
        "name": "Valcheta",
        "lat": "-40.7",
        "lng": "-66.15"
    },
    {
        "country": "AR",
        "name": "Ushuaia",
        "lat": "-54.8",
        "lng": "-68.3"
    },
    {
        "country": "AR",
        "name": "Uriburu",
        "lat": "-36.50682",
        "lng": "-63.86225"
    },
    {
        "country": "AR",
        "name": "Unquillo",
        "lat": "-31.23073",
        "lng": "-64.31615"
    },
    {
        "country": "AR",
        "name": "Unión",
        "lat": "-35.15282",
        "lng": "-65.94602"
    },
    {
        "country": "AR",
        "name": "Ulapes",
        "lat": "-31.57362",
        "lng": "-66.2365"
    },
    {
        "country": "AR",
        "name": "Ucacha",
        "lat": "-33.03203",
        "lng": "-63.50666"
    },
    {
        "country": "AR",
        "name": "Tupungato",
        "lat": "-33.37146",
        "lng": "-69.14845"
    },
    {
        "country": "AR",
        "name": "Tunuyán",
        "lat": "-33.57653",
        "lng": "-69.01538"
    },
    {
        "country": "AR",
        "name": "Tumbaya",
        "lat": "-23.85626",
        "lng": "-65.45741"
    },
    {
        "country": "AR",
        "name": "Trevelin",
        "lat": "-43.0858",
        "lng": "-71.46386"
    },
    {
        "country": "AR",
        "name": "Tres Lomas",
        "lat": "-36.45722",
        "lng": "-62.86047"
    },
    {
        "country": "AR",
        "name": "Tres Isletas",
        "lat": "-26.34067",
        "lng": "-60.43207"
    },
    {
        "country": "AR",
        "name": "Tres Arroyos",
        "lat": "-38.37394",
        "lng": "-60.27978"
    },
    {
        "country": "AR",
        "name": "Tres Algarrobos",
        "lat": "-35.19471",
        "lng": "-62.77396"
    },
    {
        "country": "AR",
        "name": "Trenque Lauquen",
        "lat": "-35.97035",
        "lng": "-62.73432"
    },
    {
        "country": "AR",
        "name": "Trenel",
        "lat": "-35.69837",
        "lng": "-64.13218"
    },
    {
        "country": "AR",
        "name": "Trelew",
        "lat": "-43.24895",
        "lng": "-65.30505"
    },
    {
        "country": "AR",
        "name": "Trancas",
        "lat": "-26.21575",
        "lng": "-65.28492"
    },
    {
        "country": "AR",
        "name": "Totoras",
        "lat": "-32.5844",
        "lng": "-61.16852"
    },
    {
        "country": "AR",
        "name": "Tostado",
        "lat": "-29.23202",
        "lng": "-61.76917"
    },
    {
        "country": "AR",
        "name": "Tornquist",
        "lat": "-38.10122",
        "lng": "-62.22267"
    },
    {
        "country": "AR",
        "name": "Toledo",
        "lat": "-31.55574",
        "lng": "-64.00947"
    },
    {
        "country": "AR",
        "name": "Toay",
        "lat": "-36.67338",
        "lng": "-64.3786"
    },
    {
        "country": "AR",
        "name": "Tío Pujio",
        "lat": "-32.2879",
        "lng": "-63.35598"
    },
    {
        "country": "AR",
        "name": "Tintina",
        "lat": "-27.03333",
        "lng": "-62.71667"
    },
    {
        "country": "AR",
        "name": "Tinogasta",
        "lat": "-28.06319",
        "lng": "-67.56488"
    },
    {
        "country": "AR",
        "name": "Tilisarao",
        "lat": "-32.73292",
        "lng": "-65.29109"
    },
    {
        "country": "AR",
        "name": "Tilcara",
        "lat": "-23.57757",
        "lng": "-65.3509"
    },
    {
        "country": "AR",
        "name": "Ticino",
        "lat": "-32.6935",
        "lng": "-63.43606"
    },
    {
        "country": "AR",
        "name": "Termas de Río Hondo",
        "lat": "-27.49362",
        "lng": "-64.85972"
    },
    {
        "country": "AR",
        "name": "Telsen",
        "lat": "-42.43553",
        "lng": "-66.94078"
    },
    {
        "country": "AR",
        "name": "Telén",
        "lat": "-36.26429",
        "lng": "-65.51018"
    },
    {
        "country": "AR",
        "name": "Tecka",
        "lat": "-43.49489",
        "lng": "-70.8102"
    },
    {
        "country": "AR",
        "name": "Tartagal",
        "lat": "-22.51637",
        "lng": "-63.80131"
    },
    {
        "country": "AR",
        "name": "Tapalqué",
        "lat": "-36.35493",
        "lng": "-60.0264"
    },
    {
        "country": "AR",
        "name": "Tancacha",
        "lat": "-32.24309",
        "lng": "-63.9807"
    },
    {
        "country": "AR",
        "name": "Tamberías",
        "lat": "-31.46646",
        "lng": "-69.4255"
    },
    {
        "country": "AR",
        "name": "Tama",
        "lat": "-30.50798",
        "lng": "-66.5298"
    },
    {
        "country": "AR",
        "name": "Tafí Viejo",
        "lat": "-26.73201",
        "lng": "-65.25921"
    },
    {
        "country": "AR",
        "name": "Tafí del Valle",
        "lat": "-26.85275",
        "lng": "-65.70983"
    },
    {
        "country": "AR",
        "name": "Taco Pozo",
        "lat": "-25.61667",
        "lng": "-63.28333"
    },
    {
        "country": "AR",
        "name": "Susques",
        "lat": "-23.39864",
        "lng": "-66.36701"
    },
    {
        "country": "AR",
        "name": "Suncho Corral",
        "lat": "-27.93357",
        "lng": "-63.42938"
    },
    {
        "country": "AR",
        "name": "Sunchales",
        "lat": "-30.94404",
        "lng": "-61.56148"
    },
    {
        "country": "AR",
        "name": "Sumampa",
        "lat": "-29.3847",
        "lng": "-63.46907"
    },
    {
        "country": "AR",
        "name": "Simoca",
        "lat": "-27.26272",
        "lng": "-65.35647"
    },
    {
        "country": "AR",
        "name": "Sierra Grande",
        "lat": "-41.60603",
        "lng": "-65.35574"
    },
    {
        "country": "AR",
        "name": "Sierra Colorada",
        "lat": "-40.58487",
        "lng": "-67.75674"
    },
    {
        "country": "AR",
        "name": "Serrezuela",
        "lat": "-30.63761",
        "lng": "-65.38692"
    },
    {
        "country": "AR",
        "name": "Serrano",
        "lat": "-34.46971",
        "lng": "-63.53842"
    },
    {
        "country": "AR",
        "name": "Senillosa",
        "lat": "-39.01412",
        "lng": "-68.43281"
    },
    {
        "country": "AR",
        "name": "Selva",
        "lat": "-29.76776",
        "lng": "-62.0477"
    },
    {
        "country": "AR",
        "name": "Seguí",
        "lat": "-31.95642",
        "lng": "-60.12488"
    },
    {
        "country": "AR",
        "name": "Sebastián Elcano",
        "lat": "-30.16105",
        "lng": "-63.5936"
    },
    {
        "country": "AR",
        "name": "Saujil",
        "lat": "-28.17442",
        "lng": "-66.21177"
    },
    {
        "country": "AR",
        "name": "Saturnino M. Laspiur",
        "lat": "-31.70287",
        "lng": "-62.48202"
    },
    {
        "country": "AR",
        "name": "Sastre",
        "lat": "-31.76762",
        "lng": "-61.82887"
    },
    {
        "country": "AR",
        "name": "Sarmiento",
        "lat": "-45.58815",
        "lng": "-69.06996"
    },
    {
        "country": "AR",
        "name": "Santo Tomé",
        "lat": "-31.66274",
        "lng": "-60.7653"
    },
    {
        "country": "AR",
        "name": "Santiago Temple",
        "lat": "-31.38731",
        "lng": "-63.41821"
    },
    {
        "country": "AR",
        "name": "Santiago del Estero",
        "lat": "-27.79511",
        "lng": "-64.26149"
    },
    {
        "country": "AR",
        "name": "Santa Victoria",
        "lat": "-22.25",
        "lng": "-64.96667"
    },
    {
        "country": "AR",
        "name": "Santa Sylvina",
        "lat": "-27.83261",
        "lng": "-61.13747"
    },
    {
        "country": "AR",
        "name": "Santa Rosa de Tastil",
        "lat": "-24.45166",
        "lng": "-65.97452"
    },
    {
        "country": "AR",
        "name": "Santa Rosa de Río Primero",
        "lat": "-31.15231",
        "lng": "-63.40191"
    },
    {
        "country": "AR",
        "name": "Santa Rosa del Conlara",
        "lat": "-32.34286",
        "lng": "-65.20323"
    },
    {
        "country": "AR",
        "name": "Santa Rosa de Calamuchita",
        "lat": "-32.06905",
        "lng": "-64.53631"
    },
    {
        "country": "AR",
        "name": "Santa Rosa",
        "lat": "-36.61667",
        "lng": "-64.28333"
    },
    {
        "country": "AR",
        "name": "Santa Rosa",
        "lat": "-33.25407",
        "lng": "-68.14937"
    },
    {
        "country": "AR",
        "name": "Santa María",
        "lat": "-26.69547",
        "lng": "-66.04732"
    },
    {
        "country": "AR",
        "name": "Santa Magdalena",
        "lat": "-34.51776",
        "lng": "-63.94409"
    },
    {
        "country": "AR",
        "name": "Santa Lucía",
        "lat": "-31.53987",
        "lng": "-68.49503"
    },
    {
        "country": "AR",
        "name": "Santa Isabel",
        "lat": "-36.22724",
        "lng": "-66.9424"
    },
    {
        "country": "AR",
        "name": "Santa Fe de la Vera Cruz",
        "lat": "-31.63333",
        "lng": "-60.7"
    },
    {
        "country": "AR",
        "name": "Santa Eufemia",
        "lat": "-33.17659",
        "lng": "-63.28281"
    },
    {
        "country": "AR",
        "name": "Santa Clara",
        "lat": "-24.30921",
        "lng": "-64.66253"
    },
    {
        "country": "AR",
        "name": "Santa Catalina",
        "lat": "-21.94467",
        "lng": "-66.05219"
    },
    {
        "country": "AR",
        "name": "San Salvador de Jujuy",
        "lat": "-24.19457",
        "lng": "-65.29712"
    },
    {
        "country": "AR",
        "name": "San Ramón de la Nueva Orán",
        "lat": "-23.13705",
        "lng": "-64.32426"
    },
    {
        "country": "AR",
        "name": "San Rafael",
        "lat": "-34.61772",
        "lng": "-68.33007"
    },
    {
        "country": "AR",
        "name": "San Pedro",
        "lat": "-27.95386",
        "lng": "-65.16651"
    },
    {
        "country": "AR",
        "name": "San Pedro",
        "lat": "-24.23127",
        "lng": "-64.86614"
    },
    {
        "country": "AR",
        "name": "Sañogasta",
        "lat": "-29.0952",
        "lng": "-67.11586"
    },
    {
        "country": "AR",
        "name": "San Nicolás de los Arroyos",
        "lat": "-33.33425",
        "lng": "-60.2108"
    },
    {
        "country": "AR",
        "name": "San Miguel de Tucumán",
        "lat": "-26.82414",
        "lng": "-65.2226"
    },
    {
        "country": "AR",
        "name": "San Martín de los Andes",
        "lat": "-40.15789",
        "lng": "-71.35337"
    },
    {
        "country": "AR",
        "name": "San Martín",
        "lat": "-33.08103",
        "lng": "-68.46814"
    },
    {
        "country": "AR",
        "name": "San Martín",
        "lat": "-31.42957",
        "lng": "-68.50065"
    },
    {
        "country": "AR",
        "name": "San Luis",
        "lat": "-33.29501",
        "lng": "-66.33563"
    },
    {
        "country": "AR",
        "name": "San Lorenzo",
        "lat": "-32.74341",
        "lng": "-60.73613"
    },
    {
        "country": "AR",
        "name": "San Justo",
        "lat": "-30.78913",
        "lng": "-60.59189"
    },
    {
        "country": "AR",
        "name": "San Juan",
        "lat": "-31.5375",
        "lng": "-68.53639"
    },
    {
        "country": "AR",
        "name": "San José de la Dormida",
        "lat": "-30.3544",
        "lng": "-63.94871"
    },
    {
        "country": "AR",
        "name": "San José de Jáchal",
        "lat": "-30.24057",
        "lng": "-68.74693"
    },
    {
        "country": "AR",
        "name": "San José",
        "lat": "-28.38333",
        "lng": "-65.7"
    },
    {
        "country": "AR",
        "name": "San Jorge",
        "lat": "-31.89618",
        "lng": "-61.85984"
    },
    {
        "country": "AR",
        "name": "San Isidro",
        "lat": "-28.46265",
        "lng": "-65.72585"
    },
    {
        "country": "AR",
        "name": "San Francisco del Monte de Oro",
        "lat": "-32.59825",
        "lng": "-66.12539"
    },
    {
        "country": "AR",
        "name": "San Francisco del Chañar",
        "lat": "-29.78991",
        "lng": "-63.93861"
    },
    {
        "country": "AR",
        "name": "San Francisco",
        "lat": "-31.42797",
        "lng": "-62.08266"
    },
    {
        "country": "AR",
        "name": "San Fernando del Valle de Catamarca",
        "lat": "-28.46957",
        "lng": "-65.78524"
    },
    {
        "country": "AR",
        "name": "San Cristóbal",
        "lat": "-30.31053",
        "lng": "-61.23724"
    },
    {
        "country": "AR",
        "name": "San Carlos de Bolívar",
        "lat": "-36.25",
        "lng": "-61.1"
    },
    {
        "country": "AR",
        "name": "San Carlos Centro",
        "lat": "-31.72864",
        "lng": "-61.09192"
    },
    {
        "country": "AR",
        "name": "San Carlos",
        "lat": "-31.17761",
        "lng": "-65.10245"
    },
    {
        "country": "AR",
        "name": "San Carlos",
        "lat": "-25.88618",
        "lng": "-65.92941"
    },
    {
        "country": "AR",
        "name": "San Blas de los Sauces",
        "lat": "-28.41051",
        "lng": "-67.09341"
    },
    {
        "country": "AR",
        "name": "San Bernardo",
        "lat": "-27.28782",
        "lng": "-60.71252"
    },
    {
        "country": "AR",
        "name": "San Benito",
        "lat": "-31.78371",
        "lng": "-60.44156"
    },
    {
        "country": "AR",
        "name": "San Basilio",
        "lat": "-33.49763",
        "lng": "-64.31495"
    },
    {
        "country": "AR",
        "name": "San Antonio Oeste",
        "lat": "-40.73193",
        "lng": "-64.94769"
    },
    {
        "country": "AR",
        "name": "San Antonio de los Cobres",
        "lat": "-24.21804",
        "lng": "-66.31877"
    },
    {
        "country": "AR",
        "name": "San Antonio de Litín",
        "lat": "-32.21377",
        "lng": "-62.63237"
    },
    {
        "country": "AR",
        "name": "San Antonio",
        "lat": "-28.00927",
        "lng": "-65.71218"
    },
    {
        "country": "AR",
        "name": "San Antonio",
        "lat": "-24.36753",
        "lng": "-65.33471"
    },
    {
        "country": "AR",
        "name": "San Agustín de Valle Fértil",
        "lat": "-30.63353",
        "lng": "-67.46821"
    },
    {
        "country": "AR",
        "name": "San Agustín",
        "lat": "-31.97681",
        "lng": "-64.374"
    },
    {
        "country": "AR",
        "name": "Samuhú",
        "lat": "-27.52116",
        "lng": "-60.39167"
    },
    {
        "country": "AR",
        "name": "Sampacho",
        "lat": "-33.3839",
        "lng": "-64.72211"
    },
    {
        "country": "AR",
        "name": "Salto",
        "lat": "-34.29292",
        "lng": "-60.25455"
    },
    {
        "country": "AR",
        "name": "Salta",
        "lat": "-24.7859",
        "lng": "-65.41166"
    },
    {
        "country": "AR",
        "name": "Salsipuedes",
        "lat": "-31.13725",
        "lng": "-64.29589"
    },
    {
        "country": "AR",
        "name": "Salsacate",
        "lat": "-31.31649",
        "lng": "-65.08529"
    },
    {
        "country": "AR",
        "name": "Salliqueló",
        "lat": "-36.75216",
        "lng": "-62.96053"
    },
    {
        "country": "AR",
        "name": "Saldán",
        "lat": "-31.30262",
        "lng": "-64.307"
    },
    {
        "country": "AR",
        "name": "Sacanta",
        "lat": "-31.663",
        "lng": "-63.04505"
    },
    {
        "country": "AR",
        "name": "Rufino",
        "lat": "-34.26827",
        "lng": "-62.71262"
    },
    {
        "country": "AR",
        "name": "Rosario de Lerma",
        "lat": "-24.98206",
        "lng": "-65.57891"
    },
    {
        "country": "AR",
        "name": "Rosario de la Frontera",
        "lat": "-25.79693",
        "lng": "-64.97094"
    },
    {
        "country": "AR",
        "name": "Rosario",
        "lat": "-32.94682",
        "lng": "-60.63932"
    },
    {
        "country": "AR",
        "name": "Roldán",
        "lat": "-32.89846",
        "lng": "-60.90681"
    },
    {
        "country": "AR",
        "name": "Rojas",
        "lat": "-34.19528",
        "lng": "-60.735"
    },
    {
        "country": "AR",
        "name": "Rodeo",
        "lat": "-30.21186",
        "lng": "-69.13661"
    },
    {
        "country": "AR",
        "name": "Rivadavia",
        "lat": "-33.19051",
        "lng": "-68.46081"
    },
    {
        "country": "AR",
        "name": "Rivadavia",
        "lat": "-31.55389",
        "lng": "-68.6525"
    },
    {
        "country": "AR",
        "name": "Rivadavia",
        "lat": "-24.18832",
        "lng": "-62.89021"
    },
    {
        "country": "AR",
        "name": "Río Tercero",
        "lat": "-32.17301",
        "lng": "-64.11405"
    },
    {
        "country": "AR",
        "name": "Río Segundo",
        "lat": "-31.6526",
        "lng": "-63.9099"
    },
    {
        "country": "AR",
        "name": "Río Pico",
        "lat": "-44.17905",
        "lng": "-71.36847"
    },
    {
        "country": "AR",
        "name": "Río Mayo",
        "lat": "-45.68573",
        "lng": "-70.25797"
    },
    {
        "country": "AR",
        "name": "Río Grande",
        "lat": "-53.78769",
        "lng": "-67.70946"
    },
    {
        "country": "AR",
        "name": "Río Gallegos",
        "lat": "-51.62261",
        "lng": "-69.21813"
    },
    {
        "country": "AR",
        "name": "Río Cuarto",
        "lat": "-33.13067",
        "lng": "-64.34992"
    },
    {
        "country": "AR",
        "name": "Río Colorado",
        "lat": "-38.99397",
        "lng": "-64.09295"
    },
    {
        "country": "AR",
        "name": "Río Ceballos",
        "lat": "-31.16486",
        "lng": "-64.32241"
    },
    {
        "country": "AR",
        "name": "Rinconada",
        "lat": "-22.44083",
        "lng": "-66.16779"
    },
    {
        "country": "AR",
        "name": "Recreo",
        "lat": "-31.49076",
        "lng": "-60.73299"
    },
    {
        "country": "AR",
        "name": "Recreo",
        "lat": "-29.28184",
        "lng": "-65.06096"
    },
    {
        "country": "AR",
        "name": "Realicó",
        "lat": "-35.03658",
        "lng": "-64.2447"
    },
    {
        "country": "AR",
        "name": "Rawson",
        "lat": "-43.30016",
        "lng": "-65.10228"
    },
    {
        "country": "AR",
        "name": "Rancul",
        "lat": "-35.06862",
        "lng": "-64.68107"
    },
    {
        "country": "AR",
        "name": "Ramallo",
        "lat": "-33.48508",
        "lng": "-60.00629"
    },
    {
        "country": "AR",
        "name": "Rafaela",
        "lat": "-31.25033",
        "lng": "-61.4867"
    },
    {
        "country": "AR",
        "name": "Rada Tilly",
        "lat": "-45.92462",
        "lng": "-67.55424"
    },
    {
        "country": "AR",
        "name": "Quitilipi",
        "lat": "-26.86913",
        "lng": "-60.21683"
    },
    {
        "country": "AR",
        "name": "Quimilí",
        "lat": "-27.63333",
        "lng": "-62.41667"
    },
    {
        "country": "AR",
        "name": "Quilino",
        "lat": "-30.21397",
        "lng": "-64.50063"
    },
    {
        "country": "AR",
        "name": "Quemú Quemú",
        "lat": "-36.05463",
        "lng": "-63.56428"
    },
    {
        "country": "AR",
        "name": "Punta Alta",
        "lat": "-38.87588",
        "lng": "-62.07359"
    },
    {
        "country": "AR",
        "name": "Puerto Santa Cruz",
        "lat": "-50.0191",
        "lng": "-68.52321"
    },
    {
        "country": "AR",
        "name": "San Julián",
        "lat": "-49.30554",
        "lng": "-67.72743"
    },
    {
        "country": "AR",
        "name": "Puerto Madryn",
        "lat": "-42.7692",
        "lng": "-65.03851"
    },
    {
        "country": "AR",
        "name": "Puerto Deseado",
        "lat": "-47.75034",
        "lng": "-65.89382"
    },
    {
        "country": "AR",
        "name": "Puerta de Corral Quemado",
        "lat": "-27.23",
        "lng": "-66.93635"
    },
    {
        "country": "AR",
        "name": "Puelches",
        "lat": "-38.14562",
        "lng": "-65.91426"
    },
    {
        "country": "AR",
        "name": "Puan",
        "lat": "-37.54576",
        "lng": "-62.76723"
    },
    {
        "country": "AR",
        "name": "Presidencia Roque Sáenz Peña",
        "lat": "-26.78522",
        "lng": "-60.43876"
    },
    {
        "country": "AR",
        "name": "Pozo Hondo",
        "lat": "-27.16607",
        "lng": "-64.49"
    },
    {
        "country": "AR",
        "name": "Pozo del Tigre",
        "lat": "-24.89682",
        "lng": "-60.32359"
    },
    {
        "country": "AR",
        "name": "Pozo del Molle",
        "lat": "-32.0186",
        "lng": "-62.91984"
    },
    {
        "country": "AR",
        "name": "Porteña",
        "lat": "-31.01391",
        "lng": "-62.0665"
    },
    {
        "country": "AR",
        "name": "Pomán",
        "lat": "-28.39455",
        "lng": "-66.22052"
    },
    {
        "country": "AR",
        "name": "Pocito",
        "lat": "-31.68333",
        "lng": "-68.58333"
    },
    {
        "country": "AR",
        "name": "Plottier",
        "lat": "-38.96667",
        "lng": "-68.23333"
    },
    {
        "country": "AR",
        "name": "Plaza Huincul",
        "lat": "-38.92598",
        "lng": "-69.20863"
    },
    {
        "country": "AR",
        "name": "Piquillín",
        "lat": "-31.30158",
        "lng": "-63.75788"
    },
    {
        "country": "AR",
        "name": "Pilcaniyeu",
        "lat": "-41.11881",
        "lng": "-70.72914"
    },
    {
        "country": "AR",
        "name": "Pilar",
        "lat": "-31.6789",
        "lng": "-63.87964"
    },
    {
        "country": "AR",
        "name": "Pigüé",
        "lat": "-37.60509",
        "lng": "-62.40333"
    },
    {
        "country": "AR",
        "name": "Piedra del Águila",
        "lat": "-40.04811",
        "lng": "-70.0741"
    },
    {
        "country": "AR",
        "name": "Picún Leufú",
        "lat": "-39.52351",
        "lng": "-69.27966"
    },
    {
        "country": "AR",
        "name": "Pico Truncado",
        "lat": "-46.7949",
        "lng": "-67.95731"
    },
    {
        "country": "AR",
        "name": "Perito Moreno",
        "lat": "-46.58995",
        "lng": "-70.92975"
    },
    {
        "country": "AR",
        "name": "Pergamino",
        "lat": "-33.88995",
        "lng": "-60.57357"
    },
    {
        "country": "AR",
        "name": "Pérez",
        "lat": "-32.99835",
        "lng": "-60.76791"
    },
    {
        "country": "AR",
        "name": "Pellegrini",
        "lat": "-36.26584",
        "lng": "-63.1655"
    },
    {
        "country": "AR",
        "name": "Pehuajó",
        "lat": "-35.81077",
        "lng": "-61.8968"
    },
    {
        "country": "AR",
        "name": "Patquía",
        "lat": "-30.04681",
        "lng": "-66.88231"
    },
    {
        "country": "AR",
        "name": "Paso de Indios",
        "lat": "-43.86225",
        "lng": "-69.04603"
    },
    {
        "country": "AR",
        "name": "Pasco",
        "lat": "-32.74733",
        "lng": "-63.34232"
    },
    {
        "country": "AR",
        "name": "Pascanas",
        "lat": "-33.1255",
        "lng": "-63.04084"
    },
    {
        "country": "AR",
        "name": "Parera",
        "lat": "-35.146",
        "lng": "-64.50089"
    },
    {
        "country": "AR",
        "name": "Paraná",
        "lat": "-31.73197",
        "lng": "-60.5238"
    },
    {
        "country": "AR",
        "name": "Pampa de los Guanacos",
        "lat": "-26.23333",
        "lng": "-61.85"
    },
    {
        "country": "AR",
        "name": "Pampa del Infierno",
        "lat": "-26.50517",
        "lng": "-61.17436"
    },
    {
        "country": "AR",
        "name": "Palpalá",
        "lat": "-24.25647",
        "lng": "-65.21163"
    },
    {
        "country": "AR",
        "name": "Palma Sola",
        "lat": "-23.96336",
        "lng": "-64.29872"
    },
    {
        "country": "AR",
        "name": "Oro Verde",
        "lat": "-31.82508",
        "lng": "-60.51749"
    },
    {
        "country": "AR",
        "name": "Ordóñez",
        "lat": "-32.84057",
        "lng": "-62.86552"
    },
    {
        "country": "AR",
        "name": "Oncativo",
        "lat": "-31.91353",
        "lng": "-63.68201"
    },
    {
        "country": "AR",
        "name": "Olta",
        "lat": "-30.63117",
        "lng": "-66.263"
    },
    {
        "country": "AR",
        "name": "Oliva",
        "lat": "-32.04158",
        "lng": "-63.56978"
    },
    {
        "country": "AR",
        "name": "Olavarría",
        "lat": "-36.89272",
        "lng": "-60.32254"
    },
    {
        "country": "AR",
        "name": "Obispo Trejo",
        "lat": "-30.78128",
        "lng": "-63.41349"
    },
    {
        "country": "AR",
        "name": "Nueve de Julio",
        "lat": "-35.44437",
        "lng": "-60.88313"
    },
    {
        "country": "AR",
        "name": "Nueve de Julio",
        "lat": "-31.66914",
        "lng": "-68.39023"
    },
    {
        "country": "AR",
        "name": "Nueva Esperanza",
        "lat": "-26.19907",
        "lng": "-64.23792"
    },
    {
        "country": "AR",
        "name": "Ñorquinco",
        "lat": "-41.85072",
        "lng": "-70.90173"
    },
    {
        "country": "AR",
        "name": "Noetinger",
        "lat": "-32.36597",
        "lng": "-62.31126"
    },
    {
        "country": "AR",
        "name": "Neuquén",
        "lat": "-38.95161",
        "lng": "-68.0591"
    },
    {
        "country": "AR",
        "name": "Naschel",
        "lat": "-32.91656",
        "lng": "-65.37535"
    },
    {
        "country": "AR",
        "name": "Napenay",
        "lat": "-26.73333",
        "lng": "-60.61667"
    },
    {
        "country": "AR",
        "name": "Mutquín",
        "lat": "-28.32104",
        "lng": "-66.14253"
    },
    {
        "country": "AR",
        "name": "Morteros",
        "lat": "-30.71164",
        "lng": "-61.99862"
    },
    {
        "country": "AR",
        "name": "Morrison",
        "lat": "-32.5948",
        "lng": "-62.83455"
    },
    {
        "country": "AR",
        "name": "Monteros",
        "lat": "-27.16741",
        "lng": "-65.49832"
    },
    {
        "country": "AR",
        "name": "Monte Quemado Airport",
        "lat": "-25.80565",
        "lng": "-62.83019"
    },
    {
        "country": "AR",
        "name": "Monte Maíz",
        "lat": "-33.20462",
        "lng": "-62.60085"
    },
    {
        "country": "AR",
        "name": "Monte Hermoso",
        "lat": "-38.9825",
        "lng": "-61.29472"
    },
    {
        "country": "AR",
        "name": "Monte Cristo",
        "lat": "-31.34312",
        "lng": "-63.94437"
    },
    {
        "country": "AR",
        "name": "Monte Buey",
        "lat": "-32.91642",
        "lng": "-62.45669"
    },
    {
        "country": "AR",
        "name": "Molinos",
        "lat": "-25.4395",
        "lng": "-66.29126"
    },
    {
        "country": "AR",
        "name": "Miramar",
        "lat": "-30.91859",
        "lng": "-62.67814"
    },
    {
        "country": "AR",
        "name": "Mina Clavero",
        "lat": "-31.72101",
        "lng": "-65.00619"
    },
    {
        "country": "AR",
        "name": "Milagro",
        "lat": "-31.00326",
        "lng": "-66.00098"
    },
    {
        "country": "AR",
        "name": "Miguel Riglos",
        "lat": "-36.85398",
        "lng": "-63.68842"
    },
    {
        "country": "AR",
        "name": "Metán",
        "lat": "-25.49905",
        "lng": "-64.97388"
    },
    {
        "country": "AR",
        "name": "Merlo",
        "lat": "-32.34288",
        "lng": "-65.01396"
    },
    {
        "country": "AR",
        "name": "Mendoza",
        "lat": "-32.89084",
        "lng": "-68.82717"
    },
    {
        "country": "AR",
        "name": "Mendiolaza",
        "lat": "-31.26738",
        "lng": "-64.30087"
    },
    {
        "country": "AR",
        "name": "Melincué",
        "lat": "-33.65847",
        "lng": "-61.45459"
    },
    {
        "country": "AR",
        "name": "Médanos",
        "lat": "-38.82803",
        "lng": "-62.69355"
    },
    {
        "country": "AR",
        "name": "Mattaldi",
        "lat": "-34.48194",
        "lng": "-64.17255"
    },
    {
        "country": "AR",
        "name": "Marull",
        "lat": "-30.99471",
        "lng": "-62.82576"
    },
    {
        "country": "AR",
        "name": "Mariano Moreno",
        "lat": "-38.75029",
        "lng": "-70.02367"
    },
    {
        "country": "AR",
        "name": "Marcos Juárez",
        "lat": "-32.6978",
        "lng": "-62.10672"
    },
    {
        "country": "AR",
        "name": "Maquinchao",
        "lat": "-41.25",
        "lng": "-68.73333"
    },
    {
        "country": "AR",
        "name": "Malvinas Argentinas",
        "lat": "-31.38333",
        "lng": "-64.06667"
    },
    {
        "country": "AR",
        "name": "Malargüe",
        "lat": "-35.47545",
        "lng": "-69.58427"
    },
    {
        "country": "AR",
        "name": "Malanzán",
        "lat": "-30.80394",
        "lng": "-66.60908"
    },
    {
        "country": "AR",
        "name": "Malagueño",
        "lat": "-31.46467",
        "lng": "-64.3584"
    },
    {
        "country": "AR",
        "name": "Maipú",
        "lat": "-32.97963",
        "lng": "-68.78461"
    },
    {
        "country": "AR",
        "name": "Mainque",
        "lat": "-39.06667",
        "lng": "-67.3"
    },
    {
        "country": "AR",
        "name": "Maimará",
        "lat": "-23.62392",
        "lng": "-65.40797"
    },
    {
        "country": "AR",
        "name": "Machagai",
        "lat": "-26.92614",
        "lng": "-60.04955"
    },
    {
        "country": "AR",
        "name": "Macachín",
        "lat": "-37.13598",
        "lng": "-63.6665"
    },
    {
        "country": "AR",
        "name": "San Isidro de Lules",
        "lat": "-26.9277",
        "lng": "-65.33869"
    },
    {
        "country": "AR",
        "name": "Luján de Cuyo",
        "lat": "-33.03547",
        "lng": "-68.87782"
    },
    {
        "country": "AR",
        "name": "Luján",
        "lat": "-32.3757",
        "lng": "-65.9296"
    },
    {
        "country": "AR",
        "name": "Los Varela",
        "lat": "-27.95482",
        "lng": "-65.8655"
    },
    {
        "country": "AR",
        "name": "Los Telares",
        "lat": "-28.98479",
        "lng": "-63.44889"
    },
    {
        "country": "AR",
        "name": "Los Surgentes",
        "lat": "-32.98454",
        "lng": "-62.02191"
    },
    {
        "country": "AR",
        "name": "Los Menucos",
        "lat": "-40.84402",
        "lng": "-68.08718"
    },
    {
        "country": "AR",
        "name": "Los Juríes",
        "lat": "-28.46539",
        "lng": "-62.10862"
    },
    {
        "country": "AR",
        "name": "Los Frentones",
        "lat": "-26.4077",
        "lng": "-61.41367"
    },
    {
        "country": "AR",
        "name": "Los Cóndores",
        "lat": "-32.31983",
        "lng": "-64.27751"
    },
    {
        "country": "AR",
        "name": "Los Antiguos",
        "lat": "-46.54972",
        "lng": "-71.63086"
    },
    {
        "country": "AR",
        "name": "Los Altos",
        "lat": "-28.04845",
        "lng": "-65.49945"
    },
    {
        "country": "AR",
        "name": "Lonquimay",
        "lat": "-36.46546",
        "lng": "-63.62429"
    },
    {
        "country": "AR",
        "name": "Londres",
        "lat": "-27.71439",
        "lng": "-67.13349"
    },
    {
        "country": "AR",
        "name": "Loncopué",
        "lat": "-38.07284",
        "lng": "-70.61609"
    },
    {
        "country": "AR",
        "name": "Lincoln",
        "lat": "-34.86649",
        "lng": "-61.5302"
    },
    {
        "country": "AR",
        "name": "Limay Mahuida",
        "lat": "-37.15959",
        "lng": "-66.67595"
    },
    {
        "country": "AR",
        "name": "Libertador General San Martín",
        "lat": "-32.41074",
        "lng": "-65.67586"
    },
    {
        "country": "AR",
        "name": "Libertador General San Martín",
        "lat": "-23.80644",
        "lng": "-64.78757"
    },
    {
        "country": "AR",
        "name": "Leones",
        "lat": "-32.66174",
        "lng": "-62.29678"
    },
    {
        "country": "AR",
        "name": "Leleque",
        "lat": "-42.41225",
        "lng": "-71.06797"
    },
    {
        "country": "AR",
        "name": "La Viña",
        "lat": "-25.46642",
        "lng": "-65.5663"
    },
    {
        "country": "AR",
        "name": "La Toma",
        "lat": "-33.05258",
        "lng": "-65.62385"
    },
    {
        "country": "AR",
        "name": "La Tigra",
        "lat": "-27.10996",
        "lng": "-60.58719"
    },
    {
        "country": "AR",
        "name": "Las Varillas",
        "lat": "-31.87208",
        "lng": "-62.71946"
    },
    {
        "country": "AR",
        "name": "Las Varas",
        "lat": "-31.8026",
        "lng": "-62.61655"
    },
    {
        "country": "AR",
        "name": "Las Rosas",
        "lat": "-32.47661",
        "lng": "-61.58041"
    },
    {
        "country": "AR",
        "name": "Las Plumas",
        "lat": "-43.72058",
        "lng": "-67.28319"
    },
    {
        "country": "AR",
        "name": "Las Perdices",
        "lat": "-32.69794",
        "lng": "-63.70634"
    },
    {
        "country": "AR",
        "name": "Las Parejas",
        "lat": "-32.68478",
        "lng": "-61.51637"
    },
    {
        "country": "AR",
        "name": "Las Ovejas",
        "lat": "-36.98881",
        "lng": "-70.74991"
    },
    {
        "country": "AR",
        "name": "Las Lomitas",
        "lat": "-24.70955",
        "lng": "-60.59303"
    },
    {
        "country": "AR",
        "name": "Las Lajitas",
        "lat": "-24.68333",
        "lng": "-64.25"
    },
    {
        "country": "AR",
        "name": "Las Lajas",
        "lat": "-38.52322",
        "lng": "-70.36745"
    },
    {
        "country": "AR",
        "name": "Las Junturas",
        "lat": "-31.83125",
        "lng": "-63.45016"
    },
    {
        "country": "AR",
        "name": "Las Higueras",
        "lat": "-33.09231",
        "lng": "-64.28899"
    },
    {
        "country": "AR",
        "name": "Las Heras",
        "lat": "-46.54186",
        "lng": "-68.93593"
    },
    {
        "country": "AR",
        "name": "Las Heras",
        "lat": "-32.85273",
        "lng": "-68.82837"
    },
    {
        "country": "AR",
        "name": "Las Coloradas",
        "lat": "-39.55534",
        "lng": "-70.59491"
    },
    {
        "country": "AR",
        "name": "Las Breñas",
        "lat": "-27.08966",
        "lng": "-61.08161"
    },
    {
        "country": "AR",
        "name": "Las Acequias",
        "lat": "-33.28155",
        "lng": "-63.9761"
    },
    {
        "country": "AR",
        "name": "La Rioja",
        "lat": "-29.41105",
        "lng": "-66.85067"
    },
    {
        "country": "AR",
        "name": "La Quiaca",
        "lat": "-22.10236",
        "lng": "-65.59299"
    },
    {
        "country": "AR",
        "name": "La Puerta de San José",
        "lat": "-27.55",
        "lng": "-67.01667"
    },
    {
        "country": "AR",
        "name": "La Puerta",
        "lat": "-28.17036",
        "lng": "-65.79015"
    },
    {
        "country": "AR",
        "name": "Laprida",
        "lat": "-37.54415",
        "lng": "-60.79969"
    },
    {
        "country": "AR",
        "name": "La Poma",
        "lat": "-24.72196",
        "lng": "-66.20106"
    },
    {
        "country": "AR",
        "name": "La Playosa",
        "lat": "-32.10002",
        "lng": "-63.03088"
    },
    {
        "country": "AR",
        "name": "La Paz",
        "lat": "-33.46091",
        "lng": "-67.54956"
    },
    {
        "country": "AR",
        "name": "La Para",
        "lat": "-30.89416",
        "lng": "-63.00107"
    },
    {
        "country": "AR",
        "name": "La Merced",
        "lat": "-28.15195",
        "lng": "-65.66029"
    },
    {
        "country": "AR",
        "name": "La Mendieta",
        "lat": "-24.31187",
        "lng": "-64.96377"
    },
    {
        "country": "AR",
        "name": "La Maruja",
        "lat": "-35.6736",
        "lng": "-64.93997"
    },
    {
        "country": "AR",
        "name": "Lamarque",
        "lat": "-39.42304",
        "lng": "-65.70208"
    },
    {
        "country": "AR",
        "name": "Laguna Yema",
        "lat": "-24.25391",
        "lng": "-61.24466"
    },
    {
        "country": "AR",
        "name": "Laguna Paiva",
        "lat": "-31.30391",
        "lng": "-60.65894"
    },
    {
        "country": "AR",
        "name": "Laguna Larga",
        "lat": "-31.77652",
        "lng": "-63.80104"
    },
    {
        "country": "AR",
        "name": "La Granja",
        "lat": "-31.00919",
        "lng": "-64.26869"
    },
    {
        "country": "AR",
        "name": "Lago Puelo",
        "lat": "-42.08095",
        "lng": "-71.61405"
    },
    {
        "country": "AR",
        "name": "La Francia",
        "lat": "-31.40675",
        "lng": "-62.63396"
    },
    {
        "country": "AR",
        "name": "La Falda",
        "lat": "-31.08841",
        "lng": "-64.48987"
    },
    {
        "country": "AR",
        "name": "La Cumbre",
        "lat": "-30.98201",
        "lng": "-64.49139"
    },
    {
        "country": "AR",
        "name": "La Consulta",
        "lat": "-33.73579",
        "lng": "-69.12181"
    },
    {
        "country": "AR",
        "name": "La Cocha",
        "lat": "-27.77729",
        "lng": "-65.57035"
    },
    {
        "country": "AR",
        "name": "La Clotilde",
        "lat": "-27.13333",
        "lng": "-60.66667"
    },
    {
        "country": "AR",
        "name": "La Cesira",
        "lat": "-33.95115",
        "lng": "-62.97238"
    },
    {
        "country": "AR",
        "name": "La Carlota",
        "lat": "-33.41993",
        "lng": "-63.29769"
    },
    {
        "country": "AR",
        "name": "La Candelaria",
        "lat": "-26.12708",
        "lng": "-65.0496"
    },
    {
        "country": "AR",
        "name": "La Cañada",
        "lat": "-27.70826",
        "lng": "-63.77366"
    },
    {
        "country": "AR",
        "name": "La Calera",
        "lat": "-31.34377",
        "lng": "-64.33529"
    },
    {
        "country": "AR",
        "name": "La Caldera",
        "lat": "-24.60159",
        "lng": "-65.38098"
    },
    {
        "country": "AR",
        "name": "Laboulaye",
        "lat": "-34.12662",
        "lng": "-63.39119"
    },
    {
        "country": "AR",
        "name": "Laborde",
        "lat": "-33.15319",
        "lng": "-62.85661"
    },
    {
        "country": "AR",
        "name": "La Banda",
        "lat": "-27.73348",
        "lng": "-64.24278"
    },
    {
        "country": "AR",
        "name": "Justo Daract",
        "lat": "-33.8594",
        "lng": "-65.18277"
    },
    {
        "country": "AR",
        "name": "Justiniano Posse",
        "lat": "-32.88411",
        "lng": "-62.67788"
    },
    {
        "country": "AR",
        "name": "Junín de los Andes",
        "lat": "-39.95043",
        "lng": "-71.06936"
    },
    {
        "country": "AR",
        "name": "Junín",
        "lat": "-34.58382",
        "lng": "-60.94332"
    },
    {
        "country": "AR",
        "name": "Junín",
        "lat": "-33.14765",
        "lng": "-68.47899"
    },
    {
        "country": "AR",
        "name": "José de San Martín",
        "lat": "-44.05032",
        "lng": "-70.46967"
    },
    {
        "country": "AR",
        "name": "Joaquín V. González",
        "lat": "-25.08333",
        "lng": "-64.18333"
    },
    {
        "country": "AR",
        "name": "Jesús María",
        "lat": "-30.98153",
        "lng": "-64.09424"
    },
    {
        "country": "AR",
        "name": "James Craik",
        "lat": "-32.1612",
        "lng": "-63.46688"
    },
    {
        "country": "AR",
        "name": "Jacinto Arauz",
        "lat": "-38.08606",
        "lng": "-63.43169"
    },
    {
        "country": "AR",
        "name": "Italó",
        "lat": "-34.79237",
        "lng": "-63.78199"
    },
    {
        "country": "AR",
        "name": "Isla Verde",
        "lat": "-33.24104",
        "lng": "-62.40297"
    },
    {
        "country": "AR",
        "name": "Iruya",
        "lat": "-22.76667",
        "lng": "-65.23333"
    },
    {
        "country": "AR",
        "name": "Intendente Alvear",
        "lat": "-35.23383",
        "lng": "-63.59205"
    },
    {
        "country": "AR",
        "name": "Inriville",
        "lat": "-32.94424",
        "lng": "-62.23028"
    },
    {
        "country": "AR",
        "name": "Ingenio La Esperanza",
        "lat": "-24.22554",
        "lng": "-64.83896"
    },
    {
        "country": "AR",
        "name": "Ingeniero Luis A. Huergo",
        "lat": "-39.08333",
        "lng": "-67.23333"
    },
    {
        "country": "AR",
        "name": "Ingeniero Luiggi",
        "lat": "-35.38585",
        "lng": "-64.46519"
    },
    {
        "country": "AR",
        "name": "Ingeniero Jacobacci",
        "lat": "-41.3292",
        "lng": "-69.55015"
    },
    {
        "country": "AR",
        "name": "Ingeniero Guillermo N. Juárez",
        "lat": "-23.9",
        "lng": "-61.85"
    },
    {
        "country": "AR",
        "name": "Idiazábal",
        "lat": "-32.81411",
        "lng": "-63.03252"
    },
    {
        "country": "AR",
        "name": "Icaño",
        "lat": "-28.91934",
        "lng": "-65.32817"
    },
    {
        "country": "AR",
        "name": "Humahuaca",
        "lat": "-23.20544",
        "lng": "-65.35048"
    },
    {
        "country": "AR",
        "name": "Huinca Renancó",
        "lat": "-34.84038",
        "lng": "-64.3758"
    },
    {
        "country": "AR",
        "name": "Huillapima",
        "lat": "-28.72533",
        "lng": "-65.9787"
    },
    {
        "country": "AR",
        "name": "Huerta Grande",
        "lat": "-31.07524",
        "lng": "-64.49063"
    },
    {
        "country": "AR",
        "name": "Huanchillas",
        "lat": "-33.66653",
        "lng": "-63.63701"
    },
    {
        "country": "AR",
        "name": "Hualfín",
        "lat": "-27.22896",
        "lng": "-66.83131"
    },
    {
        "country": "AR",
        "name": "Hoyo de Epuyén",
        "lat": "-42.07189",
        "lng": "-71.50811"
    },
    {
        "country": "AR",
        "name": "Hersilia",
        "lat": "-30.00447",
        "lng": "-61.8408"
    },
    {
        "country": "AR",
        "name": "Herrera",
        "lat": "-28.48094",
        "lng": "-63.06779"
    },
    {
        "country": "AR",
        "name": "Hernando",
        "lat": "-32.42657",
        "lng": "-63.73333"
    },
    {
        "country": "AR",
        "name": "Hernández",
        "lat": "-32.3373",
        "lng": "-60.0216"
    },
    {
        "country": "AR",
        "name": "Hermoso Campo",
        "lat": "-27.60816",
        "lng": "-61.34441"
    },
    {
        "country": "AR",
        "name": "Henderson",
        "lat": "-36.3",
        "lng": "-61.71667"
    },
    {
        "country": "AR",
        "name": "Helvecia",
        "lat": "-31.09834",
        "lng": "-60.0883"
    },
    {
        "country": "AR",
        "name": "Guatraché",
        "lat": "-37.66776",
        "lng": "-63.53021"
    },
    {
        "country": "AR",
        "name": "Guatimozín",
        "lat": "-33.46149",
        "lng": "-62.43844"
    },
    {
        "country": "AR",
        "name": "Guaminí",
        "lat": "-37.03333",
        "lng": "-62.41667"
    },
    {
        "country": "AR",
        "name": "Guachipas",
        "lat": "-25.52031",
        "lng": "-65.50937"
    },
    {
        "country": "AR",
        "name": "Graneros",
        "lat": "-27.64934",
        "lng": "-65.4383"
    },
    {
        "country": "AR",
        "name": "Granadero Baigorria",
        "lat": "-32.85683",
        "lng": "-60.71754"
    },
    {
        "country": "AR",
        "name": "Godoy Cruz",
        "lat": "-32.92863",
        "lng": "-68.8351"
    },
    {
        "country": "AR",
        "name": "Gobernador Gregores",
        "lat": "-48.75057",
        "lng": "-70.24741"
    },
    {
        "country": "AR",
        "name": "Gobernador Gálvez",
        "lat": "-33.03016",
        "lng": "-60.64045"
    },
    {
        "country": "AR",
        "name": "Gobernador Costa",
        "lat": "-44.04992",
        "lng": "-70.59798"
    },
    {
        "country": "AR",
        "name": "General Villegas",
        "lat": "-35.03285",
        "lng": "-63.01252"
    },
    {
        "country": "AR",
        "name": "General Viamonte",
        "lat": "-35.00014",
        "lng": "-61.03508"
    },
    {
        "country": "AR",
        "name": "General San Martín",
        "lat": "-37.97904",
        "lng": "-63.60449"
    },
    {
        "country": "AR",
        "name": "General Roca",
        "lat": "-39.03333",
        "lng": "-67.58333"
    },
    {
        "country": "AR",
        "name": "General Roca",
        "lat": "-32.73196",
        "lng": "-61.91599"
    },
    {
        "country": "AR",
        "name": "General Ramírez",
        "lat": "-32.17601",
        "lng": "-60.20079"
    },
    {
        "country": "AR",
        "name": "General Pinto",
        "lat": "-34.76459",
        "lng": "-61.89093"
    },
    {
        "country": "AR",
        "name": "General Pinedo",
        "lat": "-27.31667",
        "lng": "-61.28333"
    },
    {
        "country": "AR",
        "name": "General Pico",
        "lat": "-35.65662",
        "lng": "-63.75682"
    },
    {
        "country": "AR",
        "name": "General Martín Miguel de Güemes",
        "lat": "-24.67034",
        "lng": "-65.04765"
    },
    {
        "country": "AR",
        "name": "General Manuel J. Campos",
        "lat": "-37.46025",
        "lng": "-63.58537"
    },
    {
        "country": "AR",
        "name": "General Levalle",
        "lat": "-34.01472",
        "lng": "-63.92413"
    },
    {
        "country": "AR",
        "name": "General Lavalle",
        "lat": "-32.72218",
        "lng": "-68.59137"
    },
    {
        "country": "AR",
        "name": "General La Madrid",
        "lat": "-37.24755",
        "lng": "-61.26273"
    },
    {
        "country": "AR",
        "name": "General Fernández Oro",
        "lat": "-38.95297",
        "lng": "-67.92489"
    },
    {
        "country": "AR",
        "name": "General Enrique Mosconi",
        "lat": "-23.21667",
        "lng": "-62.3"
    },
    {
        "country": "AR",
        "name": "General Enrique Mosconi",
        "lat": "-22.59588",
        "lng": "-63.81255"
    },
    {
        "country": "AR",
        "name": "General Enrique Godoy",
        "lat": "-39.08333",
        "lng": "-67.15"
    },
    {
        "country": "AR",
        "name": "General Conesa",
        "lat": "-40.1",
        "lng": "-64.43333"
    },
    {
        "country": "AR",
        "name": "General Cabrera",
        "lat": "-32.81313",
        "lng": "-63.87243"
    },
    {
        "country": "AR",
        "name": "General Baldissera",
        "lat": "-33.12246",
        "lng": "-62.3063"
    },
    {
        "country": "AR",
        "name": "General Arenales",
        "lat": "-34.30264",
        "lng": "-61.30522"
    },
    {
        "country": "AR",
        "name": "General Alvear",
        "lat": "-36.02078",
        "lng": "-60.0145"
    },
    {
        "country": "AR",
        "name": "General Alvear",
        "lat": "-34.97696",
        "lng": "-67.69116"
    },
    {
        "country": "AR",
        "name": "General Acha",
        "lat": "-37.37698",
        "lng": "-64.60431"
    },
    {
        "country": "AR",
        "name": "Gastre",
        "lat": "-42.27514",
        "lng": "-69.21967"
    },
    {
        "country": "AR",
        "name": "Garza",
        "lat": "-28.15321",
        "lng": "-63.53935"
    },
    {
        "country": "AR",
        "name": "Gancedo",
        "lat": "-27.49038",
        "lng": "-61.67571"
    },
    {
        "country": "AR",
        "name": "Gálvez",
        "lat": "-32.02927",
        "lng": "-61.22103"
    },
    {
        "country": "AR",
        "name": "Gaimán",
        "lat": "-43.2897",
        "lng": "-65.4929"
    },
    {
        "country": "AR",
        "name": "Funes",
        "lat": "-32.91568",
        "lng": "-60.80995"
    },
    {
        "country": "AR",
        "name": "Frías",
        "lat": "-28.63593",
        "lng": "-65.13102"
    },
    {
        "country": "AR",
        "name": "Fray Luis Beltrán",
        "lat": "-39.31667",
        "lng": "-65.76667"
    },
    {
        "country": "AR",
        "name": "Fray Luis A. Beltrán",
        "lat": "-32.79122",
        "lng": "-60.72819"
    },
    {
        "country": "AR",
        "name": "Fraile Pintado",
        "lat": "-23.94079",
        "lng": "-64.79943"
    },
    {
        "country": "AR",
        "name": "Firmat",
        "lat": "-33.45937",
        "lng": "-61.4832"
    },
    {
        "country": "AR",
        "name": "Fiambalá",
        "lat": "-27.68705",
        "lng": "-67.6149"
    },
    {
        "country": "AR",
        "name": "Fernández",
        "lat": "-27.92037",
        "lng": "-63.89349"
    },
    {
        "country": "AR",
        "name": "Famatina",
        "lat": "-28.92322",
        "lng": "-67.52253"
    },
    {
        "country": "AR",
        "name": "Famaillá",
        "lat": "-27.05413",
        "lng": "-65.40329"
    },
    {
        "country": "AR",
        "name": "Etruria",
        "lat": "-32.94008",
        "lng": "-63.2466"
    },
    {
        "country": "AR",
        "name": "Estanislao del Campo",
        "lat": "-25.05504",
        "lng": "-60.09218"
    },
    {
        "country": "AR",
        "name": "Esquel",
        "lat": "-42.91147",
        "lng": "-71.31947"
    },
    {
        "country": "AR",
        "name": "Esperanza",
        "lat": "-31.4488",
        "lng": "-60.93173"
    },
    {
        "country": "AR",
        "name": "Embarcación",
        "lat": "-23.21003",
        "lng": "-64.09965"
    },
    {
        "country": "AR",
        "name": "Embalse",
        "lat": "-32.18",
        "lng": "-64.41809"
    },
    {
        "country": "AR",
        "name": "Embajador Martini",
        "lat": "-35.38633",
        "lng": "-64.28092"
    },
    {
        "country": "AR",
        "name": "El Trébol",
        "lat": "-32.2008",
        "lng": "-61.7014"
    },
    {
        "country": "AR",
        "name": "El Tío",
        "lat": "-31.38357",
        "lng": "-62.82938"
    },
    {
        "country": "AR",
        "name": "El Simbolar",
        "lat": "-27.97167",
        "lng": "-64.89544"
    },
    {
        "country": "AR",
        "name": "El Rodeo",
        "lat": "-28.21518",
        "lng": "-65.8742"
    },
    {
        "country": "AR",
        "name": "El Quebrachal",
        "lat": "-25.28333",
        "lng": "-64.06667"
    },
    {
        "country": "AR",
        "name": "El Maitén",
        "lat": "-42.04924",
        "lng": "-71.16693"
    },
    {
        "country": "AR",
        "name": "El Huecú",
        "lat": "-37.64522",
        "lng": "-70.58006"
    },
    {
        "country": "AR",
        "name": "El Hoyo",
        "lat": "-27.02872",
        "lng": "-63.23197"
    },
    {
        "country": "AR",
        "name": "El Galpón",
        "lat": "-25.38069",
        "lng": "-64.65259"
    },
    {
        "country": "AR",
        "name": "Elena",
        "lat": "-32.57205",
        "lng": "-64.39481"
    },
    {
        "country": "AR",
        "name": "El Cuy",
        "lat": "-39.92685",
        "lng": "-68.34208"
    },
    {
        "country": "AR",
        "name": "El Carril",
        "lat": "-25.0741",
        "lng": "-65.49174"
    },
    {
        "country": "AR",
        "name": "El Carmen",
        "lat": "-24.38682",
        "lng": "-65.26177"
    },
    {
        "country": "AR",
        "name": "El Calafate",
        "lat": "-50.34075",
        "lng": "-72.27682"
    },
    {
        "country": "AR",
        "name": "El Bolsón",
        "lat": "-41.96051",
        "lng": "-71.53336"
    },
    {
        "country": "AR",
        "name": "El Arañado",
        "lat": "-31.7412",
        "lng": "-62.89322"
    },
    {
        "country": "AR",
        "name": "El Alto",
        "lat": "-28.31123",
        "lng": "-65.36397"
    },
    {
        "country": "AR",
        "name": "El Aguilar",
        "lat": "-23.22582",
        "lng": "-65.69213"
    },
    {
        "country": "AR",
        "name": "Eduardo Castex",
        "lat": "-35.91501",
        "lng": "-64.29448"
    },
    {
        "country": "AR",
        "name": "Dolavón",
        "lat": "-43.3",
        "lng": "-65.7"
    },
    {
        "country": "AR",
        "name": "Doblas",
        "lat": "-37.14967",
        "lng": "-64.01183"
    },
    {
        "country": "AR",
        "name": "Diamante",
        "lat": "-32.06641",
        "lng": "-60.63837"
    },
    {
        "country": "AR",
        "name": "Devoto",
        "lat": "-31.40431",
        "lng": "-62.30634"
    },
    {
        "country": "AR",
        "name": "Despeñaderos",
        "lat": "-31.81626",
        "lng": "-64.28989"
    },
    {
        "country": "AR",
        "name": "Del Campillo",
        "lat": "-34.37659",
        "lng": "-64.49504"
    },
    {
        "country": "AR",
        "name": "Deán Funes",
        "lat": "-30.42036",
        "lng": "-64.34984"
    },
    {
        "country": "AR",
        "name": "Darwin",
        "lat": "-39.2",
        "lng": "-65.76667"
    },
    {
        "country": "AR",
        "name": "Dalmacio Vélez Sársfield",
        "lat": "-32.61072",
        "lng": "-63.58038"
    },
    {
        "country": "AR",
        "name": "Daireaux",
        "lat": "-36.6",
        "lng": "-61.75"
    },
    {
        "country": "AR",
        "name": "Cutral-Có",
        "lat": "-38.93424",
        "lng": "-69.23052"
    },
    {
        "country": "AR",
        "name": "Cuchillo Có",
        "lat": "-38.33349",
        "lng": "-64.64331"
    },
    {
        "country": "AR",
        "name": "Cruz del Eje",
        "lat": "-30.72644",
        "lng": "-64.80387"
    },
    {
        "country": "AR",
        "name": "Cruz Alta",
        "lat": "-33.00887",
        "lng": "-61.80746"
    },
    {
        "country": "AR",
        "name": "Crespo",
        "lat": "-32.02873",
        "lng": "-60.30658"
    },
    {
        "country": "AR",
        "name": "Costa Sacate",
        "lat": "-31.6477",
        "lng": "-63.75935"
    },
    {
        "country": "AR",
        "name": "Cosquín",
        "lat": "-31.24508",
        "lng": "-64.46563"
    },
    {
        "country": "AR",
        "name": "Corzuela",
        "lat": "-26.95374",
        "lng": "-60.96928"
    },
    {
        "country": "AR",
        "name": "Corralito",
        "lat": "-32.02462",
        "lng": "-64.19216"
    },
    {
        "country": "AR",
        "name": "Corral de Bustos",
        "lat": "-33.28205",
        "lng": "-62.18463"
    },
    {
        "country": "AR",
        "name": "Coronel Suárez",
        "lat": "-37.45467",
        "lng": "-61.93343"
    },
    {
        "country": "AR",
        "name": "Coronel Pringles",
        "lat": "-37.98295",
        "lng": "-61.35615"
    },
    {
        "country": "AR",
        "name": "Coronel Moldes",
        "lat": "-33.6227",
        "lng": "-64.59711"
    },
    {
        "country": "AR",
        "name": "Coronel Du Graty",
        "lat": "-27.68038",
        "lng": "-60.91462"
    },
    {
        "country": "AR",
        "name": "Coronel Dorrego",
        "lat": "-38.71867",
        "lng": "-61.28733"
    },
    {
        "country": "AR",
        "name": "Coronel Belisle",
        "lat": "-39.18333",
        "lng": "-65.98333"
    },
    {
        "country": "AR",
        "name": "Coronel Baigorria",
        "lat": "-32.8477",
        "lng": "-64.36107"
    },
    {
        "country": "AR",
        "name": "Coronda",
        "lat": "-31.97263",
        "lng": "-60.91983"
    },
    {
        "country": "AR",
        "name": "Córdoba",
        "lat": "-31.4135",
        "lng": "-64.18105"
    },
    {
        "country": "AR",
        "name": "Contraalmirante Cordero",
        "lat": "-38.72423",
        "lng": "-68.15284"
    },
    {
        "country": "AR",
        "name": "Concepción del Bermejo",
        "lat": "-26.59926",
        "lng": "-60.94617"
    },
    {
        "country": "AR",
        "name": "Concepción",
        "lat": "-27.34127",
        "lng": "-65.59636"
    },
    {
        "country": "AR",
        "name": "Concarán",
        "lat": "-32.56009",
        "lng": "-65.2427"
    },
    {
        "country": "AR",
        "name": "Comodoro Rivadavia",
        "lat": "-45.86413",
        "lng": "-67.49656"
    },
    {
        "country": "AR",
        "name": "Comandante Luis Piedra Buena",
        "lat": "-49.98513",
        "lng": "-68.91467"
    },
    {
        "country": "AR",
        "name": "Comallo",
        "lat": "-41.02993",
        "lng": "-70.26784"
    },
    {
        "country": "AR",
        "name": "Colonia San Bartolomé",
        "lat": "-31.5278",
        "lng": "-62.72436"
    },
    {
        "country": "AR",
        "name": "Colonia La Tordilla",
        "lat": "-31.23746",
        "lng": "-63.06134"
    },
    {
        "country": "AR",
        "name": "Colonia Dora",
        "lat": "-28.6",
        "lng": "-62.95"
    },
    {
        "country": "AR",
        "name": "Colonia Barón",
        "lat": "-36.15152",
        "lng": "-63.85404"
    },
    {
        "country": "AR",
        "name": "Colón",
        "lat": "-33.89512",
        "lng": "-61.1011"
    },
    {
        "country": "AR",
        "name": "Clodomira",
        "lat": "-27.5744",
        "lng": "-64.13108"
    },
    {
        "country": "AR",
        "name": "Cipolletti",
        "lat": "-38.93392",
        "lng": "-67.99032"
    },
    {
        "country": "AR",
        "name": "Cintra",
        "lat": "-32.30673",
        "lng": "-62.65214"
    },
    {
        "country": "AR",
        "name": "Cinco Saltos",
        "lat": "-38.82225",
        "lng": "-68.06293"
    },
    {
        "country": "AR",
        "name": "Chumbicha",
        "lat": "-28.8543",
        "lng": "-66.235"
    },
    {
        "country": "AR",
        "name": "Chos Malal",
        "lat": "-37.37809",
        "lng": "-70.27085"
    },
    {
        "country": "AR",
        "name": "Chorotis",
        "lat": "-27.91578",
        "lng": "-61.39982"
    },
    {
        "country": "AR",
        "name": "Choele Choel",
        "lat": "-39.26667",
        "lng": "-65.68333"
    },
    {
        "country": "AR",
        "name": "Chivilcoy",
        "lat": "-34.89566",
        "lng": "-60.01667"
    },
    {
        "country": "AR",
        "name": "Chimpay",
        "lat": "-39.16482",
        "lng": "-66.14236"
    },
    {
        "country": "AR",
        "name": "Chimbas",
        "lat": "-31.48333",
        "lng": "-68.53333"
    },
    {
        "country": "AR",
        "name": "Chilecito",
        "lat": "-29.16195",
        "lng": "-67.4974"
    },
    {
        "country": "AR",
        "name": "Chicoana",
        "lat": "-25.10088",
        "lng": "-65.5331"
    },
    {
        "country": "AR",
        "name": "Chichinales",
        "lat": "-39.11505",
        "lng": "-66.92714"
    },
    {
        "country": "AR",
        "name": "Chepes",
        "lat": "-31.35",
        "lng": "-66.6"
    },
    {
        "country": "AR",
        "name": "Chazón",
        "lat": "-33.07872",
        "lng": "-63.27657"
    },
    {
        "country": "AR",
        "name": "Charras",
        "lat": "-33.024",
        "lng": "-64.04719"
    },
    {
        "country": "AR",
        "name": "Charata",
        "lat": "-27.21438",
        "lng": "-61.18795"
    },
    {
        "country": "AR",
        "name": "Chamical",
        "lat": "-30.36002",
        "lng": "-66.31399"
    },
    {
        "country": "AR",
        "name": "Chacabuco",
        "lat": "-34.64167",
        "lng": "-60.47389"
    },
    {
        "country": "AR",
        "name": "Cervantes",
        "lat": "-39.05",
        "lng": "-67.38333"
    },
    {
        "country": "AR",
        "name": "Cerrillos",
        "lat": "-24.89833",
        "lng": "-65.48706"
    },
    {
        "country": "AR",
        "name": "Ceres",
        "lat": "-29.881",
        "lng": "-61.94504"
    },
    {
        "country": "AR",
        "name": "Centenario",
        "lat": "-38.82955",
        "lng": "-68.1318"
    },
    {
        "country": "AR",
        "name": "Cavanagh",
        "lat": "-33.47606",
        "lng": "-62.33888"
    },
    {
        "country": "AR",
        "name": "Caucete",
        "lat": "-31.65179",
        "lng": "-68.28105"
    },
    {
        "country": "AR",
        "name": "Catriló",
        "lat": "-36.40597",
        "lng": "-63.42168"
    },
    {
        "country": "AR",
        "name": "Catriel",
        "lat": "-37.87907",
        "lng": "-67.7956"
    },
    {
        "country": "AR",
        "name": "Castro Barros",
        "lat": "-30.57952",
        "lng": "-65.72696"
    },
    {
        "country": "AR",
        "name": "Castelli",
        "lat": "-25.94679",
        "lng": "-60.61947"
    },
    {
        "country": "AR",
        "name": "Casilda",
        "lat": "-33.04417",
        "lng": "-61.16806"
    },
    {
        "country": "AR",
        "name": "Carrilobo",
        "lat": "-31.87296",
        "lng": "-63.11715"
    },
    {
        "country": "AR",
        "name": "Carnerillo",
        "lat": "-32.91371",
        "lng": "-64.02175"
    },
    {
        "country": "AR",
        "name": "Carmen de Patagones",
        "lat": "-40.79828",
        "lng": "-62.98097"
    },
    {
        "country": "AR",
        "name": "Carlos Tejedor",
        "lat": "-35.39265",
        "lng": "-62.42146"
    },
    {
        "country": "AR",
        "name": "Carlos Casares",
        "lat": "-35.62171",
        "lng": "-61.36169"
    },
    {
        "country": "AR",
        "name": "Carhué",
        "lat": "-37.17668",
        "lng": "-62.75792"
    },
    {
        "country": "AR",
        "name": "Carcarañá",
        "lat": "-32.85679",
        "lng": "-61.15331"
    },
    {
        "country": "AR",
        "name": "Capitán Bermúdez",
        "lat": "-32.82262",
        "lng": "-60.71852"
    },
    {
        "country": "AR",
        "name": "Capilla del Monte",
        "lat": "-30.86088",
        "lng": "-64.52515"
    },
    {
        "country": "AR",
        "name": "Capayán",
        "lat": "-28.76105",
        "lng": "-66.05025"
    },
    {
        "country": "AR",
        "name": "Candelaria",
        "lat": "-32.06036",
        "lng": "-65.82477"
    },
    {
        "country": "AR",
        "name": "Canals",
        "lat": "-33.56542",
        "lng": "-62.88927"
    },
    {
        "country": "AR",
        "name": "Cañada de Luque",
        "lat": "-30.73341",
        "lng": "-63.72375"
    },
    {
        "country": "AR",
        "name": "Cañada de Gómez",
        "lat": "-32.81636",
        "lng": "-61.39493"
    },
    {
        "country": "AR",
        "name": "Campo Quijano",
        "lat": "-24.90982",
        "lng": "-65.63656"
    },
    {
        "country": "AR",
        "name": "Campo Largo",
        "lat": "-26.80077",
        "lng": "-60.84215"
    },
    {
        "country": "AR",
        "name": "Campo Gallo",
        "lat": "-26.58333",
        "lng": "-62.85"
    },
    {
        "country": "AR",
        "name": "Camilo Aldao",
        "lat": "-33.12745",
        "lng": "-62.09453"
    },
    {
        "country": "AR",
        "name": "Camarones",
        "lat": "-44.79709",
        "lng": "-65.70994"
    },
    {
        "country": "AR",
        "name": "Calingasta",
        "lat": "-31.33394",
        "lng": "-69.4208"
    },
    {
        "country": "AR",
        "name": "Calilegua",
        "lat": "-23.77368",
        "lng": "-64.77002"
    },
    {
        "country": "AR",
        "name": "Caleufú",
        "lat": "-35.59559",
        "lng": "-64.55778"
    },
    {
        "country": "AR",
        "name": "Caleta Olivia",
        "lat": "-46.43929",
        "lng": "-67.52814"
    },
    {
        "country": "AR",
        "name": "Calchaquí",
        "lat": "-29.88767",
        "lng": "-60.28697"
    },
    {
        "country": "AR",
        "name": "Caimancito",
        "lat": "-23.74069",
        "lng": "-64.5937"
    },
    {
        "country": "AR",
        "name": "Cafayate",
        "lat": "-26.07286",
        "lng": "-65.97766"
    },
    {
        "country": "AR",
        "name": "Cachí",
        "lat": "-25.12033",
        "lng": "-66.16519"
    },
    {
        "country": "AR",
        "name": "Buta Ranquil",
        "lat": "-37.05222",
        "lng": "-69.87713"
    },
    {
        "country": "AR",
        "name": "Burruyacú",
        "lat": "-26.49918",
        "lng": "-64.74206"
    },
    {
        "country": "AR",
        "name": "Buena Esperanza",
        "lat": "-34.75647",
        "lng": "-65.25379"
    },
    {
        "country": "AR",
        "name": "Buchardo",
        "lat": "-34.72263",
        "lng": "-63.5092"
    },
    {
        "country": "AR",
        "name": "Brinkmann",
        "lat": "-30.86589",
        "lng": "-62.03742"
    },
    {
        "country": "AR",
        "name": "Brea Pozo",
        "lat": "-28.24382",
        "lng": "-63.95091"
    },
    {
        "country": "AR",
        "name": "Bragado",
        "lat": "-35.11912",
        "lng": "-60.48966"
    },
    {
        "country": "AR",
        "name": "Berrotarán",
        "lat": "-32.451",
        "lng": "-64.38867"
    },
    {
        "country": "AR",
        "name": "Bernasconi",
        "lat": "-37.90459",
        "lng": "-63.7424"
    },
    {
        "country": "AR",
        "name": "Bernardo Larroudé",
        "lat": "-35.02449",
        "lng": "-63.58253"
    },
    {
        "country": "AR",
        "name": "Beltrán",
        "lat": "-27.82913",
        "lng": "-64.06098"
    },
    {
        "country": "AR",
        "name": "Bell Ville",
        "lat": "-32.62591",
        "lng": "-62.68873"
    },
    {
        "country": "AR",
        "name": "Bella Vista",
        "lat": "-27.03424",
        "lng": "-65.30196"
    },
    {
        "country": "AR",
        "name": "Belén",
        "lat": "-27.65103",
        "lng": "-67.02869"
    },
    {
        "country": "AR",
        "name": "Barrancas",
        "lat": "-36.81667",
        "lng": "-69.91667"
    },
    {
        "country": "AR",
        "name": "Bandera",
        "lat": "-28.8884",
        "lng": "-62.266"
    },
    {
        "country": "AR",
        "name": "Banda del Río Salí",
        "lat": "-26.83333",
        "lng": "-65.16667"
    },
    {
        "country": "AR",
        "name": "Bañado de Ovanta",
        "lat": "-28.10564",
        "lng": "-65.31884"
    },
    {
        "country": "AR",
        "name": "Balnearia",
        "lat": "-31.0088",
        "lng": "-62.66733"
    },
    {
        "country": "AR",
        "name": "Bahía Blanca",
        "lat": "-38.71959",
        "lng": "-62.27243"
    },
    {
        "country": "AR",
        "name": "Aviá Terai",
        "lat": "-26.68532",
        "lng": "-60.7292"
    },
    {
        "country": "AR",
        "name": "Arrufó",
        "lat": "-30.23281",
        "lng": "-61.72862"
    },
    {
        "country": "AR",
        "name": "Arroyo Seco",
        "lat": "-33.15489",
        "lng": "-60.50863"
    },
    {
        "country": "AR",
        "name": "Arroyo Cabral",
        "lat": "-32.49119",
        "lng": "-63.40126"
    },
    {
        "country": "AR",
        "name": "Arroyito",
        "lat": "-31.42022",
        "lng": "-63.05002"
    },
    {
        "country": "AR",
        "name": "Arrecifes",
        "lat": "-34.0639",
        "lng": "-60.10357"
    },
    {
        "country": "AR",
        "name": "Arraga",
        "lat": "-28.04978",
        "lng": "-64.22254"
    },
    {
        "country": "AR",
        "name": "Armstrong",
        "lat": "-32.78215",
        "lng": "-61.60222"
    },
    {
        "country": "AR",
        "name": "Arias",
        "lat": "-33.64411",
        "lng": "-62.40272"
    },
    {
        "country": "AR",
        "name": "Arauco",
        "lat": "-28.5805",
        "lng": "-66.79438"
    },
    {
        "country": "AR",
        "name": "Arata",
        "lat": "-35.63895",
        "lng": "-64.35621"
    },
    {
        "country": "AR",
        "name": "Aranguren",
        "lat": "-32.24252",
        "lng": "-60.16107"
    },
    {
        "country": "AR",
        "name": "Apolinario Saravia",
        "lat": "-24.43276",
        "lng": "-63.99535"
    },
    {
        "country": "AR",
        "name": "Antofagasta de la Sierra",
        "lat": "-26.0594",
        "lng": "-67.40636"
    },
    {
        "country": "AR",
        "name": "Anguil",
        "lat": "-36.52567",
        "lng": "-64.01025"
    },
    {
        "country": "AR",
        "name": "Añelo",
        "lat": "-38.35441",
        "lng": "-68.7884"
    },
    {
        "country": "AR",
        "name": "Andalgalá",
        "lat": "-27.60012",
        "lng": "-66.31794"
    },
    {
        "country": "AR",
        "name": "Andacollo",
        "lat": "-37.17945",
        "lng": "-70.66912"
    },
    {
        "country": "AR",
        "name": "Ancasti",
        "lat": "-28.81247",
        "lng": "-65.50145"
    },
    {
        "country": "AR",
        "name": "Añatuya",
        "lat": "-28.46064",
        "lng": "-62.83472"
    },
    {
        "country": "AR",
        "name": "Aminga",
        "lat": "-28.8426",
        "lng": "-66.94546"
    },
    {
        "country": "AR",
        "name": "Florentino Ameghino",
        "lat": "-34.84405",
        "lng": "-62.46701"
    },
    {
        "country": "AR",
        "name": "Aluminé",
        "lat": "-39.23686",
        "lng": "-70.9197"
    },
    {
        "country": "AR",
        "name": "Altos de Chipión",
        "lat": "-30.9559",
        "lng": "-62.33727"
    },
    {
        "country": "AR",
        "name": "Alto Río Senguer",
        "lat": "-45.04105",
        "lng": "-70.81982"
    },
    {
        "country": "AR",
        "name": "Alta Italia",
        "lat": "-35.3335",
        "lng": "-64.11496"
    },
    {
        "country": "AR",
        "name": "Alta Gracia",
        "lat": "-31.65292",
        "lng": "-64.42826"
    },
    {
        "country": "AR",
        "name": "Alpachiri",
        "lat": "-37.37704",
        "lng": "-63.77445"
    },
    {
        "country": "AR",
        "name": "Almafuerte",
        "lat": "-32.19296",
        "lng": "-64.25559"
    },
    {
        "country": "AR",
        "name": "Allen",
        "lat": "-38.97736",
        "lng": "-67.82714"
    },
    {
        "country": "AR",
        "name": "Algarrobo del Águila",
        "lat": "-36.39968",
        "lng": "-67.14511"
    },
    {
        "country": "AR",
        "name": "Alejo Ledesma",
        "lat": "-33.60643",
        "lng": "-62.62304"
    },
    {
        "country": "AR",
        "name": "Alejandro Roca",
        "lat": "-33.35369",
        "lng": "-63.71849"
    },
    {
        "country": "AR",
        "name": "Alderetes",
        "lat": "-26.81667",
        "lng": "-65.13333"
    },
    {
        "country": "AR",
        "name": "Alberti",
        "lat": "-35.02692",
        "lng": "-60.27587"
    },
    {
        "country": "AR",
        "name": "Albardón",
        "lat": "-31.43722",
        "lng": "-68.52556"
    },
    {
        "country": "AR",
        "name": "Aimogasta",
        "lat": "-28.56091",
        "lng": "-66.80588"
    },
    {
        "country": "AR",
        "name": "Aguilares",
        "lat": "-27.4338",
        "lng": "-65.61427"
    },
    {
        "country": "AR",
        "name": "Agua de Oro",
        "lat": "-31.06661",
        "lng": "-64.30017"
    },
    {
        "country": "AR",
        "name": "Adelia María",
        "lat": "-33.63152",
        "lng": "-64.02097"
    },
    {
        "country": "AR",
        "name": "Achiras",
        "lat": "-33.17538",
        "lng": "-64.99331"
    },
    {
        "country": "AR",
        "name": "Abra Pampa",
        "lat": "-22.72049",
        "lng": "-65.69697"
    },
    {
        "country": "AR",
        "name": "Balvanera",
        "lat": "-34.61032",
        "lng": "-58.39766"
    },
    {
        "country": "AR",
        "name": "Villa Santa Rita",
        "lat": "-34.61082",
        "lng": "-58.481"
    },
    {
        "country": "AR",
        "name": "Bella Italia",
        "lat": "-31.27268",
        "lng": "-61.42396"
    },
    {
        "country": "AR",
        "name": "Cariló",
        "lat": "-37.16524",
        "lng": "-56.89133"
    },
    {
        "country": "AR",
        "name": "Villa Mercedes",
        "lat": "-33.67571",
        "lng": "-65.45783"
    },
    {
        "country": "AR",
        "name": "La Punta",
        "lat": "-33.18368",
        "lng": "-66.3127"
    },
    {
        "country": "AR",
        "name": "Villa Berna",
        "lat": "-31.90321",
        "lng": "-64.75651"
    },
    {
        "country": "AR",
        "name": "Cuesta Blanca",
        "lat": "-31.48658",
        "lng": "-64.5715"
    },
    {
        "country": "AR",
        "name": "Avellaneda",
        "lat": "-34.66018",
        "lng": "-58.36744"
    },
    {
        "country": "AR",
        "name": "San Carlos de Bariloche",
        "lat": "-41.14557",
        "lng": "-71.30822"
    },
    {
        "country": "AR",
        "name": "Santa Catalina - Dique Lujan",
        "lat": "-34.38375",
        "lng": "-58.70673"
    },
    {
        "country": "AR",
        "name": "Río Turbio",
        "lat": "-51.53587",
        "lng": "-72.33673"
    },
    {
        "country": "AR",
        "name": "Adrogué",
        "lat": "-34.80041",
        "lng": "-58.38384"
    },
    {
        "country": "AR",
        "name": "Los Laureles",
        "lat": "-29.36847",
        "lng": "-59.73634"
    },
    {
        "country": "AR",
        "name": "Gato Colorado",
        "lat": "-28.02219",
        "lng": "-61.18663"
    },
    {
        "country": "AR",
        "name": "Tolhuin",
        "lat": "-54.51083",
        "lng": "-67.1955"
    },
    {
        "country": "AR",
        "name": "Villa General Guemes",
        "lat": "-24.7553",
        "lng": "-59.4894"
    },
    {
        "country": "AR",
        "name": "Rincón de Los Sauces",
        "lat": "-37.39844",
        "lng": "-68.92874"
    },
    {
        "country": "AR",
        "name": "La Adela",
        "lat": "-38.98333",
        "lng": "-64.08333"
    },
    {
        "country": "AS",
        "name": "Aūa",
        "lat": "-14.27611",
        "lng": "-170.66389"
    },
    {
        "country": "AS",
        "name": "Vaitogi",
        "lat": "-14.35889",
        "lng": "-170.73472"
    },
    {
        "country": "AS",
        "name": "Vailoatai",
        "lat": "-14.36111",
        "lng": "-170.78222"
    },
    {
        "country": "AS",
        "name": "Taulaga",
        "lat": "-11.05528",
        "lng": "-171.08833"
    },
    {
        "country": "AS",
        "name": "Ta`ū",
        "lat": "-14.23361",
        "lng": "-169.51444"
    },
    {
        "country": "AS",
        "name": "Tāfuna",
        "lat": "-14.33583",
        "lng": "-170.72"
    },
    {
        "country": "AS",
        "name": "Faleniu",
        "lat": "-14.3325",
        "lng": "-170.74444"
    },
    {
        "country": "AS",
        "name": "Fagatogo",
        "lat": "-14.2825",
        "lng": "-170.69"
    },
    {
        "country": "AS",
        "name": "Faleāsao",
        "lat": "-14.2222",
        "lng": "-169.51278"
    },
    {
        "country": "AS",
        "name": "Leone",
        "lat": "-14.34389",
        "lng": "-170.785"
    },
    {
        "country": "AS",
        "name": "Malaeimi",
        "lat": "-14.32278",
        "lng": "-170.73389"
    },
    {
        "country": "AS",
        "name": "Mapusagafou",
        "lat": "-14.33056",
        "lng": "-170.75278"
    },
    {
        "country": "AS",
        "name": "Ofu",
        "lat": "-14.17528",
        "lng": "-169.6775"
    },
    {
        "country": "AS",
        "name": "Pago Pago",
        "lat": "-14.27806",
        "lng": "-170.7025"
    },
    {
        "country": "AT",
        "name": "Neu-Guntramsdorf",
        "lat": "48.0642",
        "lng": "16.31573"
    },
    {
        "country": "AT",
        "name": "Kleinarl",
        "lat": "47.27725",
        "lng": "13.31955"
    },
    {
        "country": "AT",
        "name": "Edelstauden",
        "lat": "46.98333",
        "lng": "15.61667"
    },
    {
        "country": "AT",
        "name": "Neuhaus am Klausenbach",
        "lat": "46.86667",
        "lng": "16.03333"
    },
    {
        "country": "AT",
        "name": "Millstatt",
        "lat": "46.80417",
        "lng": "13.58056"
    },
    {
        "country": "AT",
        "name": "Zwölfaxing",
        "lat": "48.1099",
        "lng": "16.46267"
    },
    {
        "country": "AT",
        "name": "Zwettl Stadt",
        "lat": "48.60726",
        "lng": "15.16714"
    },
    {
        "country": "AT",
        "name": "Zwettl an der Rodl",
        "lat": "48.46552",
        "lng": "14.27133"
    },
    {
        "country": "AT",
        "name": "Zwentendorf",
        "lat": "48.3453",
        "lng": "15.91026"
    },
    {
        "country": "AT",
        "name": "Zurndorf",
        "lat": "47.98314",
        "lng": "17.00315"
    },
    {
        "country": "AT",
        "name": "Zöblen",
        "lat": "47.50772",
        "lng": "10.47971"
    },
    {
        "country": "AT",
        "name": "Zöbern",
        "lat": "47.51459",
        "lng": "16.13111"
    },
    {
        "country": "AT",
        "name": "Zistersdorf",
        "lat": "48.54252",
        "lng": "16.76136"
    },
    {
        "country": "AT",
        "name": "Zirl",
        "lat": "47.2741",
        "lng": "11.23961"
    },
    {
        "country": "AT",
        "name": "Zillingtal",
        "lat": "47.81442",
        "lng": "16.40928"
    },
    {
        "country": "AT",
        "name": "Zillingdorf",
        "lat": "47.85",
        "lng": "16.33333"
    },
    {
        "country": "AT",
        "name": "Ziersdorf",
        "lat": "48.5303",
        "lng": "15.92691"
    },
    {
        "country": "AT",
        "name": "Zeutschach",
        "lat": "47.06667",
        "lng": "14.36667"
    },
    {
        "country": "AT",
        "name": "Zettling",
        "lat": "46.9522",
        "lng": "15.4342"
    },
    {
        "country": "AT",
        "name": "Zerlach",
        "lat": "46.94593",
        "lng": "15.65093"
    },
    {
        "country": "AT",
        "name": "Zeltweg",
        "lat": "47.18333",
        "lng": "14.75"
    },
    {
        "country": "AT",
        "name": "Zellerndorf",
        "lat": "48.69657",
        "lng": "15.95841"
    },
    {
        "country": "AT",
        "name": "Zellberg",
        "lat": "47.23333",
        "lng": "11.85"
    },
    {
        "country": "AT",
        "name": "Zell an der Pram",
        "lat": "48.31625",
        "lng": "13.62923"
    },
    {
        "country": "AT",
        "name": "Zell am Ziller",
        "lat": "47.23333",
        "lng": "11.88333"
    },
    {
        "country": "AT",
        "name": "Zell am See",
        "lat": "47.32556",
        "lng": "12.79444"
    },
    {
        "country": "AT",
        "name": "Zell am Pettenfirst",
        "lat": "48.08009",
        "lng": "13.59936"
    },
    {
        "country": "AT",
        "name": "Zell am Moos",
        "lat": "47.9",
        "lng": "13.31667"
    },
    {
        "country": "AT",
        "name": "Zell",
        "lat": "47.58333",
        "lng": "12.15"
    },
    {
        "country": "AT",
        "name": "Zeiselmauer",
        "lat": "48.32852",
        "lng": "16.17565"
    },
    {
        "country": "AT",
        "name": "Zeillern",
        "lat": "48.13029",
        "lng": "14.8076"
    },
    {
        "country": "AT",
        "name": "Zederhaus",
        "lat": "47.1557",
        "lng": "13.50576"
    },
    {
        "country": "AT",
        "name": "Zams",
        "lat": "47.15844",
        "lng": "10.5897"
    },
    {
        "country": "AT",
        "name": "Zagersdorf",
        "lat": "47.76471",
        "lng": "16.51382"
    },
    {
        "country": "AT",
        "name": "Ybbsitz",
        "lat": "47.94745",
        "lng": "14.8918"
    },
    {
        "country": "AT",
        "name": "Ybbs an der Donau",
        "lat": "48.16667",
        "lng": "15.08333"
    },
    {
        "country": "AT",
        "name": "Würmla",
        "lat": "48.25497",
        "lng": "15.86031"
    },
    {
        "country": "AT",
        "name": "Würflach",
        "lat": "47.77648",
        "lng": "16.05463"
    },
    {
        "country": "AT",
        "name": "Wundschuh",
        "lat": "46.92639",
        "lng": "15.45111"
    },
    {
        "country": "AT",
        "name": "Wullersdorf",
        "lat": "48.62792",
        "lng": "16.10089"
    },
    {
        "country": "AT",
        "name": "Wulkaprodersdorf",
        "lat": "47.79753",
        "lng": "16.50447"
    },
    {
        "country": "AT",
        "name": "Wörth an der Lafnitz",
        "lat": "47.21368",
        "lng": "16.08081"
    },
    {
        "country": "AT",
        "name": "Wörterberg",
        "lat": "47.21667",
        "lng": "16.1"
    },
    {
        "country": "AT",
        "name": "Wörschach",
        "lat": "47.55",
        "lng": "14.15"
    },
    {
        "country": "AT",
        "name": "Wörgl",
        "lat": "47.48906",
        "lng": "12.06174"
    },
    {
        "country": "AT",
        "name": "Wördern",
        "lat": "48.334",
        "lng": "16.21016"
    },
    {
        "country": "AT",
        "name": "Wöllersdorf",
        "lat": "47.865",
        "lng": "16.17119"
    },
    {
        "country": "AT",
        "name": "Wolkersdorf im Weinviertel",
        "lat": "48.38333",
        "lng": "16.51667"
    },
    {
        "country": "AT",
        "name": "Wolfurt",
        "lat": "47.46667",
        "lng": "9.75"
    },
    {
        "country": "AT",
        "name": "Wolfsthal",
        "lat": "48.13333",
        "lng": "17"
    },
    {
        "country": "AT",
        "name": "Wolfsgraben",
        "lat": "48.1587",
        "lng": "16.12098"
    },
    {
        "country": "AT",
        "name": "Wolfsegg am Hausruck",
        "lat": "48.10669",
        "lng": "13.67274"
    },
    {
        "country": "AT",
        "name": "Wolfsberg im Schwarzautal",
        "lat": "46.84389",
        "lng": "15.65889"
    },
    {
        "country": "AT",
        "name": "Wolfsberg",
        "lat": "46.84056",
        "lng": "14.84417"
    },
    {
        "country": "AT",
        "name": "Wolfsbach",
        "lat": "48.06667",
        "lng": "14.66667"
    },
    {
        "country": "AT",
        "name": "Wolfpassing",
        "lat": "48.07785",
        "lng": "15.06453"
    },
    {
        "country": "AT",
        "name": "Wölfnitz",
        "lat": "46.66639",
        "lng": "14.25806"
    },
    {
        "country": "AT",
        "name": "Wolfau",
        "lat": "47.25",
        "lng": "16.1"
    },
    {
        "country": "AT",
        "name": "Wippenham",
        "lat": "48.2225",
        "lng": "13.3792"
    },
    {
        "country": "AT",
        "name": "Winzendorf",
        "lat": "47.81154",
        "lng": "16.113"
    },
    {
        "country": "AT",
        "name": "Winklern bei Oberwölz",
        "lat": "47.2",
        "lng": "14.23333"
    },
    {
        "country": "AT",
        "name": "Winklern",
        "lat": "46.87361",
        "lng": "12.87472"
    },
    {
        "country": "AT",
        "name": "Winklarn",
        "lat": "48.09135",
        "lng": "14.84819"
    },
    {
        "country": "AT",
        "name": "Windischgarsten",
        "lat": "47.72223",
        "lng": "14.32755"
    },
    {
        "country": "AT",
        "name": "Windigsteig",
        "lat": "48.76667",
        "lng": "15.28333"
    },
    {
        "country": "AT",
        "name": "Windhag",
        "lat": "47.97906",
        "lng": "14.80245"
    },
    {
        "country": "AT",
        "name": "Windhaag bei Perg",
        "lat": "48.28582",
        "lng": "14.68091"
    },
    {
        "country": "AT",
        "name": "Windhaag bei Freistadt",
        "lat": "48.58774",
        "lng": "14.56186"
    },
    {
        "country": "AT",
        "name": "Winden am See",
        "lat": "47.95",
        "lng": "16.75"
    },
    {
        "country": "AT",
        "name": "Wimpassing an der Leitha",
        "lat": "47.91667",
        "lng": "16.43333"
    },
    {
        "country": "AT",
        "name": "Wimpassing",
        "lat": "48.16667",
        "lng": "13.98333"
    },
    {
        "country": "AT",
        "name": "Wimpassing im Schwarzatale",
        "lat": "47.70295",
        "lng": "16.03334"
    },
    {
        "country": "AT",
        "name": "Wilten",
        "lat": "47.25829",
        "lng": "11.38808"
    },
    {
        "country": "AT",
        "name": "Willendorf am Steinfelde",
        "lat": "47.78929",
        "lng": "16.05686"
    },
    {
        "country": "AT",
        "name": "Wilhelmsburg",
        "lat": "48.10571",
        "lng": "15.60539"
    },
    {
        "country": "AT",
        "name": "Wilfleinsdorf",
        "lat": "48.01667",
        "lng": "16.71667"
    },
    {
        "country": "AT",
        "name": "Wilfersdorf",
        "lat": "48.58333",
        "lng": "16.63333"
    },
    {
        "country": "AT",
        "name": "Wildon",
        "lat": "46.88333",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Wildermieming",
        "lat": "47.31667",
        "lng": "11.01667"
    },
    {
        "country": "AT",
        "name": "Wildalpen",
        "lat": "47.65",
        "lng": "14.98333"
    },
    {
        "country": "AT",
        "name": "Wiesmath",
        "lat": "47.61667",
        "lng": "16.28333"
    },
    {
        "country": "AT",
        "name": "Wiesing",
        "lat": "47.40486",
        "lng": "11.79708"
    },
    {
        "country": "AT",
        "name": "Wiesfleck",
        "lat": "47.38458",
        "lng": "16.14552"
    },
    {
        "country": "AT",
        "name": "Wiesen",
        "lat": "47.73776",
        "lng": "16.33798"
    },
    {
        "country": "AT",
        "name": "Wieselburg",
        "lat": "48.13333",
        "lng": "15.13333"
    },
    {
        "country": "AT",
        "name": "Wies",
        "lat": "46.72028",
        "lng": "15.27194"
    },
    {
        "country": "AT",
        "name": "Wienersdorf",
        "lat": "48.00819",
        "lng": "16.29169"
    },
    {
        "country": "AT",
        "name": "Wiener Neustadt",
        "lat": "47.80485",
        "lng": "16.23196"
    },
    {
        "country": "AT",
        "name": "Wiener Neudorf",
        "lat": "48.08278",
        "lng": "16.31384"
    },
    {
        "country": "AT",
        "name": "Vienna",
        "lat": "48.20849",
        "lng": "16.37208"
    },
    {
        "country": "AT",
        "name": "Weyregg",
        "lat": "47.90294",
        "lng": "13.57193"
    },
    {
        "country": "AT",
        "name": "Weyer",
        "lat": "47.85717",
        "lng": "14.66409"
    },
    {
        "country": "AT",
        "name": "Wetzelsdorf",
        "lat": "47.05293",
        "lng": "15.39923"
    },
    {
        "country": "AT",
        "name": "Wettmannstätten",
        "lat": "46.83056",
        "lng": "15.38722"
    },
    {
        "country": "AT",
        "name": "Westendorf",
        "lat": "47.43208",
        "lng": "12.21406"
    },
    {
        "country": "AT",
        "name": "Wernstein am Inn",
        "lat": "48.50802",
        "lng": "13.461"
    },
    {
        "country": "AT",
        "name": "Wernersdorf",
        "lat": "46.71592",
        "lng": "15.20718"
    },
    {
        "country": "AT",
        "name": "Werndorf",
        "lat": "46.92417",
        "lng": "15.49083"
    },
    {
        "country": "AT",
        "name": "Wernberg",
        "lat": "46.61667",
        "lng": "13.93333"
    },
    {
        "country": "AT",
        "name": "Werfenweng",
        "lat": "47.46204",
        "lng": "13.25582"
    },
    {
        "country": "AT",
        "name": "Werfen",
        "lat": "47.47585",
        "lng": "13.1902"
    },
    {
        "country": "AT",
        "name": "Weppersdorf",
        "lat": "47.57954",
        "lng": "16.42679"
    },
    {
        "country": "AT",
        "name": "Wenns",
        "lat": "47.16667",
        "lng": "10.73333"
    },
    {
        "country": "AT",
        "name": "Weng im Innkreis",
        "lat": "48.23508",
        "lng": "13.17801"
    },
    {
        "country": "AT",
        "name": "Wendling",
        "lat": "48.23182",
        "lng": "13.66622"
    },
    {
        "country": "AT",
        "name": "Wels",
        "lat": "48.16667",
        "lng": "14.03333"
    },
    {
        "country": "AT",
        "name": "Weiz",
        "lat": "47.21667",
        "lng": "15.61667"
    },
    {
        "country": "AT",
        "name": "Weitra",
        "lat": "48.7",
        "lng": "14.88333"
    },
    {
        "country": "AT",
        "name": "Weitersfelden",
        "lat": "48.4773",
        "lng": "14.72546"
    },
    {
        "country": "AT",
        "name": "Weitersfeld",
        "lat": "48.78097",
        "lng": "15.81345"
    },
    {
        "country": "AT",
        "name": "Weitensfeld",
        "lat": "46.84743",
        "lng": "14.19213"
    },
    {
        "country": "AT",
        "name": "Weiten",
        "lat": "48.29564",
        "lng": "15.2601"
    },
    {
        "country": "AT",
        "name": "Weistrach",
        "lat": "48.05",
        "lng": "14.58333"
    },
    {
        "country": "AT",
        "name": "Weißkirchen in Steiermark",
        "lat": "47.15",
        "lng": "14.73333"
    },
    {
        "country": "AT",
        "name": "Weisskirchen an der Traun",
        "lat": "48.16202",
        "lng": "14.12395"
    },
    {
        "country": "AT",
        "name": "Weißenkirchen in der Wachau",
        "lat": "48.3979",
        "lng": "15.46931"
    },
    {
        "country": "AT",
        "name": "Weißenbach bei Liezen",
        "lat": "47.56667",
        "lng": "14.21667"
    },
    {
        "country": "AT",
        "name": "Weissenbach an der Triesting",
        "lat": "47.98211",
        "lng": "16.03935"
    },
    {
        "country": "AT",
        "name": "Weissenbach am Lech",
        "lat": "47.44162",
        "lng": "10.64071"
    },
    {
        "country": "AT",
        "name": "Weissach",
        "lat": "47.57091",
        "lng": "12.16255"
    },
    {
        "country": "AT",
        "name": "Weinzierl bei Krems",
        "lat": "48.4",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Weinzierl am Walde",
        "lat": "48.43211",
        "lng": "15.4321"
    },
    {
        "country": "AT",
        "name": "Weingraben",
        "lat": "47.51393",
        "lng": "16.36375"
    },
    {
        "country": "AT",
        "name": "Weinburg am Saßbach",
        "lat": "46.75361",
        "lng": "15.72111"
    },
    {
        "country": "AT",
        "name": "Weinburg",
        "lat": "48.11351",
        "lng": "15.53295"
    },
    {
        "country": "AT",
        "name": "Weiler",
        "lat": "47.29972",
        "lng": "9.65"
    },
    {
        "country": "AT",
        "name": "Weilbach",
        "lat": "48.27725",
        "lng": "13.37165"
    },
    {
        "country": "AT",
        "name": "Weikersdorf am Steinfelde",
        "lat": "47.80612",
        "lng": "16.14389"
    },
    {
        "country": "AT",
        "name": "Weikendorf",
        "lat": "48.34438",
        "lng": "16.76651"
    },
    {
        "country": "AT",
        "name": "Weigelsdorf",
        "lat": "47.95",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Weidling",
        "lat": "48.291",
        "lng": "16.30865"
    },
    {
        "country": "AT",
        "name": "Weiden bei Rechnitz",
        "lat": "47.3",
        "lng": "16.35"
    },
    {
        "country": "AT",
        "name": "Weiden am See",
        "lat": "47.92532",
        "lng": "16.86899"
    },
    {
        "country": "AT",
        "name": "Weichselbaum",
        "lat": "46.9425",
        "lng": "16.1875"
    },
    {
        "country": "AT",
        "name": "Weibern",
        "lat": "48.18333",
        "lng": "13.7"
    },
    {
        "country": "AT",
        "name": "Weer",
        "lat": "47.30375",
        "lng": "11.64498"
    },
    {
        "country": "AT",
        "name": "Wattens",
        "lat": "47.29419",
        "lng": "11.5907"
    },
    {
        "country": "AT",
        "name": "Wattenberg",
        "lat": "47.28333",
        "lng": "11.6"
    },
    {
        "country": "AT",
        "name": "Wartmannstetten",
        "lat": "47.69359",
        "lng": "16.07506"
    },
    {
        "country": "AT",
        "name": "Warth",
        "lat": "47.65",
        "lng": "16.11667"
    },
    {
        "country": "AT",
        "name": "Warth",
        "lat": "47.25",
        "lng": "10.18333"
    },
    {
        "country": "AT",
        "name": "Wartberg ob der Aist",
        "lat": "48.34792",
        "lng": "14.50796"
    },
    {
        "country": "AT",
        "name": "Wartberg an der Krems",
        "lat": "47.98909",
        "lng": "14.11863"
    },
    {
        "country": "AT",
        "name": "Wartberg",
        "lat": "47.52717",
        "lng": "15.48095"
    },
    {
        "country": "AT",
        "name": "Wängle",
        "lat": "47.48658",
        "lng": "10.68995"
    },
    {
        "country": "AT",
        "name": "Wang",
        "lat": "48.04538",
        "lng": "15.02672"
    },
    {
        "country": "AT",
        "name": "Waltendorf",
        "lat": "47.06667",
        "lng": "15.46667"
    },
    {
        "country": "AT",
        "name": "Wals",
        "lat": "47.78333",
        "lng": "12.96667"
    },
    {
        "country": "AT",
        "name": "Walpersbach",
        "lat": "47.71667",
        "lng": "16.23333"
    },
    {
        "country": "AT",
        "name": "Wallsee",
        "lat": "48.16667",
        "lng": "14.71667"
    },
    {
        "country": "AT",
        "name": "Wallern im Burgenland",
        "lat": "47.72847",
        "lng": "16.93706"
    },
    {
        "country": "AT",
        "name": "Wallern an der Trattnach",
        "lat": "48.23296",
        "lng": "13.9462"
    },
    {
        "country": "AT",
        "name": "Waldzell",
        "lat": "48.13562",
        "lng": "13.42701"
    },
    {
        "country": "AT",
        "name": "Waldprechting",
        "lat": "47.9",
        "lng": "13.11667"
    },
    {
        "country": "AT",
        "name": "Waldneukirchen",
        "lat": "47.99854",
        "lng": "14.25879"
    },
    {
        "country": "AT",
        "name": "Waldkirchen an der Thaya",
        "lat": "48.93333",
        "lng": "15.35"
    },
    {
        "country": "AT",
        "name": "Waldkirchen am Wesen",
        "lat": "48.44059",
        "lng": "13.82174"
    },
    {
        "country": "AT",
        "name": "Walding",
        "lat": "48.35209",
        "lng": "14.1576"
    },
    {
        "country": "AT",
        "name": "Wald im Pinzgau",
        "lat": "47.25",
        "lng": "12.23333"
    },
    {
        "country": "AT",
        "name": "Waldhausen",
        "lat": "48.52183",
        "lng": "15.2625"
    },
    {
        "country": "AT",
        "name": "Waldenstein",
        "lat": "48.72845",
        "lng": "15.01419"
    },
    {
        "country": "AT",
        "name": "Waldegg",
        "lat": "47.86852",
        "lng": "16.05154"
    },
    {
        "country": "AT",
        "name": "Wald am Schoberpaß",
        "lat": "47.44936",
        "lng": "14.67567"
    },
    {
        "country": "AT",
        "name": "Walchsee",
        "lat": "47.65163",
        "lng": "12.31868"
    },
    {
        "country": "AT",
        "name": "Walchen",
        "lat": "47.28822",
        "lng": "12.68739"
    },
    {
        "country": "AT",
        "name": "Waizenkirchen",
        "lat": "48.33018",
        "lng": "13.85754"
    },
    {
        "country": "AT",
        "name": "Waiern",
        "lat": "46.73028",
        "lng": "14.08194"
    },
    {
        "country": "AT",
        "name": "Waidring",
        "lat": "47.58333",
        "lng": "12.56667"
    },
    {
        "country": "AT",
        "name": "Waidmannsfeld",
        "lat": "47.87042",
        "lng": "15.98116"
    },
    {
        "country": "AT",
        "name": "Waidhofen an der Ybbs",
        "lat": "47.96004",
        "lng": "14.77361"
    },
    {
        "country": "AT",
        "name": "Waidhofen an der Thaya",
        "lat": "48.81667",
        "lng": "15.28333"
    },
    {
        "country": "AT",
        "name": "Wagram",
        "lat": "48.23828",
        "lng": "14.22996"
    },
    {
        "country": "AT",
        "name": "Wagrain",
        "lat": "47.33528",
        "lng": "13.29889"
    },
    {
        "country": "AT",
        "name": "Wagnitz",
        "lat": "46.98333",
        "lng": "15.46667"
    },
    {
        "country": "AT",
        "name": "Wagna",
        "lat": "46.76682",
        "lng": "15.55906"
    },
    {
        "country": "AT",
        "name": "Vösendorf",
        "lat": "48.12107",
        "lng": "16.34036"
    },
    {
        "country": "AT",
        "name": "Murau",
        "lat": "47.11056",
        "lng": "14.16944"
    },
    {
        "country": "AT",
        "name": "Vorderthiersee",
        "lat": "47.58333",
        "lng": "12.1"
    },
    {
        "country": "AT",
        "name": "Vordernberg",
        "lat": "47.48809",
        "lng": "14.99436"
    },
    {
        "country": "AT",
        "name": "Vorderhornbach",
        "lat": "47.37009",
        "lng": "10.53947"
    },
    {
        "country": "AT",
        "name": "Vorchdorf",
        "lat": "48.00388",
        "lng": "13.92122"
    },
    {
        "country": "AT",
        "name": "Vorau",
        "lat": "47.40548",
        "lng": "15.88754"
    },
    {
        "country": "AT",
        "name": "Vomp",
        "lat": "47.33333",
        "lng": "11.68333"
    },
    {
        "country": "AT",
        "name": "Völs",
        "lat": "47.25",
        "lng": "11.33333"
    },
    {
        "country": "AT",
        "name": "Völkermarkt",
        "lat": "46.66222",
        "lng": "14.63444"
    },
    {
        "country": "AT",
        "name": "Völkendorf",
        "lat": "46.60806",
        "lng": "13.83153"
    },
    {
        "country": "AT",
        "name": "Voldöpp",
        "lat": "47.44469",
        "lng": "11.88343"
    },
    {
        "country": "AT",
        "name": "Volders",
        "lat": "47.28333",
        "lng": "11.56667"
    },
    {
        "country": "AT",
        "name": "Voitsberg",
        "lat": "47.04445",
        "lng": "15.15313"
    },
    {
        "country": "AT",
        "name": "Vöcklamarkt",
        "lat": "48.0025",
        "lng": "13.48383"
    },
    {
        "country": "AT",
        "name": "Vöcklabruck",
        "lat": "48.00279",
        "lng": "13.65652"
    },
    {
        "country": "AT",
        "name": "Vitis",
        "lat": "48.75964",
        "lng": "15.18259"
    },
    {
        "country": "AT",
        "name": "Virgen",
        "lat": "47.00159",
        "lng": "12.45661"
    },
    {
        "country": "AT",
        "name": "Vils",
        "lat": "47.55",
        "lng": "10.63333"
    },
    {
        "country": "AT",
        "name": "Villach",
        "lat": "46.61028",
        "lng": "13.85583"
    },
    {
        "country": "AT",
        "name": "Viktring",
        "lat": "46.59194",
        "lng": "14.26917"
    },
    {
        "country": "AT",
        "name": "Viktorsberg",
        "lat": "47.30092",
        "lng": "9.67484"
    },
    {
        "country": "AT",
        "name": "Vigaun",
        "lat": "47.66667",
        "lng": "13.13333"
    },
    {
        "country": "AT",
        "name": "Viehofen",
        "lat": "48.21667",
        "lng": "15.61667"
    },
    {
        "country": "AT",
        "name": "Viehhofen",
        "lat": "47.36667",
        "lng": "12.73333"
    },
    {
        "country": "AT",
        "name": "Viehhausen",
        "lat": "47.78333",
        "lng": "12.98333"
    },
    {
        "country": "AT",
        "name": "Viechtwang",
        "lat": "47.91531",
        "lng": "13.96345"
    },
    {
        "country": "AT",
        "name": "Velm",
        "lat": "48.03333",
        "lng": "16.43333"
    },
    {
        "country": "AT",
        "name": "Velden am Wörthersee",
        "lat": "46.61301",
        "lng": "14.0413"
    },
    {
        "country": "AT",
        "name": "Veitsch",
        "lat": "47.57815",
        "lng": "15.4945"
    },
    {
        "country": "AT",
        "name": "Utzenaich",
        "lat": "48.27622",
        "lng": "13.46091"
    },
    {
        "country": "AT",
        "name": "Uttendorf",
        "lat": "48.15887",
        "lng": "13.1218"
    },
    {
        "country": "AT",
        "name": "Uttendorf",
        "lat": "47.28333",
        "lng": "12.56667"
    },
    {
        "country": "AT",
        "name": "Utschtal",
        "lat": "47.4",
        "lng": "15.2"
    },
    {
        "country": "AT",
        "name": "Unterwart",
        "lat": "47.25",
        "lng": "16.23333"
    },
    {
        "country": "AT",
        "name": "Unterwaltersdorf",
        "lat": "47.95",
        "lng": "16.41667"
    },
    {
        "country": "AT",
        "name": "Unterwagram",
        "lat": "48.21243",
        "lng": "15.64951"
    },
    {
        "country": "AT",
        "name": "Untertauern",
        "lat": "47.3",
        "lng": "13.5"
    },
    {
        "country": "AT",
        "name": "Unterstinkenbrunn",
        "lat": "48.6677",
        "lng": "16.34607"
    },
    {
        "country": "AT",
        "name": "Untersiebenbrunn",
        "lat": "48.25",
        "lng": "16.73333"
    },
    {
        "country": "AT",
        "name": "Rohrbach an der Gölsen",
        "lat": "48.04699",
        "lng": "15.74169"
    },
    {
        "country": "AT",
        "name": "Unterpremstätten",
        "lat": "46.96472",
        "lng": "15.40417"
    },
    {
        "country": "AT",
        "name": "Unterperfuss",
        "lat": "47.26667",
        "lng": "11.25"
    },
    {
        "country": "AT",
        "name": "Unterlangkampfen",
        "lat": "47.55",
        "lng": "12.1"
    },
    {
        "country": "AT",
        "name": "Unterlamm",
        "lat": "46.97694",
        "lng": "16.06389"
    },
    {
        "country": "AT",
        "name": "Unterkohlstätten",
        "lat": "47.38333",
        "lng": "16.31667"
    },
    {
        "country": "AT",
        "name": "Grünburg",
        "lat": "47.97234",
        "lng": "14.26472"
    },
    {
        "country": "AT",
        "name": "Unterfrauenhaid",
        "lat": "47.57116",
        "lng": "16.49885"
    },
    {
        "country": "AT",
        "name": "Unterfladnitz",
        "lat": "47.18333",
        "lng": "15.66667"
    },
    {
        "country": "AT",
        "name": "Untere Fellach",
        "lat": "46.62504",
        "lng": "13.82681"
    },
    {
        "country": "AT",
        "name": "Unterbergla",
        "lat": "46.80746",
        "lng": "15.31516"
    },
    {
        "country": "AT",
        "name": "Unterauersbach",
        "lat": "46.86556",
        "lng": "15.77028"
    },
    {
        "country": "AT",
        "name": "Unken",
        "lat": "47.64966",
        "lng": "12.72946"
    },
    {
        "country": "AT",
        "name": "Ungerdorf",
        "lat": "47.08333",
        "lng": "15.66667"
    },
    {
        "country": "AT",
        "name": "Ungenach",
        "lat": "48.04756",
        "lng": "13.61472"
    },
    {
        "country": "AT",
        "name": "Umhausen",
        "lat": "47.13503",
        "lng": "10.92826"
    },
    {
        "country": "AT",
        "name": "Ulrichskirchen",
        "lat": "48.4",
        "lng": "16.48333"
    },
    {
        "country": "AT",
        "name": "Ulrichsberg",
        "lat": "48.67498",
        "lng": "13.91049"
    },
    {
        "country": "AT",
        "name": "Uderns",
        "lat": "47.31667",
        "lng": "11.86667"
    },
    {
        "country": "AT",
        "name": "Übersbach",
        "lat": "47.02318",
        "lng": "16.05517"
    },
    {
        "country": "AT",
        "name": "Übersaxen",
        "lat": "47.25284",
        "lng": "9.6708"
    },
    {
        "country": "AT",
        "name": "Übelbach",
        "lat": "47.22534",
        "lng": "15.23615"
    },
    {
        "country": "AT",
        "name": "Tweng",
        "lat": "47.18333",
        "lng": "13.6"
    },
    {
        "country": "AT",
        "name": "Türnitz",
        "lat": "47.93095",
        "lng": "15.49295"
    },
    {
        "country": "AT",
        "name": "Turnau",
        "lat": "47.55776",
        "lng": "15.33739"
    },
    {
        "country": "AT",
        "name": "Tulln",
        "lat": "48.32829",
        "lng": "16.05858"
    },
    {
        "country": "AT",
        "name": "Tulfes",
        "lat": "47.25806",
        "lng": "11.53333"
    },
    {
        "country": "AT",
        "name": "Tulbing",
        "lat": "48.29336",
        "lng": "16.12226"
    },
    {
        "country": "AT",
        "name": "Tschanigraben",
        "lat": "47.01667",
        "lng": "16.3"
    },
    {
        "country": "AT",
        "name": "Tschagguns",
        "lat": "47.08333",
        "lng": "9.9"
    },
    {
        "country": "AT",
        "name": "Trumau",
        "lat": "47.99348",
        "lng": "16.34268"
    },
    {
        "country": "AT",
        "name": "Trössing",
        "lat": "46.81667",
        "lng": "15.81667"
    },
    {
        "country": "AT",
        "name": "Trofaiach",
        "lat": "47.42524",
        "lng": "15.00681"
    },
    {
        "country": "AT",
        "name": "Tristach",
        "lat": "46.81611",
        "lng": "12.78972"
    },
    {
        "country": "AT",
        "name": "Trins",
        "lat": "47.08333",
        "lng": "11.41667"
    },
    {
        "country": "AT",
        "name": "Triebendorf",
        "lat": "47.11667",
        "lng": "14.23333"
    },
    {
        "country": "AT",
        "name": "Trieben",
        "lat": "47.48574",
        "lng": "14.48744"
    },
    {
        "country": "AT",
        "name": "Tribuswinkel",
        "lat": "48.00623",
        "lng": "16.27075"
    },
    {
        "country": "AT",
        "name": "Treibach",
        "lat": "46.86667",
        "lng": "14.46667"
    },
    {
        "country": "AT",
        "name": "Treglwang",
        "lat": "47.47458",
        "lng": "14.59083"
    },
    {
        "country": "AT",
        "name": "Trebesing",
        "lat": "46.88639",
        "lng": "13.51028"
    },
    {
        "country": "AT",
        "name": "Trautmannsdorf in Oststeiermark",
        "lat": "46.8753",
        "lng": "15.88451"
    },
    {
        "country": "AT",
        "name": "Trautmannsdorf an der Leitha",
        "lat": "48.02357",
        "lng": "16.63266"
    },
    {
        "country": "AT",
        "name": "Trausdorf an der Wulka",
        "lat": "47.8135",
        "lng": "16.5576"
    },
    {
        "country": "AT",
        "name": "Bad Traunstein",
        "lat": "48.4385",
        "lng": "15.11712"
    },
    {
        "country": "AT",
        "name": "Traunkirchen",
        "lat": "47.84462",
        "lng": "13.78939"
    },
    {
        "country": "AT",
        "name": "Traun",
        "lat": "48.22656",
        "lng": "14.23459"
    },
    {
        "country": "AT",
        "name": "Trattenbach",
        "lat": "47.6",
        "lng": "15.86667"
    },
    {
        "country": "AT",
        "name": "Traismauer",
        "lat": "48.35",
        "lng": "15.73333"
    },
    {
        "country": "AT",
        "name": "Traiskirchen",
        "lat": "48.01485",
        "lng": "16.29324"
    },
    {
        "country": "AT",
        "name": "Traisen",
        "lat": "48.03333",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Trahütten",
        "lat": "46.825",
        "lng": "15.15694"
    },
    {
        "country": "AT",
        "name": "Tragwein",
        "lat": "48.33314",
        "lng": "14.62237"
    },
    {
        "country": "AT",
        "name": "Traboch",
        "lat": "47.37705",
        "lng": "14.98647"
    },
    {
        "country": "AT",
        "name": "Tobaj",
        "lat": "47.08333",
        "lng": "16.3"
    },
    {
        "country": "AT",
        "name": "Tobadill",
        "lat": "47.12513",
        "lng": "10.51404"
    },
    {
        "country": "AT",
        "name": "Timelkam",
        "lat": "48.00394",
        "lng": "13.6076"
    },
    {
        "country": "AT",
        "name": "Tieschen",
        "lat": "46.78611",
        "lng": "15.94222"
    },
    {
        "country": "AT",
        "name": "Tiefgraben",
        "lat": "47.87385",
        "lng": "13.30591"
    },
    {
        "country": "AT",
        "name": "Thüringen",
        "lat": "47.2",
        "lng": "9.76667"
    },
    {
        "country": "AT",
        "name": "Thumersbach",
        "lat": "47.32952",
        "lng": "12.81675"
    },
    {
        "country": "AT",
        "name": "Thörl",
        "lat": "47.51952",
        "lng": "15.22276"
    },
    {
        "country": "AT",
        "name": "Thomatal",
        "lat": "47.06667",
        "lng": "13.75"
    },
    {
        "country": "AT",
        "name": "Thomasberg",
        "lat": "47.56667",
        "lng": "16.13333"
    },
    {
        "country": "AT",
        "name": "Theresienfeld",
        "lat": "47.85",
        "lng": "16.23333"
    },
    {
        "country": "AT",
        "name": "Thaya",
        "lat": "48.85489",
        "lng": "15.28902"
    },
    {
        "country": "AT",
        "name": "Thaur",
        "lat": "47.29476",
        "lng": "11.47529"
    },
    {
        "country": "AT",
        "name": "Thalheim bei Wels",
        "lat": "48.15",
        "lng": "14.03333"
    },
    {
        "country": "AT",
        "name": "Thalgau",
        "lat": "47.84142",
        "lng": "13.25325"
    },
    {
        "country": "AT",
        "name": "Teufenbach",
        "lat": "47.1287",
        "lng": "14.35913"
    },
    {
        "country": "AT",
        "name": "Ternitz",
        "lat": "47.71565",
        "lng": "16.03575"
    },
    {
        "country": "AT",
        "name": "Ternberg",
        "lat": "47.94518",
        "lng": "14.3587"
    },
    {
        "country": "AT",
        "name": "Terfens",
        "lat": "47.32355",
        "lng": "11.64388"
    },
    {
        "country": "AT",
        "name": "Telfs",
        "lat": "47.30707",
        "lng": "11.06817"
    },
    {
        "country": "AT",
        "name": "Telfes im Stubai",
        "lat": "47.16667",
        "lng": "11.36667"
    },
    {
        "country": "AT",
        "name": "Teesdorf",
        "lat": "47.95",
        "lng": "16.28333"
    },
    {
        "country": "AT",
        "name": "Taxenbach",
        "lat": "47.29116",
        "lng": "12.96215"
    },
    {
        "country": "AT",
        "name": "Taxach",
        "lat": "47.7261",
        "lng": "13.07184"
    },
    {
        "country": "AT",
        "name": "Tauplitz",
        "lat": "47.56005",
        "lng": "14.01293"
    },
    {
        "country": "AT",
        "name": "Taufkirchen an der Trattnach",
        "lat": "48.24731",
        "lng": "13.74767"
    },
    {
        "country": "AT",
        "name": "Tattendorf",
        "lat": "47.95",
        "lng": "16.3"
    },
    {
        "country": "AT",
        "name": "Tarrenz",
        "lat": "47.26667",
        "lng": "10.76667"
    },
    {
        "country": "AT",
        "name": "Tannheim",
        "lat": "47.49934",
        "lng": "10.51641"
    },
    {
        "country": "AT",
        "name": "Tamsweg",
        "lat": "47.12808",
        "lng": "13.81102"
    },
    {
        "country": "AT",
        "name": "Taiskirchen im Innkreis",
        "lat": "48.26468",
        "lng": "13.57318"
    },
    {
        "country": "AT",
        "name": "Tadten",
        "lat": "47.76667",
        "lng": "16.98333"
    },
    {
        "country": "AT",
        "name": "Sulzberg",
        "lat": "47.52178",
        "lng": "9.91353"
    },
    {
        "country": "AT",
        "name": "Sulz",
        "lat": "47.28721",
        "lng": "9.65183"
    },
    {
        "country": "AT",
        "name": "Sulztal an der Weinstraße",
        "lat": "46.68333",
        "lng": "15.55"
    },
    {
        "country": "AT",
        "name": "Stummerberg",
        "lat": "47.28333",
        "lng": "11.91667"
    },
    {
        "country": "AT",
        "name": "Stumm",
        "lat": "47.29052",
        "lng": "11.88755"
    },
    {
        "country": "AT",
        "name": "Stuhlfelden",
        "lat": "47.28761",
        "lng": "12.52755"
    },
    {
        "country": "AT",
        "name": "Studenzen",
        "lat": "47.00583",
        "lng": "15.75417"
    },
    {
        "country": "AT",
        "name": "Stubenberg",
        "lat": "47.2446",
        "lng": "15.80027"
    },
    {
        "country": "AT",
        "name": "Stronsdorf",
        "lat": "48.65157",
        "lng": "16.2989"
    },
    {
        "country": "AT",
        "name": "Strobl",
        "lat": "47.71667",
        "lng": "13.48333"
    },
    {
        "country": "AT",
        "name": "Strengen",
        "lat": "47.12589",
        "lng": "10.46199"
    },
    {
        "country": "AT",
        "name": "Strengberg",
        "lat": "48.14685",
        "lng": "14.65147"
    },
    {
        "country": "AT",
        "name": "Strem",
        "lat": "47.04498",
        "lng": "16.41426"
    },
    {
        "country": "AT",
        "name": "Stratzing",
        "lat": "48.45",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Strasswalchen",
        "lat": "47.97947",
        "lng": "13.25535"
    },
    {
        "country": "AT",
        "name": "Straß in Steiermark",
        "lat": "46.72722",
        "lng": "15.62444"
    },
    {
        "country": "AT",
        "name": "Strasshof an der Nordbahn",
        "lat": "48.31667",
        "lng": "16.66667"
    },
    {
        "country": "AT",
        "name": "Strassham",
        "lat": "48.28348",
        "lng": "14.14524"
    },
    {
        "country": "AT",
        "name": "Straßgang",
        "lat": "47.03333",
        "lng": "15.4"
    },
    {
        "country": "AT",
        "name": "Strassengel",
        "lat": "47.11573",
        "lng": "15.33288"
    },
    {
        "country": "AT",
        "name": "Strassen",
        "lat": "46.75389",
        "lng": "12.48417"
    },
    {
        "country": "AT",
        "name": "Straßburg-Stadt",
        "lat": "46.89444",
        "lng": "14.32861"
    },
    {
        "country": "AT",
        "name": "Strass im Zillertal",
        "lat": "47.39556",
        "lng": "11.81966"
    },
    {
        "country": "AT",
        "name": "Strass",
        "lat": "48.46667",
        "lng": "15.73333"
    },
    {
        "country": "AT",
        "name": "Strallegg",
        "lat": "47.41165",
        "lng": "15.72534"
    },
    {
        "country": "AT",
        "name": "Straden",
        "lat": "46.80917",
        "lng": "15.86806"
    },
    {
        "country": "AT",
        "name": "Stotzing",
        "lat": "47.90688",
        "lng": "16.5458"
    },
    {
        "country": "AT",
        "name": "Stössing",
        "lat": "48.12267",
        "lng": "15.81379"
    },
    {
        "country": "AT",
        "name": "Stoob",
        "lat": "47.52845",
        "lng": "16.4776"
    },
    {
        "country": "AT",
        "name": "Stollhofen",
        "lat": "48.35226",
        "lng": "15.75963"
    },
    {
        "country": "AT",
        "name": "Stockerau",
        "lat": "48.38333",
        "lng": "16.21667"
    },
    {
        "country": "AT",
        "name": "Stockenboi",
        "lat": "46.72611",
        "lng": "13.52306"
    },
    {
        "country": "AT",
        "name": "Stiwoll",
        "lat": "47.1",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Steinbrunn",
        "lat": "47.83333",
        "lng": "16.41667"
    },
    {
        "country": "AT",
        "name": "Stinatz",
        "lat": "47.20266",
        "lng": "16.13312"
    },
    {
        "country": "AT",
        "name": "Steyregg",
        "lat": "48.28513",
        "lng": "14.36995"
    },
    {
        "country": "AT",
        "name": "Steyr",
        "lat": "48.04274",
        "lng": "14.42127"
    },
    {
        "country": "AT",
        "name": "Steuerberg",
        "lat": "46.78804",
        "lng": "14.1129"
    },
    {
        "country": "AT",
        "name": "Stetten",
        "lat": "48.36667",
        "lng": "16.38333"
    },
    {
        "country": "AT",
        "name": "Stetteldorf am Wagram",
        "lat": "48.40815",
        "lng": "16.01862"
    },
    {
        "country": "AT",
        "name": "Stephanshart",
        "lat": "48.15",
        "lng": "14.81667"
    },
    {
        "country": "AT",
        "name": "Stenzengreith",
        "lat": "47.2",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Steinhaus",
        "lat": "48.11614",
        "lng": "14.0189"
    },
    {
        "country": "AT",
        "name": "Steinfeld",
        "lat": "46.75812",
        "lng": "13.24934"
    },
    {
        "country": "AT",
        "name": "Steinerkirchen an der Traun",
        "lat": "48.07911",
        "lng": "13.95796"
    },
    {
        "country": "AT",
        "name": "Steindorf am Ossiacher See",
        "lat": "46.69833",
        "lng": "14.00917"
    },
    {
        "country": "AT",
        "name": "Steinbach am Attersee",
        "lat": "47.83087",
        "lng": "13.54613"
    },
    {
        "country": "AT",
        "name": "Stein an der Donau",
        "lat": "48.40163",
        "lng": "15.58102"
    },
    {
        "country": "AT",
        "name": "Steinakirchen am Forst",
        "lat": "48.06965",
        "lng": "15.04801"
    },
    {
        "country": "AT",
        "name": "Steinach am Brenner",
        "lat": "47.08333",
        "lng": "11.46667"
    },
    {
        "country": "AT",
        "name": "Steinabrückl",
        "lat": "47.87053",
        "lng": "16.20277"
    },
    {
        "country": "AT",
        "name": "Stein",
        "lat": "46.99778",
        "lng": "16.08694"
    },
    {
        "country": "AT",
        "name": "Stegersbach",
        "lat": "47.16667",
        "lng": "16.16667"
    },
    {
        "country": "AT",
        "name": "Steeg",
        "lat": "47.24393",
        "lng": "10.29436"
    },
    {
        "country": "AT",
        "name": "Statzendorf",
        "lat": "48.30752",
        "lng": "15.64127"
    },
    {
        "country": "AT",
        "name": "Stattersdorf",
        "lat": "48.18333",
        "lng": "15.63333"
    },
    {
        "country": "AT",
        "name": "Stattegg",
        "lat": "47.13333",
        "lng": "15.41667"
    },
    {
        "country": "AT",
        "name": "Stanz bei Landeck",
        "lat": "47.14758",
        "lng": "10.5534"
    },
    {
        "country": "AT",
        "name": "Stanzach",
        "lat": "47.38333",
        "lng": "10.56667"
    },
    {
        "country": "AT",
        "name": "Stans",
        "lat": "47.36667",
        "lng": "11.71667"
    },
    {
        "country": "AT",
        "name": "Stams",
        "lat": "47.27603",
        "lng": "10.98315"
    },
    {
        "country": "AT",
        "name": "Stambach",
        "lat": "47.33333",
        "lng": "15.93333"
    },
    {
        "country": "AT",
        "name": "Stallhofen",
        "lat": "47.05",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Stallhof",
        "lat": "46.88333",
        "lng": "15.28333"
    },
    {
        "country": "AT",
        "name": "Stallehr",
        "lat": "47.13306",
        "lng": "9.85"
    },
    {
        "country": "AT",
        "name": "Stall",
        "lat": "46.89056",
        "lng": "13.03694"
    },
    {
        "country": "AT",
        "name": "Stainz bei Straden",
        "lat": "46.82444",
        "lng": "15.89222"
    },
    {
        "country": "AT",
        "name": "Stainz",
        "lat": "46.89444",
        "lng": "15.26722"
    },
    {
        "country": "AT",
        "name": "Stainach",
        "lat": "47.53343",
        "lng": "14.10872"
    },
    {
        "country": "AT",
        "name": "Stadtschlaining",
        "lat": "47.31667",
        "lng": "16.28333"
    },
    {
        "country": "AT",
        "name": "Stadl-Traun",
        "lat": "48.07963",
        "lng": "13.8614"
    },
    {
        "country": "AT",
        "name": "Stadl-Paura",
        "lat": "48.08333",
        "lng": "13.86667"
    },
    {
        "country": "AT",
        "name": "Stadl-Hausruck",
        "lat": "48.08255",
        "lng": "13.85213"
    },
    {
        "country": "AT",
        "name": "Stadl an der Mur",
        "lat": "47.08333",
        "lng": "13.96667"
    },
    {
        "country": "AT",
        "name": "Staatz",
        "lat": "48.67617",
        "lng": "16.48726"
    },
    {
        "country": "AT",
        "name": "Spratzern",
        "lat": "48.17044",
        "lng": "15.61844"
    },
    {
        "country": "AT",
        "name": "Spitz",
        "lat": "48.3656",
        "lng": "15.41416"
    },
    {
        "country": "AT",
        "name": "Spittal an der Drau",
        "lat": "46.8",
        "lng": "13.5"
    },
    {
        "country": "AT",
        "name": "Spital am Semmering",
        "lat": "47.61345",
        "lng": "15.75096"
    },
    {
        "country": "AT",
        "name": "Spital am Pyhrn",
        "lat": "47.66487",
        "lng": "14.34014"
    },
    {
        "country": "AT",
        "name": "Spillern",
        "lat": "48.38333",
        "lng": "16.25"
    },
    {
        "country": "AT",
        "name": "Spielfeld",
        "lat": "46.70556",
        "lng": "15.63722"
    },
    {
        "country": "AT",
        "name": "Spielberg bei Knittelfeld",
        "lat": "47.21667",
        "lng": "14.78333"
    },
    {
        "country": "AT",
        "name": "Spatenhof",
        "lat": "46.9625",
        "lng": "15.36111"
    },
    {
        "country": "AT",
        "name": "Spannberg",
        "lat": "48.46393",
        "lng": "16.73647"
    },
    {
        "country": "AT",
        "name": "Sooss",
        "lat": "47.98464",
        "lng": "16.21737"
    },
    {
        "country": "AT",
        "name": "Sonntagberg",
        "lat": "47.99561",
        "lng": "14.76065"
    },
    {
        "country": "AT",
        "name": "Sommerein",
        "lat": "47.98333",
        "lng": "16.65"
    },
    {
        "country": "AT",
        "name": "Sollenau",
        "lat": "47.89834",
        "lng": "16.24833"
    },
    {
        "country": "AT",
        "name": "Söll",
        "lat": "47.50378",
        "lng": "12.19221"
    },
    {
        "country": "AT",
        "name": "Sölden",
        "lat": "46.96667",
        "lng": "11"
    },
    {
        "country": "AT",
        "name": "Hall in Tirol",
        "lat": "47.28333",
        "lng": "11.51667"
    },
    {
        "country": "AT",
        "name": "Södingberg",
        "lat": "47.10703",
        "lng": "15.17169"
    },
    {
        "country": "AT",
        "name": "Söchau",
        "lat": "47.03333",
        "lng": "16.01667"
    },
    {
        "country": "AT",
        "name": "Soboth",
        "lat": "46.68139",
        "lng": "15.07833"
    },
    {
        "country": "AT",
        "name": "Sitzendorf an der Schmida",
        "lat": "48.59841",
        "lng": "15.94254"
    },
    {
        "country": "AT",
        "name": "Sittersdorf",
        "lat": "46.54444",
        "lng": "14.60583"
    },
    {
        "country": "AT",
        "name": "Sistrans",
        "lat": "47.23333",
        "lng": "11.45"
    },
    {
        "country": "AT",
        "name": "Sinabelkirchen",
        "lat": "47.102",
        "lng": "15.82795"
    },
    {
        "country": "AT",
        "name": "Simmering",
        "lat": "48.18333",
        "lng": "16.43333"
    },
    {
        "country": "AT",
        "name": "Silz",
        "lat": "47.26667",
        "lng": "10.93333"
    },
    {
        "country": "AT",
        "name": "Sillian",
        "lat": "46.75278",
        "lng": "12.42111"
    },
    {
        "country": "AT",
        "name": "Silbertal",
        "lat": "47.09368",
        "lng": "9.98314"
    },
    {
        "country": "AT",
        "name": "Sigmundsherberg",
        "lat": "48.68333",
        "lng": "15.75"
    },
    {
        "country": "AT",
        "name": "Sigless",
        "lat": "47.77533",
        "lng": "16.39503"
    },
    {
        "country": "AT",
        "name": "Siezenheim",
        "lat": "47.81529",
        "lng": "12.99039"
    },
    {
        "country": "AT",
        "name": "Sierning",
        "lat": "48.04343",
        "lng": "14.30935"
    },
    {
        "country": "AT",
        "name": "Sierndorf",
        "lat": "48.43024",
        "lng": "16.16658"
    },
    {
        "country": "AT",
        "name": "Sieghartskirchen",
        "lat": "48.25531",
        "lng": "16.01223"
    },
    {
        "country": "AT",
        "name": "Sieggraben",
        "lat": "47.65128",
        "lng": "16.37993"
    },
    {
        "country": "AT",
        "name": "Siegersdorf bei Herberstein",
        "lat": "47.2",
        "lng": "15.78333"
    },
    {
        "country": "AT",
        "name": "Siegendorf im Burgenland",
        "lat": "47.78098",
        "lng": "16.54232"
    },
    {
        "country": "AT",
        "name": "Sibratsgfäll",
        "lat": "47.42667",
        "lng": "10.03806"
    },
    {
        "country": "AT",
        "name": "Seyring",
        "lat": "48.33333",
        "lng": "16.48333"
    },
    {
        "country": "AT",
        "name": "Serfaus",
        "lat": "47.04018",
        "lng": "10.60339"
    },
    {
        "country": "AT",
        "name": "Senftenberg",
        "lat": "48.43333",
        "lng": "15.55"
    },
    {
        "country": "AT",
        "name": "Semriach",
        "lat": "47.21667",
        "lng": "15.4"
    },
    {
        "country": "AT",
        "name": "Semmering",
        "lat": "47.63347",
        "lng": "15.82993"
    },
    {
        "country": "AT",
        "name": "Selzthal",
        "lat": "47.54988",
        "lng": "14.31201"
    },
    {
        "country": "AT",
        "name": "Seitenstetten Markt",
        "lat": "48.03333",
        "lng": "14.65"
    },
    {
        "country": "AT",
        "name": "Seiersberg",
        "lat": "47",
        "lng": "15.4"
    },
    {
        "country": "AT",
        "name": "Seibersdorf",
        "lat": "47.95858",
        "lng": "16.51842"
    },
    {
        "country": "AT",
        "name": "Seggauberg",
        "lat": "46.76667",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Seewalchen",
        "lat": "47.95246",
        "lng": "13.58382"
    },
    {
        "country": "AT",
        "name": "Seewalchen",
        "lat": "47.9",
        "lng": "13.13333"
    },
    {
        "country": "AT",
        "name": "Seekirchen am Wallersee",
        "lat": "47.9",
        "lng": "13.13333"
    },
    {
        "country": "AT",
        "name": "Seeham",
        "lat": "47.96746",
        "lng": "13.07699"
    },
    {
        "country": "AT",
        "name": "Seefeld in Tirol",
        "lat": "47.33016",
        "lng": "11.18786"
    },
    {
        "country": "AT",
        "name": "Seeboden",
        "lat": "46.81909",
        "lng": "13.5143"
    },
    {
        "country": "AT",
        "name": "Seebenstein",
        "lat": "47.69936",
        "lng": "16.14484"
    },
    {
        "country": "AT",
        "name": "Seebach",
        "lat": "46.61667",
        "lng": "13.86667"
    },
    {
        "country": "AT",
        "name": "See",
        "lat": "47.08333",
        "lng": "10.46667"
    },
    {
        "country": "AT",
        "name": "Seckau",
        "lat": "47.26667",
        "lng": "14.78333"
    },
    {
        "country": "AT",
        "name": "Sebersdorf",
        "lat": "47.1865",
        "lng": "15.9936"
    },
    {
        "country": "AT",
        "name": "Schwoich",
        "lat": "47.546",
        "lng": "12.14049"
    },
    {
        "country": "AT",
        "name": "Schwertberg",
        "lat": "48.2734",
        "lng": "14.58474"
    },
    {
        "country": "AT",
        "name": "Schwendt",
        "lat": "47.63182",
        "lng": "12.39266"
    },
    {
        "country": "AT",
        "name": "Schwendau",
        "lat": "47.19753",
        "lng": "11.85897"
    },
    {
        "country": "AT",
        "name": "Schweinbach",
        "lat": "48.34033",
        "lng": "14.42247"
    },
    {
        "country": "AT",
        "name": "Schweiggers",
        "lat": "48.66667",
        "lng": "15.06667"
    },
    {
        "country": "AT",
        "name": "Schwechat",
        "lat": "48.13333",
        "lng": "16.46667"
    },
    {
        "country": "AT",
        "name": "Schwaz",
        "lat": "47.35169",
        "lng": "11.71014"
    },
    {
        "country": "AT",
        "name": "Schwarzenbach an der Pielach",
        "lat": "47.93333",
        "lng": "15.38333"
    },
    {
        "country": "AT",
        "name": "Schwarzenbach",
        "lat": "47.63547",
        "lng": "16.35109"
    },
    {
        "country": "AT",
        "name": "Schwarzenau",
        "lat": "48.74436",
        "lng": "15.25838"
    },
    {
        "country": "AT",
        "name": "Schwarzau im Schwarzautal",
        "lat": "46.87886",
        "lng": "15.66421"
    },
    {
        "country": "AT",
        "name": "Schwarzau im Gebirge",
        "lat": "47.81223",
        "lng": "15.70581"
    },
    {
        "country": "AT",
        "name": "Schwarzau am Steinfeld",
        "lat": "47.73183",
        "lng": "16.17094"
    },
    {
        "country": "AT",
        "name": "Schwarzach im Pongau",
        "lat": "47.32048",
        "lng": "13.15171"
    },
    {
        "country": "AT",
        "name": "Schwarzach",
        "lat": "47.44904",
        "lng": "9.76213"
    },
    {
        "country": "AT",
        "name": "Schwanenstadt",
        "lat": "48.05537",
        "lng": "13.77505"
    },
    {
        "country": "AT",
        "name": "Schwanberg",
        "lat": "46.75833",
        "lng": "15.20833"
    },
    {
        "country": "AT",
        "name": "Schwadorf",
        "lat": "48.06936",
        "lng": "16.57957"
    },
    {
        "country": "AT",
        "name": "Schützen am Gebirge",
        "lat": "47.85233",
        "lng": "16.62334"
    },
    {
        "country": "AT",
        "name": "Schruns",
        "lat": "47.06667",
        "lng": "9.91667"
    },
    {
        "country": "AT",
        "name": "Schrems bei Frohnleiten",
        "lat": "47.28109",
        "lng": "15.35932"
    },
    {
        "country": "AT",
        "name": "Schrems",
        "lat": "48.78333",
        "lng": "15.06667"
    },
    {
        "country": "AT",
        "name": "Schrattenthal",
        "lat": "48.7",
        "lng": "15.9"
    },
    {
        "country": "AT",
        "name": "Schrattenberg",
        "lat": "48.72359",
        "lng": "16.72201"
    },
    {
        "country": "AT",
        "name": "Schrattenbach",
        "lat": "47.77902",
        "lng": "15.9906"
    },
    {
        "country": "AT",
        "name": "Schottwien",
        "lat": "47.65683",
        "lng": "15.8725"
    },
    {
        "country": "AT",
        "name": "Schörfling",
        "lat": "47.94544",
        "lng": "13.60408"
    },
    {
        "country": "AT",
        "name": "Schoppernau",
        "lat": "47.31205",
        "lng": "10.01646"
    },
    {
        "country": "AT",
        "name": "Schönwies",
        "lat": "47.19665",
        "lng": "10.65742"
    },
    {
        "country": "AT",
        "name": "Schönering",
        "lat": "48.29838",
        "lng": "14.16103"
    },
    {
        "country": "AT",
        "name": "Schöndorf",
        "lat": "48.00167",
        "lng": "13.6588"
    },
    {
        "country": "AT",
        "name": "Schönberg im Stubaital",
        "lat": "47.18333",
        "lng": "11.41667"
    },
    {
        "country": "AT",
        "name": "Schönbach",
        "lat": "48.45",
        "lng": "15.03333"
    },
    {
        "country": "AT",
        "name": "Schönau an der Triesting",
        "lat": "47.93452",
        "lng": "16.25376"
    },
    {
        "country": "AT",
        "name": "Schöder",
        "lat": "47.18333",
        "lng": "14.1"
    },
    {
        "country": "AT",
        "name": "Schnifis",
        "lat": "47.21667",
        "lng": "9.73306"
    },
    {
        "country": "AT",
        "name": "Schnepfau",
        "lat": "47.35206",
        "lng": "9.9452"
    },
    {
        "country": "AT",
        "name": "Schmirn",
        "lat": "47.08333",
        "lng": "11.56667"
    },
    {
        "country": "AT",
        "name": "Schlüsslberg",
        "lat": "48.21861",
        "lng": "13.87161"
    },
    {
        "country": "AT",
        "name": "Schlitters",
        "lat": "47.38045",
        "lng": "11.83888"
    },
    {
        "country": "AT",
        "name": "Schlins",
        "lat": "47.2",
        "lng": "9.7"
    },
    {
        "country": "AT",
        "name": "Schlierbach",
        "lat": "47.93636",
        "lng": "14.12018"
    },
    {
        "country": "AT",
        "name": "Schleinbach",
        "lat": "48.41667",
        "lng": "16.46667"
    },
    {
        "country": "AT",
        "name": "Schleedorf",
        "lat": "47.95",
        "lng": "13.15"
    },
    {
        "country": "AT",
        "name": "Schlatt",
        "lat": "48.07177",
        "lng": "13.78904"
    },
    {
        "country": "AT",
        "name": "Schladming",
        "lat": "47.39289",
        "lng": "13.68699"
    },
    {
        "country": "AT",
        "name": "Schildorn",
        "lat": "48.14564",
        "lng": "13.46314"
    },
    {
        "country": "AT",
        "name": "Schiefling am See",
        "lat": "46.60444",
        "lng": "14.0975"
    },
    {
        "country": "AT",
        "name": "Schenkenfelden",
        "lat": "48.50273",
        "lng": "14.36188"
    },
    {
        "country": "AT",
        "name": "Scheifling",
        "lat": "47.15044",
        "lng": "14.41278"
    },
    {
        "country": "AT",
        "name": "Scheibbs",
        "lat": "48.00474",
        "lng": "15.16817"
    },
    {
        "country": "AT",
        "name": "Scheffau am Wilden Kaiser",
        "lat": "47.52943",
        "lng": "12.25139"
    },
    {
        "country": "AT",
        "name": "Scheffau am Tennengebirge",
        "lat": "47.58831",
        "lng": "13.2202"
    },
    {
        "country": "AT",
        "name": "Schattwald",
        "lat": "47.51427",
        "lng": "10.46143"
    },
    {
        "country": "AT",
        "name": "Schattendorf",
        "lat": "47.70973",
        "lng": "16.50979"
    },
    {
        "country": "AT",
        "name": "Scharnstein",
        "lat": "47.90426",
        "lng": "13.96135"
    },
    {
        "country": "AT",
        "name": "Scharnitz",
        "lat": "47.38899",
        "lng": "11.26455"
    },
    {
        "country": "AT",
        "name": "Scharndorf",
        "lat": "48.09399",
        "lng": "16.79883"
    },
    {
        "country": "AT",
        "name": "Schärding",
        "lat": "48.45294",
        "lng": "13.43722"
    },
    {
        "country": "AT",
        "name": "Schardenberg",
        "lat": "48.52058",
        "lng": "13.49791"
    },
    {
        "country": "AT",
        "name": "Schandorf",
        "lat": "47.24183",
        "lng": "16.42267"
    },
    {
        "country": "AT",
        "name": "Schalchham",
        "lat": "47.99825",
        "lng": "13.67188"
    },
    {
        "country": "AT",
        "name": "Schalchen",
        "lat": "48.11924",
        "lng": "13.15716"
    },
    {
        "country": "AT",
        "name": "Schafwiesen",
        "lat": "48.16667",
        "lng": "14.06667"
    },
    {
        "country": "AT",
        "name": "Schäffern",
        "lat": "47.47771",
        "lng": "16.10956"
    },
    {
        "country": "AT",
        "name": "Schachendorf",
        "lat": "47.26667",
        "lng": "16.43333"
    },
    {
        "country": "AT",
        "name": "Sautens",
        "lat": "47.2",
        "lng": "10.86667"
    },
    {
        "country": "AT",
        "name": "Bad Sauerbrunn",
        "lat": "47.77439",
        "lng": "16.32841"
    },
    {
        "country": "AT",
        "name": "Sattledt",
        "lat": "48.07372",
        "lng": "14.05478"
    },
    {
        "country": "AT",
        "name": "Satteins",
        "lat": "47.21667",
        "lng": "9.66667"
    },
    {
        "country": "AT",
        "name": "Sarleinsbach",
        "lat": "48.54525",
        "lng": "13.90491"
    },
    {
        "country": "AT",
        "name": "Sankt Wolfgang im Salzkammergut",
        "lat": "47.73932",
        "lng": "13.44666"
    },
    {
        "country": "AT",
        "name": "Sankt Veit in Defereggen",
        "lat": "46.92722",
        "lng": "12.42972"
    },
    {
        "country": "AT",
        "name": "Sankt Veit im Pongau",
        "lat": "47.33333",
        "lng": "13.15"
    },
    {
        "country": "AT",
        "name": "Sankt Veit an der Gölsen",
        "lat": "48.0432",
        "lng": "15.66942"
    },
    {
        "country": "AT",
        "name": "Sankt Veit an der Glan",
        "lat": "46.76806",
        "lng": "14.36028"
    },
    {
        "country": "AT",
        "name": "Sankt Veit am Vogau",
        "lat": "46.74048",
        "lng": "15.64217"
    },
    {
        "country": "AT",
        "name": "Sankt Valentin",
        "lat": "48.16667",
        "lng": "14.51667"
    },
    {
        "country": "AT",
        "name": "Sankt Ulrich bei Steyr",
        "lat": "48.02116",
        "lng": "14.43685"
    },
    {
        "country": "AT",
        "name": "Sankt Ulrich am Waasen",
        "lat": "46.93333",
        "lng": "15.53333"
    },
    {
        "country": "AT",
        "name": "Sankt Ulrich am Pillersee",
        "lat": "47.5274",
        "lng": "12.57239"
    },
    {
        "country": "AT",
        "name": "Sankt Stefan ob Stainz",
        "lat": "46.92861",
        "lng": "15.25889"
    },
    {
        "country": "AT",
        "name": "Sankt Stefan ob Leoben",
        "lat": "47.31672",
        "lng": "14.97831"
    },
    {
        "country": "AT",
        "name": "Sankt Stefan im Rosental",
        "lat": "46.90389",
        "lng": "15.71"
    },
    {
        "country": "AT",
        "name": "Sankt Stefan",
        "lat": "46.8075",
        "lng": "14.85167"
    },
    {
        "country": "AT",
        "name": "Sankt Ruprecht an der Raab",
        "lat": "47.15336",
        "lng": "15.66256"
    },
    {
        "country": "AT",
        "name": "Sankt Ruprecht",
        "lat": "46.6",
        "lng": "14.31667"
    },
    {
        "country": "AT",
        "name": "Sankt Roman",
        "lat": "48.48183",
        "lng": "13.61056"
    },
    {
        "country": "AT",
        "name": "Sankt Radegund bei Graz",
        "lat": "47.18172",
        "lng": "15.49192"
    },
    {
        "country": "AT",
        "name": "Sankt Pölten",
        "lat": "48.2",
        "lng": "15.63333"
    },
    {
        "country": "AT",
        "name": "Sankt Peter ob Judenburg",
        "lat": "47.18417",
        "lng": "14.58639"
    },
    {
        "country": "AT",
        "name": "Sankt Peter in der Au Markt",
        "lat": "48.044",
        "lng": "14.62182"
    },
    {
        "country": "AT",
        "name": "Sankt Peter im Sulmtal",
        "lat": "46.75",
        "lng": "15.25"
    },
    {
        "country": "AT",
        "name": "Sankt Peter-Freienstein",
        "lat": "47.38333",
        "lng": "15.01667"
    },
    {
        "country": "AT",
        "name": "Sankt Peter am Ottersbach",
        "lat": "46.79778",
        "lng": "15.75917"
    },
    {
        "country": "AT",
        "name": "Sankt Peter am Kammersberg",
        "lat": "47.18706",
        "lng": "14.18464"
    },
    {
        "country": "AT",
        "name": "Sankt Peter",
        "lat": "47.05",
        "lng": "15.46667"
    },
    {
        "country": "AT",
        "name": "Sankt Peter",
        "lat": "46.62978",
        "lng": "14.34016"
    },
    {
        "country": "AT",
        "name": "Sankt Peter am Hart",
        "lat": "48.25268",
        "lng": "13.09613"
    },
    {
        "country": "AT",
        "name": "Sankt Paul im Levanttal",
        "lat": "46.70056",
        "lng": "14.87556"
    },
    {
        "country": "AT",
        "name": "Sankt Pantaleon",
        "lat": "48.21058",
        "lng": "14.56804"
    },
    {
        "country": "AT",
        "name": "Sankt Oswald bei Plankenwarth",
        "lat": "47.08701",
        "lng": "15.27703"
    },
    {
        "country": "AT",
        "name": "Sankt Oswald bei Freistadt",
        "lat": "48.5",
        "lng": "14.58333"
    },
    {
        "country": "AT",
        "name": "Sankt Nikolai ob Draßling",
        "lat": "46.80889",
        "lng": "15.65083"
    },
    {
        "country": "AT",
        "name": "Sankt Nikolai im Sölktal",
        "lat": "47.31667",
        "lng": "14.05"
    },
    {
        "country": "AT",
        "name": "Sankt Nikolai im Sausal",
        "lat": "46.82111",
        "lng": "15.45194"
    },
    {
        "country": "AT",
        "name": "Sankt Michael in Obersteiermark",
        "lat": "47.33839",
        "lng": "15.01784"
    },
    {
        "country": "AT",
        "name": "Sankt Michael im Lungau",
        "lat": "47.1",
        "lng": "13.63333"
    },
    {
        "country": "AT",
        "name": "Sankt Michael im Burgenland",
        "lat": "47.12852",
        "lng": "16.27148"
    },
    {
        "country": "AT",
        "name": "Sankt Martin im Sulmtal",
        "lat": "46.75611",
        "lng": "15.29722"
    },
    {
        "country": "AT",
        "name": "Sankt Martin im Mühlkreis",
        "lat": "48.41553",
        "lng": "14.03821"
    },
    {
        "country": "AT",
        "name": "Sankt Martin im Innkreis",
        "lat": "48.29387",
        "lng": "13.43868"
    },
    {
        "country": "AT",
        "name": "Sankt Martin bei Lofer",
        "lat": "47.56667",
        "lng": "12.7"
    },
    {
        "country": "AT",
        "name": "Sankt Martin an der Raab",
        "lat": "46.9225",
        "lng": "16.13611"
    },
    {
        "country": "AT",
        "name": "Sankt Martin am Ybbsfelde",
        "lat": "48.16425",
        "lng": "15.02076"
    },
    {
        "country": "AT",
        "name": "Sankt Martin am Grimming",
        "lat": "47.48873",
        "lng": "13.98105"
    },
    {
        "country": "AT",
        "name": "Sankt Martin",
        "lat": "46.61667",
        "lng": "14.28333"
    },
    {
        "country": "AT",
        "name": "Sankt Martin",
        "lat": "46.61721",
        "lng": "13.82879"
    },
    {
        "country": "AT",
        "name": "Sankt Martin",
        "lat": "48.23091",
        "lng": "14.26781"
    },
    {
        "country": "AT",
        "name": "Sankt Martin",
        "lat": "47.44667",
        "lng": "15.2849"
    },
    {
        "country": "AT",
        "name": "Sankt Marien",
        "lat": "48.14805",
        "lng": "14.27759"
    },
    {
        "country": "AT",
        "name": "Sankt Margareten im Rosental",
        "lat": "46.54417",
        "lng": "14.42444"
    },
    {
        "country": "AT",
        "name": "Sankt Margarethen im Lungau",
        "lat": "47.07927",
        "lng": "13.69613"
    },
    {
        "country": "AT",
        "name": "Sankt Margarethen im Burgenland",
        "lat": "47.80335",
        "lng": "16.60875"
    },
    {
        "country": "AT",
        "name": "Sankt Margarethen bei Knittelfeld",
        "lat": "47.21667",
        "lng": "14.86667"
    },
    {
        "country": "AT",
        "name": "Sankt Margarethen an der Sierning",
        "lat": "48.1591",
        "lng": "15.49055"
    },
    {
        "country": "AT",
        "name": "Sankt Margarethen an der Raab",
        "lat": "47.05",
        "lng": "15.75"
    },
    {
        "country": "AT",
        "name": "Sankt Marein im Mürztal",
        "lat": "47.46667",
        "lng": "15.36667"
    },
    {
        "country": "AT",
        "name": "Sankt Marein bei Neumarkt",
        "lat": "47.06591",
        "lng": "14.42908"
    },
    {
        "country": "AT",
        "name": "Sankt Marein bei Knittelfeld",
        "lat": "47.27396",
        "lng": "14.86085"
    },
    {
        "country": "AT",
        "name": "Sankt Marein bei Graz",
        "lat": "47.01583",
        "lng": "15.68389"
    },
    {
        "country": "AT",
        "name": "Sankt Magdalen",
        "lat": "46.61667",
        "lng": "13.88333"
    },
    {
        "country": "AT",
        "name": "Sankt Lorenzen im Mürztal",
        "lat": "47.48333",
        "lng": "15.36667"
    },
    {
        "country": "AT",
        "name": "Sankt Lorenzen bei Scheifling",
        "lat": "47.14556",
        "lng": "14.40583"
    },
    {
        "country": "AT",
        "name": "Sankt Lorenzen bei Knittelfeld",
        "lat": "47.25078",
        "lng": "14.89549"
    },
    {
        "country": "AT",
        "name": "Sankt Lorenzen am Wechsel",
        "lat": "47.44153",
        "lng": "15.95498"
    },
    {
        "country": "AT",
        "name": "Sankt Lorenz",
        "lat": "47.8324",
        "lng": "13.34839"
    },
    {
        "country": "AT",
        "name": "Sankt Leonhard am Hornerwald",
        "lat": "48.6",
        "lng": "15.53333"
    },
    {
        "country": "AT",
        "name": "Sankt Leonhard am Forst",
        "lat": "48.14232",
        "lng": "15.28464"
    },
    {
        "country": "AT",
        "name": "Sankt Leonhard",
        "lat": "47.72587",
        "lng": "13.04577"
    },
    {
        "country": "AT",
        "name": "Sankt Leonhard",
        "lat": "46.62528",
        "lng": "13.85865"
    },
    {
        "country": "AT",
        "name": "Sankt Lambrecht",
        "lat": "47.06667",
        "lng": "14.3"
    },
    {
        "country": "AT",
        "name": "Sankt Kathrein am Offenegg",
        "lat": "47.3",
        "lng": "15.58333"
    },
    {
        "country": "AT",
        "name": "Sankt Kathrein am Hauenstein",
        "lat": "47.48943",
        "lng": "15.69414"
    },
    {
        "country": "AT",
        "name": "Sankt Katharein an der Laming",
        "lat": "47.47069",
        "lng": "15.16319"
    },
    {
        "country": "AT",
        "name": "Sankt Josef (Weststeiermark)",
        "lat": "46.90917",
        "lng": "15.33639"
    },
    {
        "country": "AT",
        "name": "Sankt Johann in Tirol",
        "lat": "47.5233",
        "lng": "12.4232"
    },
    {
        "country": "AT",
        "name": "Sankt Johann in der Haide",
        "lat": "47.2808",
        "lng": "16.02579"
    },
    {
        "country": "AT",
        "name": "Sankt Johann im Saggautal",
        "lat": "46.70389",
        "lng": "15.40278"
    },
    {
        "country": "AT",
        "name": "Sankt Johann im Pongau",
        "lat": "47.35",
        "lng": "13.2"
    },
    {
        "country": "AT",
        "name": "Sankt Johann bei Herberstein",
        "lat": "47.2",
        "lng": "15.8"
    },
    {
        "country": "AT",
        "name": "Sankt Johann am Tauern",
        "lat": "47.35",
        "lng": "14.46667"
    },
    {
        "country": "AT",
        "name": "Sankt Jakob im Walde",
        "lat": "47.46667",
        "lng": "15.78333"
    },
    {
        "country": "AT",
        "name": "Sankt Jakob-Breitenau",
        "lat": "47.39229",
        "lng": "15.4297"
    },
    {
        "country": "AT",
        "name": "Sankt Ilgen",
        "lat": "47.55",
        "lng": "15.16667"
    },
    {
        "country": "AT",
        "name": "Sankt Gotthard im Mühlkreis",
        "lat": "48.3802",
        "lng": "14.13185"
    },
    {
        "country": "AT",
        "name": "Sankt Gilgen",
        "lat": "47.76667",
        "lng": "13.36667"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen ob Murau",
        "lat": "47.1",
        "lng": "14.1"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen ob Judenburg",
        "lat": "47.20744",
        "lng": "14.49736"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen im Attergau",
        "lat": "47.93595",
        "lng": "13.48306"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen bei Obernberg am Inn",
        "lat": "48.29193",
        "lng": "13.3332"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen an der Stiefing",
        "lat": "46.87333",
        "lng": "15.57972"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen an der Leys",
        "lat": "48.03149",
        "lng": "15.22722"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen an der Gusen",
        "lat": "48.27183",
        "lng": "14.44951"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen am Ybbsfelde",
        "lat": "48.12892",
        "lng": "14.95364"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen am Leithagebirge",
        "lat": "47.85",
        "lng": "16.55"
    },
    {
        "country": "AT",
        "name": "Sankt Georgen am Fillmannsbach",
        "lat": "48.1256",
        "lng": "13.00811"
    },
    {
        "country": "AT",
        "name": "Sankt Gallenkirch",
        "lat": "47.02102",
        "lng": "9.97335"
    },
    {
        "country": "AT",
        "name": "Sankt Gallen",
        "lat": "47.68631",
        "lng": "14.61705"
    },
    {
        "country": "AT",
        "name": "Sankt Dionysen",
        "lat": "48.22439",
        "lng": "14.25485"
    },
    {
        "country": "AT",
        "name": "Sankt Corona am Wechsel",
        "lat": "47.58333",
        "lng": "16"
    },
    {
        "country": "AT",
        "name": "Sankt Blasen",
        "lat": "47.08333",
        "lng": "14.3"
    },
    {
        "country": "AT",
        "name": "Sankt Bartholomä",
        "lat": "47.05457",
        "lng": "15.25889"
    },
    {
        "country": "AT",
        "name": "Sankt Anton im Montafon",
        "lat": "47.11667",
        "lng": "9.86667"
    },
    {
        "country": "AT",
        "name": "St Anton am Arlberg",
        "lat": "47.1275",
        "lng": "10.26372"
    },
    {
        "country": "AT",
        "name": "Sankt Anna am Aigen",
        "lat": "46.83111",
        "lng": "15.97139"
    },
    {
        "country": "AT",
        "name": "Sankt Andrä vor dem Hagenthale",
        "lat": "48.32202",
        "lng": "16.20724"
    },
    {
        "country": "AT",
        "name": "Sankt Andrä im Lungau",
        "lat": "47.15",
        "lng": "13.78333"
    },
    {
        "country": "AT",
        "name": "Sankt Andrä am Zicksee",
        "lat": "47.7841",
        "lng": "16.94195"
    },
    {
        "country": "AT",
        "name": "Sankt Andrä",
        "lat": "46.76829",
        "lng": "14.81991"
    },
    {
        "country": "AT",
        "name": "Sankt Aegyd am Neuwalde",
        "lat": "47.85383",
        "lng": "15.56969"
    },
    {
        "country": "AT",
        "name": "Sankt Aegidi",
        "lat": "48.47929",
        "lng": "13.73737"
    },
    {
        "country": "AT",
        "name": "Sammersdorf",
        "lat": "48.18091",
        "lng": "14.1667"
    },
    {
        "country": "AT",
        "name": "Salzburg",
        "lat": "47.79941",
        "lng": "13.04399"
    },
    {
        "country": "AT",
        "name": "Sallingberg",
        "lat": "48.46667",
        "lng": "15.23333"
    },
    {
        "country": "AT",
        "name": "Salla",
        "lat": "47.1",
        "lng": "14.96667"
    },
    {
        "country": "AT",
        "name": "Sachsenburg",
        "lat": "46.82917",
        "lng": "13.355"
    },
    {
        "country": "AT",
        "name": "Saalfelden am Steinernen Meer",
        "lat": "47.42681",
        "lng": "12.848"
    },
    {
        "country": "AT",
        "name": "Saalbach",
        "lat": "47.39138",
        "lng": "12.63642"
    },
    {
        "country": "AT",
        "name": "Rutzenham",
        "lat": "48.06076",
        "lng": "13.71883"
    },
    {
        "country": "AT",
        "name": "Rüstorf",
        "lat": "48.04326",
        "lng": "13.78982"
    },
    {
        "country": "AT",
        "name": "Rust",
        "lat": "47.80122",
        "lng": "16.67158"
    },
    {
        "country": "AT",
        "name": "Ruprechtshofen",
        "lat": "48.13648",
        "lng": "15.27675"
    },
    {
        "country": "AT",
        "name": "Rum",
        "lat": "47.28333",
        "lng": "11.45"
    },
    {
        "country": "AT",
        "name": "Rufling",
        "lat": "48.27839",
        "lng": "14.21674"
    },
    {
        "country": "AT",
        "name": "Rudersdorf",
        "lat": "47.05",
        "lng": "16.11667"
    },
    {
        "country": "AT",
        "name": "Ruden",
        "lat": "46.65833",
        "lng": "14.77639"
    },
    {
        "country": "AT",
        "name": "Rottenmann",
        "lat": "47.51667",
        "lng": "14.35"
    },
    {
        "country": "AT",
        "name": "Röthis",
        "lat": "47.29332",
        "lng": "9.65484"
    },
    {
        "country": "AT",
        "name": "Röthelstein",
        "lat": "47.31667",
        "lng": "15.36667"
    },
    {
        "country": "AT",
        "name": "Rotenturm an der Pinka",
        "lat": "47.25",
        "lng": "16.25"
    },
    {
        "country": "AT",
        "name": "Rosenau am Sonntagberg",
        "lat": "48.00778",
        "lng": "14.73927"
    },
    {
        "country": "AT",
        "name": "Rosegg",
        "lat": "46.58833",
        "lng": "14.01718"
    },
    {
        "country": "AT",
        "name": "Röschitz",
        "lat": "48.64312",
        "lng": "15.81774"
    },
    {
        "country": "AT",
        "name": "Roppen",
        "lat": "47.21667",
        "lng": "10.81667"
    },
    {
        "country": "AT",
        "name": "Röns",
        "lat": "47.21667",
        "lng": "9.7"
    },
    {
        "country": "AT",
        "name": "Roitham",
        "lat": "48.02432",
        "lng": "13.82166"
    },
    {
        "country": "AT",
        "name": "Rohr im Gebirge",
        "lat": "47.89471",
        "lng": "15.73603"
    },
    {
        "country": "AT",
        "name": "Rohr im Burgenland",
        "lat": "47.11667",
        "lng": "16.16667"
    },
    {
        "country": "AT",
        "name": "Röhrenbach",
        "lat": "48.65",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Rohrbach in Oberösterreich",
        "lat": "48.57275",
        "lng": "13.98834"
    },
    {
        "country": "AT",
        "name": "Rohrbach bei Mattersburg",
        "lat": "47.7052",
        "lng": "16.43005"
    },
    {
        "country": "AT",
        "name": "Rohrbach an der Lafnitz",
        "lat": "47.38333",
        "lng": "16"
    },
    {
        "country": "AT",
        "name": "Rohrau",
        "lat": "48.06667",
        "lng": "16.85"
    },
    {
        "country": "AT",
        "name": "Ritzing",
        "lat": "47.61316",
        "lng": "16.49546"
    },
    {
        "country": "AT",
        "name": "Rinn",
        "lat": "47.25",
        "lng": "11.5"
    },
    {
        "country": "AT",
        "name": "Riezlern",
        "lat": "47.35778",
        "lng": "10.18759"
    },
    {
        "country": "AT",
        "name": "Rietz",
        "lat": "47.28593",
        "lng": "11.03075"
    },
    {
        "country": "AT",
        "name": "Ries",
        "lat": "47.08315",
        "lng": "15.485"
    },
    {
        "country": "AT",
        "name": "Riegersburg",
        "lat": "47",
        "lng": "15.93028"
    },
    {
        "country": "AT",
        "name": "Riefensberg",
        "lat": "47.50137",
        "lng": "9.95838"
    },
    {
        "country": "AT",
        "name": "Riedlingsdorf",
        "lat": "47.35",
        "lng": "16.13333"
    },
    {
        "country": "AT",
        "name": "Ried in der Riedmark",
        "lat": "48.27114",
        "lng": "14.52796"
    },
    {
        "country": "AT",
        "name": "Ried im Traunkreis",
        "lat": "48.02581",
        "lng": "14.07452"
    },
    {
        "country": "AT",
        "name": "Ried im Oberinntal",
        "lat": "47.05",
        "lng": "10.65"
    },
    {
        "country": "AT",
        "name": "Ried im Innkreis",
        "lat": "48.21123",
        "lng": "13.48855"
    },
    {
        "country": "AT",
        "name": "Reutte",
        "lat": "47.48333",
        "lng": "10.71667"
    },
    {
        "country": "AT",
        "name": "Reuthe",
        "lat": "47.37113",
        "lng": "9.89481"
    },
    {
        "country": "AT",
        "name": "Retznei",
        "lat": "46.73333",
        "lng": "15.56667"
    },
    {
        "country": "AT",
        "name": "Retz",
        "lat": "48.75712",
        "lng": "15.95485"
    },
    {
        "country": "AT",
        "name": "Rettenschöss",
        "lat": "47.65718",
        "lng": "12.26872"
    },
    {
        "country": "AT",
        "name": "Rettenegg",
        "lat": "47.52694",
        "lng": "15.78104"
    },
    {
        "country": "AT",
        "name": "Rennweg am Katschberg",
        "lat": "47.01667",
        "lng": "13.61667"
    },
    {
        "country": "AT",
        "name": "Reith bei Seefeld",
        "lat": "47.3",
        "lng": "11.2"
    },
    {
        "country": "AT",
        "name": "Reith bei Kitzbühel",
        "lat": "47.46667",
        "lng": "12.35"
    },
    {
        "country": "AT",
        "name": "Reith im Alpbachtal",
        "lat": "47.41694",
        "lng": "11.87785"
    },
    {
        "country": "AT",
        "name": "Reiterndorf",
        "lat": "47.70364",
        "lng": "13.62914"
    },
    {
        "country": "AT",
        "name": "Reisenberg",
        "lat": "47.99406",
        "lng": "16.52018"
    },
    {
        "country": "AT",
        "name": "Reinsberg",
        "lat": "47.98567",
        "lng": "15.07067"
    },
    {
        "country": "AT",
        "name": "Reingers",
        "lat": "48.96667",
        "lng": "15.13333"
    },
    {
        "country": "AT",
        "name": "Reifling",
        "lat": "47.13333",
        "lng": "14.66667"
    },
    {
        "country": "AT",
        "name": "Reichraming",
        "lat": "47.88333",
        "lng": "14.45"
    },
    {
        "country": "AT",
        "name": "Reichenthal",
        "lat": "48.54286",
        "lng": "14.38471"
    },
    {
        "country": "AT",
        "name": "Reichenfels",
        "lat": "47.00718",
        "lng": "14.74425"
    },
    {
        "country": "AT",
        "name": "Reichendorf",
        "lat": "47.18922",
        "lng": "15.7616"
    },
    {
        "country": "AT",
        "name": "Reichenau im Mühlkreis",
        "lat": "48.45704",
        "lng": "14.34875"
    },
    {
        "country": "AT",
        "name": "Reichenau an der Rax",
        "lat": "47.69509",
        "lng": "15.84572"
    },
    {
        "country": "AT",
        "name": "Rehberg",
        "lat": "48.43114",
        "lng": "15.58702"
    },
    {
        "country": "AT",
        "name": "Redlham",
        "lat": "48.02443",
        "lng": "13.74738"
    },
    {
        "country": "AT",
        "name": "Redleiten",
        "lat": "48.08138",
        "lng": "13.46894"
    },
    {
        "country": "AT",
        "name": "Reding",
        "lat": "46.81667",
        "lng": "14.85"
    },
    {
        "country": "AT",
        "name": "Rechnitz",
        "lat": "47.30467",
        "lng": "16.44095"
    },
    {
        "country": "AT",
        "name": "Raxendorf",
        "lat": "48.34108",
        "lng": "15.27666"
    },
    {
        "country": "AT",
        "name": "Ravelsbach",
        "lat": "48.55",
        "lng": "15.85"
    },
    {
        "country": "AT",
        "name": "Rauris",
        "lat": "47.22656",
        "lng": "12.99459"
    },
    {
        "country": "AT",
        "name": "Rauchenwarth",
        "lat": "48.0833",
        "lng": "16.52794"
    },
    {
        "country": "AT",
        "name": "Rattenberg",
        "lat": "47.43941",
        "lng": "11.89407"
    },
    {
        "country": "AT",
        "name": "Ratten",
        "lat": "47.48333",
        "lng": "15.71667"
    },
    {
        "country": "AT",
        "name": "Ratschendorf",
        "lat": "46.74222",
        "lng": "15.81583"
    },
    {
        "country": "AT",
        "name": "Ratsch an der Weinstraße",
        "lat": "46.68944",
        "lng": "15.57111"
    },
    {
        "country": "AT",
        "name": "Rastenfeld",
        "lat": "48.57348",
        "lng": "15.33176"
    },
    {
        "country": "AT",
        "name": "Rassach",
        "lat": "46.86028",
        "lng": "15.27"
    },
    {
        "country": "AT",
        "name": "Rappottenstein",
        "lat": "48.52161",
        "lng": "15.07925"
    },
    {
        "country": "AT",
        "name": "Ranten",
        "lat": "47.15949",
        "lng": "14.08349"
    },
    {
        "country": "AT",
        "name": "Ranshofen",
        "lat": "48.23308",
        "lng": "13.01571"
    },
    {
        "country": "AT",
        "name": "Rannersdorf",
        "lat": "48.12772",
        "lng": "16.46379"
    },
    {
        "country": "AT",
        "name": "Rankweil",
        "lat": "47.27108",
        "lng": "9.64308"
    },
    {
        "country": "AT",
        "name": "Raning",
        "lat": "46.85",
        "lng": "15.81667"
    },
    {
        "country": "AT",
        "name": "Ranggen",
        "lat": "47.25727",
        "lng": "11.2112"
    },
    {
        "country": "AT",
        "name": "Rangersdorf",
        "lat": "46.86001",
        "lng": "12.94923"
    },
    {
        "country": "AT",
        "name": "Randegg",
        "lat": "48.01198",
        "lng": "14.97334"
    },
    {
        "country": "AT",
        "name": "Ramsau",
        "lat": "48.00313",
        "lng": "15.80332"
    },
    {
        "country": "AT",
        "name": "Ramsau am Dachstein",
        "lat": "47.4215",
        "lng": "13.65545"
    },
    {
        "country": "AT",
        "name": "Ramsau im Zillertal",
        "lat": "47.20383",
        "lng": "11.87545"
    },
    {
        "country": "AT",
        "name": "Ramingstein",
        "lat": "47.07462",
        "lng": "13.83642"
    },
    {
        "country": "AT",
        "name": "Rainbach im Mühlkreis",
        "lat": "48.55758",
        "lng": "14.47449"
    },
    {
        "country": "AT",
        "name": "Raiding",
        "lat": "47.56581",
        "lng": "16.53056"
    },
    {
        "country": "AT",
        "name": "Ragnitz",
        "lat": "46.83667",
        "lng": "15.59278"
    },
    {
        "country": "AT",
        "name": "Raggal",
        "lat": "47.21082",
        "lng": "9.83688"
    },
    {
        "country": "AT",
        "name": "Raffelstetten",
        "lat": "48.23759",
        "lng": "14.40273"
    },
    {
        "country": "AT",
        "name": "Radstadt",
        "lat": "47.38333",
        "lng": "13.45"
    },
    {
        "country": "AT",
        "name": "Bad Radkersburg",
        "lat": "46.68806",
        "lng": "15.98806"
    },
    {
        "country": "AT",
        "name": "Radfeld",
        "lat": "47.44806",
        "lng": "11.91424"
    },
    {
        "country": "AT",
        "name": "Radenthein",
        "lat": "46.80056",
        "lng": "13.71167"
    },
    {
        "country": "AT",
        "name": "Rachau",
        "lat": "47.21667",
        "lng": "14.9"
    },
    {
        "country": "AT",
        "name": "Rabenwald",
        "lat": "47.4",
        "lng": "15.9"
    },
    {
        "country": "AT",
        "name": "Rabenstein an der Pielach",
        "lat": "48.06535",
        "lng": "15.46772"
    },
    {
        "country": "AT",
        "name": "Rabensburg",
        "lat": "48.65",
        "lng": "16.9"
    },
    {
        "country": "AT",
        "name": "Raasdorf",
        "lat": "48.24657",
        "lng": "16.56532"
    },
    {
        "country": "AT",
        "name": "Raach am Hochgebirge",
        "lat": "47.63333",
        "lng": "15.93333"
    },
    {
        "country": "AT",
        "name": "Raabs an der Thaya",
        "lat": "48.85",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Raabau",
        "lat": "46.96278",
        "lng": "15.91167"
    },
    {
        "country": "AT",
        "name": "Raaba",
        "lat": "47.03333",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Raab",
        "lat": "48.35243",
        "lng": "13.64691"
    },
    {
        "country": "AT",
        "name": "Pyhra",
        "lat": "48.1591",
        "lng": "15.68624"
    },
    {
        "country": "AT",
        "name": "Pusterwald",
        "lat": "47.30611",
        "lng": "14.37556"
    },
    {
        "country": "AT",
        "name": "Purkersdorf",
        "lat": "48.20769",
        "lng": "16.17539"
    },
    {
        "country": "AT",
        "name": "Purgstall",
        "lat": "48.05841",
        "lng": "15.13478"
    },
    {
        "country": "AT",
        "name": "Purbach am Neusiedler See",
        "lat": "47.91289",
        "lng": "16.69561"
    },
    {
        "country": "AT",
        "name": "Puntigam",
        "lat": "47.03333",
        "lng": "15.43333"
    },
    {
        "country": "AT",
        "name": "Pulkau",
        "lat": "48.70484",
        "lng": "15.86031"
    },
    {
        "country": "AT",
        "name": "Pühret",
        "lat": "48.03488",
        "lng": "13.72252"
    },
    {
        "country": "AT",
        "name": "Pucking",
        "lat": "48.18886",
        "lng": "14.18824"
    },
    {
        "country": "AT",
        "name": "Puchheim",
        "lat": "48",
        "lng": "13.71667"
    },
    {
        "country": "AT",
        "name": "Puchenstuben",
        "lat": "47.92819",
        "lng": "15.28748"
    },
    {
        "country": "AT",
        "name": "Puchenau",
        "lat": "48.31214",
        "lng": "14.23614"
    },
    {
        "country": "AT",
        "name": "Puchberg am Schneeberg",
        "lat": "47.78709",
        "lng": "15.91352"
    },
    {
        "country": "AT",
        "name": "Puch bei Weiz",
        "lat": "47.21667",
        "lng": "15.71667"
    },
    {
        "country": "AT",
        "name": "Puch bei Hallein",
        "lat": "47.71536",
        "lng": "13.09296"
    },
    {
        "country": "AT",
        "name": "Prutz",
        "lat": "47.08333",
        "lng": "10.66667"
    },
    {
        "country": "AT",
        "name": "Pruggern",
        "lat": "47.4237",
        "lng": "13.87625"
    },
    {
        "country": "AT",
        "name": "Proleb",
        "lat": "47.4",
        "lng": "15.13333"
    },
    {
        "country": "AT",
        "name": "Prinzersdorf",
        "lat": "48.2",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Prigglitz",
        "lat": "47.70318",
        "lng": "15.92794"
    },
    {
        "country": "AT",
        "name": "Hofamt Priel",
        "lat": "48.19581",
        "lng": "15.07746"
    },
    {
        "country": "AT",
        "name": "Pressbaum",
        "lat": "48.18333",
        "lng": "16.08333"
    },
    {
        "country": "AT",
        "name": "Prellenkirchen",
        "lat": "48.07401",
        "lng": "16.95233"
    },
    {
        "country": "AT",
        "name": "Preitenegg",
        "lat": "46.94",
        "lng": "14.92583"
    },
    {
        "country": "AT",
        "name": "Pregarten",
        "lat": "48.35488",
        "lng": "14.53217"
    },
    {
        "country": "AT",
        "name": "Preding",
        "lat": "46.85861",
        "lng": "15.40972"
    },
    {
        "country": "AT",
        "name": "Pramet",
        "lat": "48.14295",
        "lng": "13.48752"
    },
    {
        "country": "AT",
        "name": "Prambachkirchen",
        "lat": "48.31711",
        "lng": "13.90449"
    },
    {
        "country": "AT",
        "name": "Pram",
        "lat": "48.23536",
        "lng": "13.60579"
    },
    {
        "country": "AT",
        "name": "Prägraten",
        "lat": "47.01667",
        "lng": "12.38333"
    },
    {
        "country": "AT",
        "name": "Pradl",
        "lat": "47.26539",
        "lng": "11.4152"
    },
    {
        "country": "AT",
        "name": "Poysdorf",
        "lat": "48.66667",
        "lng": "16.63333"
    },
    {
        "country": "AT",
        "name": "Potzneusiedl",
        "lat": "48.04504",
        "lng": "16.9477"
    },
    {
        "country": "AT",
        "name": "Pöttsching",
        "lat": "47.80445",
        "lng": "16.37109"
    },
    {
        "country": "AT",
        "name": "Pottschach",
        "lat": "47.69856",
        "lng": "16.00725"
    },
    {
        "country": "AT",
        "name": "Pötting",
        "lat": "48.28325",
        "lng": "13.77059"
    },
    {
        "country": "AT",
        "name": "Pottenstein",
        "lat": "47.9582",
        "lng": "16.0948"
    },
    {
        "country": "AT",
        "name": "Pottendorf",
        "lat": "47.9",
        "lng": "16.38333"
    },
    {
        "country": "AT",
        "name": "Pottenbrunn",
        "lat": "48.23333",
        "lng": "15.7"
    },
    {
        "country": "AT",
        "name": "Pöttelsdorf",
        "lat": "47.75369",
        "lng": "16.43864"
    },
    {
        "country": "AT",
        "name": "Pörtschach am Wörthersee",
        "lat": "46.63639",
        "lng": "14.14639"
    },
    {
        "country": "AT",
        "name": "Poppendorf",
        "lat": "46.85611",
        "lng": "15.85639"
    },
    {
        "country": "AT",
        "name": "Pöls",
        "lat": "47.21667",
        "lng": "14.58333"
    },
    {
        "country": "AT",
        "name": "Polling in Tirol",
        "lat": "47.28333",
        "lng": "11.15"
    },
    {
        "country": "AT",
        "name": "Pöllauberg",
        "lat": "47.31667",
        "lng": "15.85"
    },
    {
        "country": "AT",
        "name": "Pöllau",
        "lat": "47.3",
        "lng": "15.83333"
    },
    {
        "country": "AT",
        "name": "Pöggstall",
        "lat": "48.31734",
        "lng": "15.20405"
    },
    {
        "country": "AT",
        "name": "Poggersdorf",
        "lat": "46.65",
        "lng": "14.45"
    },
    {
        "country": "AT",
        "name": "Podersdorf am See",
        "lat": "47.85412",
        "lng": "16.83706"
    },
    {
        "country": "AT",
        "name": "Pöchlarn",
        "lat": "48.2",
        "lng": "15.2"
    },
    {
        "country": "AT",
        "name": "Plesching",
        "lat": "48.32099",
        "lng": "14.33802"
    },
    {
        "country": "AT",
        "name": "Plankenau",
        "lat": "47.32673",
        "lng": "13.19282"
    },
    {
        "country": "AT",
        "name": "Plainfeld",
        "lat": "47.83333",
        "lng": "13.18333"
    },
    {
        "country": "AT",
        "name": "Pitzenberg",
        "lat": "48.07361",
        "lng": "13.72484"
    },
    {
        "country": "AT",
        "name": "Pitten",
        "lat": "47.71667",
        "lng": "16.18333"
    },
    {
        "country": "AT",
        "name": "Pitschgau",
        "lat": "46.7",
        "lng": "15.26667"
    },
    {
        "country": "AT",
        "name": "Pistorf",
        "lat": "46.76667",
        "lng": "15.36667"
    },
    {
        "country": "AT",
        "name": "Pischelsdorf in der Steiermark",
        "lat": "47.17417",
        "lng": "15.80572"
    },
    {
        "country": "AT",
        "name": "Pirka",
        "lat": "47",
        "lng": "15.38333"
    },
    {
        "country": "AT",
        "name": "Piringsdorf",
        "lat": "47.44826",
        "lng": "16.41516"
    },
    {
        "country": "AT",
        "name": "Pirching am Traubenberg",
        "lat": "46.95",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Pinsdorf",
        "lat": "47.9298",
        "lng": "13.77068"
    },
    {
        "country": "AT",
        "name": "Pinkafeld",
        "lat": "47.36667",
        "lng": "16.11667"
    },
    {
        "country": "AT",
        "name": "Pinggau",
        "lat": "47.44232",
        "lng": "16.06713"
    },
    {
        "country": "AT",
        "name": "Pillichsdorf",
        "lat": "48.35",
        "lng": "16.53333"
    },
    {
        "country": "AT",
        "name": "Pill",
        "lat": "47.32352",
        "lng": "11.68018"
    },
    {
        "country": "AT",
        "name": "Pilgersdorf",
        "lat": "47.44106",
        "lng": "16.3492"
    },
    {
        "country": "AT",
        "name": "Piesendorf",
        "lat": "47.29077",
        "lng": "12.71839"
    },
    {
        "country": "AT",
        "name": "Pierbach",
        "lat": "48.34815",
        "lng": "14.75575"
    },
    {
        "country": "AT",
        "name": "Pichling bei Köflach",
        "lat": "47.04785",
        "lng": "15.07098"
    },
    {
        "country": "AT",
        "name": "Pichl bei Wels",
        "lat": "48.18515",
        "lng": "13.89882"
    },
    {
        "country": "AT",
        "name": "Pichl",
        "lat": "47.28333",
        "lng": "12.85"
    },
    {
        "country": "AT",
        "name": "Piberegg",
        "lat": "47.09424",
        "lng": "15.11658"
    },
    {
        "country": "AT",
        "name": "Pians",
        "lat": "47.13486",
        "lng": "10.51237"
    },
    {
        "country": "AT",
        "name": "Pfunds",
        "lat": "46.96667",
        "lng": "10.55"
    },
    {
        "country": "AT",
        "name": "Pfons",
        "lat": "47.14201",
        "lng": "11.46071"
    },
    {
        "country": "AT",
        "name": "Pflach",
        "lat": "47.51667",
        "lng": "10.71667"
    },
    {
        "country": "AT",
        "name": "Pfarrkirchen bei Bad Hall",
        "lat": "48.03046",
        "lng": "14.19914"
    },
    {
        "country": "AT",
        "name": "Pfaffstätten",
        "lat": "48.01737",
        "lng": "16.26354"
    },
    {
        "country": "AT",
        "name": "Pfaffing",
        "lat": "48.018",
        "lng": "13.47507"
    },
    {
        "country": "AT",
        "name": "Pfaffenhofen",
        "lat": "47.3",
        "lng": "11.08333"
    },
    {
        "country": "AT",
        "name": "Peuerbach",
        "lat": "48.3453",
        "lng": "13.77205"
    },
    {
        "country": "AT",
        "name": "Petzenkirchen",
        "lat": "48.14693",
        "lng": "15.15465"
    },
    {
        "country": "AT",
        "name": "Pettneu",
        "lat": "47.14595",
        "lng": "10.33655"
    },
    {
        "country": "AT",
        "name": "Pettenbach",
        "lat": "47.96016",
        "lng": "14.01692"
    },
    {
        "country": "AT",
        "name": "Petronell-Carnuntum",
        "lat": "48.11296",
        "lng": "16.86582"
    },
    {
        "country": "AT",
        "name": "Perwang am Grabensee",
        "lat": "48.00692",
        "lng": "13.083"
    },
    {
        "country": "AT",
        "name": "Pertlstein",
        "lat": "46.94056",
        "lng": "15.96167"
    },
    {
        "country": "AT",
        "name": "Persenbeug",
        "lat": "48.18733",
        "lng": "15.08809"
    },
    {
        "country": "AT",
        "name": "Pernitz",
        "lat": "47.89759",
        "lng": "15.96022"
    },
    {
        "country": "AT",
        "name": "Pernersdorf",
        "lat": "48.7",
        "lng": "16.01667"
    },
    {
        "country": "AT",
        "name": "Pernegg an der Mur",
        "lat": "47.35979",
        "lng": "15.34236"
    },
    {
        "country": "AT",
        "name": "Pernegg",
        "lat": "48.73333",
        "lng": "15.61667"
    },
    {
        "country": "AT",
        "name": "Perlsdorf",
        "lat": "46.91361",
        "lng": "15.81417"
    },
    {
        "country": "AT",
        "name": "Perg",
        "lat": "48.25",
        "lng": "14.63333"
    },
    {
        "country": "AT",
        "name": "Perchtoldsdorf",
        "lat": "48.11935",
        "lng": "16.26607"
    },
    {
        "country": "AT",
        "name": "Perchau am Sattel",
        "lat": "47.1",
        "lng": "14.45"
    },
    {
        "country": "AT",
        "name": "Penz",
        "lat": "48.03333",
        "lng": "14.48333"
    },
    {
        "country": "AT",
        "name": "Pennewang",
        "lat": "48.13333",
        "lng": "13.85"
    },
    {
        "country": "AT",
        "name": "Peggau",
        "lat": "47.2",
        "lng": "15.35"
    },
    {
        "country": "AT",
        "name": "Payerbach",
        "lat": "47.69209",
        "lng": "15.8634"
    },
    {
        "country": "AT",
        "name": "Paudorf",
        "lat": "48.35416",
        "lng": "15.61853"
    },
    {
        "country": "AT",
        "name": "Pattigham",
        "lat": "48.15521",
        "lng": "13.48443"
    },
    {
        "country": "AT",
        "name": "Patsch",
        "lat": "47.20527",
        "lng": "11.4151"
    },
    {
        "country": "AT",
        "name": "Paternion",
        "lat": "46.71417",
        "lng": "13.63611"
    },
    {
        "country": "AT",
        "name": "Passail",
        "lat": "47.28333",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Pasching",
        "lat": "48.25931",
        "lng": "14.20369"
    },
    {
        "country": "AT",
        "name": "Parschlug",
        "lat": "47.4809",
        "lng": "15.28645"
    },
    {
        "country": "AT",
        "name": "Parndorf",
        "lat": "47.99963",
        "lng": "16.86049"
    },
    {
        "country": "AT",
        "name": "Parbasdorf",
        "lat": "48.28333",
        "lng": "16.6"
    },
    {
        "country": "AT",
        "name": "Pamhagen",
        "lat": "47.70092",
        "lng": "16.90779"
    },
    {
        "country": "AT",
        "name": "Pama",
        "lat": "48.05",
        "lng": "17.03333"
    },
    {
        "country": "AT",
        "name": "Palting",
        "lat": "48.01542",
        "lng": "13.12712"
    },
    {
        "country": "AT",
        "name": "Palfau",
        "lat": "47.7",
        "lng": "14.8"
    },
    {
        "country": "AT",
        "name": "Paldau",
        "lat": "46.94222",
        "lng": "15.79583"
    },
    {
        "country": "AT",
        "name": "Pack",
        "lat": "46.97917",
        "lng": "14.98417"
    },
    {
        "country": "AT",
        "name": "Pabneukirchen",
        "lat": "48.32367",
        "lng": "14.81747"
    },
    {
        "country": "AT",
        "name": "Oeynhausen",
        "lat": "47.98533",
        "lng": "16.29187"
    },
    {
        "country": "AT",
        "name": "Oetz",
        "lat": "47.2",
        "lng": "10.9"
    },
    {
        "country": "AT",
        "name": "Otterthal",
        "lat": "47.61667",
        "lng": "15.93333"
    },
    {
        "country": "AT",
        "name": "Ottenthal",
        "lat": "48.761",
        "lng": "16.57914"
    },
    {
        "country": "AT",
        "name": "Ottensheim",
        "lat": "48.33246",
        "lng": "14.17425"
    },
    {
        "country": "AT",
        "name": "Ottenschlag im Mühlkreis",
        "lat": "48.46632",
        "lng": "14.38471"
    },
    {
        "country": "AT",
        "name": "Ottenschlag",
        "lat": "48.42393",
        "lng": "15.21924"
    },
    {
        "country": "AT",
        "name": "Ottakring",
        "lat": "48.21667",
        "lng": "16.3"
    },
    {
        "country": "AT",
        "name": "Osterwitz",
        "lat": "46.85943",
        "lng": "15.08998"
    },
    {
        "country": "AT",
        "name": "Ostermiething",
        "lat": "48.04636",
        "lng": "12.82937"
    },
    {
        "country": "AT",
        "name": "Ossiach",
        "lat": "46.67435",
        "lng": "13.98358"
    },
    {
        "country": "AT",
        "name": "Oslip",
        "lat": "47.82906",
        "lng": "16.61957"
    },
    {
        "country": "AT",
        "name": "Ort im Innkreis",
        "lat": "48.31654",
        "lng": "13.43362"
    },
    {
        "country": "AT",
        "name": "Orth an der Donau",
        "lat": "48.14524",
        "lng": "16.70089"
    },
    {
        "country": "AT",
        "name": "Ortgraben",
        "lat": "47.45",
        "lng": "16.05"
    },
    {
        "country": "AT",
        "name": "Opponitz",
        "lat": "47.87776",
        "lng": "14.82283"
    },
    {
        "country": "AT",
        "name": "Oppenberg",
        "lat": "47.48333",
        "lng": "14.26667"
    },
    {
        "country": "AT",
        "name": "Ollersdorf im Burgenland",
        "lat": "47.18333",
        "lng": "16.16667"
    },
    {
        "country": "AT",
        "name": "Olbendorf",
        "lat": "47.18333",
        "lng": "16.2"
    },
    {
        "country": "AT",
        "name": "Ohlsdorf",
        "lat": "47.96073",
        "lng": "13.79145"
    },
    {
        "country": "AT",
        "name": "Oggau",
        "lat": "47.83333",
        "lng": "16.66667"
    },
    {
        "country": "AT",
        "name": "Offenhausen",
        "lat": "48.15",
        "lng": "13.83333"
    },
    {
        "country": "AT",
        "name": "Oepping",
        "lat": "48.60284",
        "lng": "13.94586"
    },
    {
        "country": "AT",
        "name": "Oehling",
        "lat": "48.1",
        "lng": "14.8"
    },
    {
        "country": "AT",
        "name": "Oedt",
        "lat": "48.21335",
        "lng": "14.21013"
    },
    {
        "country": "AT",
        "name": "Öblarn",
        "lat": "47.45938",
        "lng": "13.99023"
    },
    {
        "country": "AT",
        "name": "Oberzeiring",
        "lat": "47.25",
        "lng": "14.48333"
    },
    {
        "country": "AT",
        "name": "Oberwölz Stadt",
        "lat": "47.20117",
        "lng": "14.28321"
    },
    {
        "country": "AT",
        "name": "Oberwölbling",
        "lat": "48.31719",
        "lng": "15.59166"
    },
    {
        "country": "AT",
        "name": "Oberweg",
        "lat": "47.15",
        "lng": "14.63333"
    },
    {
        "country": "AT",
        "name": "Oberwart",
        "lat": "47.28971",
        "lng": "16.20595"
    },
    {
        "country": "AT",
        "name": "Oberwang",
        "lat": "47.86667",
        "lng": "13.43333"
    },
    {
        "country": "AT",
        "name": "Oberwaltersdorf",
        "lat": "47.97567",
        "lng": "16.32191"
    },
    {
        "country": "AT",
        "name": "Oberwagram",
        "lat": "48.20157",
        "lng": "15.64917"
    },
    {
        "country": "AT",
        "name": "Obervogau",
        "lat": "46.745",
        "lng": "15.58472"
    },
    {
        "country": "AT",
        "name": "Obervellach",
        "lat": "46.93667",
        "lng": "13.20417"
    },
    {
        "country": "AT",
        "name": "Obertrum am See",
        "lat": "47.93722",
        "lng": "13.07722"
    },
    {
        "country": "AT",
        "name": "Obertilliach",
        "lat": "46.71056",
        "lng": "12.61444"
    },
    {
        "country": "AT",
        "name": "Oberstorcha",
        "lat": "46.96667",
        "lng": "15.8"
    },
    {
        "country": "AT",
        "name": "Obersiebenbrunn",
        "lat": "48.26537",
        "lng": "16.71076"
    },
    {
        "country": "AT",
        "name": "Obersdorf",
        "lat": "48.36667",
        "lng": "16.51667"
    },
    {
        "country": "AT",
        "name": "Oberschützen",
        "lat": "47.35138",
        "lng": "16.20732"
    },
    {
        "country": "AT",
        "name": "Oberrettenbach",
        "lat": "47.15",
        "lng": "15.8"
    },
    {
        "country": "AT",
        "name": "Oberpullendorf",
        "lat": "47.50352",
        "lng": "16.50447"
    },
    {
        "country": "AT",
        "name": "Oberpremstätten",
        "lat": "46.97444",
        "lng": "15.40444"
    },
    {
        "country": "AT",
        "name": "Pettnau",
        "lat": "47.29204",
        "lng": "11.15962"
    },
    {
        "country": "AT",
        "name": "Oberneukirchen",
        "lat": "48.46404",
        "lng": "14.22275"
    },
    {
        "country": "AT",
        "name": "Oberndorf in Tirol",
        "lat": "47.5",
        "lng": "12.38333"
    },
    {
        "country": "AT",
        "name": "Oberndorf bei Schwanenstadt",
        "lat": "48.05559",
        "lng": "13.75677"
    },
    {
        "country": "AT",
        "name": "Oberndorf bei Salzburg",
        "lat": "47.95",
        "lng": "12.93333"
    },
    {
        "country": "AT",
        "name": "Oberndorf an der Melk",
        "lat": "48.06386",
        "lng": "15.2243"
    },
    {
        "country": "AT",
        "name": "Oberndorf in der Ebene",
        "lat": "48.2704",
        "lng": "15.68985"
    },
    {
        "country": "AT",
        "name": "Obernberg am Inn",
        "lat": "48.32133",
        "lng": "13.33427"
    },
    {
        "country": "AT",
        "name": "Obermieming",
        "lat": "47.3",
        "lng": "10.98333"
    },
    {
        "country": "AT",
        "name": "Oberloisdorf",
        "lat": "47.44736",
        "lng": "16.50764"
    },
    {
        "country": "AT",
        "name": "Oberlienz",
        "lat": "46.84722",
        "lng": "12.73139"
    },
    {
        "country": "AT",
        "name": "Oberkurzheim",
        "lat": "47.23333",
        "lng": "14.58333"
    },
    {
        "country": "AT",
        "name": "Oberhofen im Inntal",
        "lat": "47.3",
        "lng": "11.08333"
    },
    {
        "country": "AT",
        "name": "Oberhofen am Irrsee",
        "lat": "47.95",
        "lng": "13.3"
    },
    {
        "country": "AT",
        "name": "Oberhausen",
        "lat": "48.1769",
        "lng": "16.58506"
    },
    {
        "country": "AT",
        "name": "Oberhart",
        "lat": "48.18333",
        "lng": "14.05"
    },
    {
        "country": "AT",
        "name": "Oberhaag",
        "lat": "46.6869",
        "lng": "15.33202"
    },
    {
        "country": "AT",
        "name": "Ober-Grafendorf",
        "lat": "48.1504",
        "lng": "15.54531"
    },
    {
        "country": "AT",
        "name": "Oberdrauburg",
        "lat": "46.74306",
        "lng": "12.97028"
    },
    {
        "country": "AT",
        "name": "Oberdorf im Burgenland",
        "lat": "47.21667",
        "lng": "16.21667"
    },
    {
        "country": "AT",
        "name": "Oberdorf am Hochegg",
        "lat": "46.97861",
        "lng": "15.72167"
    },
    {
        "country": "AT",
        "name": "Aurach",
        "lat": "47.41223",
        "lng": "12.42734"
    },
    {
        "country": "AT",
        "name": "Oberau",
        "lat": "47.44336",
        "lng": "12.04891"
    },
    {
        "country": "AT",
        "name": "Oberalm",
        "lat": "47.7",
        "lng": "13.1"
    },
    {
        "country": "AT",
        "name": "Oberaich",
        "lat": "47.4",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Obdach",
        "lat": "47.06667",
        "lng": "14.68333"
    },
    {
        "country": "AT",
        "name": "Nüziders",
        "lat": "47.16667",
        "lng": "9.8"
    },
    {
        "country": "AT",
        "name": "Nußdorf am Haunsberg",
        "lat": "47.95944",
        "lng": "13.00917"
    },
    {
        "country": "AT",
        "name": "Nöchling",
        "lat": "48.22433",
        "lng": "14.98123"
    },
    {
        "country": "AT",
        "name": "Nitscha",
        "lat": "47.11667",
        "lng": "15.75"
    },
    {
        "country": "AT",
        "name": "Nikolsdorf",
        "lat": "46.78583",
        "lng": "12.91333"
    },
    {
        "country": "AT",
        "name": "Niklasdorf",
        "lat": "47.38333",
        "lng": "15.15"
    },
    {
        "country": "AT",
        "name": "Nikitsch",
        "lat": "47.53615",
        "lng": "16.66017"
    },
    {
        "country": "AT",
        "name": "Niederwölz",
        "lat": "47.15126",
        "lng": "14.37479"
    },
    {
        "country": "AT",
        "name": "Niederthalheim",
        "lat": "48.09901",
        "lng": "13.7687"
    },
    {
        "country": "AT",
        "name": "Niederöblarn",
        "lat": "47.47667",
        "lng": "14.01937"
    },
    {
        "country": "AT",
        "name": "Niedernsill",
        "lat": "47.28333",
        "lng": "12.65"
    },
    {
        "country": "AT",
        "name": "Niederneukirchen",
        "lat": "48.16093",
        "lng": "14.33965"
    },
    {
        "country": "AT",
        "name": "Niederndorf",
        "lat": "47.65",
        "lng": "12.21667"
    },
    {
        "country": "AT",
        "name": "Niederleis",
        "lat": "48.55",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Niederhollabrunn",
        "lat": "48.43333",
        "lng": "16.3"
    },
    {
        "country": "AT",
        "name": "Niederdorf",
        "lat": "46.61667",
        "lng": "14.4"
    },
    {
        "country": "AT",
        "name": "Niederbreitenbach",
        "lat": "47.53333",
        "lng": "12.08333"
    },
    {
        "country": "AT",
        "name": "Niederau",
        "lat": "47.45",
        "lng": "12.08333"
    },
    {
        "country": "AT",
        "name": "Niederalm",
        "lat": "47.72872",
        "lng": "13.06223"
    },
    {
        "country": "AT",
        "name": "Nickelsdorf",
        "lat": "47.94056",
        "lng": "17.06944"
    },
    {
        "country": "AT",
        "name": "Neuzeug",
        "lat": "48.05",
        "lng": "14.33333"
    },
    {
        "country": "AT",
        "name": "Neutillmitsch",
        "lat": "46.81667",
        "lng": "15.53333"
    },
    {
        "country": "AT",
        "name": "Neutal",
        "lat": "47.54548",
        "lng": "16.44619"
    },
    {
        "country": "AT",
        "name": "Neustift im Stubaital",
        "lat": "47.11667",
        "lng": "11.31667"
    },
    {
        "country": "AT",
        "name": "Neustift bei Güssing",
        "lat": "47.02474",
        "lng": "16.26041"
    },
    {
        "country": "AT",
        "name": "Neustift an der Lafnitz",
        "lat": "47.36667",
        "lng": "16.03333"
    },
    {
        "country": "AT",
        "name": "Neusiedl an der Zaya",
        "lat": "48.5992",
        "lng": "16.7799"
    },
    {
        "country": "AT",
        "name": "Neusiedl am See",
        "lat": "47.94901",
        "lng": "16.8417"
    },
    {
        "country": "AT",
        "name": "Neusiedl",
        "lat": "47.88769",
        "lng": "15.96313"
    },
    {
        "country": "AT",
        "name": "Neunkirchen",
        "lat": "47.72096",
        "lng": "16.08107"
    },
    {
        "country": "AT",
        "name": "Neumarkt in Steiermark",
        "lat": "47.07398",
        "lng": "14.42728"
    },
    {
        "country": "AT",
        "name": "Neumarkt im Mühlkreis",
        "lat": "48.42818",
        "lng": "14.48444"
    },
    {
        "country": "AT",
        "name": "Neumarkt im Hausruckkreis",
        "lat": "48.2728",
        "lng": "13.72836"
    },
    {
        "country": "AT",
        "name": "Neumarkt an der Ybbs",
        "lat": "48.14103",
        "lng": "15.05758"
    },
    {
        "country": "AT",
        "name": "Neumarkt am Wallersee",
        "lat": "47.95",
        "lng": "13.23333"
    },
    {
        "country": "AT",
        "name": "Neulengbach",
        "lat": "48.19745",
        "lng": "15.90219"
    },
    {
        "country": "AT",
        "name": "Neukirchen bei Lambach",
        "lat": "48.1",
        "lng": "13.81667"
    },
    {
        "country": "AT",
        "name": "Neukirchen an der Vöckla",
        "lat": "48.04053",
        "lng": "13.5376"
    },
    {
        "country": "AT",
        "name": "Neukirchen am Walde",
        "lat": "48.40584",
        "lng": "13.78158"
    },
    {
        "country": "AT",
        "name": "Neukirchen am Großvenediger",
        "lat": "47.25046",
        "lng": "12.27585"
    },
    {
        "country": "AT",
        "name": "Neukirchen",
        "lat": "47.87502",
        "lng": "13.71111"
    },
    {
        "country": "AT",
        "name": "Neuhofen an der Ybbs",
        "lat": "48.05795",
        "lng": "14.85489"
    },
    {
        "country": "AT",
        "name": "Neuhofen an der Krems",
        "lat": "48.13866",
        "lng": "14.22764"
    },
    {
        "country": "AT",
        "name": "Neuhaus",
        "lat": "46.63333",
        "lng": "14.88333"
    },
    {
        "country": "AT",
        "name": "Neufeld an der Leitha",
        "lat": "47.86558",
        "lng": "16.37856"
    },
    {
        "country": "AT",
        "name": "Neudörfl",
        "lat": "47.79655",
        "lng": "16.2977"
    },
    {
        "country": "AT",
        "name": "Neudorf bei Staatz",
        "lat": "48.72083",
        "lng": "16.49139"
    },
    {
        "country": "AT",
        "name": "Neudau",
        "lat": "47.17554",
        "lng": "16.10184"
    },
    {
        "country": "AT",
        "name": "Neuberg an der Mürz",
        "lat": "47.66423",
        "lng": "15.57226"
    },
    {
        "country": "AT",
        "name": "Nestelbach im Ilztal",
        "lat": "47.08333",
        "lng": "15.86667"
    },
    {
        "country": "AT",
        "name": "Nestelbach bei Graz",
        "lat": "47.06053",
        "lng": "15.6114"
    },
    {
        "country": "AT",
        "name": "Nesselwängle",
        "lat": "47.48333",
        "lng": "10.61667"
    },
    {
        "country": "AT",
        "name": "Nenzing",
        "lat": "47.18436",
        "lng": "9.70539"
    },
    {
        "country": "AT",
        "name": "Neidling",
        "lat": "48.24005",
        "lng": "15.55591"
    },
    {
        "country": "AT",
        "name": "Neckenmarkt",
        "lat": "47.59964",
        "lng": "16.5467"
    },
    {
        "country": "AT",
        "name": "Nauders",
        "lat": "46.88859",
        "lng": "10.50126"
    },
    {
        "country": "AT",
        "name": "Natters",
        "lat": "47.23414",
        "lng": "11.37342"
    },
    {
        "country": "AT",
        "name": "Natternbach",
        "lat": "48.3973",
        "lng": "13.74965"
    },
    {
        "country": "AT",
        "name": "Nassereith",
        "lat": "47.31667",
        "lng": "10.83333"
    },
    {
        "country": "AT",
        "name": "Namlos",
        "lat": "47.35",
        "lng": "10.66667"
    },
    {
        "country": "AT",
        "name": "Naas",
        "lat": "47.25086",
        "lng": "15.59449"
    },
    {
        "country": "AT",
        "name": "Naarn im Machlande",
        "lat": "48.22553",
        "lng": "14.60838"
    },
    {
        "country": "AT",
        "name": "Mutters",
        "lat": "47.23333",
        "lng": "11.38333"
    },
    {
        "country": "AT",
        "name": "Musau",
        "lat": "47.53195",
        "lng": "10.67339"
    },
    {
        "country": "AT",
        "name": "Mürzzuschlag",
        "lat": "47.6066",
        "lng": "15.67226"
    },
    {
        "country": "AT",
        "name": "Mürzsteg",
        "lat": "47.67556",
        "lng": "15.49145"
    },
    {
        "country": "AT",
        "name": "Mürzhofen",
        "lat": "47.48333",
        "lng": "15.38333"
    },
    {
        "country": "AT",
        "name": "Mureck",
        "lat": "46.70806",
        "lng": "15.77472"
    },
    {
        "country": "AT",
        "name": "Münzkirchen",
        "lat": "48.48333",
        "lng": "13.56667"
    },
    {
        "country": "AT",
        "name": "Münzbach",
        "lat": "48.26737",
        "lng": "14.71009"
    },
    {
        "country": "AT",
        "name": "Muntlix",
        "lat": "47.28296",
        "lng": "9.65939"
    },
    {
        "country": "AT",
        "name": "Münster",
        "lat": "47.42164",
        "lng": "11.83356"
    },
    {
        "country": "AT",
        "name": "Munderfing",
        "lat": "48.07039",
        "lng": "13.18162"
    },
    {
        "country": "AT",
        "name": "Münchendorf",
        "lat": "48.03333",
        "lng": "16.38333"
    },
    {
        "country": "AT",
        "name": "Müllendorf",
        "lat": "47.83943",
        "lng": "16.46258"
    },
    {
        "country": "AT",
        "name": "Muhr",
        "lat": "47.09893",
        "lng": "13.49756"
    },
    {
        "country": "AT",
        "name": "Mühlgraben",
        "lat": "46.88972",
        "lng": "16.04083"
    },
    {
        "country": "AT",
        "name": "Mühlen",
        "lat": "47.03071",
        "lng": "14.50848"
    },
    {
        "country": "AT",
        "name": "Mühldorf bei Feldbach",
        "lat": "46.93861",
        "lng": "15.9075"
    },
    {
        "country": "AT",
        "name": "Mühldorf",
        "lat": "48.37432",
        "lng": "15.34672"
    },
    {
        "country": "AT",
        "name": "Mühldorf",
        "lat": "47.89689",
        "lng": "13.95049"
    },
    {
        "country": "AT",
        "name": "Mühldorf",
        "lat": "46.86028",
        "lng": "13.35361"
    },
    {
        "country": "AT",
        "name": "Mühlbach am Hochkönig",
        "lat": "47.37746",
        "lng": "13.12926"
    },
    {
        "country": "AT",
        "name": "Mühlau",
        "lat": "47.28333",
        "lng": "11.4"
    },
    {
        "country": "AT",
        "name": "Muggendorf",
        "lat": "47.91059",
        "lng": "15.93533"
    },
    {
        "country": "AT",
        "name": "Muckendorf an der Donau",
        "lat": "48.33183",
        "lng": "16.1554"
    },
    {
        "country": "AT",
        "name": "Mötz",
        "lat": "47.28333",
        "lng": "10.95"
    },
    {
        "country": "AT",
        "name": "Moschendorf",
        "lat": "47.05843",
        "lng": "16.47728"
    },
    {
        "country": "AT",
        "name": "Mörtschach",
        "lat": "46.92389",
        "lng": "12.91778"
    },
    {
        "country": "AT",
        "name": "Mortantsch",
        "lat": "47.20752",
        "lng": "15.57954"
    },
    {
        "country": "AT",
        "name": "Mörbisch am See",
        "lat": "47.75",
        "lng": "16.66667"
    },
    {
        "country": "AT",
        "name": "Mooskirchen",
        "lat": "46.98167",
        "lng": "15.27889"
    },
    {
        "country": "AT",
        "name": "Moosdorf",
        "lat": "48.04492",
        "lng": "12.98902"
    },
    {
        "country": "AT",
        "name": "Moosburg",
        "lat": "46.6575",
        "lng": "14.17472"
    },
    {
        "country": "AT",
        "name": "Moosbrunn",
        "lat": "48.01667",
        "lng": "16.45"
    },
    {
        "country": "AT",
        "name": "Mönichwald",
        "lat": "47.44655",
        "lng": "15.88275"
    },
    {
        "country": "AT",
        "name": "Mönichkirchen",
        "lat": "47.51062",
        "lng": "16.03425"
    },
    {
        "country": "AT",
        "name": "Mondsee",
        "lat": "47.85648",
        "lng": "13.34908"
    },
    {
        "country": "AT",
        "name": "Mönchhof",
        "lat": "47.8802",
        "lng": "16.94126"
    },
    {
        "country": "AT",
        "name": "Molln",
        "lat": "47.88723",
        "lng": "14.25819"
    },
    {
        "country": "AT",
        "name": "Möllersdorf",
        "lat": "48.02575",
        "lng": "16.30508"
    },
    {
        "country": "AT",
        "name": "Möllbrücke",
        "lat": "46.83639",
        "lng": "13.37278"
    },
    {
        "country": "AT",
        "name": "Mölbling",
        "lat": "46.85",
        "lng": "14.43333"
    },
    {
        "country": "AT",
        "name": "Möggers",
        "lat": "47.56667",
        "lng": "9.81667"
    },
    {
        "country": "AT",
        "name": "Mogersdorf",
        "lat": "46.94917",
        "lng": "16.23222"
    },
    {
        "country": "AT",
        "name": "Modriach",
        "lat": "46.95",
        "lng": "15.05"
    },
    {
        "country": "AT",
        "name": "Mödling",
        "lat": "48.08605",
        "lng": "16.28921"
    },
    {
        "country": "AT",
        "name": "Mittertreffling",
        "lat": "48.33868",
        "lng": "14.36505"
    },
    {
        "country": "AT",
        "name": "Mittersill",
        "lat": "47.28333",
        "lng": "12.48333"
    },
    {
        "country": "AT",
        "name": "Mitterpullendorf",
        "lat": "47.49325",
        "lng": "16.52129"
    },
    {
        "country": "AT",
        "name": "Mitterndorf im Steirischen Salzkammergut",
        "lat": "47.55556",
        "lng": "13.93187"
    },
    {
        "country": "AT",
        "name": "Mitterndorf an der Fischa",
        "lat": "47.99739",
        "lng": "16.47357"
    },
    {
        "country": "AT",
        "name": "Mitterlabill",
        "lat": "46.88917",
        "lng": "15.63556"
    },
    {
        "country": "AT",
        "name": "Mitterkirchen im Machland",
        "lat": "48.18795",
        "lng": "14.69593"
    },
    {
        "country": "AT",
        "name": "Mitterhofen",
        "lat": "47.38333",
        "lng": "12.8"
    },
    {
        "country": "AT",
        "name": "Mitterdorf im Mürztal",
        "lat": "47.53333",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Mitterdorf an der Raab",
        "lat": "47.16667",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Mitterberghütten",
        "lat": "47.4",
        "lng": "13.21667"
    },
    {
        "country": "AT",
        "name": "Mitterbach am Erlaufsee",
        "lat": "47.81396",
        "lng": "15.29537"
    },
    {
        "country": "AT",
        "name": "Mittelberg",
        "lat": "47.35127",
        "lng": "10.17197"
    },
    {
        "country": "AT",
        "name": "Mistelbach",
        "lat": "48.57",
        "lng": "16.57667"
    },
    {
        "country": "AT",
        "name": "Mischendorf",
        "lat": "47.19277",
        "lng": "16.31444"
    },
    {
        "country": "AT",
        "name": "Mils bei Solbad Hall",
        "lat": "47.28333",
        "lng": "11.53333"
    },
    {
        "country": "AT",
        "name": "Mils bei Imst",
        "lat": "47.20616",
        "lng": "10.67485"
    },
    {
        "country": "AT",
        "name": "Miesenbach",
        "lat": "47.84035",
        "lng": "15.98236"
    },
    {
        "country": "AT",
        "name": "Miesenbach bei Birkfeld",
        "lat": "47.36667",
        "lng": "15.76667"
    },
    {
        "country": "AT",
        "name": "Mieming",
        "lat": "47.3",
        "lng": "10.98333"
    },
    {
        "country": "AT",
        "name": "Mieders",
        "lat": "47.16667",
        "lng": "11.38333"
    },
    {
        "country": "AT",
        "name": "Michelhausen",
        "lat": "48.29085",
        "lng": "15.93893"
    },
    {
        "country": "AT",
        "name": "Micheldorf in Oberösterreich",
        "lat": "47.87764",
        "lng": "14.13357"
    },
    {
        "country": "AT",
        "name": "Micheldorf",
        "lat": "46.91667",
        "lng": "14.41667"
    },
    {
        "country": "AT",
        "name": "Michaelnbach",
        "lat": "48.28788",
        "lng": "13.83144"
    },
    {
        "country": "AT",
        "name": "Michaelerberg",
        "lat": "47.41083",
        "lng": "13.89333"
    },
    {
        "country": "AT",
        "name": "Mettersdorf am Saßbach",
        "lat": "46.80583",
        "lng": "15.71111"
    },
    {
        "country": "AT",
        "name": "Metnitz",
        "lat": "46.98056",
        "lng": "14.21667"
    },
    {
        "country": "AT",
        "name": "Merkendorf",
        "lat": "46.85722",
        "lng": "15.90389"
    },
    {
        "country": "AT",
        "name": "Mellach",
        "lat": "46.93333",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Melk",
        "lat": "48.22737",
        "lng": "15.33186"
    },
    {
        "country": "AT",
        "name": "Meiningen",
        "lat": "47.29891",
        "lng": "9.57862"
    },
    {
        "country": "AT",
        "name": "Meidling",
        "lat": "48.16667",
        "lng": "16.33333"
    },
    {
        "country": "AT",
        "name": "Mehrnbach",
        "lat": "48.20809",
        "lng": "13.43525"
    },
    {
        "country": "AT",
        "name": "Meggenhofen",
        "lat": "48.18017",
        "lng": "13.79582"
    },
    {
        "country": "AT",
        "name": "Medraz",
        "lat": "47.14256",
        "lng": "11.34287"
    },
    {
        "country": "AT",
        "name": "Mayrhofen",
        "lat": "47.16667",
        "lng": "11.86667"
    },
    {
        "country": "AT",
        "name": "Mautern in Steiermark",
        "lat": "47.4",
        "lng": "14.83333"
    },
    {
        "country": "AT",
        "name": "Mauterndorf",
        "lat": "47.13451",
        "lng": "13.67884"
    },
    {
        "country": "AT",
        "name": "Mautern",
        "lat": "48.39319",
        "lng": "15.57793"
    },
    {
        "country": "AT",
        "name": "Mauerkirchen",
        "lat": "48.19173",
        "lng": "13.13338"
    },
    {
        "country": "AT",
        "name": "Mauer bei Amstetten",
        "lat": "48.09643",
        "lng": "14.8006"
    },
    {
        "country": "AT",
        "name": "Mauerbach",
        "lat": "48.24514",
        "lng": "16.16793"
    },
    {
        "country": "AT",
        "name": "Matzendorf",
        "lat": "47.89137",
        "lng": "16.21393"
    },
    {
        "country": "AT",
        "name": "Matzen",
        "lat": "48.4",
        "lng": "16.7"
    },
    {
        "country": "AT",
        "name": "Mattsee",
        "lat": "47.96667",
        "lng": "13.1"
    },
    {
        "country": "AT",
        "name": "Mattighofen",
        "lat": "48.10732",
        "lng": "13.15081"
    },
    {
        "country": "AT",
        "name": "Mattersburg",
        "lat": "47.73333",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Matrei in Osttirol",
        "lat": "47",
        "lng": "12.53333"
    },
    {
        "country": "AT",
        "name": "Matrei am Brenner",
        "lat": "47.12794",
        "lng": "11.45176"
    },
    {
        "country": "AT",
        "name": "Marz",
        "lat": "47.71667",
        "lng": "16.41667"
    },
    {
        "country": "AT",
        "name": "Martinsberg",
        "lat": "48.37549",
        "lng": "15.14997"
    },
    {
        "country": "AT",
        "name": "Markt Sankt Martin",
        "lat": "47.56205",
        "lng": "16.42508"
    },
    {
        "country": "AT",
        "name": "Sankt Florian",
        "lat": "48.20568",
        "lng": "14.37836"
    },
    {
        "country": "AT",
        "name": "Bad Pirawarth",
        "lat": "48.45194",
        "lng": "16.59833"
    },
    {
        "country": "AT",
        "name": "Markt Piesting",
        "lat": "47.87358",
        "lng": "16.1251"
    },
    {
        "country": "AT",
        "name": "Markt Neuhodis",
        "lat": "47.29565",
        "lng": "16.39564"
    },
    {
        "country": "AT",
        "name": "Markt Allhau",
        "lat": "47.28333",
        "lng": "16.08333"
    },
    {
        "country": "AT",
        "name": "Markgrafneusiedl",
        "lat": "48.26667",
        "lng": "16.63333"
    },
    {
        "country": "AT",
        "name": "Markersdorf an der Pielach",
        "lat": "48.18333",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Mariazell",
        "lat": "47.77306",
        "lng": "15.31639"
    },
    {
        "country": "AT",
        "name": "Maria Wörth",
        "lat": "46.61639",
        "lng": "14.16306"
    },
    {
        "country": "AT",
        "name": "Mariatrost",
        "lat": "47.1",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Mariatal",
        "lat": "47.44788",
        "lng": "11.8721"
    },
    {
        "country": "AT",
        "name": "Maria Taferl",
        "lat": "48.22702",
        "lng": "15.15954"
    },
    {
        "country": "AT",
        "name": "Mariastein",
        "lat": "47.52772",
        "lng": "12.05479"
    },
    {
        "country": "AT",
        "name": "Mariasdorf",
        "lat": "47.3658",
        "lng": "16.23136"
    },
    {
        "country": "AT",
        "name": "Maria Schmolln",
        "lat": "48.1382",
        "lng": "13.21981"
    },
    {
        "country": "AT",
        "name": "Maria Saal",
        "lat": "46.68083",
        "lng": "14.34861"
    },
    {
        "country": "AT",
        "name": "Maria Rain",
        "lat": "46.55389",
        "lng": "14.29556"
    },
    {
        "country": "AT",
        "name": "Mariapfarr",
        "lat": "47.15",
        "lng": "13.75"
    },
    {
        "country": "AT",
        "name": "Maria Neustift",
        "lat": "47.93333",
        "lng": "14.6"
    },
    {
        "country": "AT",
        "name": "Maria Lanzendorf",
        "lat": "48.09923",
        "lng": "16.41984"
    },
    {
        "country": "AT",
        "name": "Maria Lankowitz",
        "lat": "47.0622",
        "lng": "15.06525"
    },
    {
        "country": "AT",
        "name": "Maria Laach am Jauerling",
        "lat": "48.30412",
        "lng": "15.34472"
    },
    {
        "country": "AT",
        "name": "Mariahof",
        "lat": "47.1",
        "lng": "14.4"
    },
    {
        "country": "AT",
        "name": "Maria Enzersdorf",
        "lat": "48.1",
        "lng": "16.28333"
    },
    {
        "country": "AT",
        "name": "Maria Ellend",
        "lat": "48.1",
        "lng": "16.68333"
    },
    {
        "country": "AT",
        "name": "Maria-Anzbach",
        "lat": "48.19012",
        "lng": "15.93155"
    },
    {
        "country": "AT",
        "name": "Maria Alm am Steinernen Meer",
        "lat": "47.40579",
        "lng": "12.90121"
    },
    {
        "country": "AT",
        "name": "Margarethen am Moos",
        "lat": "48.03333",
        "lng": "16.6"
    },
    {
        "country": "AT",
        "name": "Marchtrenk",
        "lat": "48.19275",
        "lng": "14.11394"
    },
    {
        "country": "AT",
        "name": "Marchegg",
        "lat": "48.26217",
        "lng": "16.91045"
    },
    {
        "country": "AT",
        "name": "Marbach an der Donau",
        "lat": "48.21667",
        "lng": "15.15"
    },
    {
        "country": "AT",
        "name": "Mannswörth",
        "lat": "48.14547",
        "lng": "16.51374"
    },
    {
        "country": "AT",
        "name": "Mannsdorf an der Donau",
        "lat": "48.152",
        "lng": "16.66506"
    },
    {
        "country": "AT",
        "name": "Manning",
        "lat": "48.08851",
        "lng": "13.66682"
    },
    {
        "country": "AT",
        "name": "Mannersdorf an der Rabnitz",
        "lat": "47.42815",
        "lng": "16.52678"
    },
    {
        "country": "AT",
        "name": "Mannersdorf am Leithagebirge",
        "lat": "47.96667",
        "lng": "16.6"
    },
    {
        "country": "AT",
        "name": "Mank",
        "lat": "48.11024",
        "lng": "15.33915"
    },
    {
        "country": "AT",
        "name": "Maissau",
        "lat": "48.573",
        "lng": "15.83005"
    },
    {
        "country": "AT",
        "name": "Maishofen",
        "lat": "47.36667",
        "lng": "12.8"
    },
    {
        "country": "AT",
        "name": "Mailberg",
        "lat": "48.67379",
        "lng": "16.18132"
    },
    {
        "country": "AT",
        "name": "Maierdorf",
        "lat": "46.8925",
        "lng": "15.84972"
    },
    {
        "country": "AT",
        "name": "Mäder",
        "lat": "47.35",
        "lng": "9.61667"
    },
    {
        "country": "AT",
        "name": "Lutzmannsburg",
        "lat": "47.46373",
        "lng": "16.63665"
    },
    {
        "country": "AT",
        "name": "Lustenau",
        "lat": "47.42642",
        "lng": "9.65851"
    },
    {
        "country": "AT",
        "name": "Lunz am See",
        "lat": "47.8612",
        "lng": "15.02998"
    },
    {
        "country": "AT",
        "name": "Luftenberg an der Donau",
        "lat": "48.27462",
        "lng": "14.41303"
    },
    {
        "country": "AT",
        "name": "Ludmannsdorf",
        "lat": "46.54139",
        "lng": "14.13425"
    },
    {
        "country": "AT",
        "name": "Ludesch",
        "lat": "47.2",
        "lng": "9.78306"
    },
    {
        "country": "AT",
        "name": "Losenstein",
        "lat": "47.92428",
        "lng": "14.43672"
    },
    {
        "country": "AT",
        "name": "Lorüns",
        "lat": "47.13306",
        "lng": "9.85"
    },
    {
        "country": "AT",
        "name": "Loretto",
        "lat": "47.91559",
        "lng": "16.5179"
    },
    {
        "country": "AT",
        "name": "Loosdorf",
        "lat": "48.2",
        "lng": "15.4"
    },
    {
        "country": "AT",
        "name": "Loipersdorf bei Fürstenfeld",
        "lat": "47",
        "lng": "16.1"
    },
    {
        "country": "AT",
        "name": "Loipersbach im Burgenland",
        "lat": "47.69656",
        "lng": "16.47919"
    },
    {
        "country": "AT",
        "name": "Loich",
        "lat": "47.99578",
        "lng": "15.40163"
    },
    {
        "country": "AT",
        "name": "Lofer",
        "lat": "47.58475",
        "lng": "12.69333"
    },
    {
        "country": "AT",
        "name": "Lödersdorf",
        "lat": "46.95861",
        "lng": "15.94333"
    },
    {
        "country": "AT",
        "name": "Lochau",
        "lat": "47.53333",
        "lng": "9.75"
    },
    {
        "country": "AT",
        "name": "Litzelsdorf",
        "lat": "47.20826",
        "lng": "16.1717"
    },
    {
        "country": "AT",
        "name": "Litschau",
        "lat": "48.94409",
        "lng": "15.04483"
    },
    {
        "country": "AT",
        "name": "Linz",
        "lat": "48.30639",
        "lng": "14.28611"
    },
    {
        "country": "AT",
        "name": "Lilienfeld",
        "lat": "48.01312",
        "lng": "15.59664"
    },
    {
        "country": "AT",
        "name": "Ligist",
        "lat": "46.99389",
        "lng": "15.21083"
    },
    {
        "country": "AT",
        "name": "Liezen",
        "lat": "47.56667",
        "lng": "14.23333"
    },
    {
        "country": "AT",
        "name": "Liesingtal",
        "lat": "47.34597",
        "lng": "15.01352"
    },
    {
        "country": "AT",
        "name": "Lienz",
        "lat": "46.8289",
        "lng": "12.76903"
    },
    {
        "country": "AT",
        "name": "Lieboch",
        "lat": "46.97417",
        "lng": "15.3375"
    },
    {
        "country": "AT",
        "name": "Liebenau",
        "lat": "47.03333",
        "lng": "15.46667"
    },
    {
        "country": "AT",
        "name": "Lichtenwörth",
        "lat": "47.82756",
        "lng": "16.29873"
    },
    {
        "country": "AT",
        "name": "Lichtenegg",
        "lat": "48.15",
        "lng": "14"
    },
    {
        "country": "AT",
        "name": "Lichtenegg",
        "lat": "47.6",
        "lng": "16.2"
    },
    {
        "country": "AT",
        "name": "Leutschach",
        "lat": "46.66722",
        "lng": "15.46889"
    },
    {
        "country": "AT",
        "name": "Lermoos",
        "lat": "47.40358",
        "lng": "10.8807"
    },
    {
        "country": "AT",
        "name": "Leopoldsdorf im Marchfelde",
        "lat": "48.22261",
        "lng": "16.68858"
    },
    {
        "country": "AT",
        "name": "Leopoldsdorf",
        "lat": "48.11557",
        "lng": "16.39126"
    },
    {
        "country": "AT",
        "name": "Leonstein",
        "lat": "47.89666",
        "lng": "14.23124"
    },
    {
        "country": "AT",
        "name": "Leonding",
        "lat": "48.27965",
        "lng": "14.2533"
    },
    {
        "country": "AT",
        "name": "Leogang",
        "lat": "47.43906",
        "lng": "12.76109"
    },
    {
        "country": "AT",
        "name": "Leobersdorf",
        "lat": "47.92796",
        "lng": "16.21651"
    },
    {
        "country": "AT",
        "name": "Leobendorf",
        "lat": "48.38333",
        "lng": "16.31667"
    },
    {
        "country": "AT",
        "name": "Leoben",
        "lat": "47.3765",
        "lng": "15.09144"
    },
    {
        "country": "AT",
        "name": "Lenzing",
        "lat": "47.97326",
        "lng": "13.60846"
    },
    {
        "country": "AT",
        "name": "Lengfelden",
        "lat": "47.85",
        "lng": "13.05"
    },
    {
        "country": "AT",
        "name": "Lengenfeld",
        "lat": "48.47187",
        "lng": "15.5987"
    },
    {
        "country": "AT",
        "name": "Lendorf",
        "lat": "46.83528",
        "lng": "13.43028"
    },
    {
        "country": "AT",
        "name": "Lend",
        "lat": "47.29856",
        "lng": "13.05176"
    },
    {
        "country": "AT",
        "name": "Lembach im Mühlkreis",
        "lat": "48.49517",
        "lng": "13.89513"
    },
    {
        "country": "AT",
        "name": "Leitzersdorf",
        "lat": "48.41918",
        "lng": "16.24513"
    },
    {
        "country": "AT",
        "name": "Leitring",
        "lat": "46.76667",
        "lng": "15.56667"
    },
    {
        "country": "AT",
        "name": "Leithaprodersdorf",
        "lat": "47.93348",
        "lng": "16.47915"
    },
    {
        "country": "AT",
        "name": "Leitersdorf im Raabtal",
        "lat": "46.94182",
        "lng": "15.93365"
    },
    {
        "country": "AT",
        "name": "Leitendorf",
        "lat": "47.36667",
        "lng": "15.08333"
    },
    {
        "country": "AT",
        "name": "Leisach",
        "lat": "46.8125",
        "lng": "12.74861"
    },
    {
        "country": "AT",
        "name": "Leibnitz",
        "lat": "46.78161",
        "lng": "15.53836"
    },
    {
        "country": "AT",
        "name": "Leiben",
        "lat": "48.24628",
        "lng": "15.2746"
    },
    {
        "country": "AT",
        "name": "Ledenitzen",
        "lat": "46.56639",
        "lng": "13.96139"
    },
    {
        "country": "AT",
        "name": "Lechaschau",
        "lat": "47.48804",
        "lng": "10.70652"
    },
    {
        "country": "AT",
        "name": "Lech",
        "lat": "47.20797",
        "lng": "10.14184"
    },
    {
        "country": "AT",
        "name": "Laxenburg",
        "lat": "48.06833",
        "lng": "16.35607"
    },
    {
        "country": "AT",
        "name": "Tullnerbach-Lawies",
        "lat": "48.18912",
        "lng": "16.09117"
    },
    {
        "country": "AT",
        "name": "Lavant",
        "lat": "46.79889",
        "lng": "12.83806"
    },
    {
        "country": "AT",
        "name": "Lavamünd",
        "lat": "46.64019",
        "lng": "14.94733"
    },
    {
        "country": "AT",
        "name": "Lauterach",
        "lat": "47.47572",
        "lng": "9.72941"
    },
    {
        "country": "AT",
        "name": "Laussa",
        "lat": "47.95",
        "lng": "14.45"
    },
    {
        "country": "AT",
        "name": "Launsdorf",
        "lat": "46.77056",
        "lng": "14.45194"
    },
    {
        "country": "AT",
        "name": "Lassnitzhöhe",
        "lat": "47.06667",
        "lng": "15.58333"
    },
    {
        "country": "AT",
        "name": "Lassing",
        "lat": "47.53374",
        "lng": "14.25808"
    },
    {
        "country": "AT",
        "name": "Lassee",
        "lat": "48.22479",
        "lng": "16.8223"
    },
    {
        "country": "AT",
        "name": "Lanzenkirchen",
        "lat": "47.73621",
        "lng": "16.21985"
    },
    {
        "country": "AT",
        "name": "Lans",
        "lat": "47.23833",
        "lng": "11.43139"
    },
    {
        "country": "AT",
        "name": "Lannach",
        "lat": "46.94611",
        "lng": "15.33722"
    },
    {
        "country": "AT",
        "name": "Langschlag",
        "lat": "48.57448",
        "lng": "14.88459"
    },
    {
        "country": "AT",
        "name": "Langenzersdorf",
        "lat": "48.30432",
        "lng": "16.36143"
    },
    {
        "country": "AT",
        "name": "Langenwang",
        "lat": "47.56667",
        "lng": "15.61667"
    },
    {
        "country": "AT",
        "name": "Langenstein",
        "lat": "48.252",
        "lng": "14.47655"
    },
    {
        "country": "AT",
        "name": "Langenrohr",
        "lat": "48.30489",
        "lng": "16.01034"
    },
    {
        "country": "AT",
        "name": "Langenlois",
        "lat": "48.46667",
        "lng": "15.66667"
    },
    {
        "country": "AT",
        "name": "Langen",
        "lat": "47.51667",
        "lng": "9.81667"
    },
    {
        "country": "AT",
        "name": "Langegg bei Graz",
        "lat": "47.05",
        "lng": "15.63333"
    },
    {
        "country": "AT",
        "name": "Langau",
        "lat": "48.83207",
        "lng": "15.7156"
    },
    {
        "country": "AT",
        "name": "Lang",
        "lat": "46.83765",
        "lng": "15.50471"
    },
    {
        "country": "AT",
        "name": "Landskron",
        "lat": "46.61667",
        "lng": "13.88333"
    },
    {
        "country": "AT",
        "name": "Landl",
        "lat": "47.65666",
        "lng": "14.73189"
    },
    {
        "country": "AT",
        "name": "Landegg",
        "lat": "47.9",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Landeck",
        "lat": "47.13988",
        "lng": "10.56593"
    },
    {
        "country": "AT",
        "name": "Lamprechtshausen",
        "lat": "47.99098",
        "lng": "12.95481"
    },
    {
        "country": "AT",
        "name": "Lambach",
        "lat": "48.09276",
        "lng": "13.87453"
    },
    {
        "country": "AT",
        "name": "Lafnitz",
        "lat": "47.36792",
        "lng": "16.01103"
    },
    {
        "country": "AT",
        "name": "Ladis",
        "lat": "47.07459",
        "lng": "10.64949"
    },
    {
        "country": "AT",
        "name": "Ladendorf",
        "lat": "48.53333",
        "lng": "16.48333"
    },
    {
        "country": "AT",
        "name": "Lackendorf",
        "lat": "47.58996",
        "lng": "16.50404"
    },
    {
        "country": "AT",
        "name": "Lackenbach",
        "lat": "47.59042",
        "lng": "16.46533"
    },
    {
        "country": "AT",
        "name": "Labuch",
        "lat": "47.06667",
        "lng": "15.66667"
    },
    {
        "country": "AT",
        "name": "Laakirchen",
        "lat": "47.98188",
        "lng": "13.82166"
    },
    {
        "country": "AT",
        "name": "Laab im Walde",
        "lat": "48.15486",
        "lng": "16.17359"
    },
    {
        "country": "AT",
        "name": "Laa an der Thaya",
        "lat": "48.71667",
        "lng": "16.38333"
    },
    {
        "country": "AT",
        "name": "Kundl",
        "lat": "47.46667",
        "lng": "11.98333"
    },
    {
        "country": "AT",
        "name": "Kumberg",
        "lat": "47.16422",
        "lng": "15.53261"
    },
    {
        "country": "AT",
        "name": "Kulm am Zirbitz",
        "lat": "47.05814",
        "lng": "14.48702"
    },
    {
        "country": "AT",
        "name": "Kukmirn",
        "lat": "47.07544",
        "lng": "16.21033"
    },
    {
        "country": "AT",
        "name": "Kühnsdorf",
        "lat": "46.62194",
        "lng": "14.63639"
    },
    {
        "country": "AT",
        "name": "Kufstein",
        "lat": "47.58333",
        "lng": "12.16667"
    },
    {
        "country": "AT",
        "name": "Kuchl",
        "lat": "47.62647",
        "lng": "13.1448"
    },
    {
        "country": "AT",
        "name": "Krusdorf",
        "lat": "46.83944",
        "lng": "15.86083"
    },
    {
        "country": "AT",
        "name": "Krumpendorf",
        "lat": "46.63333",
        "lng": "14.21667"
    },
    {
        "country": "AT",
        "name": "Krumnussbaum",
        "lat": "48.20877",
        "lng": "15.16212"
    },
    {
        "country": "AT",
        "name": "Krumegg",
        "lat": "47.02111",
        "lng": "15.63139"
    },
    {
        "country": "AT",
        "name": "Krumbach Markt",
        "lat": "47.51667",
        "lng": "16.18333"
    },
    {
        "country": "AT",
        "name": "Krumbach",
        "lat": "47.48306",
        "lng": "9.93583"
    },
    {
        "country": "AT",
        "name": "Krumau am Kamp",
        "lat": "48.58879",
        "lng": "15.44914"
    },
    {
        "country": "AT",
        "name": "Krottendorf bei Ligist",
        "lat": "47.01667",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Krottendorf",
        "lat": "47.2",
        "lng": "15.63333"
    },
    {
        "country": "AT",
        "name": "Kronstorf",
        "lat": "48.14324",
        "lng": "14.46307"
    },
    {
        "country": "AT",
        "name": "Kritzendorf",
        "lat": "48.32932",
        "lng": "16.30011"
    },
    {
        "country": "AT",
        "name": "Krispl",
        "lat": "47.71667",
        "lng": "13.18333"
    },
    {
        "country": "AT",
        "name": "Krieglach",
        "lat": "47.54728",
        "lng": "15.56248"
    },
    {
        "country": "AT",
        "name": "Bad Kreuzen",
        "lat": "48.26737",
        "lng": "14.80648"
    },
    {
        "country": "AT",
        "name": "Krensdorf",
        "lat": "47.78552",
        "lng": "16.41495"
    },
    {
        "country": "AT",
        "name": "Kremsmünster",
        "lat": "48.0529",
        "lng": "14.12919"
    },
    {
        "country": "AT",
        "name": "Kremsdorf",
        "lat": "48.20031",
        "lng": "14.26249"
    },
    {
        "country": "AT",
        "name": "Krems an der Donau",
        "lat": "48.40921",
        "lng": "15.61415"
    },
    {
        "country": "AT",
        "name": "Kraubath an der Mur",
        "lat": "47.3",
        "lng": "14.93333"
    },
    {
        "country": "AT",
        "name": "Kramsach",
        "lat": "47.44312",
        "lng": "11.87545"
    },
    {
        "country": "AT",
        "name": "Krakauschatten",
        "lat": "47.18333",
        "lng": "13.96667"
    },
    {
        "country": "AT",
        "name": "Krakauhintermühlen",
        "lat": "47.18333",
        "lng": "13.98333"
    },
    {
        "country": "AT",
        "name": "Krakaudorf",
        "lat": "47.18131",
        "lng": "14.02061"
    },
    {
        "country": "AT",
        "name": "Köttmannsdorf",
        "lat": "46.56139",
        "lng": "14.23389"
    },
    {
        "country": "AT",
        "name": "Kottingbrunn",
        "lat": "47.95096",
        "lng": "16.22715"
    },
    {
        "country": "AT",
        "name": "Kötschach",
        "lat": "46.68167",
        "lng": "13.00694"
    },
    {
        "country": "AT",
        "name": "Köstendorf",
        "lat": "47.95",
        "lng": "13.2"
    },
    {
        "country": "AT",
        "name": "Kössen",
        "lat": "47.6699",
        "lng": "12.40545"
    },
    {
        "country": "AT",
        "name": "Korneuburg",
        "lat": "48.35",
        "lng": "16.33333"
    },
    {
        "country": "AT",
        "name": "Koppl",
        "lat": "47.80808",
        "lng": "13.15561"
    },
    {
        "country": "AT",
        "name": "Kopfing im Innkreis",
        "lat": "48.43991",
        "lng": "13.65841"
    },
    {
        "country": "AT",
        "name": "Königswiesen",
        "lat": "48.40453",
        "lng": "14.83824"
    },
    {
        "country": "AT",
        "name": "Königstetten",
        "lat": "48.30198",
        "lng": "16.14492"
    },
    {
        "country": "AT",
        "name": "Königsdorf",
        "lat": "47",
        "lng": "16.16667"
    },
    {
        "country": "AT",
        "name": "Kolsassberg",
        "lat": "47.2815",
        "lng": "11.65289"
    },
    {
        "country": "AT",
        "name": "Kohlschwarz",
        "lat": "47.11667",
        "lng": "15.11667"
    },
    {
        "country": "AT",
        "name": "Kohlberg",
        "lat": "46.9",
        "lng": "15.78333"
    },
    {
        "country": "AT",
        "name": "Kohfidisch",
        "lat": "47.17472",
        "lng": "16.35701"
    },
    {
        "country": "AT",
        "name": "Köflach",
        "lat": "47.06667",
        "lng": "15.08333"
    },
    {
        "country": "AT",
        "name": "Koblach",
        "lat": "47.33306",
        "lng": "9.6"
    },
    {
        "country": "AT",
        "name": "Kobersdorf",
        "lat": "47.59572",
        "lng": "16.39173"
    },
    {
        "country": "AT",
        "name": "Kobenz",
        "lat": "47.25",
        "lng": "14.85"
    },
    {
        "country": "AT",
        "name": "Knittelfeld",
        "lat": "47.21667",
        "lng": "14.81667"
    },
    {
        "country": "AT",
        "name": "Klosterneuburg",
        "lat": "48.30521",
        "lng": "16.32522"
    },
    {
        "country": "AT",
        "name": "Klösterle",
        "lat": "47.13333",
        "lng": "10.08333"
    },
    {
        "country": "AT",
        "name": "Kloster",
        "lat": "46.88333",
        "lng": "15.08333"
    },
    {
        "country": "AT",
        "name": "Klöch",
        "lat": "46.76472",
        "lng": "15.96556"
    },
    {
        "country": "AT",
        "name": "Klingenbach",
        "lat": "47.75202",
        "lng": "16.54069"
    },
    {
        "country": "AT",
        "name": "Kleinzell",
        "lat": "47.97993",
        "lng": "15.7362"
    },
    {
        "country": "AT",
        "name": "Kleinsölk",
        "lat": "47.39444",
        "lng": "13.93944"
    },
    {
        "country": "AT",
        "name": "Kleinsöding",
        "lat": "47",
        "lng": "15.28333"
    },
    {
        "country": "AT",
        "name": "Klein Sankt Paul",
        "lat": "46.83611",
        "lng": "14.54139"
    },
    {
        "country": "AT",
        "name": "Klein-Pöchlarn",
        "lat": "48.21667",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Kleinmürbisch",
        "lat": "47.03299",
        "lng": "16.32448"
    },
    {
        "country": "AT",
        "name": "Kleinlobming",
        "lat": "47.14954",
        "lng": "14.84875"
    },
    {
        "country": "AT",
        "name": "Kleinhöflein im Burgenland",
        "lat": "47.84151",
        "lng": "16.50413"
    },
    {
        "country": "AT",
        "name": "Klausen-Leopoldsdorf",
        "lat": "48.08771",
        "lng": "16.01686"
    },
    {
        "country": "AT",
        "name": "Klaus",
        "lat": "47.30913",
        "lng": "9.64678"
    },
    {
        "country": "AT",
        "name": "Klagenfurt am Wörthersee",
        "lat": "46.62472",
        "lng": "14.30528"
    },
    {
        "country": "AT",
        "name": "Klaffer am Hochficht",
        "lat": "48.69544",
        "lng": "13.88131"
    },
    {
        "country": "AT",
        "name": "Kitzeck im Sausal",
        "lat": "46.78072",
        "lng": "15.45384"
    },
    {
        "country": "AT",
        "name": "Kitzbühel",
        "lat": "47.44637",
        "lng": "12.39215"
    },
    {
        "country": "AT",
        "name": "Kittsee",
        "lat": "48.0925",
        "lng": "17.06389"
    },
    {
        "country": "AT",
        "name": "Kirnberg an der Mank",
        "lat": "48.07246",
        "lng": "15.32232"
    },
    {
        "country": "AT",
        "name": "Kirchstetten",
        "lat": "48.18333",
        "lng": "15.81667"
    },
    {
        "country": "AT",
        "name": "Kirchschlag in der Buckligen Welt",
        "lat": "47.5",
        "lng": "16.28333"
    },
    {
        "country": "AT",
        "name": "Kirchschlag bei Linz",
        "lat": "48.41149",
        "lng": "14.27656"
    },
    {
        "country": "AT",
        "name": "Kirchschlag",
        "lat": "48.39345",
        "lng": "15.22285"
    },
    {
        "country": "AT",
        "name": "Kirchdorf in Tirol",
        "lat": "47.55626",
        "lng": "12.44511"
    },
    {
        "country": "AT",
        "name": "Kirchdorf an der Krems",
        "lat": "47.90558",
        "lng": "14.12228"
    },
    {
        "country": "AT",
        "name": "Kirchberg ob der Donau",
        "lat": "48.4444",
        "lng": "13.93805"
    },
    {
        "country": "AT",
        "name": "Kirchberg in Tirol",
        "lat": "47.44539",
        "lng": "12.31602"
    },
    {
        "country": "AT",
        "name": "Kirchberg an der Raab",
        "lat": "46.98583",
        "lng": "15.76694"
    },
    {
        "country": "AT",
        "name": "Kirchberg an der Pielach",
        "lat": "48.0269",
        "lng": "15.42875"
    },
    {
        "country": "AT",
        "name": "Kirchberg am Wechsel",
        "lat": "47.60738",
        "lng": "15.99103"
    },
    {
        "country": "AT",
        "name": "Kirchberg am Walde",
        "lat": "48.72477",
        "lng": "15.08826"
    },
    {
        "country": "AT",
        "name": "Kirchberg am Wagram",
        "lat": "48.43182",
        "lng": "15.89692"
    },
    {
        "country": "AT",
        "name": "Kirchbach in Steiermark",
        "lat": "46.93167",
        "lng": "15.66194"
    },
    {
        "country": "AT",
        "name": "Kirchbach",
        "lat": "46.6416",
        "lng": "13.18454"
    },
    {
        "country": "AT",
        "name": "Kindberg",
        "lat": "47.5",
        "lng": "15.45"
    },
    {
        "country": "AT",
        "name": "Kilb",
        "lat": "48.10101",
        "lng": "15.4085"
    },
    {
        "country": "AT",
        "name": "Kierling",
        "lat": "48.30997",
        "lng": "16.27616"
    },
    {
        "country": "AT",
        "name": "Keutschach am See",
        "lat": "46.59306",
        "lng": "14.18889"
    },
    {
        "country": "AT",
        "name": "Kennelbach",
        "lat": "47.48306",
        "lng": "9.76667"
    },
    {
        "country": "AT",
        "name": "Kemeten",
        "lat": "47.24859",
        "lng": "16.15213"
    },
    {
        "country": "AT",
        "name": "Kematen in Tirol",
        "lat": "47.25",
        "lng": "11.26667"
    },
    {
        "country": "AT",
        "name": "Kematen an der Krems",
        "lat": "48.1115",
        "lng": "14.19391"
    },
    {
        "country": "AT",
        "name": "Kematen an der Ybbs",
        "lat": "48.02541",
        "lng": "14.76468"
    },
    {
        "country": "AT",
        "name": "Kautzen",
        "lat": "48.93",
        "lng": "15.23932"
    },
    {
        "country": "AT",
        "name": "Kauns",
        "lat": "47.07822",
        "lng": "10.69219"
    },
    {
        "country": "AT",
        "name": "Kaumberg",
        "lat": "48.02415",
        "lng": "15.89842"
    },
    {
        "country": "AT",
        "name": "Katzelsdorf",
        "lat": "48.28456",
        "lng": "16.10836"
    },
    {
        "country": "AT",
        "name": "Katzelsdorf",
        "lat": "47.78055",
        "lng": "16.26985"
    },
    {
        "country": "AT",
        "name": "Katsdorf",
        "lat": "48.31791",
        "lng": "14.47432"
    },
    {
        "country": "AT",
        "name": "Kasten bei Böheimkirchen",
        "lat": "48.1532",
        "lng": "15.77946"
    },
    {
        "country": "AT",
        "name": "Kartitsch",
        "lat": "46.72889",
        "lng": "12.50083"
    },
    {
        "country": "AT",
        "name": "Karrösten",
        "lat": "47.2254",
        "lng": "10.76561"
    },
    {
        "country": "AT",
        "name": "Karres",
        "lat": "47.21667",
        "lng": "10.78333"
    },
    {
        "country": "AT",
        "name": "Karlstetten",
        "lat": "48.2592",
        "lng": "15.56544"
    },
    {
        "country": "AT",
        "name": "Kaprun",
        "lat": "47.27239",
        "lng": "12.75985"
    },
    {
        "country": "AT",
        "name": "Kappl",
        "lat": "47.06667",
        "lng": "10.38333"
    },
    {
        "country": "AT",
        "name": "Kappel am Krappfeld",
        "lat": "46.83861",
        "lng": "14.48639"
    },
    {
        "country": "AT",
        "name": "Kapfing",
        "lat": "47.33333",
        "lng": "11.85"
    },
    {
        "country": "AT",
        "name": "Kapfenstein",
        "lat": "46.88611",
        "lng": "15.97167"
    },
    {
        "country": "AT",
        "name": "Kapfenberg",
        "lat": "47.44458",
        "lng": "15.29331"
    },
    {
        "country": "AT",
        "name": "Kapelln",
        "lat": "48.25817",
        "lng": "15.75731"
    },
    {
        "country": "AT",
        "name": "Kapellen",
        "lat": "47.64784",
        "lng": "15.62863"
    },
    {
        "country": "AT",
        "name": "Kammern im Liesingtal",
        "lat": "47.39245",
        "lng": "14.90407"
    },
    {
        "country": "AT",
        "name": "Kalwang",
        "lat": "47.42678",
        "lng": "14.75442"
    },
    {
        "country": "AT",
        "name": "Kaltenleutgeben",
        "lat": "48.11646",
        "lng": "16.19956"
    },
    {
        "country": "AT",
        "name": "Kaltenbach",
        "lat": "47.70618",
        "lng": "13.60914"
    },
    {
        "country": "AT",
        "name": "Kaltenbach",
        "lat": "47.28333",
        "lng": "11.86667"
    },
    {
        "country": "AT",
        "name": "Kalsdorf bei Graz",
        "lat": "46.96528",
        "lng": "15.48028"
    },
    {
        "country": "AT",
        "name": "Kaisersdorf",
        "lat": "47.53741",
        "lng": "16.39198"
    },
    {
        "country": "AT",
        "name": "Kaindorf an der Sulm",
        "lat": "46.79248",
        "lng": "15.53879"
    },
    {
        "country": "AT",
        "name": "Kaindorf",
        "lat": "47.22537",
        "lng": "15.91125"
    },
    {
        "country": "AT",
        "name": "Kainbach",
        "lat": "47.08333",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Kainach bei Voitsberg",
        "lat": "47.13637",
        "lng": "15.0953"
    },
    {
        "country": "AT",
        "name": "Kaibing",
        "lat": "47.2",
        "lng": "15.83333"
    },
    {
        "country": "AT",
        "name": "Jungholz",
        "lat": "47.57409",
        "lng": "10.44723"
    },
    {
        "country": "AT",
        "name": "Judendorf",
        "lat": "47.4",
        "lng": "15.1"
    },
    {
        "country": "AT",
        "name": "Warmbad-Judendorf",
        "lat": "46.60126",
        "lng": "13.82241"
    },
    {
        "country": "AT",
        "name": "Judendorf",
        "lat": "47.11667",
        "lng": "15.35"
    },
    {
        "country": "AT",
        "name": "Judenburg",
        "lat": "47.16667",
        "lng": "14.66667"
    },
    {
        "country": "AT",
        "name": "Jois",
        "lat": "47.96165",
        "lng": "16.79604"
    },
    {
        "country": "AT",
        "name": "Johnsbach",
        "lat": "47.53333",
        "lng": "14.58333"
    },
    {
        "country": "AT",
        "name": "Jochberg",
        "lat": "47.3792",
        "lng": "12.41807"
    },
    {
        "country": "AT",
        "name": "Jerzens",
        "lat": "47.1512",
        "lng": "10.74686"
    },
    {
        "country": "AT",
        "name": "Jennersdorf",
        "lat": "46.93848",
        "lng": "16.14158"
    },
    {
        "country": "AT",
        "name": "Jenbach",
        "lat": "47.39173",
        "lng": "11.77245"
    },
    {
        "country": "AT",
        "name": "Jedenspeigen",
        "lat": "48.49807",
        "lng": "16.87225"
    },
    {
        "country": "AT",
        "name": "Japons",
        "lat": "48.7925",
        "lng": "15.56831"
    },
    {
        "country": "AT",
        "name": "Jaidhof",
        "lat": "48.53333",
        "lng": "15.48333"
    },
    {
        "country": "AT",
        "name": "Jagerberg",
        "lat": "46.85361",
        "lng": "15.73806"
    },
    {
        "country": "AT",
        "name": "Jabing",
        "lat": "47.2388",
        "lng": "16.27659"
    },
    {
        "country": "AT",
        "name": "Ysper",
        "lat": "48.28865",
        "lng": "15.06131"
    },
    {
        "country": "AT",
        "name": "Ischgl",
        "lat": "47.01257",
        "lng": "10.29179"
    },
    {
        "country": "AT",
        "name": "Irschen",
        "lat": "46.75694",
        "lng": "13.02528"
    },
    {
        "country": "AT",
        "name": "Irrsdorf",
        "lat": "47.96667",
        "lng": "13.28333"
    },
    {
        "country": "AT",
        "name": "Irdning",
        "lat": "47.50529",
        "lng": "14.10155"
    },
    {
        "country": "AT",
        "name": "Inzing",
        "lat": "47.2737",
        "lng": "11.19751"
    },
    {
        "country": "AT",
        "name": "Inzersdorf im Kremstal",
        "lat": "47.92808",
        "lng": "14.08104"
    },
    {
        "country": "AT",
        "name": "Inzenhof",
        "lat": "47.01667",
        "lng": "16.31667"
    },
    {
        "country": "AT",
        "name": "Innsbruck",
        "lat": "47.26266",
        "lng": "11.39454"
    },
    {
        "country": "AT",
        "name": "Weerberg",
        "lat": "47.29841",
        "lng": "11.66592"
    },
    {
        "country": "AT",
        "name": "Innervillgraten",
        "lat": "46.81194",
        "lng": "12.37472"
    },
    {
        "country": "AT",
        "name": "Innerschwand",
        "lat": "47.83333",
        "lng": "13.4"
    },
    {
        "country": "AT",
        "name": "Innere Stadt",
        "lat": "48.20906",
        "lng": "16.37135"
    },
    {
        "country": "AT",
        "name": "Innerbraz",
        "lat": "47.15",
        "lng": "9.91667"
    },
    {
        "country": "AT",
        "name": "Imsterberg",
        "lat": "47.20517",
        "lng": "10.69605"
    },
    {
        "country": "AT",
        "name": "Imst",
        "lat": "47.24504",
        "lng": "10.73974"
    },
    {
        "country": "AT",
        "name": "Ilz",
        "lat": "47.08649",
        "lng": "15.92676"
    },
    {
        "country": "AT",
        "name": "Illmitz",
        "lat": "47.76148",
        "lng": "16.80024"
    },
    {
        "country": "AT",
        "name": "Hüttschlag",
        "lat": "47.17635",
        "lng": "13.23239"
    },
    {
        "country": "AT",
        "name": "Hüttenberg",
        "lat": "46.94139",
        "lng": "14.55"
    },
    {
        "country": "AT",
        "name": "Hutten",
        "lat": "48.17856",
        "lng": "15.98579"
    },
    {
        "country": "AT",
        "name": "Hüttau",
        "lat": "47.4161",
        "lng": "13.30775"
    },
    {
        "country": "AT",
        "name": "Hürm",
        "lat": "48.15601",
        "lng": "15.41262"
    },
    {
        "country": "AT",
        "name": "Hundsheim",
        "lat": "48.11749",
        "lng": "16.93581"
    },
    {
        "country": "AT",
        "name": "Hötting",
        "lat": "47.26815",
        "lng": "11.36868"
    },
    {
        "country": "AT",
        "name": "Hörtendorf",
        "lat": "46.63333",
        "lng": "14.4"
    },
    {
        "country": "AT",
        "name": "Hörsching",
        "lat": "48.22627",
        "lng": "14.17786"
    },
    {
        "country": "AT",
        "name": "Hornstein",
        "lat": "47.88049",
        "lng": "16.44447"
    },
    {
        "country": "AT",
        "name": "Horn",
        "lat": "48.66274",
        "lng": "15.65663"
    },
    {
        "country": "AT",
        "name": "Horitschon",
        "lat": "47.58729",
        "lng": "16.54696"
    },
    {
        "country": "AT",
        "name": "Hörbranz",
        "lat": "47.55",
        "lng": "9.75"
    },
    {
        "country": "AT",
        "name": "Hopfgarten im Brixental",
        "lat": "47.4498",
        "lng": "12.15659"
    },
    {
        "country": "AT",
        "name": "Hopfgarten in Defereggen",
        "lat": "46.91917",
        "lng": "12.53639"
    },
    {
        "country": "AT",
        "name": "Hönigsberg",
        "lat": "47.58038",
        "lng": "15.64808"
    },
    {
        "country": "AT",
        "name": "Holzhausen",
        "lat": "48.22296",
        "lng": "14.09683"
    },
    {
        "country": "AT",
        "name": "Hollersbach im Pinzgau",
        "lat": "47.27658",
        "lng": "12.42326"
    },
    {
        "country": "AT",
        "name": "Hollenthon",
        "lat": "47.58938",
        "lng": "16.26131"
    },
    {
        "country": "AT",
        "name": "Hollenstein an der Ybbs",
        "lat": "47.80305",
        "lng": "14.77312"
    },
    {
        "country": "AT",
        "name": "Hollenegg",
        "lat": "46.79147",
        "lng": "15.21345"
    },
    {
        "country": "AT",
        "name": "Hollabrunn",
        "lat": "48.55",
        "lng": "16.08333"
    },
    {
        "country": "AT",
        "name": "Hohenweiler",
        "lat": "47.58333",
        "lng": "9.78333"
    },
    {
        "country": "AT",
        "name": "Hohenthurn",
        "lat": "46.55774",
        "lng": "13.66038"
    },
    {
        "country": "AT",
        "name": "Hohentauern",
        "lat": "47.43333",
        "lng": "14.48333"
    },
    {
        "country": "AT",
        "name": "Hohenruppersdorf",
        "lat": "48.46439",
        "lng": "16.65244"
    },
    {
        "country": "AT",
        "name": "Hohenems",
        "lat": "47.36667",
        "lng": "9.68306"
    },
    {
        "country": "AT",
        "name": "Hoheneich",
        "lat": "48.77195",
        "lng": "15.02857"
    },
    {
        "country": "AT",
        "name": "Hohenberg",
        "lat": "47.90679",
        "lng": "15.61998"
    },
    {
        "country": "AT",
        "name": "Hohenau an der Raab",
        "lat": "47.3",
        "lng": "15.55"
    },
    {
        "country": "AT",
        "name": "Hohenau",
        "lat": "48.6042",
        "lng": "16.9047"
    },
    {
        "country": "AT",
        "name": "Hofstetten",
        "lat": "48.09711",
        "lng": "15.51149"
    },
    {
        "country": "AT",
        "name": "Hofstätten an der Raab",
        "lat": "47.06667",
        "lng": "15.73333"
    },
    {
        "country": "AT",
        "name": "Höflein",
        "lat": "48.06667",
        "lng": "16.78333"
    },
    {
        "country": "AT",
        "name": "Hofkirchen im Traunkreis",
        "lat": "48.14312",
        "lng": "14.37776"
    },
    {
        "country": "AT",
        "name": "Höfen",
        "lat": "47.46667",
        "lng": "10.68333"
    },
    {
        "country": "AT",
        "name": "Hof bei Straden",
        "lat": "46.8",
        "lng": "15.93333"
    },
    {
        "country": "AT",
        "name": "Hof bei Salzburg",
        "lat": "47.81929",
        "lng": "13.21488"
    },
    {
        "country": "AT",
        "name": "Hof am Leithaberge",
        "lat": "47.95",
        "lng": "16.58333"
    },
    {
        "country": "AT",
        "name": "Hof",
        "lat": "47.86667",
        "lng": "13.31667"
    },
    {
        "country": "AT",
        "name": "Hof",
        "lat": "47.35",
        "lng": "13.31667"
    },
    {
        "country": "AT",
        "name": "Hochwolkersdorf",
        "lat": "47.66127",
        "lng": "16.28072"
    },
    {
        "country": "AT",
        "name": "Hochtregist",
        "lat": "47.1",
        "lng": "15.13333"
    },
    {
        "country": "AT",
        "name": "Höchst",
        "lat": "47.45934",
        "lng": "9.6405"
    },
    {
        "country": "AT",
        "name": "Hochfilzen",
        "lat": "47.46667",
        "lng": "12.61667"
    },
    {
        "country": "AT",
        "name": "Hochburg-Ach",
        "lat": "48.13001",
        "lng": "12.87735"
    },
    {
        "country": "AT",
        "name": "Hitzendorf",
        "lat": "47.03333",
        "lng": "15.3"
    },
    {
        "country": "AT",
        "name": "Hirtenberg",
        "lat": "47.93095",
        "lng": "16.17908"
    },
    {
        "country": "AT",
        "name": "Hirschegg",
        "lat": "47.34813",
        "lng": "10.17137"
    },
    {
        "country": "AT",
        "name": "Hirschbach",
        "lat": "48.74329",
        "lng": "15.12521"
    },
    {
        "country": "AT",
        "name": "Hirnsdorf",
        "lat": "47.19167",
        "lng": "15.82868"
    },
    {
        "country": "AT",
        "name": "Hirm",
        "lat": "47.78652",
        "lng": "16.4546"
    },
    {
        "country": "AT",
        "name": "Hippach",
        "lat": "47.20435",
        "lng": "11.86523"
    },
    {
        "country": "AT",
        "name": "Hintersee",
        "lat": "47.7",
        "lng": "13.28333"
    },
    {
        "country": "AT",
        "name": "Hinterbrühl",
        "lat": "48.08611",
        "lng": "16.24809"
    },
    {
        "country": "AT",
        "name": "Himmelreich",
        "lat": "47.8",
        "lng": "12.98333"
    },
    {
        "country": "AT",
        "name": "Himmelberg",
        "lat": "46.75667",
        "lng": "14.03056"
    },
    {
        "country": "AT",
        "name": "Himberg",
        "lat": "48.08333",
        "lng": "16.43333"
    },
    {
        "country": "AT",
        "name": "Hietzing",
        "lat": "48.18623",
        "lng": "16.2965"
    },
    {
        "country": "AT",
        "name": "Hieflau",
        "lat": "47.60639",
        "lng": "14.74503"
    },
    {
        "country": "AT",
        "name": "Heugraben",
        "lat": "47.11783",
        "lng": "16.19041"
    },
    {
        "country": "AT",
        "name": "Hetzendorf",
        "lat": "47.18333",
        "lng": "14.68333"
    },
    {
        "country": "AT",
        "name": "Herzogsdorf",
        "lat": "48.43011",
        "lng": "14.1128"
    },
    {
        "country": "AT",
        "name": "Herzogenburg",
        "lat": "48.28137",
        "lng": "15.69431"
    },
    {
        "country": "AT",
        "name": "Herrnbaumgarten",
        "lat": "48.69606",
        "lng": "16.68283"
    },
    {
        "country": "AT",
        "name": "Hernstein",
        "lat": "47.89465",
        "lng": "16.10561"
    },
    {
        "country": "AT",
        "name": "Hernals",
        "lat": "48.23333",
        "lng": "16.26667"
    },
    {
        "country": "AT",
        "name": "Hermagor",
        "lat": "46.62722",
        "lng": "13.36722"
    },
    {
        "country": "AT",
        "name": "Hennersdorf",
        "lat": "48.11173",
        "lng": "16.36311"
    },
    {
        "country": "AT",
        "name": "Henndorf am Wallersee",
        "lat": "47.9",
        "lng": "13.18333"
    },
    {
        "country": "AT",
        "name": "Hengsberg",
        "lat": "46.86667",
        "lng": "15.43333"
    },
    {
        "country": "AT",
        "name": "Hellmonsödt",
        "lat": "48.43333",
        "lng": "14.3"
    },
    {
        "country": "AT",
        "name": "Heiterwang",
        "lat": "47.45",
        "lng": "10.75"
    },
    {
        "country": "AT",
        "name": "Heiligenkreuz im Lafnitztal",
        "lat": "46.98917",
        "lng": "16.26083"
    },
    {
        "country": "AT",
        "name": "Heiligenkreuz am Waasen",
        "lat": "46.95583",
        "lng": "15.58806"
    },
    {
        "country": "AT",
        "name": "Heiligenkreuz",
        "lat": "48.05559",
        "lng": "16.12493"
    },
    {
        "country": "AT",
        "name": "Heiligeneich",
        "lat": "48.29964",
        "lng": "15.89413"
    },
    {
        "country": "AT",
        "name": "Heiligenbrunn",
        "lat": "47.02705",
        "lng": "16.41688"
    },
    {
        "country": "AT",
        "name": "Heiligenblut",
        "lat": "47.03979",
        "lng": "12.84345"
    },
    {
        "country": "AT",
        "name": "Heidenreichstein",
        "lat": "48.86667",
        "lng": "15.11667"
    },
    {
        "country": "AT",
        "name": "Hausmening",
        "lat": "48.07074",
        "lng": "14.81438"
    },
    {
        "country": "AT",
        "name": "Hausmannstätten",
        "lat": "46.99111",
        "lng": "15.51139"
    },
    {
        "country": "AT",
        "name": "Hausleiten",
        "lat": "48.38333",
        "lng": "16.1"
    },
    {
        "country": "AT",
        "name": "Hauskirchen",
        "lat": "48.6",
        "lng": "16.76667"
    },
    {
        "country": "AT",
        "name": "Hausbrunn",
        "lat": "48.62602",
        "lng": "16.82844"
    },
    {
        "country": "AT",
        "name": "Haus",
        "lat": "47.40997",
        "lng": "13.76724"
    },
    {
        "country": "AT",
        "name": "Haunoldstein",
        "lat": "48.2",
        "lng": "15.45"
    },
    {
        "country": "AT",
        "name": "Haugsdorf",
        "lat": "48.70762",
        "lng": "16.07656"
    },
    {
        "country": "AT",
        "name": "Haugschlag",
        "lat": "48.98333",
        "lng": "15.05"
    },
    {
        "country": "AT",
        "name": "Hatzendorf",
        "lat": "46.97686",
        "lng": "16.00107"
    },
    {
        "country": "AT",
        "name": "Hatting",
        "lat": "47.2787",
        "lng": "11.16838"
    },
    {
        "country": "AT",
        "name": "Haslach an der Mühl",
        "lat": "48.5757",
        "lng": "14.03984"
    },
    {
        "country": "AT",
        "name": "Häselgehr",
        "lat": "47.31667",
        "lng": "10.5"
    },
    {
        "country": "AT",
        "name": "Haselbach",
        "lat": "48.25308",
        "lng": "13.05614"
    },
    {
        "country": "AT",
        "name": "Hartmannsdorf",
        "lat": "47.05461",
        "lng": "15.83941"
    },
    {
        "country": "AT",
        "name": "Hartl",
        "lat": "47.18333",
        "lng": "15.91667"
    },
    {
        "country": "AT",
        "name": "Hartkirchen",
        "lat": "48.36349",
        "lng": "14.00422"
    },
    {
        "country": "AT",
        "name": "Hart im Zillertal",
        "lat": "47.35106",
        "lng": "11.86476"
    },
    {
        "country": "AT",
        "name": "Hartheim",
        "lat": "48.28079",
        "lng": "14.11426"
    },
    {
        "country": "AT",
        "name": "Hartberg",
        "lat": "47.28333",
        "lng": "15.96667"
    },
    {
        "country": "AT",
        "name": "Hart",
        "lat": "48.26526",
        "lng": "14.25691"
    },
    {
        "country": "AT",
        "name": "Harmannsdorf",
        "lat": "48.39724",
        "lng": "16.3722"
    },
    {
        "country": "AT",
        "name": "Harland",
        "lat": "48.16162",
        "lng": "15.63835"
    },
    {
        "country": "AT",
        "name": "Haringsee",
        "lat": "48.1927",
        "lng": "16.78741"
    },
    {
        "country": "AT",
        "name": "Bad Häring",
        "lat": "47.51071",
        "lng": "12.11912"
    },
    {
        "country": "AT",
        "name": "Hardegg",
        "lat": "48.85",
        "lng": "15.85"
    },
    {
        "country": "AT",
        "name": "Hard",
        "lat": "47.48306",
        "lng": "9.68306"
    },
    {
        "country": "AT",
        "name": "Hannersdorf",
        "lat": "47.22895",
        "lng": "16.3825"
    },
    {
        "country": "AT",
        "name": "Handenberg",
        "lat": "48.13356",
        "lng": "13.00751"
    },
    {
        "country": "AT",
        "name": "Hallwang",
        "lat": "47.85",
        "lng": "13.08333"
    },
    {
        "country": "AT",
        "name": "Hallein",
        "lat": "47.68333",
        "lng": "13.1"
    },
    {
        "country": "AT",
        "name": "Halbturn",
        "lat": "47.87019",
        "lng": "16.97542"
    },
    {
        "country": "AT",
        "name": "Halbenrain",
        "lat": "46.72194",
        "lng": "15.94667"
    },
    {
        "country": "AT",
        "name": "Hainzenberg",
        "lat": "47.21788",
        "lng": "11.90034"
    },
    {
        "country": "AT",
        "name": "Hainsdorf im Schwarzautal",
        "lat": "46.83583",
        "lng": "15.64139"
    },
    {
        "country": "AT",
        "name": "Hainfeld",
        "lat": "48.0339",
        "lng": "15.77414"
    },
    {
        "country": "AT",
        "name": "Hainersdorf",
        "lat": "47.11506",
        "lng": "15.94374"
    },
    {
        "country": "AT",
        "name": "Hainburg an der Donau",
        "lat": "48.14627",
        "lng": "16.94504"
    },
    {
        "country": "AT",
        "name": "Haiming",
        "lat": "47.25",
        "lng": "10.88333"
    },
    {
        "country": "AT",
        "name": "Haidershofen",
        "lat": "48.07579",
        "lng": "14.46131"
    },
    {
        "country": "AT",
        "name": "Haiden",
        "lat": "47.7179",
        "lng": "13.56906"
    },
    {
        "country": "AT",
        "name": "Haid",
        "lat": "48.20477",
        "lng": "14.25107"
    },
    {
        "country": "AT",
        "name": "Haibach im Mühlkreis",
        "lat": "48.44429",
        "lng": "14.34411"
    },
    {
        "country": "AT",
        "name": "Hagenbrunn",
        "lat": "48.33333",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Hagenberg im Mühlkreis",
        "lat": "48.36788",
        "lng": "14.51689"
    },
    {
        "country": "AT",
        "name": "Hafnerbach",
        "lat": "48.21667",
        "lng": "15.48333"
    },
    {
        "country": "AT",
        "name": "Hafendorf",
        "lat": "47.45653",
        "lng": "15.31837"
    },
    {
        "country": "AT",
        "name": "Hadres",
        "lat": "48.70965",
        "lng": "16.13038"
    },
    {
        "country": "AT",
        "name": "Hadersdorf am Kamp",
        "lat": "48.45",
        "lng": "15.71667"
    },
    {
        "country": "AT",
        "name": "Hackerberg",
        "lat": "47.2",
        "lng": "16.11667"
    },
    {
        "country": "AT",
        "name": "Habach",
        "lat": "47.81753",
        "lng": "13.16252"
    },
    {
        "country": "AT",
        "name": "Haag am Hausruck",
        "lat": "48.18423",
        "lng": "13.64373"
    },
    {
        "country": "AT",
        "name": "Haag",
        "lat": "48.27063",
        "lng": "14.26875"
    },
    {
        "country": "AT",
        "name": "Haag",
        "lat": "48.19027",
        "lng": "15.90135"
    },
    {
        "country": "AT",
        "name": "Haag",
        "lat": "48.11362",
        "lng": "14.56753"
    },
    {
        "country": "AT",
        "name": "Güttenbach",
        "lat": "47.1571",
        "lng": "16.2923"
    },
    {
        "country": "AT",
        "name": "Guttaring",
        "lat": "46.88536",
        "lng": "14.51071"
    },
    {
        "country": "AT",
        "name": "Gutenstein",
        "lat": "47.876",
        "lng": "15.88881"
    },
    {
        "country": "AT",
        "name": "Gutenbrunn",
        "lat": "48.3652",
        "lng": "15.11899"
    },
    {
        "country": "AT",
        "name": "Gutau",
        "lat": "48.41724",
        "lng": "14.61285"
    },
    {
        "country": "AT",
        "name": "Güssing",
        "lat": "47.05936",
        "lng": "16.32431"
    },
    {
        "country": "AT",
        "name": "Gurk",
        "lat": "46.87389",
        "lng": "14.29167"
    },
    {
        "country": "AT",
        "name": "Guntramsdorf",
        "lat": "48.04687",
        "lng": "16.31384"
    },
    {
        "country": "AT",
        "name": "Guntersdorf",
        "lat": "48.65",
        "lng": "16.05"
    },
    {
        "country": "AT",
        "name": "Gunskirchen",
        "lat": "48.13333",
        "lng": "13.95"
    },
    {
        "country": "AT",
        "name": "Günselsdorf",
        "lat": "47.94395",
        "lng": "16.26062"
    },
    {
        "country": "AT",
        "name": "Gundersdorf",
        "lat": "46.95",
        "lng": "15.23333"
    },
    {
        "country": "AT",
        "name": "Gumpoldskirchen",
        "lat": "48.04538",
        "lng": "16.2771"
    },
    {
        "country": "AT",
        "name": "Gugging",
        "lat": "48.31385",
        "lng": "16.24852"
    },
    {
        "country": "AT",
        "name": "Gschwandt",
        "lat": "47.93515",
        "lng": "13.84569"
    },
    {
        "country": "AT",
        "name": "Grünbach am Schneeberg",
        "lat": "47.79747",
        "lng": "15.98785"
    },
    {
        "country": "AT",
        "name": "Grünau im Almtal",
        "lat": "47.85493",
        "lng": "13.95573"
    },
    {
        "country": "AT",
        "name": "Großweikersdorf",
        "lat": "48.47123",
        "lng": "15.98251"
    },
    {
        "country": "AT",
        "name": "Grosssulz",
        "lat": "46.94556",
        "lng": "15.49028"
    },
    {
        "country": "AT",
        "name": "Großsölk",
        "lat": "47.41667",
        "lng": "13.96667"
    },
    {
        "country": "AT",
        "name": "Groß-Siegharts",
        "lat": "48.7918",
        "lng": "15.40429"
    },
    {
        "country": "AT",
        "name": "Groß-Schweinbarth",
        "lat": "48.41472",
        "lng": "16.63194"
    },
    {
        "country": "AT",
        "name": "Groß Sankt Florian",
        "lat": "46.82444",
        "lng": "15.31861"
    },
    {
        "country": "AT",
        "name": "Großrußbach",
        "lat": "48.47412",
        "lng": "16.41649"
    },
    {
        "country": "AT",
        "name": "Großriedenthal",
        "lat": "48.48333",
        "lng": "15.86667"
    },
    {
        "country": "AT",
        "name": "Grossraming",
        "lat": "47.88333",
        "lng": "14.55"
    },
    {
        "country": "AT",
        "name": "Grosspetersdorf",
        "lat": "47.23895",
        "lng": "16.31783"
    },
    {
        "country": "AT",
        "name": "Großmugl",
        "lat": "48.49917",
        "lng": "16.23056"
    },
    {
        "country": "AT",
        "name": "Großlobming",
        "lat": "47.18333",
        "lng": "14.8"
    },
    {
        "country": "AT",
        "name": "Großkrut",
        "lat": "48.64389",
        "lng": "16.72361"
    },
    {
        "country": "AT",
        "name": "Großklein",
        "lat": "46.73611",
        "lng": "15.44444"
    },
    {
        "country": "AT",
        "name": "Grosshöflein",
        "lat": "47.83586",
        "lng": "16.48035"
    },
    {
        "country": "AT",
        "name": "Großhofen",
        "lat": "48.25834",
        "lng": "16.6194"
    },
    {
        "country": "AT",
        "name": "Heimschuh",
        "lat": "46.76",
        "lng": "15.49306"
    },
    {
        "country": "AT",
        "name": "Großharras",
        "lat": "48.66389",
        "lng": "16.24556"
    },
    {
        "country": "AT",
        "name": "Grossgmain",
        "lat": "47.71667",
        "lng": "12.91667"
    },
    {
        "country": "AT",
        "name": "Groß-Gerungs",
        "lat": "48.57422",
        "lng": "14.95789"
    },
    {
        "country": "AT",
        "name": "Groß-Enzersdorf",
        "lat": "48.20278",
        "lng": "16.55083"
    },
    {
        "country": "AT",
        "name": "Groß-Engersdorf",
        "lat": "48.35873",
        "lng": "16.5661"
    },
    {
        "country": "AT",
        "name": "Großebersdorf",
        "lat": "48.36405",
        "lng": "16.47076"
    },
    {
        "country": "AT",
        "name": "Großdorf",
        "lat": "47.43361",
        "lng": "9.91528"
    },
    {
        "country": "AT",
        "name": "Grossarl",
        "lat": "47.23333",
        "lng": "13.2"
    },
    {
        "country": "AT",
        "name": "Grödig",
        "lat": "47.73833",
        "lng": "13.0373"
    },
    {
        "country": "AT",
        "name": "Gröbming",
        "lat": "47.44272",
        "lng": "13.90122"
    },
    {
        "country": "AT",
        "name": "Grins",
        "lat": "47.14034",
        "lng": "10.51409"
    },
    {
        "country": "AT",
        "name": "Grimmenstein",
        "lat": "47.61635",
        "lng": "16.12724"
    },
    {
        "country": "AT",
        "name": "Griffen",
        "lat": "46.70444",
        "lng": "14.73278"
    },
    {
        "country": "AT",
        "name": "Grieskirchen",
        "lat": "48.23333",
        "lng": "13.83333"
    },
    {
        "country": "AT",
        "name": "Gries im Sellrain",
        "lat": "47.19554",
        "lng": "11.15619"
    },
    {
        "country": "AT",
        "name": "Gries am Brenner",
        "lat": "47.03849",
        "lng": "11.48131"
    },
    {
        "country": "AT",
        "name": "Gresten",
        "lat": "47.98521",
        "lng": "15.02552"
    },
    {
        "country": "AT",
        "name": "Gressenberg",
        "lat": "46.8",
        "lng": "15.11667"
    },
    {
        "country": "AT",
        "name": "Greisdorf",
        "lat": "46.92647",
        "lng": "15.21906"
    },
    {
        "country": "AT",
        "name": "Greinsfurth",
        "lat": "48.10846",
        "lng": "14.8439"
    },
    {
        "country": "AT",
        "name": "Grein",
        "lat": "48.22862",
        "lng": "14.85884"
    },
    {
        "country": "AT",
        "name": "Greifenburg",
        "lat": "46.75027",
        "lng": "13.17982"
    },
    {
        "country": "AT",
        "name": "Graz",
        "lat": "47.06667",
        "lng": "15.45"
    },
    {
        "country": "AT",
        "name": "Gratwein",
        "lat": "47.11667",
        "lng": "15.31667"
    },
    {
        "country": "AT",
        "name": "Gratkorn",
        "lat": "47.13333",
        "lng": "15.35"
    },
    {
        "country": "AT",
        "name": "Grän",
        "lat": "47.5",
        "lng": "10.55"
    },
    {
        "country": "AT",
        "name": "Grambach",
        "lat": "47.01473",
        "lng": "15.50407"
    },
    {
        "country": "AT",
        "name": "Gramatneusiedl",
        "lat": "48.0304",
        "lng": "16.48936"
    },
    {
        "country": "AT",
        "name": "Gramastetten",
        "lat": "48.38028",
        "lng": "14.19185"
    },
    {
        "country": "AT",
        "name": "Gramais",
        "lat": "47.26667",
        "lng": "10.53333"
    },
    {
        "country": "AT",
        "name": "Grafenwörth",
        "lat": "48.4079",
        "lng": "15.77826"
    },
    {
        "country": "AT",
        "name": "Grafenstein",
        "lat": "46.61393",
        "lng": "14.46719"
    },
    {
        "country": "AT",
        "name": "Grafenschlag",
        "lat": "48.5",
        "lng": "15.16667"
    },
    {
        "country": "AT",
        "name": "Grafenschachen",
        "lat": "47.36667",
        "lng": "16.06667"
    },
    {
        "country": "AT",
        "name": "Grafenegg",
        "lat": "48.4304",
        "lng": "15.74907"
    },
    {
        "country": "AT",
        "name": "Grafendorf bei Hartberg",
        "lat": "47.34028",
        "lng": "15.9906"
    },
    {
        "country": "AT",
        "name": "Grabersdorf",
        "lat": "46.84306",
        "lng": "15.82472"
    },
    {
        "country": "AT",
        "name": "Götzis",
        "lat": "47.33306",
        "lng": "9.63306"
    },
    {
        "country": "AT",
        "name": "Götzens",
        "lat": "47.23606",
        "lng": "11.31154"
    },
    {
        "country": "AT",
        "name": "Götzendorf an der Leitha",
        "lat": "48.01667",
        "lng": "16.58333"
    },
    {
        "country": "AT",
        "name": "Göttelsberg",
        "lat": "47.21667",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Göstling an der Ybbs",
        "lat": "47.80664",
        "lng": "14.93797"
    },
    {
        "country": "AT",
        "name": "Gösting",
        "lat": "47.09788",
        "lng": "15.39777"
    },
    {
        "country": "AT",
        "name": "Gössendorf",
        "lat": "46.99826",
        "lng": "15.48556"
    },
    {
        "country": "AT",
        "name": "Gossendorf",
        "lat": "46.91076",
        "lng": "15.93043"
    },
    {
        "country": "AT",
        "name": "Gössenberg",
        "lat": "47.41667",
        "lng": "13.81667"
    },
    {
        "country": "AT",
        "name": "Göss",
        "lat": "47.35871",
        "lng": "15.10015"
    },
    {
        "country": "AT",
        "name": "Gosdorf",
        "lat": "46.72778",
        "lng": "15.7925"
    },
    {
        "country": "AT",
        "name": "Gosau",
        "lat": "47.58417",
        "lng": "13.53447"
    },
    {
        "country": "AT",
        "name": "Göpfritz an der Wild",
        "lat": "48.72497",
        "lng": "15.40236"
    },
    {
        "country": "AT",
        "name": "Gols",
        "lat": "47.89689",
        "lng": "16.91113"
    },
    {
        "country": "AT",
        "name": "Golling an der Salzach",
        "lat": "47.6",
        "lng": "13.16667"
    },
    {
        "country": "AT",
        "name": "Göllersdorf",
        "lat": "48.49357",
        "lng": "16.11943"
    },
    {
        "country": "AT",
        "name": "Goldwörth",
        "lat": "48.32664",
        "lng": "14.10112"
    },
    {
        "country": "AT",
        "name": "Goldegg",
        "lat": "47.31875",
        "lng": "13.09922"
    },
    {
        "country": "AT",
        "name": "Going",
        "lat": "47.51332",
        "lng": "12.33164"
    },
    {
        "country": "AT",
        "name": "Göfis",
        "lat": "47.23356",
        "lng": "9.63458"
    },
    {
        "country": "AT",
        "name": "Gniebing",
        "lat": "46.96252",
        "lng": "15.85645"
    },
    {
        "country": "AT",
        "name": "Gnesau",
        "lat": "46.77544",
        "lng": "13.96251"
    },
    {
        "country": "AT",
        "name": "Gneixendorf",
        "lat": "48.43751",
        "lng": "15.6181"
    },
    {
        "country": "AT",
        "name": "Gnas",
        "lat": "46.87306",
        "lng": "15.82528"
    },
    {
        "country": "AT",
        "name": "Gnadendorf",
        "lat": "48.61667",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Gmunden",
        "lat": "47.91839",
        "lng": "13.79933"
    },
    {
        "country": "AT",
        "name": "Gmünd",
        "lat": "48.7683",
        "lng": "14.9808"
    },
    {
        "country": "AT",
        "name": "Gmünd",
        "lat": "46.90722",
        "lng": "13.52944"
    },
    {
        "country": "AT",
        "name": "Glojach",
        "lat": "46.86667",
        "lng": "15.66667"
    },
    {
        "country": "AT",
        "name": "Gloggnitz",
        "lat": "47.67487",
        "lng": "15.93893"
    },
    {
        "country": "AT",
        "name": "Glödnitz",
        "lat": "46.87398",
        "lng": "14.11915"
    },
    {
        "country": "AT",
        "name": "Globasnitz",
        "lat": "46.55694",
        "lng": "14.70278"
    },
    {
        "country": "AT",
        "name": "Glinzendorf",
        "lat": "48.24605",
        "lng": "16.6406"
    },
    {
        "country": "AT",
        "name": "Gleisdorf",
        "lat": "47.10559",
        "lng": "15.71011"
    },
    {
        "country": "AT",
        "name": "Gleinstätten",
        "lat": "46.75361",
        "lng": "15.36972"
    },
    {
        "country": "AT",
        "name": "Glasenbach",
        "lat": "47.76667",
        "lng": "13.08333"
    },
    {
        "country": "AT",
        "name": "Glanegg",
        "lat": "47.75",
        "lng": "13"
    },
    {
        "country": "AT",
        "name": "Glanegg",
        "lat": "46.72259",
        "lng": "14.19893"
    },
    {
        "country": "AT",
        "name": "Gießhübl",
        "lat": "48.0978",
        "lng": "16.23479"
    },
    {
        "country": "AT",
        "name": "Gföhl",
        "lat": "48.51667",
        "lng": "15.48333"
    },
    {
        "country": "AT",
        "name": "Gersdorf an der Feistritz",
        "lat": "47.16667",
        "lng": "15.85"
    },
    {
        "country": "AT",
        "name": "Gerolding",
        "lat": "48.25",
        "lng": "15.41667"
    },
    {
        "country": "AT",
        "name": "Gerersdorf",
        "lat": "48.20088",
        "lng": "15.55613"
    },
    {
        "country": "AT",
        "name": "Gerasdorf bei Wien",
        "lat": "48.29447",
        "lng": "16.46765"
    },
    {
        "country": "AT",
        "name": "Geras",
        "lat": "48.79725",
        "lng": "15.67268"
    },
    {
        "country": "AT",
        "name": "Georgenberg",
        "lat": "47.63333",
        "lng": "13.15"
    },
    {
        "country": "AT",
        "name": "Geistthal",
        "lat": "47.16667",
        "lng": "15.16667"
    },
    {
        "country": "AT",
        "name": "Gedersdorf",
        "lat": "48.43319",
        "lng": "15.68865"
    },
    {
        "country": "AT",
        "name": "Geboltskirchen",
        "lat": "48.15338",
        "lng": "13.6336"
    },
    {
        "country": "AT",
        "name": "Gaweinstal",
        "lat": "48.48003",
        "lng": "16.5879"
    },
    {
        "country": "AT",
        "name": "Gaubitsch",
        "lat": "48.65",
        "lng": "16.38333"
    },
    {
        "country": "AT",
        "name": "Gattendorf",
        "lat": "48.01667",
        "lng": "16.98333"
    },
    {
        "country": "AT",
        "name": "Gastern",
        "lat": "48.89446",
        "lng": "15.22027"
    },
    {
        "country": "AT",
        "name": "Gaspoltshofen",
        "lat": "48.14324",
        "lng": "13.73643"
    },
    {
        "country": "AT",
        "name": "Gasen",
        "lat": "47.38333",
        "lng": "15.56667"
    },
    {
        "country": "AT",
        "name": "Gaschurn",
        "lat": "46.98584",
        "lng": "10.02702"
    },
    {
        "country": "AT",
        "name": "Garsten",
        "lat": "48.0224",
        "lng": "14.40747"
    },
    {
        "country": "AT",
        "name": "Gars am Kamp",
        "lat": "48.58899",
        "lng": "15.65363"
    },
    {
        "country": "AT",
        "name": "Garanas",
        "lat": "46.76667",
        "lng": "15.11667"
    },
    {
        "country": "AT",
        "name": "Ganz",
        "lat": "47.6",
        "lng": "15.68333"
    },
    {
        "country": "AT",
        "name": "Gänserndorf",
        "lat": "48.33925",
        "lng": "16.72016"
    },
    {
        "country": "AT",
        "name": "Bad Gams",
        "lat": "46.87111",
        "lng": "15.22472"
    },
    {
        "country": "AT",
        "name": "Gams bei Hieflau",
        "lat": "47.66667",
        "lng": "14.78333"
    },
    {
        "country": "AT",
        "name": "Gampern",
        "lat": "47.98877",
        "lng": "13.5543"
    },
    {
        "country": "AT",
        "name": "Gamlitz",
        "lat": "46.72028",
        "lng": "15.55333"
    },
    {
        "country": "AT",
        "name": "Gaming",
        "lat": "47.929",
        "lng": "15.08818"
    },
    {
        "country": "AT",
        "name": "Galtür",
        "lat": "46.96667",
        "lng": "10.18333"
    },
    {
        "country": "AT",
        "name": "Gallzein",
        "lat": "47.36807",
        "lng": "11.77159"
    },
    {
        "country": "AT",
        "name": "Gallspach",
        "lat": "48.20992",
        "lng": "13.80981"
    },
    {
        "country": "AT",
        "name": "Gallneukirchen",
        "lat": "48.35363",
        "lng": "14.41604"
    },
    {
        "country": "AT",
        "name": "Gallmannsegg",
        "lat": "47.18333",
        "lng": "15.06667"
    },
    {
        "country": "AT",
        "name": "Gallizien",
        "lat": "46.55",
        "lng": "14.51667"
    },
    {
        "country": "AT",
        "name": "Gaißau",
        "lat": "47.46667",
        "lng": "9.6"
    },
    {
        "country": "AT",
        "name": "Gainfarn",
        "lat": "47.9628",
        "lng": "16.19368"
    },
    {
        "country": "AT",
        "name": "Gailitz",
        "lat": "46.56083",
        "lng": "13.70444"
    },
    {
        "country": "AT",
        "name": "Gaflenz",
        "lat": "47.89511",
        "lng": "14.72477"
    },
    {
        "country": "AT",
        "name": "Gablitz",
        "lat": "48.22856",
        "lng": "16.15437"
    },
    {
        "country": "AT",
        "name": "Gabersdorf",
        "lat": "46.77722",
        "lng": "15.58417"
    },
    {
        "country": "AT",
        "name": "Gaal",
        "lat": "47.27268",
        "lng": "14.67003"
    },
    {
        "country": "AT",
        "name": "Gaaden",
        "lat": "48.05364",
        "lng": "16.20003"
    },
    {
        "country": "AT",
        "name": "Fußach",
        "lat": "47.47933",
        "lng": "9.66278"
    },
    {
        "country": "AT",
        "name": "Fuschl am See",
        "lat": "47.8",
        "lng": "13.3"
    },
    {
        "country": "AT",
        "name": "Furth an der Triesting",
        "lat": "47.97326",
        "lng": "15.97326"
    },
    {
        "country": "AT",
        "name": "Furth bei Göttweig",
        "lat": "48.37385",
        "lng": "15.61408"
    },
    {
        "country": "AT",
        "name": "Fürstenfeld",
        "lat": "47.05",
        "lng": "16.08333"
    },
    {
        "country": "AT",
        "name": "Fürnitz",
        "lat": "46.56188",
        "lng": "13.81732"
    },
    {
        "country": "AT",
        "name": "Fulpmes",
        "lat": "47.15202",
        "lng": "11.34922"
    },
    {
        "country": "AT",
        "name": "Fügenberg",
        "lat": "47.3521",
        "lng": "11.84173"
    },
    {
        "country": "AT",
        "name": "Fügen",
        "lat": "47.347",
        "lng": "11.84939"
    },
    {
        "country": "AT",
        "name": "Frohsdorf",
        "lat": "47.73333",
        "lng": "16.25"
    },
    {
        "country": "AT",
        "name": "Frohnleiten",
        "lat": "47.26667",
        "lng": "15.31667"
    },
    {
        "country": "AT",
        "name": "Fritzens",
        "lat": "47.30535",
        "lng": "11.5895"
    },
    {
        "country": "AT",
        "name": "Friesach",
        "lat": "46.95528",
        "lng": "14.40583"
    },
    {
        "country": "AT",
        "name": "Friedberg",
        "lat": "47.43333",
        "lng": "16.05"
    },
    {
        "country": "AT",
        "name": "Fresach",
        "lat": "46.71556",
        "lng": "13.69083"
    },
    {
        "country": "AT",
        "name": "Freistadt",
        "lat": "48.51103",
        "lng": "14.50453"
    },
    {
        "country": "AT",
        "name": "Freindorf",
        "lat": "48.2257",
        "lng": "14.2951"
    },
    {
        "country": "AT",
        "name": "Freiland bei Deutschlandsberg",
        "lat": "46.83333",
        "lng": "15.13333"
    },
    {
        "country": "AT",
        "name": "Freidorf an der Laßnitz",
        "lat": "46.81667",
        "lng": "15.25"
    },
    {
        "country": "AT",
        "name": "Fraxern",
        "lat": "47.31498",
        "lng": "9.67391"
    },
    {
        "country": "AT",
        "name": "Frauenkirchen",
        "lat": "47.83678",
        "lng": "16.92581"
    },
    {
        "country": "AT",
        "name": "Frauenberg",
        "lat": "47.42855",
        "lng": "15.34206"
    },
    {
        "country": "AT",
        "name": "Frannach",
        "lat": "46.91306",
        "lng": "15.63361"
    },
    {
        "country": "AT",
        "name": "Frankenmarkt",
        "lat": "47.98333",
        "lng": "13.41667"
    },
    {
        "country": "AT",
        "name": "Frankenfels",
        "lat": "47.98234",
        "lng": "15.32593"
    },
    {
        "country": "AT",
        "name": "Frankenburg",
        "lat": "48.06844",
        "lng": "13.49065"
    },
    {
        "country": "AT",
        "name": "Forstau",
        "lat": "47.37842",
        "lng": "13.55558"
    },
    {
        "country": "AT",
        "name": "Fornach",
        "lat": "48.02277",
        "lng": "13.42941"
    },
    {
        "country": "AT",
        "name": "Forchtenstein",
        "lat": "47.71161",
        "lng": "16.34525"
    },
    {
        "country": "AT",
        "name": "Forchach",
        "lat": "47.41667",
        "lng": "10.58333"
    },
    {
        "country": "AT",
        "name": "Fohnsdorf",
        "lat": "47.2",
        "lng": "14.68333"
    },
    {
        "country": "AT",
        "name": "Floridsdorf",
        "lat": "48.25",
        "lng": "16.4"
    },
    {
        "country": "AT",
        "name": "Floing",
        "lat": "47.26356",
        "lng": "15.7465"
    },
    {
        "country": "AT",
        "name": "Flirsch",
        "lat": "47.15",
        "lng": "10.4"
    },
    {
        "country": "AT",
        "name": "Fliess",
        "lat": "47.11667",
        "lng": "10.61667"
    },
    {
        "country": "AT",
        "name": "Flaurling",
        "lat": "47.29143",
        "lng": "11.12319"
    },
    {
        "country": "AT",
        "name": "Flattach",
        "lat": "46.93861",
        "lng": "13.13444"
    },
    {
        "country": "AT",
        "name": "Flatschach",
        "lat": "47.21667",
        "lng": "14.75"
    },
    {
        "country": "AT",
        "name": "Fladnitz im Raabtal",
        "lat": "46.99167",
        "lng": "15.78528"
    },
    {
        "country": "AT",
        "name": "Flachau",
        "lat": "47.34406",
        "lng": "13.39148"
    },
    {
        "country": "AT",
        "name": "Fiss",
        "lat": "47.05714",
        "lng": "10.61747"
    },
    {
        "country": "AT",
        "name": "Fisching",
        "lat": "48.22301",
        "lng": "14.42771"
    },
    {
        "country": "AT",
        "name": "Fischbach",
        "lat": "47.44234",
        "lng": "15.64972"
    },
    {
        "country": "AT",
        "name": "Fischamend-Markt",
        "lat": "48.11667",
        "lng": "16.6"
    },
    {
        "country": "AT",
        "name": "Finkenstein",
        "lat": "46.56155",
        "lng": "13.87086"
    },
    {
        "country": "AT",
        "name": "Finkenberg",
        "lat": "47.15279",
        "lng": "11.82212"
    },
    {
        "country": "AT",
        "name": "Filzmoos",
        "lat": "47.43333",
        "lng": "13.51667"
    },
    {
        "country": "AT",
        "name": "Fiecht",
        "lat": "47.35",
        "lng": "11.7"
    },
    {
        "country": "AT",
        "name": "Fieberbrunn",
        "lat": "47.47626",
        "lng": "12.54347"
    },
    {
        "country": "AT",
        "name": "Ferschnitz",
        "lat": "48.09419",
        "lng": "14.98454"
    },
    {
        "country": "AT",
        "name": "Fernitz",
        "lat": "46.97389",
        "lng": "15.50111"
    },
    {
        "country": "AT",
        "name": "Ferndorf",
        "lat": "46.73651",
        "lng": "13.62661"
    },
    {
        "country": "AT",
        "name": "Ferlach",
        "lat": "46.52694",
        "lng": "14.30194"
    },
    {
        "country": "AT",
        "name": "Fendels",
        "lat": "47.0539",
        "lng": "10.67777"
    },
    {
        "country": "AT",
        "name": "Fels am Wagram",
        "lat": "48.43333",
        "lng": "15.81667"
    },
    {
        "country": "AT",
        "name": "Felixdorf",
        "lat": "47.88159",
        "lng": "16.24208"
    },
    {
        "country": "AT",
        "name": "Feldkirchen in Kärnten",
        "lat": "46.72368",
        "lng": "14.0958"
    },
    {
        "country": "AT",
        "name": "Feldkirchen bei Graz",
        "lat": "47.01667",
        "lng": "15.45"
    },
    {
        "country": "AT",
        "name": "Feldkirchen an der Donau",
        "lat": "48.34524",
        "lng": "14.05134"
    },
    {
        "country": "AT",
        "name": "Feldkirch",
        "lat": "47.23306",
        "lng": "9.6"
    },
    {
        "country": "AT",
        "name": "Feldbach",
        "lat": "46.95306",
        "lng": "15.88833"
    },
    {
        "country": "AT",
        "name": "Feld am See",
        "lat": "46.77639",
        "lng": "13.74778"
    },
    {
        "country": "AT",
        "name": "Feistritz ob Bleiburg",
        "lat": "46.55",
        "lng": "14.76667"
    },
    {
        "country": "AT",
        "name": "Feistritz im Rosental",
        "lat": "46.51667",
        "lng": "14.16667"
    },
    {
        "country": "AT",
        "name": "Feistritz bei Knittelfeld",
        "lat": "47.26802",
        "lng": "14.89334"
    },
    {
        "country": "AT",
        "name": "Feistritz an der Gail",
        "lat": "46.5775",
        "lng": "13.60667"
    },
    {
        "country": "AT",
        "name": "Feistritz an der Drau",
        "lat": "46.70157",
        "lng": "13.66479"
    },
    {
        "country": "AT",
        "name": "Feistritz am Wechsel",
        "lat": "47.6",
        "lng": "16.05"
    },
    {
        "country": "AT",
        "name": "Fehring",
        "lat": "46.94",
        "lng": "16.00806"
    },
    {
        "country": "AT",
        "name": "Farrach",
        "lat": "47.18333",
        "lng": "14.73333"
    },
    {
        "country": "AT",
        "name": "Fallbach",
        "lat": "48.65",
        "lng": "16.41667"
    },
    {
        "country": "AT",
        "name": "Falkenstein",
        "lat": "48.71667",
        "lng": "16.58333"
    },
    {
        "country": "AT",
        "name": "St. Ruprecht-Falkendorf",
        "lat": "47.1011",
        "lng": "14.01752"
    },
    {
        "country": "AT",
        "name": "Falkenburg",
        "lat": "47.5",
        "lng": "14.08333"
    },
    {
        "country": "AT",
        "name": "Faistenau",
        "lat": "47.77767",
        "lng": "13.23393"
    },
    {
        "country": "AT",
        "name": "Faak am See",
        "lat": "46.56806",
        "lng": "13.90972"
    },
    {
        "country": "AT",
        "name": "Euratsfeld",
        "lat": "48.08181",
        "lng": "14.93154"
    },
    {
        "country": "AT",
        "name": "Eugendorf",
        "lat": "47.86765",
        "lng": "13.12609"
    },
    {
        "country": "AT",
        "name": "Eschenau",
        "lat": "48.04825",
        "lng": "15.56694"
    },
    {
        "country": "AT",
        "name": "Erpfendorf",
        "lat": "47.58333",
        "lng": "12.46667"
    },
    {
        "country": "AT",
        "name": "Erpersdorf",
        "lat": "48.3421",
        "lng": "15.91747"
    },
    {
        "country": "AT",
        "name": "Ernsthofen",
        "lat": "48.12915",
        "lng": "14.48058"
    },
    {
        "country": "AT",
        "name": "Ernstbrunn",
        "lat": "48.53333",
        "lng": "16.35"
    },
    {
        "country": "AT",
        "name": "Erlauf",
        "lat": "48.18333",
        "lng": "15.18333"
    },
    {
        "country": "AT",
        "name": "Bad Erlach",
        "lat": "47.72722",
        "lng": "16.21444"
    },
    {
        "country": "AT",
        "name": "Erl",
        "lat": "47.68333",
        "lng": "12.18333"
    },
    {
        "country": "AT",
        "name": "Enzersfeld",
        "lat": "48.36338",
        "lng": "16.42387"
    },
    {
        "country": "AT",
        "name": "Enzersdorf an der Fischa",
        "lat": "48.08502",
        "lng": "16.60832"
    },
    {
        "country": "AT",
        "name": "Enzenreith",
        "lat": "47.66839",
        "lng": "15.95249"
    },
    {
        "country": "AT",
        "name": "Ennsdorf",
        "lat": "48.21181",
        "lng": "14.5029"
    },
    {
        "country": "AT",
        "name": "Enns",
        "lat": "48.21346",
        "lng": "14.47612"
    },
    {
        "country": "AT",
        "name": "Engerwitzdorf",
        "lat": "48.34507",
        "lng": "14.44204"
    },
    {
        "country": "AT",
        "name": "Engelhartstetten",
        "lat": "48.1816",
        "lng": "16.88367"
    },
    {
        "country": "AT",
        "name": "Endach",
        "lat": "47.56796",
        "lng": "12.15603"
    },
    {
        "country": "AT",
        "name": "Empersdorf",
        "lat": "46.99892",
        "lng": "15.59959"
    },
    {
        "country": "AT",
        "name": "Emmersdorf an der Donau",
        "lat": "48.2414",
        "lng": "15.33721"
    },
    {
        "country": "AT",
        "name": "Eltendorf",
        "lat": "47.0087",
        "lng": "16.20239"
    },
    {
        "country": "AT",
        "name": "Elsbethen",
        "lat": "47.76464",
        "lng": "13.08104"
    },
    {
        "country": "AT",
        "name": "Elmen",
        "lat": "47.34039",
        "lng": "10.54318"
    },
    {
        "country": "AT",
        "name": "Ellmau",
        "lat": "47.51378",
        "lng": "12.29937"
    },
    {
        "country": "AT",
        "name": "Elixhausen",
        "lat": "47.86667",
        "lng": "13.06667"
    },
    {
        "country": "AT",
        "name": "Elbigenalp",
        "lat": "47.29041",
        "lng": "10.43607"
    },
    {
        "country": "AT",
        "name": "Eisgarn",
        "lat": "48.91675",
        "lng": "15.1032"
    },
    {
        "country": "AT",
        "name": "Eisenstadt",
        "lat": "47.84565",
        "lng": "16.52327"
    },
    {
        "country": "AT",
        "name": "Eisenerz",
        "lat": "47.53333",
        "lng": "14.88333"
    },
    {
        "country": "AT",
        "name": "Eisbach",
        "lat": "47.11667",
        "lng": "15.26667"
    },
    {
        "country": "AT",
        "name": "Eidenberg",
        "lat": "48.39439",
        "lng": "14.23313"
    },
    {
        "country": "AT",
        "name": "Eichgraben",
        "lat": "48.17204",
        "lng": "15.98391"
    },
    {
        "country": "AT",
        "name": "Eichfeld",
        "lat": "46.72528",
        "lng": "15.76806"
    },
    {
        "country": "AT",
        "name": "Eichenberg",
        "lat": "47.53333",
        "lng": "9.78333"
    },
    {
        "country": "AT",
        "name": "Eichberg",
        "lat": "47.38188",
        "lng": "15.97356"
    },
    {
        "country": "AT",
        "name": "Eibiswald",
        "lat": "46.68667",
        "lng": "15.24722"
    },
    {
        "country": "AT",
        "name": "Ehrenhausen",
        "lat": "46.72417",
        "lng": "15.58667"
    },
    {
        "country": "AT",
        "name": "Ehenbichl",
        "lat": "47.46667",
        "lng": "10.7"
    },
    {
        "country": "AT",
        "name": "Eggersdorf bei Graz",
        "lat": "47.12338",
        "lng": "15.60084"
    },
    {
        "country": "AT",
        "name": "Eggern",
        "lat": "48.90829",
        "lng": "15.14877"
    },
    {
        "country": "AT",
        "name": "Eggenburg",
        "lat": "48.63892",
        "lng": "15.81903"
    },
    {
        "country": "AT",
        "name": "Eggenberg",
        "lat": "47.07",
        "lng": "15.39871"
    },
    {
        "country": "AT",
        "name": "Egg",
        "lat": "47.43154",
        "lng": "9.89762"
    },
    {
        "country": "AT",
        "name": "Eferding",
        "lat": "48.30866",
        "lng": "14.02233"
    },
    {
        "country": "AT",
        "name": "Edt bei Lambach",
        "lat": "48.11667",
        "lng": "13.88333"
    },
    {
        "country": "AT",
        "name": "Edlitz",
        "lat": "47.59797",
        "lng": "16.14052"
    },
    {
        "country": "AT",
        "name": "Edelstal",
        "lat": "48.1",
        "lng": "16.98333"
    },
    {
        "country": "AT",
        "name": "Edelsgrub",
        "lat": "47.03333",
        "lng": "15.6"
    },
    {
        "country": "AT",
        "name": "Edelschrott",
        "lat": "47.02156",
        "lng": "15.05266"
    },
    {
        "country": "AT",
        "name": "Edelsbach bei Feldbach",
        "lat": "46.98944",
        "lng": "15.83694"
    },
    {
        "country": "AT",
        "name": "Eckartsau",
        "lat": "48.14507",
        "lng": "16.79737"
    },
    {
        "country": "AT",
        "name": "Echsenbach",
        "lat": "48.71667",
        "lng": "15.21667"
    },
    {
        "country": "AT",
        "name": "Ebreichsdorf",
        "lat": "47.95579",
        "lng": "16.40705"
    },
    {
        "country": "AT",
        "name": "Eberstein",
        "lat": "46.80806",
        "lng": "14.56"
    },
    {
        "country": "AT",
        "name": "Eberstalzell",
        "lat": "48.04392",
        "lng": "13.98319"
    },
    {
        "country": "AT",
        "name": "Ebersdorf",
        "lat": "47.19852",
        "lng": "15.96219"
    },
    {
        "country": "AT",
        "name": "Eberschwang",
        "lat": "48.15504",
        "lng": "13.56194"
    },
    {
        "country": "AT",
        "name": "Eberndorf",
        "lat": "46.59139",
        "lng": "14.64361"
    },
    {
        "country": "AT",
        "name": "Ebergassing",
        "lat": "48.04567",
        "lng": "16.51709"
    },
    {
        "country": "AT",
        "name": "Eberau",
        "lat": "47.10764",
        "lng": "16.46044"
    },
    {
        "country": "AT",
        "name": "Ebenthal",
        "lat": "48.43333",
        "lng": "16.78333"
    },
    {
        "country": "AT",
        "name": "Ebenthal",
        "lat": "46.60806",
        "lng": "14.36417"
    },
    {
        "country": "AT",
        "name": "Ebensee",
        "lat": "47.80716",
        "lng": "13.779"
    },
    {
        "country": "AT",
        "name": "Eben im Pongau",
        "lat": "47.4",
        "lng": "13.4"
    },
    {
        "country": "AT",
        "name": "Ebenfurth",
        "lat": "47.87738",
        "lng": "16.36731"
    },
    {
        "country": "AT",
        "name": "Ebenau",
        "lat": "47.79073",
        "lng": "13.17527"
    },
    {
        "country": "AT",
        "name": "Eben am Achensee",
        "lat": "47.4138",
        "lng": "11.76138"
    },
    {
        "country": "AT",
        "name": "Ebbs",
        "lat": "47.63333",
        "lng": "12.21667"
    },
    {
        "country": "AT",
        "name": "Dürnstein in der Steiermark",
        "lat": "46.98833",
        "lng": "14.39167"
    },
    {
        "country": "AT",
        "name": "Dürnstein",
        "lat": "48.39582",
        "lng": "15.51973"
    },
    {
        "country": "AT",
        "name": "Dürnkrut",
        "lat": "48.47315",
        "lng": "16.85062"
    },
    {
        "country": "AT",
        "name": "Düns",
        "lat": "47.22353",
        "lng": "9.71663"
    },
    {
        "country": "AT",
        "name": "Droß",
        "lat": "48.46393",
        "lng": "15.57561"
    },
    {
        "country": "AT",
        "name": "Drösing",
        "lat": "48.539",
        "lng": "16.90264"
    },
    {
        "country": "AT",
        "name": "Drassburg",
        "lat": "47.74645",
        "lng": "16.48679"
    },
    {
        "country": "AT",
        "name": "Drasenhofen",
        "lat": "48.75",
        "lng": "16.65"
    },
    {
        "country": "AT",
        "name": "Dornbirn",
        "lat": "47.41427",
        "lng": "9.74195"
    },
    {
        "country": "AT",
        "name": "Dorfstetten",
        "lat": "48.32573",
        "lng": "14.98218"
    },
    {
        "country": "AT",
        "name": "Dorfgastein",
        "lat": "47.24172",
        "lng": "13.10223"
    },
    {
        "country": "AT",
        "name": "Dorfbeuern",
        "lat": "48.01667",
        "lng": "13.01667"
    },
    {
        "country": "AT",
        "name": "Doren",
        "lat": "47.49278",
        "lng": "9.87972"
    },
    {
        "country": "AT",
        "name": "Doppl",
        "lat": "48.24811",
        "lng": "14.25416"
    },
    {
        "country": "AT",
        "name": "Donnerskirchen",
        "lat": "47.89425",
        "lng": "16.64635"
    },
    {
        "country": "AT",
        "name": "Donnersbach",
        "lat": "47.46139",
        "lng": "14.12972"
    },
    {
        "country": "AT",
        "name": "Donawitz",
        "lat": "47.36667",
        "lng": "15.06667"
    },
    {
        "country": "AT",
        "name": "Dölsach",
        "lat": "46.82833",
        "lng": "12.84528"
    },
    {
        "country": "AT",
        "name": "Döbriach",
        "lat": "46.78049",
        "lng": "13.65738"
    },
    {
        "country": "AT",
        "name": "Dobersberg",
        "lat": "48.9151",
        "lng": "15.32188"
    },
    {
        "country": "AT",
        "name": "Diex",
        "lat": "46.75",
        "lng": "14.6"
    },
    {
        "country": "AT",
        "name": "Dietersdorf am Gnasbach",
        "lat": "46.80917",
        "lng": "15.81167"
    },
    {
        "country": "AT",
        "name": "Dietersdorf",
        "lat": "47.2",
        "lng": "14.66667"
    },
    {
        "country": "AT",
        "name": "Dietachdorf",
        "lat": "48.08364",
        "lng": "14.42874"
    },
    {
        "country": "AT",
        "name": "Dietach",
        "lat": "48.09018",
        "lng": "14.41647"
    },
    {
        "country": "AT",
        "name": "Dienten am Hochkönig",
        "lat": "47.38324",
        "lng": "13.00369"
    },
    {
        "country": "AT",
        "name": "Dienersdorf",
        "lat": "47.23979",
        "lng": "15.90142"
    },
    {
        "country": "AT",
        "name": "Diemlach",
        "lat": "47.4361",
        "lng": "15.2746"
    },
    {
        "country": "AT",
        "name": "Deutsch-Wagram",
        "lat": "48.29972",
        "lng": "16.56667"
    },
    {
        "country": "AT",
        "name": "Deutschlandsberg",
        "lat": "46.81528",
        "lng": "15.22222"
    },
    {
        "country": "AT",
        "name": "Deutschkreutz",
        "lat": "47.6",
        "lng": "16.63333"
    },
    {
        "country": "AT",
        "name": "Deutsch Kaltenbrunn",
        "lat": "47.09455",
        "lng": "16.13145"
    },
    {
        "country": "AT",
        "name": "Deutsch Jahrndorf",
        "lat": "48.01667",
        "lng": "17.1"
    },
    {
        "country": "AT",
        "name": "Deutsch Griffen",
        "lat": "46.85606",
        "lng": "14.07501"
    },
    {
        "country": "AT",
        "name": "Deutsch Goritz",
        "lat": "46.75083",
        "lng": "15.82944"
    },
    {
        "country": "AT",
        "name": "Deutschfeistritz",
        "lat": "47.19852",
        "lng": "15.33623"
    },
    {
        "country": "AT",
        "name": "Deuchendorf",
        "lat": "47.46593",
        "lng": "15.32172"
    },
    {
        "country": "AT",
        "name": "Desselbrunn",
        "lat": "48.02145",
        "lng": "13.77027"
    },
    {
        "country": "AT",
        "name": "Dellach im Drautal",
        "lat": "46.7375",
        "lng": "13.07833"
    },
    {
        "country": "AT",
        "name": "Dellach",
        "lat": "46.66175",
        "lng": "13.07965"
    },
    {
        "country": "AT",
        "name": "Dechantskirchen",
        "lat": "47.41667",
        "lng": "16.01667"
    },
    {
        "country": "AT",
        "name": "Debant",
        "lat": "46.83333",
        "lng": "12.81667"
    },
    {
        "country": "AT",
        "name": "Dalaas",
        "lat": "47.12446",
        "lng": "9.99104"
    },
    {
        "country": "AT",
        "name": "Bürs",
        "lat": "47.14972",
        "lng": "9.8"
    },
    {
        "country": "AT",
        "name": "Bürmoos",
        "lat": "47.98343",
        "lng": "12.91786"
    },
    {
        "country": "AT",
        "name": "Burgfried",
        "lat": "48.5199",
        "lng": "14.2957"
    },
    {
        "country": "AT",
        "name": "Burgfried",
        "lat": "47.68004",
        "lng": "13.11175"
    },
    {
        "country": "AT",
        "name": "Burgau",
        "lat": "47.14268",
        "lng": "16.09643"
    },
    {
        "country": "AT",
        "name": "Buchkirchen",
        "lat": "48.22427",
        "lng": "14.02242"
    },
    {
        "country": "AT",
        "name": "Buch bei Jenbach",
        "lat": "47.37447",
        "lng": "11.754"
    },
    {
        "country": "AT",
        "name": "Buchbach",
        "lat": "47.69648",
        "lng": "15.98425"
    },
    {
        "country": "AT",
        "name": "Brunn an der Wild",
        "lat": "48.69425",
        "lng": "15.52008"
    },
    {
        "country": "AT",
        "name": "Brunn an der Schneebergbahn",
        "lat": "47.82376",
        "lng": "16.15934"
    },
    {
        "country": "AT",
        "name": "Brunn am Gebirge",
        "lat": "48.10697",
        "lng": "16.28466"
    },
    {
        "country": "AT",
        "name": "Brunn",
        "lat": "46.72944",
        "lng": "15.3"
    },
    {
        "country": "AT",
        "name": "Bruckneudorf",
        "lat": "48.01667",
        "lng": "16.78333"
    },
    {
        "country": "AT",
        "name": "Brückl",
        "lat": "46.75167",
        "lng": "14.53667"
    },
    {
        "country": "AT",
        "name": "Bruck an der Mur",
        "lat": "47.41667",
        "lng": "15.28333"
    },
    {
        "country": "AT",
        "name": "Bruck an der Leitha",
        "lat": "48.01667",
        "lng": "16.76667"
    },
    {
        "country": "AT",
        "name": "Bruck an der Großglocknerstraße",
        "lat": "47.28494",
        "lng": "12.8231"
    },
    {
        "country": "AT",
        "name": "Bruck am Ziller",
        "lat": "47.38998",
        "lng": "11.85124"
    },
    {
        "country": "AT",
        "name": "Bromberg",
        "lat": "47.66539",
        "lng": "16.2099"
    },
    {
        "country": "AT",
        "name": "Brixlegg",
        "lat": "47.42942",
        "lng": "11.87794"
    },
    {
        "country": "AT",
        "name": "Brixen im Thale",
        "lat": "47.45",
        "lng": "12.25"
    },
    {
        "country": "AT",
        "name": "Bretstein",
        "lat": "47.33333",
        "lng": "14.41667"
    },
    {
        "country": "AT",
        "name": "Breitenwang",
        "lat": "47.48713",
        "lng": "10.7342"
    },
    {
        "country": "AT",
        "name": "Breitenfurt bei Wien",
        "lat": "48.13333",
        "lng": "16.15"
    },
    {
        "country": "AT",
        "name": "Breitenfeld an der Rittschein",
        "lat": "47.03333",
        "lng": "15.95"
    },
    {
        "country": "AT",
        "name": "Breitenfeld am Tannenriegel",
        "lat": "46.85",
        "lng": "15.63333"
    },
    {
        "country": "AT",
        "name": "Breitenbrunn",
        "lat": "47.94452",
        "lng": "16.73149"
    },
    {
        "country": "AT",
        "name": "Breitenbach am Inn",
        "lat": "47.47829",
        "lng": "11.97372"
    },
    {
        "country": "AT",
        "name": "Breitenau",
        "lat": "47.73355",
        "lng": "16.14338"
    },
    {
        "country": "AT",
        "name": "Bregenz",
        "lat": "47.50311",
        "lng": "9.7471"
    },
    {
        "country": "AT",
        "name": "Braunau am Inn",
        "lat": "48.25628",
        "lng": "13.04343"
    },
    {
        "country": "AT",
        "name": "Brandenberg",
        "lat": "47.49053",
        "lng": "11.89459"
    },
    {
        "country": "AT",
        "name": "Bramberg am Wildkogel",
        "lat": "47.26979",
        "lng": "12.3385"
    },
    {
        "country": "AT",
        "name": "Böheimkirchen",
        "lat": "48.19779",
        "lng": "15.76178"
    },
    {
        "country": "AT",
        "name": "Bodensdorf",
        "lat": "46.69111",
        "lng": "13.97111"
    },
    {
        "country": "AT",
        "name": "Bocksdorf",
        "lat": "47.14256",
        "lng": "16.17784"
    },
    {
        "country": "AT",
        "name": "Bockfließ",
        "lat": "48.36",
        "lng": "16.60389"
    },
    {
        "country": "AT",
        "name": "Bad Blumau",
        "lat": "47.11667",
        "lng": "16.05"
    },
    {
        "country": "AT",
        "name": "Bludesch",
        "lat": "47.2",
        "lng": "9.73306"
    },
    {
        "country": "AT",
        "name": "Bludenz",
        "lat": "47.15476",
        "lng": "9.82255"
    },
    {
        "country": "AT",
        "name": "Blons",
        "lat": "47.22327",
        "lng": "9.83414"
    },
    {
        "country": "AT",
        "name": "Blindenmarkt",
        "lat": "48.12749",
        "lng": "14.98647"
    },
    {
        "country": "AT",
        "name": "Bleiburg/Pliberk",
        "lat": "46.59",
        "lng": "14.79889"
    },
    {
        "country": "AT",
        "name": "Blaindorf",
        "lat": "47.17498",
        "lng": "15.86795"
    },
    {
        "country": "AT",
        "name": "Bizau",
        "lat": "47.36906",
        "lng": "9.92839"
    },
    {
        "country": "AT",
        "name": "Bischofstetten",
        "lat": "48.12222",
        "lng": "15.46909"
    },
    {
        "country": "AT",
        "name": "Bischofshofen",
        "lat": "47.41667",
        "lng": "13.21667"
    },
    {
        "country": "AT",
        "name": "Bisamberg",
        "lat": "48.33333",
        "lng": "16.35"
    },
    {
        "country": "AT",
        "name": "Birkfeld",
        "lat": "47.35",
        "lng": "15.68333"
    },
    {
        "country": "AT",
        "name": "Birgitz",
        "lat": "47.23536",
        "lng": "11.29922"
    },
    {
        "country": "AT",
        "name": "Bildstein",
        "lat": "47.45",
        "lng": "9.76667"
    },
    {
        "country": "AT",
        "name": "Bierbaum am Auersbach",
        "lat": "46.82949",
        "lng": "15.79237"
    },
    {
        "country": "AT",
        "name": "Biedermannsdorf",
        "lat": "48.08393",
        "lng": "16.34542"
    },
    {
        "country": "AT",
        "name": "Bichlbach",
        "lat": "47.42033",
        "lng": "10.79042"
    },
    {
        "country": "AT",
        "name": "Biberwier",
        "lat": "47.38333",
        "lng": "10.9"
    },
    {
        "country": "AT",
        "name": "Biberbach",
        "lat": "48.03006",
        "lng": "14.70795"
    },
    {
        "country": "AT",
        "name": "Bezau",
        "lat": "47.38478",
        "lng": "9.90139"
    },
    {
        "country": "AT",
        "name": "Berwang",
        "lat": "47.40807",
        "lng": "10.74735"
    },
    {
        "country": "AT",
        "name": "Bernstein",
        "lat": "47.4",
        "lng": "16.25"
    },
    {
        "country": "AT",
        "name": "Bernhardsthal",
        "lat": "48.69158",
        "lng": "16.86951"
    },
    {
        "country": "AT",
        "name": "Berndorf bei Salzburg",
        "lat": "47.99532",
        "lng": "13.06146"
    },
    {
        "country": "AT",
        "name": "Berndorf",
        "lat": "47.94567",
        "lng": "16.10973"
    },
    {
        "country": "AT",
        "name": "Berndorf",
        "lat": "47.41667",
        "lng": "15.26667"
    },
    {
        "country": "AT",
        "name": "Bergheim",
        "lat": "47.83333",
        "lng": "13.03333"
    },
    {
        "country": "AT",
        "name": "Bergham",
        "lat": "48.28182",
        "lng": "14.23141"
    },
    {
        "country": "AT",
        "name": "Berg",
        "lat": "48.10147",
        "lng": "17.03842"
    },
    {
        "country": "AT",
        "name": "Behamberg",
        "lat": "48.01667",
        "lng": "14.48333"
    },
    {
        "country": "AT",
        "name": "Baumkirchen",
        "lat": "47.3",
        "lng": "11.56667"
    },
    {
        "country": "AT",
        "name": "Baumgarten bei Gnas",
        "lat": "46.91667",
        "lng": "15.76667"
    },
    {
        "country": "AT",
        "name": "Batschuns",
        "lat": "47.28306",
        "lng": "9.65"
    },
    {
        "country": "AT",
        "name": "Barwies",
        "lat": "47.3",
        "lng": "10.96667"
    },
    {
        "country": "AT",
        "name": "Bartholomäberg",
        "lat": "47.08333",
        "lng": "9.9"
    },
    {
        "country": "AT",
        "name": "Bärnkopf",
        "lat": "48.39046",
        "lng": "15.00479"
    },
    {
        "country": "AT",
        "name": "Baldramsdorf",
        "lat": "46.80111",
        "lng": "13.45333"
    },
    {
        "country": "AT",
        "name": "Bairisch Kölldorf",
        "lat": "46.86667",
        "lng": "15.93333"
    },
    {
        "country": "AT",
        "name": "Wimsbach",
        "lat": "48.06667",
        "lng": "13.9"
    },
    {
        "country": "AT",
        "name": "Bad Vöslau",
        "lat": "47.96533",
        "lng": "16.21359"
    },
    {
        "country": "AT",
        "name": "Bad Tatzmannsdorf",
        "lat": "47.33126",
        "lng": "16.23067"
    },
    {
        "country": "AT",
        "name": "Bad Schönau",
        "lat": "47.49473",
        "lng": "16.2341"
    },
    {
        "country": "AT",
        "name": "Bad Schallerbach",
        "lat": "48.22999",
        "lng": "13.91925"
    },
    {
        "country": "AT",
        "name": "Bad Sankt Leonhard im Lavanttal",
        "lat": "46.96278",
        "lng": "14.79167"
    },
    {
        "country": "AT",
        "name": "Bad Ischl",
        "lat": "47.71109",
        "lng": "13.61893"
    },
    {
        "country": "AT",
        "name": "Bad Hofgastein",
        "lat": "47.17274",
        "lng": "13.09871"
    },
    {
        "country": "AT",
        "name": "Bad Hall",
        "lat": "48.03832",
        "lng": "14.20773"
    },
    {
        "country": "AT",
        "name": "Bad Goisern",
        "lat": "47.64252",
        "lng": "13.61609"
    },
    {
        "country": "AT",
        "name": "Bad Gleichenberg",
        "lat": "46.87556",
        "lng": "15.90861"
    },
    {
        "country": "AT",
        "name": "Bad Gastein",
        "lat": "47.11547",
        "lng": "13.13467"
    },
    {
        "country": "AT",
        "name": "Bad Fischau",
        "lat": "47.83137",
        "lng": "16.16707"
    },
    {
        "country": "AT",
        "name": "Badersdorf",
        "lat": "47.2",
        "lng": "16.36667"
    },
    {
        "country": "AT",
        "name": "Baden",
        "lat": "48.00543",
        "lng": "16.23264"
    },
    {
        "country": "AT",
        "name": "Bad Deutsch-Altenburg",
        "lat": "48.13425",
        "lng": "16.90624"
    },
    {
        "country": "AT",
        "name": "Bad Aussee",
        "lat": "47.60998",
        "lng": "13.78243"
    },
    {
        "country": "AT",
        "name": "Bachmanning",
        "lat": "48.13104",
        "lng": "13.79436"
    },
    {
        "country": "AT",
        "name": "Axams",
        "lat": "47.23111",
        "lng": "11.27892"
    },
    {
        "country": "AT",
        "name": "Außervillgraten",
        "lat": "46.7875",
        "lng": "12.43139"
    },
    {
        "country": "AT",
        "name": "Ausserbraz",
        "lat": "47.14875",
        "lng": "9.90066"
    },
    {
        "country": "AT",
        "name": "Aurolzmünster",
        "lat": "48.24834",
        "lng": "13.45533"
    },
    {
        "country": "AT",
        "name": "Aurach am Hongar",
        "lat": "47.95182",
        "lng": "13.67291"
    },
    {
        "country": "AT",
        "name": "Auersthal",
        "lat": "48.37373",
        "lng": "16.63596"
    },
    {
        "country": "AT",
        "name": "Auersbach",
        "lat": "47.01667",
        "lng": "15.86667"
    },
    {
        "country": "AT",
        "name": "Audorf",
        "lat": "48.2181",
        "lng": "14.27931"
    },
    {
        "country": "AT",
        "name": "Au an der Traun",
        "lat": "48.1802",
        "lng": "14.11248"
    },
    {
        "country": "AT",
        "name": "Au am Leithaberge",
        "lat": "47.92296",
        "lng": "16.55794"
    },
    {
        "country": "AT",
        "name": "Au",
        "lat": "47.7",
        "lng": "13.06667"
    },
    {
        "country": "AT",
        "name": "Au",
        "lat": "47.32176",
        "lng": "9.98067"
    },
    {
        "country": "AT",
        "name": "Atzesberg",
        "lat": "48.53959",
        "lng": "13.86936"
    },
    {
        "country": "AT",
        "name": "Atzenbrugg",
        "lat": "48.29119",
        "lng": "15.90614"
    },
    {
        "country": "AT",
        "name": "Atzbach",
        "lat": "48.08364",
        "lng": "13.70347"
    },
    {
        "country": "AT",
        "name": "Attnang",
        "lat": "48.01667",
        "lng": "13.71667"
    },
    {
        "country": "AT",
        "name": "Attersee",
        "lat": "47.91646",
        "lng": "13.53953"
    },
    {
        "country": "AT",
        "name": "Attendorf",
        "lat": "47.00426",
        "lng": "15.33897"
    },
    {
        "country": "AT",
        "name": "Asten",
        "lat": "48.21941",
        "lng": "14.41784"
    },
    {
        "country": "AT",
        "name": "Asperhofen",
        "lat": "48.24583",
        "lng": "15.92606"
    },
    {
        "country": "AT",
        "name": "Asparn an der Zaya",
        "lat": "48.58333",
        "lng": "16.5"
    },
    {
        "country": "AT",
        "name": "Aspang Markt",
        "lat": "47.55",
        "lng": "16.08333"
    },
    {
        "country": "AT",
        "name": "Aschbach Markt",
        "lat": "48.07234",
        "lng": "14.75395"
    },
    {
        "country": "AT",
        "name": "Aschau",
        "lat": "47.26667",
        "lng": "11.9"
    },
    {
        "country": "AT",
        "name": "Aschach an der Steyr",
        "lat": "48.01312",
        "lng": "14.33544"
    },
    {
        "country": "AT",
        "name": "Aschach an der Donau",
        "lat": "48.36458",
        "lng": "14.02044"
    },
    {
        "country": "AT",
        "name": "Arzl im Pitztal",
        "lat": "47.20712",
        "lng": "10.76261"
    },
    {
        "country": "AT",
        "name": "Arzl",
        "lat": "47.28333",
        "lng": "11.43333"
    },
    {
        "country": "AT",
        "name": "Arzberg",
        "lat": "47.25",
        "lng": "15.51667"
    },
    {
        "country": "AT",
        "name": "Arriach",
        "lat": "46.72921",
        "lng": "13.85046"
    },
    {
        "country": "AT",
        "name": "Arnreit",
        "lat": "48.52513",
        "lng": "13.99487"
    },
    {
        "country": "AT",
        "name": "Arnoldstein",
        "lat": "46.54611",
        "lng": "13.71"
    },
    {
        "country": "AT",
        "name": "Arnfels",
        "lat": "46.67639",
        "lng": "15.40306"
    },
    {
        "country": "AT",
        "name": "Ardning",
        "lat": "47.5912",
        "lng": "14.36372"
    },
    {
        "country": "AT",
        "name": "Ardagger Stift",
        "lat": "48.15",
        "lng": "14.83333"
    },
    {
        "country": "AT",
        "name": "Arbesbach",
        "lat": "48.49337",
        "lng": "14.95308"
    },
    {
        "country": "AT",
        "name": "Apfelberg",
        "lat": "47.2",
        "lng": "14.83333"
    },
    {
        "country": "AT",
        "name": "Apetlon",
        "lat": "47.74394",
        "lng": "16.8302"
    },
    {
        "country": "AT",
        "name": "Anthering",
        "lat": "47.88333",
        "lng": "13.01667"
    },
    {
        "country": "AT",
        "name": "Antau",
        "lat": "47.77377",
        "lng": "16.47984"
    },
    {
        "country": "AT",
        "name": "Ansfelden",
        "lat": "48.20969",
        "lng": "14.29004"
    },
    {
        "country": "AT",
        "name": "Anras",
        "lat": "46.77389",
        "lng": "12.56083"
    },
    {
        "country": "AT",
        "name": "Annabichl",
        "lat": "46.65",
        "lng": "14.31667"
    },
    {
        "country": "AT",
        "name": "Annaberg",
        "lat": "47.87134",
        "lng": "15.37648"
    },
    {
        "country": "AT",
        "name": "Anif",
        "lat": "47.75",
        "lng": "13.06667"
    },
    {
        "country": "AT",
        "name": "Angern an der March",
        "lat": "48.37778",
        "lng": "16.82806"
    },
    {
        "country": "AT",
        "name": "Anger",
        "lat": "47.27422",
        "lng": "15.69139"
    },
    {
        "country": "AT",
        "name": "Angath",
        "lat": "47.50758",
        "lng": "12.06513"
    },
    {
        "country": "AT",
        "name": "Andorf",
        "lat": "48.3713",
        "lng": "13.57412"
    },
    {
        "country": "AT",
        "name": "Andlersdorf",
        "lat": "48.18333",
        "lng": "16.66667"
    },
    {
        "country": "AT",
        "name": "Andelsbuch",
        "lat": "47.41167",
        "lng": "9.89326"
    },
    {
        "country": "AT",
        "name": "Andau",
        "lat": "47.77441",
        "lng": "17.03293"
    },
    {
        "country": "AT",
        "name": "Amstetten",
        "lat": "48.1229",
        "lng": "14.87206"
    },
    {
        "country": "AT",
        "name": "Amras",
        "lat": "47.25",
        "lng": "11.41667"
    },
    {
        "country": "AT",
        "name": "Ampflwang",
        "lat": "48.08333",
        "lng": "13.56667"
    },
    {
        "country": "AT",
        "name": "Ampass",
        "lat": "47.26251",
        "lng": "11.46226"
    },
    {
        "country": "AT",
        "name": "Amlach",
        "lat": "46.81639",
        "lng": "12.76361"
    },
    {
        "country": "AT",
        "name": "Altmünster",
        "lat": "47.90219",
        "lng": "13.76415"
    },
    {
        "country": "AT",
        "name": "Altmelon",
        "lat": "48.46222",
        "lng": "14.96552"
    },
    {
        "country": "AT",
        "name": "Altlichtenwarth",
        "lat": "48.64442",
        "lng": "16.79664"
    },
    {
        "country": "AT",
        "name": "Altlichtenberg",
        "lat": "48.36001",
        "lng": "14.25845"
    },
    {
        "country": "AT",
        "name": "Altlengbach",
        "lat": "48.15355",
        "lng": "15.92606"
    },
    {
        "country": "AT",
        "name": "Althofen",
        "lat": "46.87298",
        "lng": "14.47449"
    },
    {
        "country": "AT",
        "name": "Altheim",
        "lat": "48.25154",
        "lng": "13.23406"
    },
    {
        "country": "AT",
        "name": "Altenmarkt im Pongau",
        "lat": "47.38333",
        "lng": "13.41667"
    },
    {
        "country": "AT",
        "name": "Altenmarkt bei Sankt Gallen",
        "lat": "47.72327",
        "lng": "14.64838"
    },
    {
        "country": "AT",
        "name": "Altenmarkt bei Fürstenfeld",
        "lat": "47.06638",
        "lng": "16.04785"
    },
    {
        "country": "AT",
        "name": "Altenmarkt an der Triesting",
        "lat": "48.01553",
        "lng": "15.99661"
    },
    {
        "country": "AT",
        "name": "Altenfelden",
        "lat": "48.48555",
        "lng": "13.9698"
    },
    {
        "country": "AT",
        "name": "Altendorf",
        "lat": "47.65",
        "lng": "16.01667"
    },
    {
        "country": "AT",
        "name": "Altenburg",
        "lat": "48.64765",
        "lng": "15.59295"
    },
    {
        "country": "AT",
        "name": "Altenberg bei Linz",
        "lat": "48.37284",
        "lng": "14.35029"
    },
    {
        "country": "AT",
        "name": "Altaussee",
        "lat": "47.63844",
        "lng": "13.76278"
    },
    {
        "country": "AT",
        "name": "Altach",
        "lat": "47.35",
        "lng": "9.65"
    },
    {
        "country": "AT",
        "name": "Allhartsberg",
        "lat": "48.02633",
        "lng": "14.79009"
    },
    {
        "country": "AT",
        "name": "Allhaming",
        "lat": "48.15246",
        "lng": "14.17022"
    },
    {
        "country": "AT",
        "name": "Allerheiligen im Mürztal",
        "lat": "47.46667",
        "lng": "15.4"
    },
    {
        "country": "AT",
        "name": "Allerheiligen im Mühlkreis",
        "lat": "48.30215",
        "lng": "14.65061"
    },
    {
        "country": "AT",
        "name": "Allerheiligen bei Wildon",
        "lat": "46.91417",
        "lng": "15.55444"
    },
    {
        "country": "AT",
        "name": "Allentsteig",
        "lat": "48.69725",
        "lng": "15.32756"
    },
    {
        "country": "AT",
        "name": "Alland",
        "lat": "48.05829",
        "lng": "16.07901"
    },
    {
        "country": "AT",
        "name": "Alkoven",
        "lat": "48.28753",
        "lng": "14.10748"
    },
    {
        "country": "AT",
        "name": "Aldrans",
        "lat": "47.25",
        "lng": "11.45"
    },
    {
        "country": "AT",
        "name": "Albersdorf",
        "lat": "47.12166",
        "lng": "15.69826"
    },
    {
        "country": "AT",
        "name": "Alberndorf in der Riedmark",
        "lat": "48.40607",
        "lng": "14.41441"
    },
    {
        "country": "AT",
        "name": "Aistersheim",
        "lat": "48.1868",
        "lng": "13.74175"
    },
    {
        "country": "AT",
        "name": "Ainet",
        "lat": "46.86603",
        "lng": "12.68968"
    },
    {
        "country": "AT",
        "name": "Aigen im Mühlkreis",
        "lat": "48.64578",
        "lng": "13.97109"
    },
    {
        "country": "AT",
        "name": "Aigen im Ennstal",
        "lat": "47.51667",
        "lng": "14.13333"
    },
    {
        "country": "AT",
        "name": "Bad Kleinkirchheim",
        "lat": "46.81556",
        "lng": "13.78056"
    },
    {
        "country": "AT",
        "name": "Aichkirchen",
        "lat": "48.10944",
        "lng": "13.79158"
    },
    {
        "country": "AT",
        "name": "Aich",
        "lat": "47.4228",
        "lng": "13.82145"
    },
    {
        "country": "AT",
        "name": "Aibl",
        "lat": "46.68145",
        "lng": "15.22619"
    },
    {
        "country": "AT",
        "name": "Ahorn",
        "lat": "48.52382",
        "lng": "14.17408"
    },
    {
        "country": "AT",
        "name": "Aggsbach",
        "lat": "48.29404",
        "lng": "15.40382"
    },
    {
        "country": "AT",
        "name": "Aflenz Kurort",
        "lat": "47.54211",
        "lng": "15.23898"
    },
    {
        "country": "AT",
        "name": "Adnet",
        "lat": "47.69746",
        "lng": "13.13115"
    },
    {
        "country": "AT",
        "name": "Admont",
        "lat": "47.57537",
        "lng": "14.46075"
    },
    {
        "country": "AT",
        "name": "Adlwang",
        "lat": "47.99245",
        "lng": "14.21742"
    },
    {
        "country": "AT",
        "name": "Aderklaa",
        "lat": "48.28333",
        "lng": "16.53333"
    },
    {
        "country": "AT",
        "name": "Achenkirch",
        "lat": "47.52659",
        "lng": "11.70559"
    },
    {
        "country": "AT",
        "name": "Achau",
        "lat": "48.08026",
        "lng": "16.38611"
    },
    {
        "country": "AT",
        "name": "Abwinden",
        "lat": "48.25903",
        "lng": "14.42625"
    },
    {
        "country": "AT",
        "name": "Abtissendorf",
        "lat": "46.99583",
        "lng": "15.45639"
    },
    {
        "country": "AT",
        "name": "Abtenau",
        "lat": "47.56373",
        "lng": "13.34599"
    },
    {
        "country": "AT",
        "name": "Absam",
        "lat": "47.29572",
        "lng": "11.50593"
    },
    {
        "country": "AT",
        "name": "Abfaltersbach",
        "lat": "46.75715",
        "lng": "12.52828"
    },
    {
        "country": "AT",
        "name": "Schlaiten",
        "lat": "46.87944",
        "lng": "12.65417"
    },
    {
        "country": "AT",
        "name": "Thurn",
        "lat": "46.85056",
        "lng": "12.76861"
    },
    {
        "country": "AT",
        "name": "Untertilliach",
        "lat": "46.70417",
        "lng": "12.70861"
    },
    {
        "country": "AT",
        "name": "Finkenstein am Faaker See",
        "lat": "46.57904",
        "lng": "13.93496"
    },
    {
        "country": "AT",
        "name": "Sankt Jakob in Defereggen",
        "lat": "46.91972",
        "lng": "12.33139"
    },
    {
        "country": "AT",
        "name": "Attnang-Puchheim",
        "lat": "48.00833",
        "lng": "13.71667"
    },
    {
        "country": "AT",
        "name": "Obernberg am Brenner",
        "lat": "47.01667",
        "lng": "11.41667"
    },
    {
        "country": "AT",
        "name": "Mühlbachl",
        "lat": "47.13333",
        "lng": "11.45"
    },
    {
        "country": "AT",
        "name": "Ötztal-Bahnhof",
        "lat": "47.23333",
        "lng": "10.85"
    },
    {
        "country": "AT",
        "name": "Leutasch",
        "lat": "47.3689",
        "lng": "11.14404"
    },
    {
        "country": "AT",
        "name": "Maurach",
        "lat": "47.42124",
        "lng": "11.75305"
    },
    {
        "country": "AT",
        "name": "Gschnitz",
        "lat": "47.04469",
        "lng": "11.35089"
    },
    {
        "country": "AT",
        "name": "Navis",
        "lat": "47.11667",
        "lng": "11.53333"
    },
    {
        "country": "AT",
        "name": "Ellbögen",
        "lat": "47.16667",
        "lng": "11.45"
    },
    {
        "country": "AT",
        "name": "Sellrain",
        "lat": "47.21667",
        "lng": "11.21667"
    },
    {
        "country": "AT",
        "name": "Oberperfuss",
        "lat": "47.24451",
        "lng": "11.24755"
    },
    {
        "country": "AT",
        "name": "Igls",
        "lat": "47.23127",
        "lng": "11.41018"
    },
    {
        "country": "AT",
        "name": "Obsteig",
        "lat": "47.3",
        "lng": "10.93333"
    },
    {
        "country": "AT",
        "name": "Kolsass",
        "lat": "47.3",
        "lng": "11.63333"
    },
    {
        "country": "AT",
        "name": "Gnadenwald",
        "lat": "47.31667",
        "lng": "11.56667"
    },
    {
        "country": "AT",
        "name": "Ehrwald",
        "lat": "47.4",
        "lng": "10.91667"
    },
    {
        "country": "AT",
        "name": "Neue Heimat",
        "lat": "48.24565",
        "lng": "13.04172"
    },
    {
        "country": "AT",
        "name": "Gaumberg",
        "lat": "48.28085",
        "lng": "14.26935"
    },
    {
        "country": "AT",
        "name": "Berg",
        "lat": "48.29278",
        "lng": "14.24778"
    },
    {
        "country": "AT",
        "name": "Lanzendorf",
        "lat": "48.11056",
        "lng": "16.445"
    },
    {
        "country": "AT",
        "name": "Walserfeld",
        "lat": "47.79222",
        "lng": "12.98"
    },
    {
        "country": "AT",
        "name": "Lehen",
        "lat": "47.87083",
        "lng": "13.01722"
    },
    {
        "country": "AT",
        "name": "Ansfelden",
        "lat": "47.965",
        "lng": "13.04944"
    },
    {
        "country": "AT",
        "name": "Heinfels",
        "lat": "46.75",
        "lng": "12.45"
    },
    {
        "country": "AT",
        "name": "Kals am Großglockner",
        "lat": "47.00227",
        "lng": "12.64552"
    },
    {
        "country": "AT",
        "name": "Gerlosberg",
        "lat": "47.21667",
        "lng": "11.93333"
    },
    {
        "country": "AT",
        "name": "Gerlos",
        "lat": "47.22464",
        "lng": "12.03012"
    },
    {
        "country": "AT",
        "name": "Krimml",
        "lat": "47.21799",
        "lng": "12.17487"
    },
    {
        "country": "AT",
        "name": "Rohrberg",
        "lat": "47.23333",
        "lng": "11.91667"
    },
    {
        "country": "AT",
        "name": "Ried im Zillertal",
        "lat": "47.3",
        "lng": "11.86667"
    },
    {
        "country": "AT",
        "name": "Hinterglemm",
        "lat": "47.37685",
        "lng": "12.59583"
    },
    {
        "country": "AT",
        "name": "Alpbach",
        "lat": "47.39878",
        "lng": "11.94373"
    },
    {
        "country": "AT",
        "name": "Itter",
        "lat": "47.46667",
        "lng": "12.13333"
    },
    {
        "country": "AT",
        "name": "Brand",
        "lat": "47.10083",
        "lng": "9.73722"
    },
    {
        "country": "AT",
        "name": "Rain",
        "lat": "46.60528",
        "lng": "14.38611"
    },
    {
        "country": "AT",
        "name": "Kleinedling",
        "lat": "46.81417",
        "lng": "14.82778"
    },
    {
        "country": "AT",
        "name": "Liebenfels",
        "lat": "46.73778",
        "lng": "14.28667"
    },
    {
        "country": "AT",
        "name": "Frauenstein",
        "lat": "46.81417",
        "lng": "14.29417"
    },
    {
        "country": "AT",
        "name": "Eppenstein",
        "lat": "47.12833",
        "lng": "14.7375"
    },
    {
        "country": "AT",
        "name": "Hochleithen",
        "lat": "48.45317",
        "lng": "16.5309"
    },
    {
        "country": "AT",
        "name": "Höf",
        "lat": "47.13401",
        "lng": "15.49092"
    },
    {
        "country": "AT",
        "name": "Niederschöckl",
        "lat": "47.15",
        "lng": "15.5"
    },
    {
        "country": "AT",
        "name": "Sankt Oswald ob Eibiswald",
        "lat": "46.70833",
        "lng": "15.14667"
    },
    {
        "country": "AT",
        "name": "Wildschönau",
        "lat": "47.40619",
        "lng": "12.03784"
    },
    {
        "country": "AT",
        "name": "Langkampfen",
        "lat": "47.54878",
        "lng": "12.10127"
    },
    {
        "country": "AT",
        "name": "Hart bei Graz",
        "lat": "47.04311",
        "lng": "15.51527"
    },
    {
        "country": "AT",
        "name": "Berg im Drautal",
        "lat": "46.74915",
        "lng": "13.13536"
    },
    {
        "country": "AT",
        "name": "Lockenhaus",
        "lat": "47.40753",
        "lng": "16.41623"
    },
    {
        "country": "AT",
        "name": "St. Georgen am Längsee",
        "lat": "46.78061",
        "lng": "14.43033"
    },
    {
        "country": "AT",
        "name": "St. Martin of Tennengebirge",
        "lat": "47.465",
        "lng": "13.37761"
    },
    {
        "country": "AT",
        "name": "Afiesl",
        "lat": "48.58229",
        "lng": "14.12777"
    },
    {
        "country": "AT",
        "name": "Großkirchheim",
        "lat": "46.9727",
        "lng": "12.89589"
    },
    {
        "country": "AT",
        "name": "Damüls",
        "lat": "47.28034",
        "lng": "9.89164"
    },
    {
        "country": "AT",
        "name": "Bad Waltersdorf",
        "lat": "47.1696",
        "lng": "16.0087"
    },
    {
        "country": "AT",
        "name": "Bad Zell",
        "lat": "48.34906",
        "lng": "14.66945"
    },
    {
        "country": "AT",
        "name": "Auen",
        "lat": "46.59963",
        "lng": "13.84389"
    },
    {
        "country": "AT",
        "name": "Bad Leonfelden",
        "lat": "48.52047",
        "lng": "14.29459"
    },
    {
        "country": "AT",
        "name": "Pfarrwerfen",
        "lat": "47.45729",
        "lng": "13.20531"
    },
    {
        "country": "AT",
        "name": "Thal",
        "lat": "47.07644",
        "lng": "15.36052"
    },
    {
        "country": "AT",
        "name": "Bürserberg",
        "lat": "47.14644",
        "lng": "9.77736"
    },
    {
        "country": "AT",
        "name": "Holzgau",
        "lat": "47.26045",
        "lng": "10.34419"
    },
    {
        "country": "AT",
        "name": "Schröcken",
        "lat": "47.25755",
        "lng": "10.09197"
    },
    {
        "country": "AT",
        "name": "Nebelberg",
        "lat": "48.62854",
        "lng": "13.84629"
    },
    {
        "country": "AT",
        "name": "Ottnang am Hausruck",
        "lat": "48.09568",
        "lng": "13.65841"
    },
    {
        "country": "AT",
        "name": "Prottes",
        "lat": "48.3868",
        "lng": "16.7389"
    },
    {
        "country": "AT",
        "name": "Favoriten",
        "lat": "48.16116",
        "lng": "16.38233"
    },
    {
        "country": "AT",
        "name": "Sonnberg im Mühlkreis",
        "lat": "48.4608",
        "lng": "14.31167"
    },
    {
        "country": "AT",
        "name": "Unternberg",
        "lat": "47.11269",
        "lng": "13.74261"
    },
    {
        "country": "AT",
        "name": "Alberschwende",
        "lat": "47.45025",
        "lng": "9.83152"
    },
    {
        "country": "AT",
        "name": "Mellau",
        "lat": "47.35034",
        "lng": "9.88149"
    },
    {
        "country": "AT",
        "name": "Frastanz",
        "lat": "47.21735",
        "lng": "9.62995"
    },
    {
        "country": "AT",
        "name": "Kirchbichl",
        "lat": "47.51743",
        "lng": "12.09629"
    },
    {
        "country": "AT",
        "name": "Baierdorf-Umgebung",
        "lat": "47.28511",
        "lng": "15.71045"
    },
    {
        "country": "AT",
        "name": "Sonntag",
        "lat": "47.23857",
        "lng": "9.89765"
    },
    {
        "country": "AT",
        "name": "Bärnbach",
        "lat": "47.07141",
        "lng": "15.12792"
    },
    {
        "country": "AT",
        "name": "Längenfeld",
        "lat": "47.07398",
        "lng": "10.96951"
    },
    {
        "country": "AT",
        "name": "Haus im Ennstal",
        "lat": "47.41058",
        "lng": "13.76759"
    },
    {
        "country": "AT",
        "name": "Hall bei Admont",
        "lat": "47.587",
        "lng": "14.46372"
    },
    {
        "country": "AT",
        "name": "Vandans",
        "lat": "47.09569",
        "lng": "9.86525"
    },
    {
        "country": "AT",
        "name": "Ertl",
        "lat": "47.97704",
        "lng": "14.63134"
    },
    {
        "country": "AT",
        "name": "Schwarzenberg",
        "lat": "47.41415",
        "lng": "9.85154"
    },
    {
        "country": "AT",
        "name": "Hittisau",
        "lat": "47.45781",
        "lng": "9.95962"
    },
    {
        "country": "AT",
        "name": "Micheldorf in Kärnten",
        "lat": "46.91465",
        "lng": "14.42733"
    },
    {
        "country": "AT",
        "name": "Schärding Vorstadt",
        "lat": "48.45195",
        "lng": "13.43946"
    },
    {
        "country": "AT",
        "name": "Neuberg im Burgenland",
        "lat": "47.16894",
        "lng": "16.26054"
    },
    {
        "country": "AT",
        "name": "Schönkirchen-Reyersdorf",
        "lat": "48.35477",
        "lng": "16.69115"
    },
    {
        "country": "AT",
        "name": "Klein-Neusiedl",
        "lat": "48.09379",
        "lng": "16.60661"
    },
    {
        "country": "AT",
        "name": "Eggendorf",
        "lat": "47.85809",
        "lng": "16.32182"
    },
    {
        "country": "AT",
        "name": "Seitenberg",
        "lat": "46.74801",
        "lng": "14.08825"
    },
    {
        "country": "AT",
        "name": "Dünserberg",
        "lat": "47.22752",
        "lng": "9.72372"
    },
    {
        "country": "AT",
        "name": "Aschau im Zillertal",
        "lat": "47.26613",
        "lng": "11.89536"
    },
    {
        "country": "AT",
        "name": "Assling",
        "lat": "46.78663",
        "lng": "12.63951"
    },
    {
        "country": "AT",
        "name": "Thiersee",
        "lat": "47.59424",
        "lng": "12.11758"
    },
    {
        "country": "AT",
        "name": "Mitterndorf",
        "lat": "47.57728",
        "lng": "12.16968"
    },
    {
        "country": "AT",
        "name": "Grinzens",
        "lat": "47.22927",
        "lng": "11.25318"
    },
    {
        "country": "AT",
        "name": "Mauer",
        "lat": "46.74104",
        "lng": "14.22937"
    },
]