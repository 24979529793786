import { MyDropdown } from './Dropdown/Dropdown';

import { cities } from './cities';
import { dd } from './dd';
console.log('cities', cities);
const countries = {};

cities.forEach(city => {
    countries[city.country] = true;
})
const countriesList = Object.keys(countries);

console.log('!!!!!!!!!!!!', cities, countriesList);

const cuntMapping = {
    "AM": 'Armenia',
    "AT": 'Austria',
    "AM": '',
    "AM": '',
    "AM": '',
    "AM": '',
};

const countriesOptions = countriesList.map(cunt => {
    return {
        id: cunt,
        label: cuntMapping[cunt] || cunt,
    }
});

let cityIds = 0;

const citiesOptions = cities.map(city => {
    return {
        id: ++cityIds,
        label: city.name,
        parent_id: city.country,
    }
})

const systemsOptions = [];
let systemIds = 0;
citiesOptions.forEach(city => {
    const rand = Math.random();
    systemsOptions.push({
        id: ++systemIds,
        label: city.label + ' bus',
        parent_id: city.id,
    })
    if (rand > 0.6) {
        systemsOptions.push({
            id: ++systemIds,
            label: city.label + ' trolleybus',
            parent_id: city.id,
        })
    }
    if (rand > 0.9) {
        systemsOptions.push({
            id: ++systemIds,
            label: city.label + ' tram',
            parent_id: city.id,
        })
    }
})

const cc = [{ options: countriesOptions }, { options: citiesOptions }, { options: systemsOptions }];



export const DropdownTest = () => {
    return <div style={{ width: 300, margin: 30 }}>
        <h4>Dropdown example</h4><br /><br />
        <MyDropdown optionLists={cc} pleaseSelectMessage="Будь-ласка, оберіть систему" initialValue={1488} />
        <br /><br />
        Total: {systemsOptions.length} items
    </div>;
}