import React from 'react';

export const Photo = ({ card }) => {
    return (
        <div className="SinglePhotoComponent">
            <img src={card.thumbPath} alt={card.description} className="img" />
            <img src={card.thumbPath} className="img-bg" />
            <p className='card-location'>
                <span>{card.administrativeEntity.localName}, </span>
                {card.place.name}
            </p>
            <div className="card-bottom">
                {/* {card.photoVehicleRecords.length>0?
                <p>
                    {card.photoVehicleRecords[0].vehicleRecord.model.modelName}{' '}
                    <span className='number'>{(card.photoVehicleRecords.length>0&&card.photoVehicleRecords[0].vehicleRecord.number)?card.photoVehicleRecords[0].vehicleRecord.number:card.photoVehicleRecords[0].vehicleRecord.licencePlate}</span>
                </p>:<></>} */}



                <div className={card.countOfComments?"comments ":"comments coments-empty"}>
                    <div className="comments-ico"></div>
                    <span>{card.countOfComments}</span>
                    </div>
            </div>
            <div className="border"></div>
        </div>
    );
};
