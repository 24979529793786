import { useEffect, useState } from 'react';
import { getPhotos } from '../../api';
import { Photo } from './Photo';
import './photos.less';

export const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [photosFiltered, setPhotosFiltered] = useState([]);
    useEffect(() => {
        getPhotos().then(res => {
            setPhotos(res.result);
            setPhotosFiltered(res.result);
        });
    }, []);
    return (
        <div className="PhotosComponent">
            <div className="filters">
                <div className="logo">Фотогалерея</div>
                <select name="city">
                    <option value="value1" defaultValue>Всі види транспорту</option>
                    <option value="value2">Трамвай</option>
                    <option value="value3">Тролейбус</option>
                </select>
                <select name="transport-type">
                    <option value="value1" defaultValue>Всі міста</option>
                    <option value="value2">Львів</option>
                    <option value="value3">Київ</option>
                </select>
            </div>
            <div className="filters">
                <label htmlFor="description">
                    <input type="checkbox" />
                    Опис фотографії
                </label>
                <div className="switcher">Плитка / На карті</div>
                <select name="" id="">
                    <option value="value1" defaultValue>За рейтингом</option>
                    <option value="value2">По даті</option>
                    <option value="value3">Автор</option>
                </select>
            </div>
            <div className="wrapper">
            {photosFiltered.map((p, i) => (
                        <Photo card={p} key={i} />
                ))}
            </div>
        </div>
    );
};
