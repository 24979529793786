import { some, debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useOnClickOutside } from './useClickOutside';

import './Dropdown.less';

const prepare_data = optionLists => {
    if (optionLists.length === 2) {
        const parentOptions = optionLists[0].options;
        const childOptions = optionLists[1].options || optionLists[1];
        if (!childOptions) debugger;
        childOptions.forEach(o => {
            const parentO = parentOptions.find(p => p.id === o.parent_id);
            if (!parentO) return;
            if (!parentO.children) {
                parentO.children = [];
            }
            if (!parentO.children.find(v => v.id === o.id)) {
                parentO.children.push(o);
            }
        });
        return parentOptions;
    } else {
        return prepare_data([optionLists[0], prepare_data(optionLists.slice(1))]);
    }
};

const matches = (str, options) => {
    return some(options, o => {
        return (o.children ? matches(str, o.children) : false) || o.label.includes(str);
    });
};

const get_oid = (o, level) => level + '_|_' + o.id;

function OptionLayer({ options, level, openStateMap, toggle, search, shownMap, setValue, maxLevel, value }) {
    return (
        <div className="OptionLayerComponent">
            {options.map(o => {
                const oid = get_oid(o, level);
                const hasChildren = !!o.children?.length;
                const isShown = !search || shownMap[oid];
                const isOpen = hasChildren && !!openStateMap[oid];
                const isFinalLevel = level === maxLevel;
                const isSelected = value && o.id === value?.option?.id && level === value.level;
                if (!isShown) return null;
                return (
                    <div>
                        <div className={'option'}>
                            {hasChildren && (
                                <div className="optionExpander" onClick={() => toggle(oid)}>
                                    <div className={isOpen ? 'openedOption' : 'closedOption'} />
                                </div>
                            )}
                            {!hasChildren && <div className="optionExpanderSubstitute" />}
                            <div
                                onClick={() => setValue(o, level)}
                                className={'optionLabel ' + (isSelected ? 'selected' : '')}
                            >
                                {o.label}
                            </div>
                        </div>
                        {isOpen && (
                            <OptionLayer
                                options={o.children}
                                level={level + 1}
                                openStateMap={openStateMap}
                                toggle={toggle}
                                search={search}
                                shownMap={shownMap}
                                setValue={setValue}
                                maxLevel={maxLevel}
                                value={value}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

const eachMatchingOption = (options, predicate, cb, level = 1) => {
    let matches = false;
    options.forEach(o => {
        const childrenMatch = o.children && eachMatchingOption(o.children, predicate, cb, level + 1);
        if (predicate(o) || childrenMatch) {
            cb(o, level);
            matches = true;
        }
    });
    return matches;
};
const anyMatchingOption = (options, predicate, cb, level = 1) => {
    for (let o of options) {
        const match =
            predicate(o, level) || (o.children ? anyMatchingOption(o.children, predicate, cb, level + 1) : false);
        if (match) {
            cb(o, level);
            return true;
        }
    }
    return false;
};

export const MyDropdown = ({ optionLists, pleaseSelectMessage, initialValue, onChange }) => {
    const [isListOpen, setListOpen] = useState(false);
    const [isSearchInProgress, setSearchInProgress] = useState(false);
    const [openStateMap, setOpenStateMap] = useState({});
    const [shownMap, setShownMap] = useState({});
    const [search, setSearchInner] = useState('');
    const [value, setValueInner] = useState();
    const rootRef = useRef();
    const inputRef = useRef();
    const optionsListRef = useRef();
    const isMultiple = false;

    const tree = useMemo(() => prepare_data(optionLists), [optionLists]);

    const toggleOption = useCallback(oid => {
        setOpenStateMap(map => {
            return { ...map, [oid]: !map[oid] };
        });
    }, []);

    const closeList = useCallback(
        openMap => {
            setListOpen(false);
            setOpenStateMap(openMap || (value ? value.openMap : {}));
            setSearch('');
        },
        [value],
    );

    const openList = useCallback(() => {
        setListOpen(true);
    }, []);

    useEffect(() => {
        if (isListOpen) {
            inputRef.current.focus();
            const selectedEl = rootRef.current.querySelector('.list .selected');
            if (selectedEl) {
                optionsListRef.current.scroll(0, selectedEl?.offsetTop - 100);
            }
        }
    }, [isListOpen]);

    useEffect(() => {
        if (initialValue) {
            for (let k in optionLists) {
                const initialOption = optionLists[k].options.find(o => o.id === initialValue);
                if (initialOption) {
                    setValue(initialOption, Number(k) + 1);
                    return;
                }
            }
        }
    }, []);

    const setValue = useCallback((option, level) => {
        const openMap = {};
        anyMatchingOption(
            tree,
            (o, lvl) => {
                return o.id === option.id && level === lvl;
            },
            (o, level) => {
                const oid = get_oid(o, level);
                openMap[oid] = true;
            },
        );
        setValueInner({ option, openMap, level });
        onChange && onChange(option.id);
        closeList(openMap);
    }, []);

    const updateOptionsAccordingToSearch = useMemo(
        () =>
            debounce(
                str => {
                    const shownMap = {};
                    eachMatchingOption(
                        tree,
                        o => o.label.toLowerCase().includes(str.toLowerCase()),
                        (o, level) => {
                            const oid = get_oid(o, level);
                            shownMap[oid] = true;
                        },
                    );
                    setSearchInProgress(false);
                    setShownMap(shownMap);
                    setOpenStateMap({ ...shownMap });
                },
                500,
                { leading: false, trailing: true },
            ),
        [tree],
    );

    const setSearch = useCallback(
        str => {
            if (!str) {
                setSearchInner(str);
                return;
            }
            setSearchInProgress(true);
            updateOptionsAccordingToSearch(str);
            setSearchInner(str);
        },
        [tree, search],
    );

    useOnClickOutside(rootRef, () => closeList());

    const nothingFound = search && !Object.keys(shownMap).length;

    return (
        <div className="DropdownComponent" ref={rootRef}>
            <div className="picker" onClick={() => !isListOpen && openList()}>
                {isListOpen && (
                    <>
                        <div className="search">
                            <input onChange={e => setSearch(e.target.value)} value={search} ref={inputRef} />
                        </div>
                        {!!search && (
                            <div
                                className="erase"
                                onClick={() => {
                                    setSearch('');
                                    inputRef.current.focus();
                                }}
                            ></div>
                        )}
                    </>
                )}
                {!isListOpen && (
                    <>
                        <div className="value">{value ? value?.option.label : pleaseSelectMessage || ''}</div>
                        <div className="openCloseWrapper">{!isListOpen && <div className="openButton"></div>}</div>
                    </>
                )}
            </div>
            {isListOpen && (
                <div className="listWrapper">
                    <div className="list">
                        <div className="options" ref={optionsListRef}>
                            <OptionLayer
                                options={tree}
                                level={1}
                                openStateMap={openStateMap}
                                toggle={toggleOption}
                                search={search}
                                shownMap={shownMap}
                                setValue={setValue}
                                maxLevel={optionLists.length}
                                value={value}
                            />
                            {isSearchInProgress && <div className="nothingFound">Loading...</div>}
                            {nothingFound && !isSearchInProgress && (
                                <div className="nothingFound">No items matching your search</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
