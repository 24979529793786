import axios from 'axios';

const API_URL = 'http://51.158.167.89';

export const getPhotos = () => {
    return axios(API_URL + '/photobase').then(res => res.data);
};
export const getCoutries = () => {
    return axios('http://51.158.167.89/dropdown/GetAllAdministrativeEntities').then(res => res.data);
};
